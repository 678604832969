import React, { useState } from 'react'
import { connect } from 'react-redux';
import { PaymentAction } from '../../../_redux/actions';
import { Button, Box, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { AddUpdateCreditCard } from '../credit-card/AddUpdateCreditCard';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalHeader } from '../../../_components/modal';

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        marginBottom: theme.spacing(2),
        borderBottom: `1px solid #b0bec5`
    },
    headIcon: {
        paddingRight: theme.spacing(1)
    },
    headTitle: {
        fontWeight: 900,
        paddingBottom: theme.spacing(1),
    },
    headButton: {
        margin: '0 5px',
    },
    paymentPreferenceBox: {
        padding: theme.spacing(2)
    },
    paymentPreferenceContainer: {
        // marginBottom: theme.spacing(2)
    },
    paymentPrefercenceTitle: {
        paddingBottom: theme.spacing(1)
    },
    paymentMethodTitle: {
        marginTop: theme.spacing(2)
    },
    table: {
        marginBottom: theme.spacing(2)
    },
    tableHeader: {
        backgroundColor: `#b3e5fc`
    }
}));

function CreditCardDetails({ loading, cardDetails, deleteCreditCard, getPaymentDetails }) {

    const classes = useStyles();

    const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false)

    const deleteCard = (e) => {
        e.preventDefault();
        setShowDeleteWarningModal(true);
        // deleteCreditCard().then(() => { getPaymentDetails() });
    }

    const onHide = () => {
        setShowDeleteWarningModal(false);
    }

    const deleteCreditCardAction = () => {
        deleteCreditCard().then(() => {
            getPaymentDetails();
            setShowDeleteWarningModal(false);
        })
    }



    return (
        <Box className={classes.paymentPreferenceBox}>

            <ConfzModal isModalOpen={showDeleteWarningModal} onHide={onHide} loader={false} size="md">
                <ConfzModalHeader id="AddUpdateDeals" title="Remove Credit Card" onHide={onHide} />
                <ConfzModalBody className="text-center">
                    <h1>Are You Sure?</h1>
                    <p>{`Do you want to remove this credit card?`}</p>
                </ConfzModalBody>
                <ConfzModalFooter>
                    <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onHide}>Cancel</Button>
                    <Button variant="contained" color="primary" size="small" className={classes.button} onClick={deleteCreditCardAction}>Confirm</Button>
                </ConfzModalFooter>
            </ConfzModal>

            <TableContainer >
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow className={classes.tableHeader}>
                            <TableCell>Credit Card</TableCell>
                            <TableCell>Name on Card</TableCell>
                            <TableCell>Expires On</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {!loading && !cardDetails && (
                            <TableRow>
                                <TableCell colSpan={4}>Card not added</TableCell>
                            </TableRow>
                        )}

                        {!loading && cardDetails && (
                            <TableRow >
                                <TableCell style={{ textTransform: `capitalize` }}>{cardDetails.card.brand} ending in {cardDetails.card.last4}</TableCell>
                                <TableCell>{cardDetails.billing_details.name}</TableCell>
                                <TableCell>{cardDetails.card.exp_month}/{cardDetails.card.exp_year}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="secondary" size="small" className={classes.button} startIcon={<Delete />} onClick={deleteCard}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        )}

                    </TableBody>
                </Table>
            </TableContainer>
            {!loading && !cardDetails && <AddUpdateCreditCard />}
        </Box>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.PaymentReducer.payment_details_loading,
        cardDetails: state.PaymentReducer.payment_details.stripe_card_response
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteCreditCard: () => dispatch(PaymentAction.deleteCreditCard()),
        getPaymentDetails: () => dispatch(PaymentAction.getPaymentDetails()),
    }
};

const connectedCreditCardDetails = connect(mapStateToProps, mapDispatchToProps)(CreditCardDetails);
export { connectedCreditCardDetails as CreditCardDetails };