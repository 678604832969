import {
    GET_COUPONS_REQUEST, GET_COUPONS_SUCCESS, GET_COUPONS_FAILURE,
    DELETE_COUPONS_REQUEST, DELETE_COUPONS_SUCCESS, DELETE_COUPONS_FAILURE,
    GET_COUPON_REQUEST, GET_COUPON_SUCCESS, GET_COUPON_FAILURE,
    UPDATE_STATUS_REQUEST, UPDATE_STATUS_SUCCESS, UPDATE_STATUS_FAILURE,
    SET_PAGE, 
    ADD_COUPON_COUNT_REQUEST, ADD_COUPON_COUNT_SUCCESS, ADD_COUPON_COUNT_FAILURE,
    GET_COUPON_COUNT_REQUEST, GET_COUPON_COUNT_SUCCESS, GET_COUPON_COUNT_FAILURE,
    CREATE_COUPONS_REQUEST, CREATE_COUPONS_SUCCESS, CREATE_COUPONS_FAILURE,
    GET_CUSTOM_COUPONS_REQUEST, GET_CUSTOM_COUPONS_SUCCESS, GET_CUSTOM_COUPONS_FAILURE,
    CREATE_SINGLE_USE_CODES_REQUEST, CREATE_SINGLE_USE_CODES_SUCCESS, CREATE_SINGLE_USE_CODES_FAILURE,
    GET_CUSTOM_COUPON_CAMPAIGNS_REQUEST, GET_CUSTOM_COUPON_CAMPAIGNS_SUCCESS, GET_CUSTOM_COUPON_CAMPAIGNS_FAILURE,
    GET_CUSTOM_CODES_REQUEST, GET_CUSTOM_CODES_SUCCESS, GET_CUSTOM_CODES_FAILURE,

} from "../actions";

const initialState = {
    page: 1, per_page: 18, total: 0, total_pages: 0,
    coupon_loading: false, coupons: [], coupon_error: [],
    total_coupons: 0, issued_coupons: 0, used_coupons: 0, balance_coupons: 0,
    minimum_coupons_configured:0,
    create_coupons_loading: false, create_coupons: [], create_coupons_error: [],
    custom_coupons_loading: false, dcustom_coupons: [], custom_coupons_error: [],
    create_single_use_codes_loading: false, create_single_use_codes: [], create_single_use_codes_error: [],
    custom_coupon_campaigns_loading: false, custom_coupon_campaigns: [], custom_coupon_campaigns_error: [],
    custom_codes_loading: false, custom_codes: [], custom_codes_error: [],
};

export function CouponReducer(state = initialState, action) {
    switch (action.type) {
        /** begin::Coupon redux */
        case GET_COUPON_REQUEST: {
            return {
                ...state,
                coupon_error: []
            };
        } case GET_COUPON_SUCCESS: {
            return {
                ...state,
                // coupons: action.data.coupons,
                // coupon_error: [],
                // page: action.data.page,
                // per_page: action.data.per_page,
                // total: action.data.total,
                // total_pages: action.data.total_pages,
            };
        } case GET_COUPON_FAILURE: {
            return {
                ...state,
            };
        }
        /** end::Coupon redux */

        /** begin::Coupon redux */
        case GET_COUPONS_REQUEST: {
            return {
                ...state,
                coupon_loading: true,
                coupons: [],
                coupon_error: []
            };
        } case GET_COUPONS_SUCCESS: {
            return {
                ...state,
                coupon_loading: false,
                coupons: action.data.coupons,
                coupon_error: [],
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages,
                total_coupons: action.data.total,
                used_coupons: action.data.used_coupons,
                issued_coupons: action.data.issued_coupons,
                balance_coupons: action.data.balance_coupons,
            };
        } case GET_COUPONS_FAILURE: {
            return {
                ...state,
                coupon_loading: false,
                coupons: [],
                coupon_error: []
            };
        }
        /** end::Coupon redux */

        /** begin::Coupon delete redux */
        case DELETE_COUPONS_REQUEST: {
            return {
                ...state,
                coupon_loading: true,
                coupon_error: []
            };
        } case DELETE_COUPONS_SUCCESS: {
            // const coupons = state.coupons.filter((coupon, i) => coupon.en_id !== action.id)
            return {
                ...state,
                coupon_loading: false,
                // coupons: coupons,
                coupon_error: [],
                // total: (state.total - 1),
            };
        } case DELETE_COUPONS_FAILURE: {
            return {
                ...state,
                coupon_loading: false,
                coupons: [],
                coupon_error: []
            };
        }
        /** end::Coupon delete redux */

        /** begin::Coupon delete redux */
        case UPDATE_STATUS_REQUEST: {
            return {
                ...state,
                coupon_error: []
            };
        } case UPDATE_STATUS_SUCCESS: {

            const couponIndex = state.coupons.findIndex(coupon => coupon._id === action.data._id); //finding index of the item
            let newCouponsArray = [...state.coupons]; //making a new array
            if (couponIndex !== -1) {
                newCouponsArray[couponIndex].status = action.data.status//changing value in the new array
                // newCouponsArray[couponIndex].isLoading = false;
            } else {
                newCouponsArray = newCouponsArray.concat(action.data);
            }

            return {
                ...state,
                coupons: newCouponsArray,
                coupon_error: [],
            };
        } case UPDATE_STATUS_FAILURE: {
            return {
                ...state,
                coupon_error: []
            };
        }
        /** end::Coupon delete redux */

        case SET_PAGE: {
            return {
                ...state,
                page: action.page
            };
        }

        /** begin::Min Coupon redux */
        case ADD_COUPON_COUNT_REQUEST: {
            return {
                ...state,
                coupon_loading: true,
                minimum_coupons_configured: 0,
                coupon_error: []
            };
        } case ADD_COUPON_COUNT_SUCCESS: {
            return {
                ...state,
                coupon_loading: false,
                minimum_coupons_configured: action.data.minimum_coupons_configured,
                coupon_error: [],
            };
        } case ADD_COUPON_COUNT_FAILURE: {
            return {
                ...state,
                coupon_loading: false,
                minimum_coupons_configured: 0,
                coupon_error: []
            };
        }

        /** begin::Min Coupon redux */
        case GET_COUPON_COUNT_REQUEST: {
            return {
                ...state,
                coupon_loading: true,
                coupon_error: []
            };
        } case GET_COUPON_COUNT_SUCCESS: {
            return {
                ...state,
                coupon_loading: false,
                minimum_coupons_configured: action.data.minimum_coupons_configured,
            };
        } case GET_COUPON_COUNT_FAILURE: {
            return {
                ...state,
                coupon_loading: false,
                coupon_error: []
            };
        }
        /** end::Min Coupon redux */

        /** begin::Create coupons redux */
        case CREATE_COUPONS_REQUEST: {
            return {
                ...state,
                create_coupons_loading: true,
                create_coupons_error: {}
            };
        } case CREATE_COUPONS_SUCCESS: {
            return {
                ...state,
                create_coupons_loading: false,
                create_coupons: action.data,
                create_coupons_error: {}
            };
        } case CREATE_COUPONS_FAILURE: {
            return {
                ...state,
                create_coupons_loading: false,
                create_coupons_error: action.error
            };
        }
        /** end::Create coupons redux */

        /** begin::Custom coupons redux */
        case GET_CUSTOM_COUPONS_REQUEST: {
            return {
                ...state,
                custom_coupons_error: [],
                custom_coupons_loading: true,
            };
        } case GET_CUSTOM_COUPONS_SUCCESS: {
            return {
                ...state,
                custom_Coupons_deal: action.data,
                custom_coupons_loading: false,
            };
        } case GET_CUSTOM_COUPONS_FAILURE: {
            return {
                ...state,
                custom_coupons_loading: false,
                custom_coupons_error: action.error
            };
        }
        /** end::Custom coupons redux */

        /** begin::Create single use codes redux */
        case CREATE_SINGLE_USE_CODES_REQUEST: {
            return {
                ...state,
                create_single_use_codes_loading: true,
                create_single_use_codes_error: {}
            };
        } case CREATE_SINGLE_USE_CODES_SUCCESS: {
            return {
                ...state,
                create_single_use_codes_loading: false,
                create_single_use_codes: action.data,
                create_single_use_codes_error: {}
            };
        } case CREATE_SINGLE_USE_CODES_FAILURE: {
            return {
                ...state,
                create_single_use_codes_loading: false,
                create_single_use_codes_error: action.error
            };
        }
        /** end::Create single use codes redux */
        
        /** begin::Custom coupons redux */
        case GET_CUSTOM_COUPON_CAMPAIGNS_REQUEST: {
            return {
                ...state,
                custom_coupon_campaigns_error: [],
                custom_coupon_campaigns_loading: true,
            };
        } case GET_CUSTOM_COUPON_CAMPAIGNS_SUCCESS: {
            return {
                ...state,
                custom_coupon_campaigns: action.data.campaigns,
                custom_coupon_campaigns_loading: false,
            };
        } case GET_CUSTOM_COUPON_CAMPAIGNS_FAILURE: {
            return {
                ...state,
                custom_coupon_campaigns_loading: false,
                custom_coupons_error: action.error,
                custom_coupon_campaigns: []
            };
        }
        /** end::Custom coupons redux */

        /** begin::Coupon redux */
        case GET_CUSTOM_CODES_REQUEST: {
            return {
                ...state,
                custom_codes_loading: true,
                custom_codes: [],
                custom_codes_error: []
            };
        } case GET_CUSTOM_CODES_SUCCESS: {
            return {
                ...state,
                custom_codes_loading: false,
                custom_codes: action.data.coupons,
                custom_codes_error: [],
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages
            };
        } case GET_CUSTOM_CODES_FAILURE: {
            return {
                ...state,
                custom_codes_loading: false,
                custom_codes: [],
                custom_codes_error: []
            };
        }
        default:
            return state;
    }
}