import { authHeader, handleResponse, notify } from "../../_helpers";
import Config from "../../_helpers/config";

export const DesignationServices = { getDesignation, getDesignations, deleteDesignations, addDesignations, editDesignations, importDesignations };


/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function getDesignation(id) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';
    return fetch(`${Config.endpoint}/master/designation/${id}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function getDesignations(page) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    return fetch(`${Config.endpoint}/master/designation?page=${page}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}
/**
 * Service for delete designation 
 *
 * @author Akshay 
 */
function deleteDesignations(id) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'DELETE';

    return fetch(`${Config.endpoint}/master/designation/${id}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}
/**
 * Service for add designation 
 *
 * @author Akshay 
 */
function addDesignations(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);

    return fetch(`${Config.endpoint}/master/designation`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for add designation 
 *
 * @author Akshay 
 */
function editDesignations(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'PUT';
    requestOptions.body = JSON.stringify(params);

    return fetch(`${Config.endpoint}/master/designation/${params.designation_id}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for import designations
 *
 * @author Akshay 
 */
function importDesignations(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    // requestOptions.headers[`content-type`] = 'multipart/form-data';
    delete requestOptions.headers['Content-Type'];
    requestOptions.method = 'POST';
    requestOptions.body = (params);

    console.log('requestOptions');
    console.log(requestOptions);
    console.log('requestOptions');

    return fetch(`${Config.endpoint}/master/designation/import`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}