import React from 'react'
import { connect } from 'react-redux';
import { ShoppersAction } from '../../../_redux/actions';
import { SummaryWidget } from '../../../_components/summary-widget';

function TotalReach({ getTotalReach, isLoading, total_reach, ...props }) {

    return <SummaryWidget loading={isLoading} text={'Total Reach'} value={total_reach} bgColor={"#a852f7"} color={"#ffffff"} />

}


function mapStateToProps(state) {
    return {
        isLoading: state.ShoppersReducer.total_reach_loading,
        total_reach: state.ShoppersReducer.total_reach,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTotalReach: dispatch(ShoppersAction.getTotalReach())
    }
};

const connectedCoupon = (connect(mapStateToProps, mapDispatchToProps)(TotalReach));
export { connectedCoupon as TotalReach };
