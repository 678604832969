import React from 'react';
import PropTypes from 'prop-types'
import './FormSwitch.scss';

function FormSwitch({ label, data, name, value, placeholder, className, onChange, error, optionLabels, disabled, checked, id, ...props }) {
    const [toggled, setToggled] = React.useState(value);
    const [isGiveAway, setIsGiveAway] = React.useState(!value);

    const handleSelectChange = (e, a) => {
        setToggled(!e.target.checked);
        setIsGiveAway(!isGiveAway);

        let target = {
            'name': name,
            'value': isGiveAway,
            'type': 'switch'
        }
        onChange({ target })
    }
    return (
        <div className={`form-group ${className}`}>
            <label htmlFor={name}>{label}</label>
            <div className={"toggle-switch"}>
                <input
                    type="checkbox"
                    name={name}
                    className="toggle-switch-checkbox"
                    id={name}
                    checked={!toggled}
                    onChange={handleSelectChange}
                    disabled={disabled}
                />
                {name ? (
                    <label
                        className="toggle-switch-label"
                        tabIndex={disabled ? -1 : 1}
                        htmlFor={name}
                    >
                        <span
                            className={
                                disabled
                                    ? "toggle-switch-inner toggle-switch-disabled"
                                    : "toggle-switch-inner"
                            }
                            data-yes={optionLabels[0]}
                            data-no={optionLabels[1]}
                            tabIndex={-1}
                        />
                        <span
                            className={
                                disabled
                                    ? "toggle-switch-switch toggle-switch-disabled"
                                    : "toggle-switch-switch"
                            }
                            tabIndex={-1}
                        ><span className="switch-label"></span></span>
                    </label>
                ) : null}
            </div>
        </div>
    )
}

FormSwitch.defaultProps = {
    className: "",
    optionLabels: ["Yes", "No"],
}

FormSwitch.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
    id: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    optionLabels: PropTypes.array,
    disabled: PropTypes.bool
}

export { FormSwitch }
