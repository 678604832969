import React from 'react'
import { connect } from 'react-redux';
import { UserAction } from '../../../_redux/actions';
import { SummaryWidget } from '../../../_components/summary-widget';

function TotalProfileViews({ isLoading, total_profile_views }) {

    return <SummaryWidget loading={isLoading} text={'Profile Views'} value={total_profile_views ?? 0} bgColor={"#0ec164"} color={"#ffffff"} subText={'In the past 30 days'} />

}


function mapStateToProps(state) {
    return {
        isLoading: state.UserReducer.total_profile_views_loading,
        total_profile_views: state.UserReducer.total_profile_views,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTotalProfileViews: dispatch(UserAction.getTotalProfileViews())
    }
};

const connectedTotalProfileViews = (connect(mapStateToProps, mapDispatchToProps)(TotalProfileViews));
export { connectedTotalProfileViews as TotalProfileViews };
