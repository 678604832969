import { Grid, Paper } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core'
import { PageTitle } from '../../_layouts/page-title';
import { IgInsightsAction } from '../../_redux/actions';
import { ImpressionsCount, NewFollowersCount, ProfileViewsCount, ReachCount, DcVsNewFollowers, DcVsImpression, DcVsProfileViews, DcVsReachCount } from '../ig-insights';

const useStyles = makeStyles(() => ({
    widgetGrid: {
        padding: "15px"
    }
}))


function IgInsights({ loading, ig_insights, dc_loading, daily_coupons }) {

    const { follower_count, impressions, profile_views, reach } = ig_insights;

    const classes = useStyles()

    return (
        <React.Fragment>
            <PageTitle title="Insights" />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container className={`${classes.widgetGrid}`}>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <NewFollowersCount isLoading={loading} new_followers={follower_count ?? 0} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <ImpressionsCount isLoading={loading} impressions={impressions ?? 0} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <ProfileViewsCount isLoading={loading} profile_views={profile_views ?? 0} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <ReachCount isLoading={loading} reach={reach ?? 0} />
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <WebsiteClicksCount isLoading={loading} website_clicks={website_clicks ?? 0}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <PhoneClicksCount isLoading={loading} phone_call_clicks={phone_call_clicks ?? 0}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <TextMessageClicksCount isLoading={loading} text_message_clicks={text_message_clicks ?? 0}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <EmailClicksCount isLoading={loading} email_contacts={email_contacts ?? 0}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <DirectionClicksCount isLoading={loading} get_directions_clicks={get_directions_clicks ?? 0}/>
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <DcVsNewFollowers isLoading={loading || dc_loading} new_followers={follower_count ?? 0} daily_coupons={daily_coupons ?? 0} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <DcVsImpression isLoading={loading} impressions={impressions ?? 0} daily_coupons={daily_coupons ?? 0} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <DcVsProfileViews isLoading={loading} profile_views={profile_views ?? 0} daily_coupons={daily_coupons ?? 0} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <DcVsReachCount isLoading={loading} reach={reach ?? 0} daily_coupons={daily_coupons ?? 0} />
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <DcVsWebsiteClicks isLoading={loading} website_clicks={website_clicks ?? 0} daily_coupons={daily_coupons ?? 0}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <DcVsPhoneClicks isLoading={loading} phone_call_clicks={phone_call_clicks ?? 0} daily_coupons={daily_coupons ?? 0}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <DcVsTextMessageClicks isLoading={loading} text_message_clicks={text_message_clicks ?? 0} daily_coupons={daily_coupons ?? 0}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <DcVsEmailClicks isLoading={loading} email_contacts={email_contacts ?? 0} daily_coupons={daily_coupons ?? 0}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                <DcVsDirectionClicks isLoading={loading} get_directions_clicks={get_directions_clicks ?? 0} daily_coupons={daily_coupons ?? 0}/>
                            </Grid> */}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.IgInsightsReducer.ig_insights_loading,
        ig_insights: state.IgInsightsReducer.ig_insights,
        dc_loading: state.IgInsightsReducer.ig_insights_daily_coupons_loading,
        daily_coupons: state.IgInsightsReducer.ig_insights_daily_coupons,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getIgInsights: dispatch(IgInsightsAction.getIgInsights()),
        getDailyCouponCount: dispatch(IgInsightsAction.getDailyCouponCount())
    }
};

const connectedIgInsights = (connect(mapStateToProps, mapDispatchToProps)(IgInsights));
export { connectedIgInsights as IgInsights };
