import React, { createRef, useEffect, useState } from 'react'
import { connect } from 'react-redux';

import { Button, makeStyles } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { CSVLink } from "react-csv";
import { ShoppersAction } from '../../../_redux/actions';

const useStyles = makeStyles((theme) => ({
    headButton: {
        margin: '0 5px',
    },
}));

function ExportShoppers({ exportShoppersList }) {

    const csvLink = createRef();
    const [shopperList, setShopperList] = useState([])
    const [isExporting, setIsExporting] = useState(false)
    const [exportDisabled, setExportDisabled] = useState(true)

    const classes = useStyles();
    const headers = [
        { label: "#", key: "sl" },
        { label: "Instagram Handle", key: "username" },
        { label: "Email", key: "email" },
        { label: "Last Active On", key: "last_active" },
        { label: "Earned Coupons", key: "done" },
        { label: "Total Posts", key: "total_posts" },
        { label: "Followers Count", key: "followers_count" },
    ];

    const getUsers = () => {
        if (!exportDisabled) {
            setExportDisabled(true)
            exportShoppersList().then((response) => {
                if (response.status === 1) {
                    setIsExporting(true);
                    setShopperList(response.data);
                } else {
                    setExportDisabled(false)
                }
            });
        }
    }

    useEffect(() => {
        if (isExporting) {
            csvLink.current.link.click();
            setIsExporting(false);
            setExportDisabled(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopperList])

    useEffect(() => {
        if (csvLink.current) {
            setExportDisabled(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Button variant="contained" color="primary" className={classes.headButton} startIcon={<CloudDownload></CloudDownload>} onClick={getUsers} disabled={exportDisabled}>
                Export Creators
            </Button>
            <CSVLink data={shopperList} filename="shoppers.csv" asyncOnClick={true} headers={headers} ref={csvLink}></CSVLink>
        </>

    )
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        exportShoppersList: () => dispatch(ShoppersAction.exportShoppersList()),
    }
};

const connectedExportShoppers = (connect(mapStateToProps, mapDispatchToProps)(ExportShoppers));
export { connectedExportShoppers as ExportShoppers };

