import React from 'react'
import { Button } from '@material-ui/core';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalHeader } from '../../_components/modal';

function PayNowModal({ show, showLoader, data, closeModal, updatePayment }) {


    const onHide = () => {
        closeModal(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        updatePayment(data.id);
    }

    return (
        <ConfzModal isModalOpen={show} onHide={onHide} loader={showLoader} size="md">
            <form onSubmit={handleSubmit} noValidate className='ns-form' >
                <ConfzModalHeader id="AddUpdateDeals" title="Pay Now" onHide={onHide} />
                <ConfzModalBody className="text-center">
                    <h1>Are You Sure?</h1>
                    <p>{`Do you want to pay this invoice (${data.invoice_number})?`}</p>
                </ConfzModalBody>
                <ConfzModalFooter>
                    <Button variant="contained" color="secondary" size="small" onClick={onHide}>Cancel</Button>
                    <Button variant="contained" color="primary" size="small" type="submit" >Confirm</Button>
                </ConfzModalFooter>
            </form>
        </ConfzModal>
    )
}

export { PayNowModal }
