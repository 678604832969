import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'


const useStyles = makeStyles((theme) => ({
    gridContainer: {
        // paddingTop: `250px`
    }
}))

function NoDataFound({ width = '100', text = "No data found", height = "200px", caption, props }) {

    const classes = useStyles()

    return (
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ height: height, margin: 'unset' }} className={classes.gridContainer}>
            <Grid item>
                <Box textAlign="center">
                    <img src={`${process.env.PUBLIC_URL}/img/vectors/no-data.svg`} width={width} alt="No data found" />
                    <Typography variant="h6" component="h6" align="center" color="inherit">{text}</Typography>
                    {caption && <Typography variant="caption" component="p" align="center" color="initial">{caption}</Typography>}
                </Box>
            </Grid>
        </Grid>
    )
}

export { NoDataFound }
