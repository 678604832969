import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import './text-editor.css'

function FormRichTextEditor({ label, name, value, onChange, error, ...props }) {

    const [editorState, setEditorState] = useState(() => {
        const blocksFromHTML = convertFromHTML(value)
        const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        )
        return EditorState.createWithContent(contentState)
    })

    const handleChange = (editorState) => {
        const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        let target = {
            'name': name,
            'value': data,
            'type': 'text_area'
        }
        onChange({ target })
    };

    const {tools} = props;

    //add dynamic options to toolbar
    const toolBar = () => {
        let options = []

        for (let tool in tools) {
            options.push(tool)
        }

        let toolbar =
            {
                options: options,
                ...(tools && tools.link && {  link: {
                    inDropdown: false,
                    showOpenOptionOnHover: true,
                    defaultTargetOption: '_self',
                    options: ['link'],
                } }),
            }
            return toolbar;
    
    }
    
    return (
        <div className={`form-group`}>
            <label htmlFor={name} className="col-form-label">{label}</label>
            <Editor
                editorState={editorState}
                onEditorStateChange={editorState => {
                    setEditorState(editorState);
                    handleChange(editorState);
                }}
                toolbar={ !tools ? 
                    {
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link'],
                        inline: {
                            inDropdown: false,
                            options: ['bold', 'italic', 'underline', 'strikethrough',],
                        },
                        blockType: {
                            inDropdown: true,
                            placeholder: 'asd',
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                        },
                        fontSize: {
                            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                        },
                        fontFamily: {
                            options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                        },
                        list: {
                            inDropdown: false,
                            options: ['unordered', 'ordered', 'indent', 'outdent'],
                        },
                        textAlign: {
                            inDropdown: false,
                            options: ['left', 'center', 'right', 'justify'],
                        },
                        colorPicker: {
                            colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                        },
                        link: {
                            inDropdown: false,
                            showOpenOptionOnHover: true,
                            defaultTargetOption: '_self',
                            options: ['link'],
                        },
                        embedded: {
                            defaultSize: {
                                height: 'auto',
                                width: 'auto',
                            },
                        }
                    } : toolBar()
                }
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
            />
            {(error) && (<div className="invalid-feedback" style={{ display: `block` }}>{error}</div>)}
        </div>
    )
}

FormRichTextEditor.defaultProps = {
    className: "",
}


FormRichTextEditor.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
}

export { FormRichTextEditor }
