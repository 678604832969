import { Box } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { SummaryWidget } from '../../../_components/summary-widget';
import { useNavigate } from 'react-router-dom';

function DirectionClicksCount({ isLoading, get_directions_clicks }) {
    const navigate = useNavigate();

    const viewChart = () => {
        navigate({
            pathname: "/ig-insights/chart",
            search:`?metric=get_directions_clicks`
        })
    }

    return (
        <Box style={{cursor: 'pointer'}} onClick={() => viewChart()}>
            <SummaryWidget loading={isLoading} text={'Daily Directions Clicks'} value={get_directions_clicks} bgColor={"#a852f7"} color={"#ffffff"} />
        </Box>
    );

}


function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
};

const connectedDirectionClicksCount = (connect(mapStateToProps, mapDispatchToProps)(DirectionClicksCount));
export { connectedDirectionClicksCount as DirectionClicksCount };
