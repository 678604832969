import React, { FC } from "react";
import "./GuruLogo.scss"
import images from "../../theme/images";

export interface IGuruLogo {
	style?: any
}

const GuruLogo: FC<IGuruLogo> = ({ ...props }) => {
	return (<img src={images.fullLogo} {...props} alt={`Guru Club`} />)
}

export default GuruLogo
