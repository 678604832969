
const validateChangePassword = (name, value, data = {}) => {
    //eslint-disable-next-line
    const errors = {};
    switch (name) {
        case 'current_password': {
            errors.current_password = value.length < 6 ? 'Current password must be 6 characters long. ' : '';
            if (value === '')
                errors.current_password = 'Current password is required. ';
            break;
        } case 'password': {
            const currentPassword = data.current_password ?? '';
            errors.password = value.length < 6 ? 'Password must be 6 characters long. ' : '';
            if (currentPassword !== '' && currentPassword === value) {
                errors.password = 'Current password and password should be different. ';
            }
            if (value === '')
                errors.password = 'Password is required. ';
            break;
        } case 'confirm_password': {
            const password = data.password ?? '';
            errors.confirm_password = value.length < 6 ? 'Confirm password must be 6 characters long. ' : '';
            if (password !== '' && password !== value)
                errors.confirm_password += 'Password and confirm password must match.';
            if (value === '')
                errors.confirm_password = 'Confirm password is required. ';
            break;
        } default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}
export default validateChangePassword;