import React from 'react'
import { connect } from 'react-redux';
import { NavLink, Route, Routes } from 'react-router-dom';
import { Section } from '../../_components/sections';
import { SettingsAction } from '../../_redux/actions';
import { RouteConfig } from '../../_routes'
import { LoginSettings } from './login-settings/LoginSettings';
import { SubDomainSettings } from './sub-domain/SubDomainSettings';

function Settings({ user, ...props }) {

    return (
        <Section >
            <div className="screen-container">
                <div className="row">
                    <div className="col-md-12 mx-auto">
                        <div className="card card-profile-settings">
                            <div className="card-body row">
                                <div className="col-md-3 text-center settings-left">
                                    <img src={`${process.env.PUBLIC_URL}/img/vectors/gc-logo.svg`} height="100px" alt="GC Logo" />
                                    <hr class="dashed" />
                                    <NavLink to={RouteConfig('sub_domain_settings').path} className="btn btn-block text-left"><i className="icon-note"></i> &nbsp; {RouteConfig('sub_domain_settings').name}</NavLink>
                                    <NavLink to={RouteConfig('login_settings').path} className="btn btn-block text-left "><i className="icon-note"></i> &nbsp; {RouteConfig('login_settings').name}</NavLink>
                                </div>
                                <div className="col-md-9 settings-right">
                                    <Routes>
                                        <Route path={'/'} element={<SubDomainSettings />}></Route>
                                        <Route path={'/login'} element={<LoginSettings />}></Route>
                                        <Route path={'/sub-domain'} element={<SubDomainSettings />}></Route>
                                    </Routes>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}


function mapStateToProps(state) {
    return {
        user: state.UserReducer.user,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        settings: dispatch(SettingsAction.getLoginSettings()),
    }
};


const connectedSettings = connect(mapStateToProps, mapDispatchToProps)(Settings);
export { connectedSettings as Settings };

