const getImageSize = async (file) => {
    return new Promise((resolve, reject) => {
        if (file) {
            if (file.file) {
                const oFReader = new FileReader();
                oFReader.readAsDataURL(file);
                oFReader.onload = (e) => {
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        resolve({ width: image.width, height: image.height })
                    };
                    image.onerror = function () {
                        resolve();
                    };
                }
            } else {
                var image = new Image();
                image.src = file;
                image.onload = function () {
                    resolve({ width: image.width, height: image.height });
                };
                image.onerror = function () {
                    resolve();
                };
            }
        }
    })
}

export { getImageSize }

