import { authHeader, handleResponse, notify } from "../../_helpers";
import Config from "../../_helpers/config";
import queryString from 'query-string';


export const DealServices = { getDeal, getDeals, deleteDeals, addDeals, editDeals, importDeals, updateStatus, getLatestDeal, getMailContent, sendTestMail, changeDealMode, addDefaultCouponSettings };


/**
 * Service for get deals 
 *
 * @author Akshay 
 */
function getDeal(id) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';
    return fetch(`${Config.endpoint}/ns/deals/${id}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service for get deals 
 *
 * @author Akshay 
 */
function getLatestDeal() {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';
    return fetch(`${Config.endpoint}/ns/deals/latest-deal`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service for get deals 
 *
 * @author Akshay 
 */
function getDeals(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    let queryParam = {};
    queryParam.page = params.page;
    if (params.search) queryParam.search = params.search; else delete queryParam.search;

    return fetch(`${Config.endpoint}/ns/deals?${queryString.stringify(queryParam)}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}
/**
 * Service for delete deal 
 *
 * @author Akshay 
 */
function deleteDeals(id) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'DELETE';

    return fetch(`${Config.endpoint}/ns/deals/${id}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}
/**
 * Service for add deal 
 *
 * @author Akshay 
 */
function addDeals(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    delete requestOptions.headers['Content-Type'];
    requestOptions.headers['form-data'] = true;
    requestOptions.method = 'POST';
    requestOptions.body = (params);
    return fetch(`${Config.endpoint}/ns/deals/create`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for add deal 
 *
 * @author Akshay 
 */
function editDeals(id, params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    delete requestOptions.headers['Content-Type'];
    requestOptions.headers['form-data'] = true;
    requestOptions.method = 'PUT';
    requestOptions.body = (params);

    return fetch(`${Config.endpoint}/ns/deals/${id}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for add deal 
 *
 * @author Akshay 
 */
function updateStatus(id) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'PUT';

    return fetch(`${Config.endpoint}/ns/deals/status/${id}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}
/**
 * Service for add deal 
 *
 * @author Akshay 
 */
function changeDealMode(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);

    return fetch(`${Config.endpoint}/ns/deals/update-mode`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for import deals
 *
 * @author Akshay 
 */
function importDeals(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    // requestOptions.headers[`content-type`] = 'multipart/form-data';
    delete requestOptions.headers['Content-Type'];
    requestOptions.method = 'POST';
    requestOptions.body = (params);

    console.log('requestOptions');
    console.log(requestOptions);
    console.log('requestOptions');

    return fetch(`${Config.endpoint}/ns/deals/import`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}


/**
 * Service for get deals 
 *
 * @author Akshay 
 */
function getMailContent(id) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';
    return fetch(`${Config.endpoint}/ns/deals/get-mail`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}


/**
 * Service for get deals 
 *
 * @author Akshay 
 */
function sendTestMail() {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';
    return fetch(`${Config.endpoint}/ns/deals/send-test-mail`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for add deal 
 *
 * @author Akshay 
 */
function addDefaultCouponSettings(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);

    return fetch(`${Config.endpoint}/ns/deals/add-default-coupon-settings`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
        
}