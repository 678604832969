import { INITIAL_LOADER } from '../actions';

const initialState = { initialLoader: true };

export function InitialLoaderReducer(state = initialState, action) {
    switch (action.type) {
        case INITIAL_LOADER:
            return {
                initialLoader: action.loader,
            };
        default:
            return state;
    }
}
