import React from 'react'
import PropTypes from 'prop-types'

function FormCheckbox({ label, type, name, checked, value, className, onChange, tabIndex, error, ...props }) {
    return (
        <div className={`form-group ${className}`}>
            <div className="custom-control custom-checkbox">
                <input type={type} name={name} className={`custom-control-input ${(error) ? `is-invalid` : ``}`} id={`${name}`} tabIndex={tabIndex} onClick={onChange} onChange={onChange} defaultChecked={(value) ? true : false} />
                <label className="custom-control-label" htmlFor={name} dangerouslySetInnerHTML={{ __html: label }} />
            </div>
        </div>
    );
}

FormCheckbox.defaultProps = {
    type: "checkbox",
    className: ""
}

FormCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['checkbox']),
    className: PropTypes.string,
    checked: PropTypes.bool,
    tabIndex: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
}

export { FormCheckbox }
