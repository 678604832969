import { GiveawayWinnerServices } from "../services";


export const GENERATE_WINNERS_REQUEST = 'GENERATE_WINNERS_REQUEST';
export const GENERATE_WINNERS_SUCCESS = 'GENERATE_WINNERS_SUCCESS';
export const GENERATE_WINNERS_FAILURE = 'GENERATE_WINNERS_FAILURE';

export const GET_WINNERS_REQUEST = 'GET_WINNERS_REQUEST';
export const GET_WINNERS_SUCCESS = 'GET_WINNERS_SUCCESS';
export const GET_WINNERS_FAILURE = 'GET_WINNERS_FAILURE';

export const GET_LATEST_WINNER_REQUEST = 'GET_LATEST_WINNER_REQUEST';
export const GET_LATEST_WINNER_SUCCESS = 'GET_LATEST_WINNER_SUCCESS';
export const GET_LATEST_WINNER_FAILURE = 'GET_LATEST_WINNER_FAILURE';

export const SET_WINNERS_PAGE = 'SET_WINNERS_PAGE';

export const GET_GIVEAWAY_PARTICIPANTS_LIST_REQUEST = 'GET_GIVEAWAY_PARTICIPANTS_LIST_REQUEST';
export const GET_GIVEAWAY_PARTICIPANTS_LIST_SUCCESS = 'GET_GIVEAWAY_PARTICIPANTS_LIST_SUCCESS';
export const GET_GIVEAWAY_PARTICIPANTS_LIST_FAILURE = 'GET_GIVEAWAY_PARTICIPANTS_LIST_FAILURE';

export const GET_GIVEAWAY_ENTRIES_LIST_REQUEST = 'GET_GIVEAWAY_ENTRIES_LIST_REQUEST';
export const GET_GIVEAWAY_ENTRIES_LIST_SUCCESS = 'GET_GIVEAWAY_ENTRIES_LIST_SUCCESS';
export const GET_GIVEAWAY_ENTRIES_LIST_FAILURE = 'GET_GIVEAWAY_ENTRIES_LIST_FAILURE';

export const GiveawayWinnerAction = { getWinnerCount, generateWinner, getAllWinners, getLatestWinner, setPage, getParticipants, getGiveawayEntries };



/**
 * Action get LOGIN_SETTINGS
 *
 * @author Akshay 
 */
function getWinnerCount(params) {
    return dispatch => {
        return GiveawayWinnerServices.getWinnerCount(params)
            .then(
                response => { return (response); },
                error => { return (error); }
            );
    };

}

/**
 * Action get LOGIN_SETTINGS
 *
 * @author Akshay 
 */
function generateWinner(params) {
    return dispatch => {
        dispatch(request());
        return GiveawayWinnerServices.generateWinner(params)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request() { return { type: GENERATE_WINNERS_REQUEST } }
    function success(response) { return { type: GENERATE_WINNERS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GENERATE_WINNERS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };


}




/**
 * function description
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 
 */
function getAllWinners(params) {
    return dispatch => {
        dispatch(request(params));
        return GiveawayWinnerServices.getAllWinners(params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };


    function request(request) { return { type: GET_WINNERS_REQUEST, request: request } }
    function success(request, response) { return { type: GET_WINNERS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_WINNERS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

}


/**
 * function description
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 
 */
function getLatestWinner() {
    return dispatch => {
        dispatch(request());
        return GiveawayWinnerServices.getLatestWinner()
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => { return dispatch(failure(error)); }
            );
    };


    function request() { return { type: GET_LATEST_WINNER_REQUEST } }
    function success(response) { return { type: GET_LATEST_WINNER_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_LATEST_WINNER_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

}

/**
 * Action to import coupon
 *
 * @author Akshay 
 */
function setPage(page) {
    return dispatch => { dispatch({ type: SET_WINNERS_PAGE, page: page }) };

}

/**
 * function to get participants list for  giveaway
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 
 */
function getParticipants(params) {
    return dispatch => {
        dispatch(request(params));
        return GiveawayWinnerServices.getParticipants(params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };


    function request(request) { return { type: GET_GIVEAWAY_PARTICIPANTS_LIST_REQUEST, request: request } }
    function success(request, response) { return { type: GET_GIVEAWAY_PARTICIPANTS_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_GIVEAWAY_PARTICIPANTS_LIST_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * function to get giveaway entries list
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 
 */
function getGiveawayEntries(params) {
    return dispatch => {
        dispatch(request(params));
        return GiveawayWinnerServices.getGiveawayEntries(params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };


    function request(request) { return { type: GET_GIVEAWAY_ENTRIES_LIST_REQUEST, request: request } }
    function success(request, response) { return { type: GET_GIVEAWAY_ENTRIES_LIST_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_GIVEAWAY_ENTRIES_LIST_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}
