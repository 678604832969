import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { SignInReducer, SignUpReducer, UserReducer, DesignationReducer, PageLoaderReducer, InitialLoaderReducer, SettingsReducer, DealReducer, CouponReducer, GiveawayWinnerReducer, ShoppersReducer, StoriesReducer, PaymentReducer, InvoicesReducer, InstaPaymentReducer, IgInsightsReducer, IgMessageReducer, TrialCouponReducer } from './reducers';
const loggerMiddleware = createLogger({ predicate: () => process.env.NODE_ENV !== 'production' });
const rootReducer = combineReducers({ PageLoaderReducer, InitialLoaderReducer, SignUpReducer, SignInReducer, UserReducer, DesignationReducer, SettingsReducer, DealReducer, CouponReducer, GiveawayWinnerReducer, ShoppersReducer, StoriesReducer, PaymentReducer, InvoicesReducer, InstaPaymentReducer, IgInsightsReducer, IgMessageReducer, TrialCouponReducer })

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);