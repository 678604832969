import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { CouponAction, SettingsAction } from '../../../_redux/actions';

import { Grid, Button, styled, Box, IconButton, Menu, MenuItem, ListItemIcon, Typography } from '@material-ui/core'
import { Card, CardHeader, CardContent } from '../../../_components/card'

import { FormGroupInput } from '../../../_components/form-group-input';
import { FormCheckbox, FormCancelBtn, FormSaveBtn } from '../../../_components/form-input';
import { handleInputChange, validateForm } from '../../../_helpers';
import validateSubDomain from '../../settings/sub-domain/validateSubDomain';
import { SubdomainDetails } from './SubdomainDetails';
import { Edit, MoreVert } from '@material-ui/icons';
import { GenerateWidget } from '../../deals';


const HeaderAction = styled(({ ...props }) => (<Box {...props} />))``;


function SubdomainSettings({ brand_sub_domain }) {

    const dispatch = useDispatch();
    const inputs = { brand_sub_domain: brand_sub_domain, sub_domain_auth: false }

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, sub_domain_auth: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [edit, setEdit] = useState(false);
    const [showGenerateWidgetModal, setShowGenerateWidgetModal] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setData({ brand_sub_domain: brand_sub_domain, sub_domain_auth: false })
    }, [brand_sub_domain])


    /**
   * function to handle input change
   * @param object e input object
   * @author Akshay N
   */
    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateSubDomain(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
    * function to handle submit action
    * @param object e input object
    * @author Akshay N
    */
    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateSubDomain(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }
        dispatchUpdateSubdomainAction();
    }

    /**
   * function to dispatch signup action
   * @param object e input object
   * @author Akshay N
   */
    const dispatchUpdateSubdomainAction = () => {
        dispatch(SettingsAction.updateSubDomain(data))
            .then((response) => {
                if (response.status === 1) {
                    setAction({ isSubmitted: false, isProcessing: false });
                    setErrors({ ...response.errors });
                    setEdit(false);
                } else {
                    setErrors({ ...response.errors })
                    setAction({ isSubmitted: true, isProcessing: false });
                }
            });
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const resetAndBack = (e) => {
        setData({ ...inputs });
        setErrors({ ...inputs });
        setEdit(false);
    }

    const openGenerateWidgetModal = () => {
        setShowGenerateWidgetModal(!showGenerateWidgetModal);
    }

    // const generateQrCode = () => {
    //     let pageUrl = `/qr-code/generate`;
    //     navigate(pageUrl);
    // }

    return (
        <Card>
            <Grid container >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CardHeader
                        title="Subdomain Settings"
                        titleTypographyProps={{ variant: 'h4', component: 'h4' }}
                        action={
                            <HeaderAction>
                                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                    <Button variant="contained" color={"primary"} onClick={() => setEdit(!edit)} startIcon={<Edit />}>Edit</Button>
                                    {/* <Button variant="contained" color="secondary" style={{ marginLeft: 5 }} startIcon={<Widgets>Generate Widget</Widgets>} onClick={() => openGenerateWidgetModal()}>Generate Widget </Button>
                                    <Button variant="contained" color="primary" style={{ marginLeft: 5 }} startIcon={<Widgets>Generate QR Code</Widgets>} onClick={generateQrCode}>Generate QR Code </Button> */}
                                </Box>
                                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                                    <IconButton aria-haspopup="true" onClick={handleClick} ><MoreVert /></IconButton>
                                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}   >
                                        <MenuItem onClick={() => setEdit(!edit)}>
                                            <ListItemIcon> <Edit /></ListItemIcon>
                                            <Typography>Edit</Typography>
                                        </MenuItem>
                                        {/* <MenuItem onClick={() => openGenerateWidgetModal()}>
                                            <ListItemIcon> <Widgets /></ListItemIcon>
                                            <Typography>Generate Widget</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={() => generateQrCode()}>
                                            <ListItemIcon> <CodeOutlined /></ListItemIcon>
                                            <Typography>Generate QR Code</Typography>
                                        </MenuItem> */}

                                    </Menu>

                                </Box>
                            </HeaderAction>
                        }
                    />
                    <CardContent>
                        {edit ? (
                            <React.Fragment>
                                <form onSubmit={handleSubmit} noValidate>
                                    <FormGroupInput
                                        append="after"
                                        ig_text={`.${process.env.REACT_APP_DOMAIN}`}
                                        label="Subdomain name"
                                        name="brand_sub_domain"
                                        tabIndex="1"
                                        error={(action.isSubmitted && (errors.brand_sub_domain)) ? (errors.brand_sub_domain) : ''}
                                        placeholder="Enter subdomain name"
                                        value={data.brand_sub_domain}
                                        onChange={handleChange}
                                        maskType={`alphanumeric-with-hiphen-small-only`}
                                        className="col-md-6 mr-auto"
                                        onPaste={(e) => {
                                            e.preventDefault()
                                            return false;
                                        }}
                                    />
                                    <FormCheckbox
                                        label="I authorize Guru Club to create the subdomain"
                                        name="sub_domain_auth"
                                        tabIndex="2"
                                        checked={data.sub_domain_auth}
                                        error={(action.isSubmitted && (errors.sub_domain_auth)) ? (errors.sub_domain_auth) : ''}
                                        onChange={handleChange}
                                        className="col-md-12 mr-auto"
                                    />
                                    <div className="col-md-12">
                                        <FormCancelBtn onClick={resetAndBack} text="Cancel" />
                                        <FormSaveBtn isProcessing={action.isProcessing} />
                                    </div>
                                </form>
                            </React.Fragment>
                        ) :
                            (<SubdomainDetails />)}
                    </CardContent>
                </Grid>
            </Grid>
            <GenerateWidget src={`${process.env.REACT_APP_HTP}${brand_sub_domain}.${process.env.REACT_APP_DOMAIN}`} show={showGenerateWidgetModal} closeModal={openGenerateWidgetModal} />
        </Card>
    )
}



function mapStateToProps(state) {
    return {
        brand_sub_domain: state.SettingsReducer.brand_sub_domain
    };
}


function mapDispatchToProps(dispatch) {
    return {
        getCoupons: dispatch(CouponAction.getCoupons({ page: 1 }))
    }
};


const connectedSubdomainSettings = connect(mapStateToProps, mapDispatchToProps)(SubdomainSettings);
export { connectedSubdomainSettings as SubdomainSettings };


