const validateCoupon = (name, value) => {
    //eslint-disable-next-line
    const errors = {};
    switch (name) {

        case 'coupon_code': {
            errors.coupon_code = '';
            if (value === '')
                errors.coupon_code = 'Coupon code is required. ';
            break;
        } default:
            errors[name] = '';
            break;
    }
    return errors;
}
export default validateCoupon;