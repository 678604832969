import { Box } from '@material-ui/core';
import React from 'react'
import QRCode from "react-qr-code";


// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
export const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
        <Box ref={ref} display={`flex`} alignItems={`center`} justifyContent={`center`} >
            <QRCode value={`${process.env.REACT_APP_HTP}${props.brand_sub_domain}.${process.env.REACT_APP_DOMAIN}?qr=1`} size={props.qrSize} />
        </Box>
    );
});


