import React from 'react'
import { connect } from 'react-redux';
import { SummaryWidget } from '../../../_components/summary-widget';


function TotalCoupons({ isLoading, total_coupons }) {

    return <SummaryWidget loading={isLoading} text={'Total Coupons'} value={total_coupons} bgColor={"#7c82a0"} color={"#ffffff"} />

}


function mapStateToProps(state) {
    return {
        total_coupons: state.CouponReducer.total_coupons,
    };
}


const connectedTotalCoupons = (connect(mapStateToProps, null)(TotalCoupons));
export { connectedTotalCoupons as TotalCoupons };


