import React from 'react'
import { connect } from 'react-redux';
import { SummaryWidget } from '../../../_components/summary-widget';
import { UserAction } from '../../../_redux/actions';

function QrScanCount({ isLoading, get_qr_scan_count }) {

    return <SummaryWidget loading={isLoading} text={'QR scan count'} value={get_qr_scan_count} bgColor={"#5263f7"} color={"#ffffff"} />

}


function mapStateToProps(state) {
    return {
        isLoading: state.UserReducer.get_qr_scan_count_loading,
        get_qr_scan_count: state.UserReducer.get_qr_scan_count,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getQrScanScanCount: dispatch(UserAction.getQrScanScanCount())
    }
};


const connectedQrScanCount = (connect(mapStateToProps, mapDispatchToProps)(QrScanCount));
export { connectedQrScanCount as QrScanCount };


