import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

function ConfzModalFooterCloseBtn({ text, className, tabIndex, onHide, props }) {

    return (
        <Button className={className} type="button" variant="contained" color="secondary" tabIndex={tabIndex} data-dismiss="modal" onClick={() => onHide()} {...props}>
            {text}
        </Button>
    )
}


ConfzModalFooterCloseBtn.defaultProps = {
    className: "",
    text: "Close",
}


ConfzModalFooterCloseBtn.propTypes = {
    className: PropTypes.string,
    tabIndex: PropTypes.string,
    text: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired
}

export { ConfzModalFooterCloseBtn }
