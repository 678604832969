import React from 'react'
import { connect } from 'react-redux';
import { Grid, Paper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core'
import { RemainingCoupons } from '../coupons';
import { SubdomainSettings } from './subdomain-settings';
import { TotalCompletedDeals } from '../shoppers';
import { FollowerChangeGraph, ProfileViewsReachGraph } from './graphs';
import { QrScanCount } from '../shoppers/widgets/QrScanCount';
import { DefaultDealSettings } from './default-deal-settings';

const useStyles = makeStyles(() => ({
    cardHeader: {
        lineHeight: "20px !important",
        minHeight: "30px !important",
        padding: "15px 20px !important"
    },
    cardBody: {
        padding: "15px 20px !important"
    },
    helperText: {
        fontSize: "12px",
        paddingTop: "5px"
    },
    widgetGrid: {
        padding: "15px"
    }
}))


function Home() {

    const classes = useStyles()

    return (

        <Box>
            <Grid container spacing={3} >
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <DefaultDealSettings />
                </Grid>
                {/* <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <SubdomainSettings />
                </Grid> */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <Grid container className={classes.widgetGrid}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                        <TotalCompletedDeals />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                        <RemainingCoupons />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={`${classes.widgetGrid}`}>
                                        <QrScanCount />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={`${classes.widgetGrid}`}>
                                        <FollowersCount />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={`${classes.widgetGrid}`}>
                                        <TotalProfileViews />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <NavLink to={'/shoppers'} className="btn btn-block text-left " style={{ color: `blue` }}><i className="fa fa-arrow-right"></i> &nbsp; {`View all creators`}</NavLink>
                                    </Grid> */}
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Paper>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={`${classes.widgetGrid}`}>
                                        <FollowerChangeGraph />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={`${classes.widgetGrid}`}>
                                        <ProfileViewsReachGraph />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}





const connectedHome = connect(null, null)(Home);
export { connectedHome as Home };
