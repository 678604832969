import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import queryString from 'query-string';
import { CouponAction } from '../../../../_redux/actions';
import { ConfzDatatable } from '../../../../_components/data-table';



function CodesDataTable({ loading, custom_codes, page, per_page, total, search, filter, sort, setPage, ...props }) {

    const columns = [
        { name: '#', selector: (row, i) => (row._id), center: true, width: '10%', cell: (row, i) => ((per_page * (page - 1)) + (i + 1)), minWidth: 100 },
        { name: 'Coupon Code URL', selector: (row, i) => (row.coupon_code_url), sortable: true, width: '50%' },
        { name: 'Campaign', selector: (row, i) => (row.campaign_name), sortable: true, width: '25%' },
        { name: 'Status', selector: (row, i) => (row.status), sortable: true, center: true, width: '15%', cell: row => (<StatusRow row={row} />) },
    ];


    const StatusRow = ({ row }) => {
        let status = <span className="badge badge-status badge-danger" style={{ textTransform: "capitalize" }}>{row.status}</span>;
        if (row.status === 'active')
            status = <span className="badge badge-status badge-success" style={{ textTransform: "capitalize" }}>{row.status}</span>;
        else if (row.status === 'inactive')
            status = <span className="badge badge-status badge-warning" style={{ textTransform: "capitalize" }}>{row.status}</span>;
        return <React.Fragment>{status}</React.Fragment>;

    }


    useEffect(() => {

        let queryParam = filter ? filter : {};
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.p = page;
        if (sort.field) {
            queryParam.field = sort.field
            queryParam.order = sort.order
        } else {
            delete queryParam.field
            delete queryParam.order
        }
        if (search) queryParam.q = search; else delete queryParam.q
        let pageUrl = `/coupons/create/?${queryString.stringify(queryParam)}`;
        window.history.pushState({}, '', pageUrl);
        props.getAllCustomCodes({ page: page, search: search });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, search, filter, sort])


    function handleSort(page, sortDirection) {

        // console.log(page.selector, sortDirection);

    }

    const handleSelect = (data) => {
        // console.log(data.selectedRows)
    }



    return (
        <div className="card-body card-table w-100 p-0">
            <ConfzDatatable columns={columns} data={custom_codes} loading={loading} total={total} per_page={per_page} setPage={page => setPage(page)} handleSort={handleSort} handleSelect={handleSelect} />
        </div>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.CouponReducer.custom_codes_loading,
        custom_codes: state.CouponReducer.custom_codes,
        page: state.CouponReducer.page,
        per_page: state.CouponReducer.per_page,
        total: state.CouponReducer.total,
        total_pages: state.CouponReducer.total_pages,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAllCustomCodes: (params) => dispatch(CouponAction.getAllCustomCodes(params)),
        setPage: (page) => dispatch(CouponAction.setPage(page))
    }
};

const connectedCodesDataTable = (connect(mapStateToProps, mapDispatchToProps)(CodesDataTable));
export { connectedCodesDataTable as CodesDataTable };

