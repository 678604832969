import React from 'react'
import { Button } from '@material-ui/core';
import { ConfzModal, ConfzModalHeader, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn } from '../../../_components/modal';
import { notify } from '../../../_helpers';
const copy = require('clipboard-copy')


function GenerateWidget({ src, show, closeModal }) {


    const inputs = { width: '400', height: '650' }


    const onHide = () => {
        closeModal(false);
    }

    const copyToClipBoard = (e) => {
        e.preventDefault();
        const url = `<iframe src="${src}" width="${inputs.width}" height="${inputs.height}"  scrolling="yes" ></iframe>`
        copy(url);
        notify('Copied to clipboard.');

    }



    return (
        <ConfzModal isModalOpen={show} onHide={onHide} loader={false} size="lg">
            <ConfzModalHeader id="GenerateWidget" title={`Generate Widget`} onHide={onHide} />
            <ConfzModalBody>
                <div className="row">
                    <div className="col-md-12">
                        {`<iframe src="${src}" width="${inputs.width}" height="${inputs.height}" scrolling="yes" ></iframe>`}
                    </div>
                </div>
            </ConfzModalBody>
            <ConfzModalFooter>
                <ConfzModalFooterCloseBtn tabIndex="4" onHide={onHide} />
                <Button onClick={copyToClipBoard} type="button" variant="contained" color="primary" >Copy</Button>
            </ConfzModalFooter>
        </ConfzModal>
    )
}

export { GenerateWidget }