import { TrialCouponServices } from "../services";

export const APPLY_TRIAL_COUPON_CODE_REQUEST = 'APPLY_TRIAL_COUPON_CODE_REQUEST';
export const APPLY_TRIAL_COUPON_CODE_SUCCESS = 'APPLY_TRIAL_COUPON_CODE_SUCCESS';
export const APPLY_TRIAL_COUPON_CODE_FAILURE = 'APPLY_TRIAL_COUPON_CODE_FAILURE';

export const IS_BRAND_ON_TRIAL_REQUEST = 'IS_BRAND_ON_TRIAL_REQUEST';
export const IS_BRAND_ON_TRIAL_SUCCESS = 'IS_BRAND_ON_TRIAL_SUCCESS';
export const IS_BRAND_ON_TRIAL_FAILURE = 'IS_BRAND_ON_TRIAL_FAILURE';

export const TrialCouponAction = { applyTrialCouponCode, isBrandOnTrial };


/**
 * Action to import coupon
 *
 * @author Jasin 
 */
function applyTrialCouponCode(params) {
    return dispatch => {
        dispatch(request(params));
        return TrialCouponServices.applyTrialCouponCode(params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(params) { return { type: APPLY_TRIAL_COUPON_CODE_REQUEST, request: request } }
    function success(request, response) { return { type: APPLY_TRIAL_COUPON_CODE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: APPLY_TRIAL_COUPON_CODE_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action to import coupon
 *
 * @author Jasin 
 */
function isBrandOnTrial(params) {
    return dispatch => {
        dispatch(request(params));
        return TrialCouponServices.isBrandOnTrial(params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(params) { return { type: IS_BRAND_ON_TRIAL_REQUEST, request: request } }
    function success(request, response) { return { type: IS_BRAND_ON_TRIAL_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: IS_BRAND_ON_TRIAL_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

