import { authHeader, handleResponse } from "../../_helpers";
import Config from "../../_helpers/config";
import queryString from 'query-string';


export const ShoppersServices = { getShoppersList, getTotalCosts, getTotalReach, getTotalCompletedDeals, getTotalShoppers, getShopperDetails, exportShoppersList };

/**
 * function get shoppers list
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function getShoppersList(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    let queryParam = {};
    queryParam.page = params.page;
    if (params.search) queryParam.search = params.search; else delete queryParam.search;

    return fetch(`${Config.endpoint}/ns/business/shoppers?${queryString.stringify(queryParam)}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * function get shoppers total cost
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function getTotalCosts(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    return fetch(`${Config.endpoint}/ns/business/shoppers/total-cost`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * function get shoppers total cost
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function getTotalReach(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    return fetch(`${Config.endpoint}/ns/business/shoppers/total-reach`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * function get shoppers total cost
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function getTotalCompletedDeals(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    return fetch(`${Config.endpoint}/ns/business/shoppers/total-completed-deals`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * function get shoppers total cost
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function getTotalShoppers(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    return fetch(`${Config.endpoint}/ns/business/shoppers/total-shoppers`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * function get shoppers total cost
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function getShopperDetails(shopperId) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    return fetch(`${Config.endpoint}/ns/shoppers/${shopperId}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * function to export shoppers list
 * @param params
 * @return view
 * @author Jasin
 * @created_at 04/03-2022
 */
function exportShoppersList() {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';


    return fetch(`${Config.endpoint}/ns/business/export-shoppers`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}