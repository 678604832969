import { Box } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SummaryWidget } from '../../../_components/summary-widget';

function WebsiteClicksCount({ isLoading, website_clicks }) {

    const navigate = useNavigate();

    const viewChart = () => {
        navigate({
            pathname: "/ig-insights/chart",
            search:`?metric=website_clicks`
        })
    }

    return (
        <Box style={{cursor: 'pointer'}} onClick={() => viewChart()}>
            <SummaryWidget loading={isLoading} text={'Daily Website Clicks'} value={website_clicks} bgColor={"#008B8B"} color={"#ffffff"} />
        </Box>
    );


}


function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
};

const connectedWebsiteClicksCount = (connect(mapStateToProps, mapDispatchToProps)(WebsiteClicksCount));
export { connectedWebsiteClicksCount as WebsiteClicksCount };
