import React from 'react'
import PropTypes from 'prop-types'

function ConfzModalHeader({ id, title, onHide }) {
    return (
        <div className="modal-header">
            <h5 className="modal-title" id={id}>{title}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onHide()} >
                <span className='close' aria-hidden="true">&times;</span>
            </button>
        </div>
    )
}


ConfzModalHeader.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired
}

export { ConfzModalHeader }
