import { Box, Button, } from '@material-ui/core'
import { Payment } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'

import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from '../../../_components/modal'

import { handleInputChange, validateForm } from '../../../_helpers'
import { FormInput, FormSelect, FormPhoneInputNew } from '../../../_components/form-input'
import { Grid, makeStyles, Divider } from "@material-ui/core";
import validateAddUpdateCreditCard from './validateAddUpdateCreditCard'

import { PaymentAction } from './../../../_redux/actions'

import { connect } from 'react-redux';
import moment from 'moment'
import { ErrorAlert } from '../../../_components/alert'
import { useLocation, useNavigate } from 'react-router-dom'

const countries = require('../country-list.json')

const Years = () => {
    const years = []
    const dateStart = moment()
    const dateEnd = moment().add(10, 'y')
    while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push({ "label": dateStart.format('YYYY'), "value": dateStart.format('YYYY') })
        dateStart.add(1, 'year')
    }
    return years
}

const Months = () => {
    const months = []
    for (var i = 1; i <= 12; i++) {
        const month = (i < 10) ? (`0${i}`) : i;
        months.push({ "label": month, "value": month })
    }
    return months
}

const years = Years();
const months = Months();

const inputs = { number: '', card_holder: '', exp_month: '', exp_year: '', cvc: '', name: '', address_line1: '', address_line2: '', city: '', state: '', postal_code: '', country: 'US', email: '', phone: '' }

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        marginBottom: theme.spacing(2),
        borderBottom: `1px solid #b0bec5`
    },
    headIcon: {
        paddingRight: theme.spacing(1)
    },
    headTitle: {
        fontWeight: 900,
        paddingBottom: theme.spacing(1),
    },
    headButton: {
        margin: '0 5px',
    },
    paymentPreferenceBox: {
        padding: theme.spacing(2)
    },
    paymentPreferenceContainer: {
        marginBottom: theme.spacing(2)
    },
    paymentPrefercenceTitle: {
        paddingBottom: theme.spacing(1)
    },
    paymentMethodTitle: {
        marginTop: theme.spacing(2)
    },
    table: {
        marginBottom: theme.spacing(2)
    },
    creditCardDetailsGrid: {
        paddingBottom: theme.spacing(2)
    }
}));


function AddUpdateCreditCard({ user, addUpdateCard, getPaymentDetails, setDefaultPayment, props }) {

    const navigate = useNavigate();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const fromType = params.get('type'); // plan promo from url

    const [show, setShow] = useState(false);

    const classes = useStyles();

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, country: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    const [showLoader, setShowLoader] = useState(false)
    const [defaultPaymentModal, setDefaultPaymentModal] = useState(false)
    const [responseMessage, setResponseMessage] = useState(null)

    useEffect(() => {
        setData({
            ...inputs,
            name: user.name,
            card_holder: user.name,
            email: user.email,
            phone: user.company_phone,
            country: ((user.billing_details) ? (user.billing_details.address.country) : 'US'),
            address_line1: ((user.billing_details) ? (user.billing_details.address.line1) : ''),
            address_line2: ((user.billing_details) ? (user.billing_details.address.line2) : ''),
            city: ((user.billing_details) ? (user.billing_details.address.city) : ''),
            postal_code: ((user.billing_details) ? (user.billing_details.address.postal_code) : ''),
            state: ((user.billing_details) ? (user.billing_details.address.state) : ''),
        })
    }, [user])


    /**
    * function description
    * @param Null
    * @return view
    * @author Akshay N
    * @created_at 
    */
    const handleChange = (e) => {
        setResponseMessage(null);
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateAddUpdateCreditCard(name, value)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateAddUpdateCreditCard(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }

        dispatchAddUpdateCard(data);
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const dispatchAddUpdateCard = (formData) => {
        addUpdateCard(formData).then((response) => {
            if (response.status === 1) {
                setShow(false);
                setAction({ isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
                if (!response.data.default_payment) {
                    setDefaultPaymentModal(true);
                } else {
                    getPaymentDetails();
                    if (fromType && fromType === 'trial_coupons') {
                        navigate({
                            pathname: "/invoices",
                            search:`?type=trial_coupons`
                        })
                    }
                }
            } else {
                setResponseMessage(response.message);
                setAction({ isSubmitted: true, isProcessing: false });
                setErrors({ ...response.errors })
            }
        });
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const addUpdateCardModal = (e) => {
        e.preventDefault();
        setData({
            ...inputs,
            name: user.name,
            card_holder: user.name,
            email: user.email,
            phone: user.company_phone,
            country: ((user.billing_details) ? (user.billing_details.address.country) : 'US'),
            address_line1: ((user.billing_details) ? (user.billing_details.address.line1) : ''),
            address_line2: ((user.billing_details) ? (user.billing_details.address.line2) : ''),
            city: ((user.billing_details) ? (user.billing_details.address.city) : ''),
            postal_code: ((user.billing_details) ? (user.billing_details.address.postal_code) : ''),
            state: ((user.billing_details) ? (user.billing_details.address.state) : '')
        });
        setShow(true);
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const onHide = () => {
        setShow(false);
        setErrors({});
        setDefaultPaymentModal(false);
        getPaymentDetails();
    }


    const setDefaultPaymentMethod = (e) => {
        setShowLoader(true);
        setDefaultPayment({ method: 'card' }).then(() => {
            getPaymentDetails();
            setDefaultPaymentModal(false);
            setShowLoader(false);

            if (fromType && fromType === 'trial_coupons') {
                navigate({
                    pathname: "/invoices",
                    search:`?type=trial_coupons`
                })
            }
        })
    }



    return (
        <Box>

            {/* BEGIN: Confirmation modal for setting default payment method */}
            <ConfzModal isModalOpen={defaultPaymentModal} onHide={onHide} loader={showLoader} size="md">
                <ConfzModalHeader id="defaultPaymentMethodModal" title="Default Payment Method" onHide={onHide} />
                <ConfzModalBody className="text-center">
                    <h1>New Card Added</h1>
                    <p>{`Do you want to change, card as your default payment method?`}</p>
                </ConfzModalBody>
                <ConfzModalFooter>
                    <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onHide}>Cancel</Button>
                    <Button variant="contained" color="primary" size="small" className={classes.button} onClick={setDefaultPaymentMethod}>Confirm</Button>
                </ConfzModalFooter>
            </ConfzModal>
            {/* END: Confirmation modal for setting default payment method */}

            <ConfzModal isModalOpen={show} size={`lg`} onHide={onHide} className={`addUpdateCreditCard`}>
                <form onSubmit={handleSubmit} noValidate className='ns-form'>
                    <ConfzModalHeader id="addUpdateCreditCardModal" title="Add Credit Card Details" onHide={onHide} />
                    <ConfzModalBody>
                        <Grid container spacing={2} className={classes.creditCardDetailsGrid}>
                            {responseMessage && <ErrorAlert message={responseMessage} />}
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Credit Card Number"
                                    placeholder="Enter credit card number"
                                    name="number"
                                    tabIndex="1"
                                    value={data.number}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.number)) ? (errors.number) : ''}
                                    maxLength={16}
                                    className="col-md-12"
                                    maskType={'digit-only'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Card Holder Name"
                                    placeholder="Enter card holder name"
                                    name="card_holder"
                                    tabIndex="2"
                                    value={data.card_holder}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.card_holder)) ? (errors.card_holder) : ''}
                                    maxLength={20}
                                    className="col-md-12"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormSelect
                                    label="Expiration Month"
                                    placeholder="Expiration month"
                                    name="exp_month"
                                    onChange={handleChange}
                                    className="col-md-12"
                                    value={data.exp_month}
                                    error={(action.isSubmitted && (errors.exp_month)) ? (errors.exp_month) : ''}
                                    data={months}
                                    tabIndex="3"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormSelect
                                    label="Expiration Year"
                                    placeholder="Expiration Year"
                                    name="exp_year"
                                    onChange={handleChange}
                                    className="col-md-12"
                                    value={data.exp_year}
                                    error={(action.isSubmitted && (errors.exp_year)) ? (errors.exp_year) : ''}
                                    data={years}
                                    tabIndex="4"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormInput
                                    type="password"
                                    label="CVC"
                                    placeholder="Enter CVC"
                                    name="cvc"
                                    tabIndex="5"
                                    value={data.cvc}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.cvc)) ? (errors.cvc) : ''}
                                    maxLength={3}
                                    className="col-md-12"
                                    maskType={'digit-only'}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container style={{ marginTop: `20px` }} >
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Full Name"
                                    placeholder="Enter full name"
                                    name="name"
                                    tabIndex="6"
                                    value={data.name}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.name)) ? (errors.name) : ''}
                                    maxLength={100}
                                    className="col-md-12"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Address Line 1"
                                    placeholder="Enter address line 1"
                                    name="address_line1"
                                    tabIndex="7"
                                    value={`${data.address_line1}`}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.address_line1)) ? (errors.address_line1) : ''}
                                    className="col-md-12"
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Address Line 2"
                                    placeholder="Enter address line 2 (Optional)"
                                    name="address_line2"
                                    tabIndex="8"
                                    value={data.address_line2}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.address_line2)) ? (errors.address_line2) : ''}
                                    className="col-md-12"
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="City"
                                    placeholder="Enter city"
                                    name="city"
                                    tabIndex="9"
                                    value={data.city}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.city)) ? (errors.city) : ''}
                                    className="col-md-12"
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="State/Province/Region"
                                    placeholder="Enter State/Province/Region"
                                    name="state"
                                    tabIndex="10"
                                    value={data.state}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.state)) ? (errors.state) : ''}
                                    className="col-md-12"
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="ZIP/Postal Code"
                                    placeholder="Enter ZIP/Postal code"
                                    name="postal_code"
                                    tabIndex="11"
                                    value={data.postal_code}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.postal_code)) ? (errors.postal_code) : ''}
                                    className="col-md-12"
                                    maskType={'digit-only'}
                                    maxLength={10}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormSelect
                                    label="Country"
                                    placeholder="Enter country"
                                    name="country"
                                    onChange={handleChange}
                                    className="col-md-12"
                                    value={data.country}
                                    error={(action.isSubmitted && (errors.country)) ? (errors.country) : ''}
                                    data={countries}
                                    tabIndex="12"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {/* <FormPhoneInput
                                    label="Phone Number"
                                    name="phone"
                                    tabIndex="13"
                                    error={(action.isSubmitted && (errors.phone)) ? (errors.phone) : ''}
                                    placeholder="Enter phone number"
                                    value={data.phone}
                                    onChange={handleChange}
                                    className="col-md-12"
                                    maxLength={100}
                                /> */}
                                <FormPhoneInputNew
                                    label="Phone Number"
                                    name="phone"
                                    tabIndex="13"
                                    error={(action.isSubmitted && (errors.phone)) ? (errors.phone) : ''}
                                    placeholder="Enter phone number"
                                    value={data.phone}
                                    onChange={handleChange}
                                    className="col-md-12"
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Box className="col-md-12">
                                    <p>Terms and Conditions for Brands are at <a href="https://guruclub.wpengine.com/terms-of-service-brands" target="_blank" rel="noopener noreferrer">https://guruclub.wpengine.com/terms-of-service-brands</a></p>
                                </Box>
                            </Grid>
                        </Grid>
                    </ConfzModalBody>
                    <ConfzModalFooter>
                        <ConfzModalFooterCloseBtn tabIndex="15" onHide={onHide} text="Cancel" />
                        <ConfzModalFooterSaveBtn tabIndex="14" isProcessing={action.isProcessing} text="Ok" />
                    </ConfzModalFooter>
                </form>
            </ConfzModal>
            <Button variant="contained" color="primary" startIcon={<Payment>Add</Payment>} onClick={addUpdateCardModal}>Add Credit Card</Button>
        </Box >
    )
}




function mapStateToProps(state) {
    return {
        user: state.UserReducer.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPaymentDetails: () => dispatch(PaymentAction.getPaymentDetails()),
        addUpdateCard: (params) => dispatch(PaymentAction.addUpdateCard(params)),
        setDefaultPayment: (params) => dispatch(PaymentAction.setDefaultPaymentMethod(params)),
    }
};

const connectedAddUpdateCreditCard = connect(mapStateToProps, mapDispatchToProps)(AddUpdateCreditCard);
export { connectedAddUpdateCreditCard as AddUpdateCreditCard };

