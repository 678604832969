import React, { useEffect, useState } from 'react'
import { PageTitle } from '../../../../_layouts/page-title';
import { Grid } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardContent } from '../../../../_components/card';
import { FormCancelBtn, FormInput, FormSaveBtn, FormSelect } from '../../../../_components/form-input';
import { handleInputChange, validateForm } from '../../../../_helpers';
import validate from './validate';
import { CouponAction } from '../../../../_redux/actions';

function CreateSingleUseCodes({ createSingleUseCodes, getCustomCouponCampaigns, isCampaignsLoading, campaigns }) {

    const navigate = useNavigate();

    const inputs = {
        campaign: '',
        amount: '',
    }

    const [data, setData] = useState({ ...inputs });
    const [campaignSelect, setCampaignSelect] = useState([]);
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    useEffect(() => {
        getCustomCouponCampaigns();
    }, [])

    useEffect(() => {
        if (campaigns.length > 0) {
            
            const campaignArray = campaigns.map(item => {
                return {
                  value: item.ID,
                  label: item.name
                };
            });
            setCampaignSelect(campaignArray);
        }
    }, [campaigns])    

    const handleChange = async (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(await validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = await validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }

        dispatchCreateCouponAction(data);
    }

    const dispatchCreateCouponAction = (formData) => {
        let dispatchAction = createSingleUseCodes(formData);
        dispatchAction.then((response) => {
            if (response.status === 1) {
                setAction({ ...action, isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
                navigate(`/coupons/upload`);
            } else {
                setErrors({ ...response.errors })
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }
        });
    }

    const returnToCustomCoupons = () => {
        navigate(`/coupons/upload`);
    }

    return (
        <React.Fragment>
            <PageTitle title='Select Coupons' />
            <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
                    <Card style={{ overflow: `initial` }}>
                        <CardContent style={{ padding: `25px` }}>
                            <form onSubmit={handleSubmit} noValidate className='ns-form' >

                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormSelect
                                            label={`Campaign`}
                                            name={`campaign`}
                                            placeholder={`Select campaign`}
                                            onChange={handleChange}
                                            data={campaignSelect}
                                            className="col-md-12"
                                            value={data.campaign}
                                            error={(action.isSubmitted && (errors.campaign)) ? (errors.campaign) : ''}
                                    />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            label="Quantity"
                                            placeholder="Enter quantity"
                                            name="amount"
                                            maskType={'digit-only'}
                                            tabIndex="2"
                                            value={data.amount}
                                            onChange={handleChange}
                                            error={(action.isSubmitted && (errors.amount)) ? (errors.amount) : ''}
                                            maxLength={100}
                                            className="col-md-12"
                                        />
                                    </Grid>
                                    
                                </Grid>
                                <Grid container>
                                    <Grid item >
                                        <FormSaveBtn isProcessing={action.isProcessing} text='Create' style={{ marginRight: `5px` }} />
                                        <FormCancelBtn text='Cancel' onClick={returnToCustomCoupons} />
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment >
    )
}



function mapStateToProps(state) {
    return {
        isCampaignsLoading: state.CouponReducer.custom_coupon_campaigns_loading,
        campaigns: state.CouponReducer.custom_coupon_campaigns,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createSingleUseCodes: (params) => dispatch(CouponAction.createSingleUseCodes(params)),
        getCustomCouponCampaigns: () => dispatch(CouponAction.getCustomCouponCampaigns()),
    }
};

const connectedCreateSingleUseCodes = connect(mapStateToProps, mapDispatchToProps)(CreateSingleUseCodes);
export { connectedCreateSingleUseCodes as CreateSingleUseCodes };
