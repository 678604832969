import React, { useEffect } from 'react'
import { useState } from 'react';
import { handleInputChange } from '../../_helpers';
import { FormDateRangePicker } from '../form-input';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from '../modal';


function FilterModal({ show, filter, closeModal, onApplyFilter }) {

    const inputs = { ...filter }
    const [data, setData] = useState({ ...inputs });
    const [showLoader, setShowLoader] = useState(false);
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });


    const onHide = () => {
        closeModal(false);
    }

    /**
    * function to handle input change
    * @param object e input object
    * @author Akshay N
    */
    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        data[name] = value;
        if (value === '')
            delete data[name];
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ isSubmitted: false, isProcessing: false });
        onApplyFilter(data);
    }

    const handleReset = () => {
        closeModal(false);
        setData({});
        onApplyFilter({});
    }

    useEffect(() => {
        setShowLoader(false);
    }, [])

    return (
        <ConfzModal isModalOpen={show} onHide={onHide} loader={showLoader}>
            <form onSubmit={handleSubmit} noValidate>
                <ConfzModalHeader id="filter" title="Filter" onHide={onHide} />
                <ConfzModalBody>
                    <FormDateRangePicker name={`created_at`} label={`Created At`} placeholder={`Select the date range`} onApply={handleChange} value={data.created_at} />
                </ConfzModalBody>
                <ConfzModalFooter>
                    <ConfzModalFooterCloseBtn tabIndex="3" onHide={handleReset} text="Reset" />
                    <ConfzModalFooterSaveBtn tabIndex="2" isProcessing={action.isProcessing} text="Apply" />
                </ConfzModalFooter>
            </form>
        </ConfzModal>
    )
}

export { FilterModal }
