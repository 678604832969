
const validateAddUpdateBankDetails = (name, value, data) => {
    const errors = {};
    switch (name) {

        case 'account_holder_name': {
            errors.account_holder_name = '';
            if (value === '')
                errors.account_holder_name = 'Account holder name is required. ';
            break;
        } case 'routing_number': {
            errors.routing_number = value.length < 9 ? 'Routing number must be 9 characters long.' : '';
            if (value === '')
                errors.routing_number = 'Routing number is required. ';
            break;
        } case 'confirm_routing_number': {
            const routing_number = data.routing_number ?? '';
            errors.confirm_routing_number = value.length < 9 ? 'Routing number must be 9 characters long.' : '';
            if (routing_number !== '' && routing_number !== value)
                errors.confirm_routing_number = 'Confirm routing number should match with the routing number.';
            if (value === '')
                errors.confirm_routing_number = 'Re-enter routing number.';
            break;
        } case 'account_number': {
            errors.account_number = '';
            if (value === '')
                errors.account_number = 'Account number is required. ';
            break;
        } case 'confirm_account_number': {

            const account_number = data.account_number ?? '';
            errors.confirm_account_number = '';
            if (account_number !== '' && account_number !== value)
                errors.confirm_account_number = 'Confirm account number should match with the account number.';
            if (value === '')
                errors.confirm_account_number = 'Re-enter account number.';
            break;
        } case 'account_holder_type': {
            errors.account_holder_type = '';
            if (value === '')
                errors.account_holder_type = 'Select an account holder type. ';
            break;
        } case 'name': {
            errors.name = '';
            if (value === '')
                errors.name = 'Full name is required. ';
            break;
        } case 'address_line1': {
            errors.address_line1 = '';
            if (value === '')
                errors.address_line1 = 'Address line 1 is required. ';
            break;
        } case 'city': {
            errors.city = '';
            if (value === '')
                errors.city = 'City is required. ';
            break;
        } case 'state': {
            errors.state = '';
            if (value === '')
                errors.state = 'State/Province/Region is required. ';
            break;
        } case 'postal_code': {
            errors.postal_code = '';
            if (value === '')
                errors.postal_code = 'Postal code is required. ';
            break;
        } case 'country': {
            errors.country = '';
            if (value === '')
                errors.country = 'Country/Region is required. ';
            break;
        } case 'phone': {
            errors.phone = '';
            if (value === 'undefined') {
                errors.phone = 'Phone number is invalid. '
            }
            if (value === '')
                errors.phone = 'Phone number is required. ';
            break;
        } default:
            errors[name] = '';
            break;
    }
    return errors;
}
export default validateAddUpdateBankDetails;