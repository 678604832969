import { SettingsServices } from "../services";


export const GET_LOGIN_SETTINGS_REQUEST = 'GET_LOGIN_SETTINGS_REQUEST';
export const GET_LOGIN_SETTINGS_SUCCESS = 'GET_LOGIN_SETTINGS_SUCCESS';
export const GET_LOGIN_SETTINGS_FAILURE = 'GET_LOGIN_SETTINGS_FAILURE';

export const UPDATE_SUBDOMAIN_REQUEST = 'UPDATE_SUBDOMAIN_REQUEST';
export const UPDATE_SUBDOMAIN_SUCCESS = 'UPDATE_SUBDOMAIN_SUCCESS';
export const UPDATE_SUBDOMAIN_FAILURE = 'UPDATE_SUBDOMAIN_FAILURE';

export const UPDATE_LOGIN_SETTINGS_REQUEST = 'UPDATE_LOGIN_SETTINGS_REQUEST';
export const UPDATE_LOGIN_SETTINGS_SUCCESS = 'UPDATE_LOGIN_SETTINGS_SUCCESS';
export const UPDATE_LOGIN_SETTINGS_FAILURE = 'UPDATE_LOGIN_SETTINGS_FAILURE';

export const SettingsAction = { getLoginSettings, updateSubDomain, updateLoginSettings };



/**
 * Action get LOGIN_SETTINGS
 *
 * @author Akshay 
 */
function getLoginSettings() {
    return dispatch => {
        dispatch(request());
        return SettingsServices.getLoginSettings()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request() { return { type: GET_LOGIN_SETTINGS_REQUEST } }
    function success(response) { return { type: GET_LOGIN_SETTINGS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_LOGIN_SETTINGS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * function description
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at
 */

function updateSubDomain(params) {
    return dispatch => {
        dispatch(request(params));
        return SettingsServices.updateSubDomain(params)
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(request) { return { type: UPDATE_SUBDOMAIN_REQUEST, reques: request } }
    function success(response) { return { type: UPDATE_SUBDOMAIN_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: UPDATE_SUBDOMAIN_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * function description
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at
 */

function updateLoginSettings(params) {
    return dispatch => {
        dispatch(request(params));
        return SettingsServices.updateLoginSettings(params)
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(request) { return { type: UPDATE_LOGIN_SETTINGS_REQUEST, reques: request } }
    function success(response) { return { type: UPDATE_LOGIN_SETTINGS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: UPDATE_LOGIN_SETTINGS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}