import React from 'react'
import { Box, Grid } from '@material-ui/core';
import { DataTable, TrialCouponCodes } from '.';
import { connect } from 'react-redux';
import { PageTitle } from '../../_layouts/page-title';
import { Card, CardHeader, CardContent } from '../../_components/card';
import { useLocation } from 'react-router-dom';

function Invoices() {

    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const sessionId = params.get('session_id'); // plan promo from url

    return (
        <Box>
            <PageTitle title="Statements" action={
                    <React.Fragment>
                        <TrialCouponCodes />
                    </React.Fragment>
                }/>
            {/* <Payments /> */}
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Card>
                        <CardHeader title="Statements List" />
                        <CardContent>
                            <DataTable sessionId={sessionId}/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </Box>
    )
}


const connectedInvoices = connect(null, null)(Invoices);
export { connectedInvoices as Invoices };
