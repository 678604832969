const validate = async (name, value, data) => {
    //eslint-disable-next-line
    const errors = {};
    switch (name) {

        case 'campaign': {
            errors.campaign = '';
            if (value === '')
                errors.campaign = 'Campaign is required. ';
            break;
        }  case 'amount': {
            errors.amount = '';
            if (value === '')
                errors.amount = 'Quantity is required. ';
            break;
        }  default:
            errors[name] = '';
            break;
    }
    return errors;
}
export default validate;