import { Box } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ComparisonWidget } from '../../../_components/comparison-widget';

function DcVsNewFollowers({ isLoading, new_followers, daily_coupons }) {

    const navigate = useNavigate();

    const viewChart = () => {
        navigate({
            pathname: "/ig-insights/chart",
            search: `?metric=follower_count&type=dc`
        })
    }

    return (
        <Box style={{ cursor: 'pointer' }} onClick={() => viewChart()}>
            <ComparisonWidget loading={isLoading} text={'Daily Coupon Count'} value={daily_coupons} text2={'New Followers'} value2={new_followers} bgColor={"#0ec164"} color={"#ffffff"} />
        </Box>
    );


}


function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    }
};

const connectedDcVsNewFollowers = (connect(mapStateToProps, mapDispatchToProps)(DcVsNewFollowers));
export { connectedDcVsNewFollowers as DcVsNewFollowers };
