import { Box, Typography } from '@material-ui/core'
import { Error } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    errorBox: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: `100%`,
    },
    innerBox: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: `5px`,
        padding: `15px`,
        color: `#721c24`,
        backgroundColor: `#f8d7da`,
        borderColor: `#f5c6cb`
    },
    errorIcon: {
        marginRight: `10px`
    }
}))

function ErrorAlert({ message }) {

    const classes = useStyles();

    return (
        <Box className={classes.errorBox}>
            <Box className={classes.innerBox}>
                <Error color="error" className={classes.errorIcon} />
                <Typography variant="subtitle1" component="p">{message}</Typography>
            </Box>
        </Box>
    )
}

export { ErrorAlert }
