import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { UserAction } from '../../../_redux/actions';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function FollowerChangeGraph({ follower_change_graph_graph, isLoading }) {

    const { maxFollowerChange, avgFollowerChange, y, xAxis, date_period, latestFollowerChange} = follower_change_graph_graph || {maxFollowerChange: {}, avgFollowerChange: '', y: [], xAxis: [], date_period: {}, latestFollowerChange: {} };

    const options = {
        title: {
            text: ''
        },
    
        yAxis: {
            title: {
                text: 'Followers'
            }
        },
    
        xAxis: {
            title: {
                text: `${date_period.from??''} - ${date_period.to ?? ''}`
            },
            type: 'category',
            categories: xAxis
        },
    
        legend: {
            align: 'right',
            verticalAlign: 'top',
            itemMarginBottom: 10
        },
    
        series: [{
            name: 'Followers',
            data: y
        }],
    
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 450
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    }
   
    return (
        <React.Fragment>
            <Card>
                <CardContent style={{ minHeight: `550px` }}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <table style={{textAlign: 'center'}}>
                                <tr>
                                    <th style={{padding: '0.5vw', verticalAlign:'top', fontWeight:'normal'}}>Followers</th>
                                    <th style={{padding: '0.5vw', verticalAlign:'top', fontWeight:'normal'}}>Follower Change</th>
                                    <th style={{padding: '0.5vw', verticalAlign:'top', fontWeight:'normal'}}>Max. Follower Change</th>
                                    <th style={{padding: '0.5vw', verticalAlign:'top', fontWeight:'normal'}}>Avg. Follower Change</th>
                                </tr>
                                <tr>
                                    <td style={{fontWeight:'bold'}}><h5>{y.length > 0 ? y[y.length-1] : 0}</h5></td>
                                    <td style={{fontWeight:'bold'}}>
                                        <Grid container justify="center">  
                                            <Typography style={{color: '#37bd68' }} inline variant="h5" >{latestFollowerChange.follower_change ?? 0}</Typography>
                                            <Typography inline variant="inherit" style={{paddingLeft: '0.5vw', color: '#37bd68'}}>{latestFollowerChange.percent ? (latestFollowerChange.percent+ '%'): '0%'}</Typography>
                                        </Grid>
                                    </td>
                                    <td style={{fontWeight:'bold'}}><h5>{maxFollowerChange.follower_change}</h5></td>
                                    <td style={{fontWeight:'bold'}}><h5>{avgFollowerChange>0 ? '+'+ avgFollowerChange : avgFollowerChange }</h5></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td style={{fontWeight:'bold', fontSize: 'smaller'}}>{maxFollowerChange.date}</td>
                                    <td></td>
                                </tr>
                            </table>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {(typeof(isLoading) !== 'undefined' && !isLoading) && <HighchartsReact highcharts={Highcharts} options={options} />}
                        </Grid>
                    </Grid>
                    
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        isLoading: state.UserReducer.follower_change_graph_graph_loading,
        follower_change_graph_graph: state.UserReducer.follower_change_graph_graph
    };
}


function mapDispatchToProps(dispatch) {
    return {
        getFollowerChange: dispatch(UserAction.getFollowerChange())
    }
};

const connectedFollowerChangeGraph = connect(mapStateToProps, mapDispatchToProps)(FollowerChangeGraph);
export { connectedFollowerChangeGraph as FollowerChangeGraph };
