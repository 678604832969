const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => {
        (val !== '') && (valid = false)
    });
    return valid;
}

export { validateForm}


