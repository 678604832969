import React from 'react'

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { BlurOn } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        marginBottom: theme.spacing(3),
        borderBottom: `1px solid #b0bec5`
    },
    headIcon: {
        paddingRight: theme.spacing(1)
    },
    headTitle: {
        fontWeight: 900,
        paddingBottom: theme.spacing(1),
    },
    pageActions: {
        '&  button': {
            margin: '0 5px 5px 5px',
            "@media (max-width: 600px)": {
                width: `100%`
            }
        }
    }
}))


function PageTitle({ title, action = null }) {

    const classes = useStyles()

    return (
        <Box className={classes.titleContainer} >
            <Grid container spacing={1} justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4" component="h4" color="initial" className={classes.headTitle}>
                        <BlurOn className={classes.headIcon} />{title}
                    </Typography>
                </Grid>
                {action && (<Grid item className={classes.pageActions}> {action} </Grid>)}
            </Grid>
        </Box>
    )
}

export { PageTitle }
