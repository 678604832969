const validate = (name, value) => {
    //eslint-disable-next-line
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const errors = {};
    switch (name) {
        case 'email': {
            errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid. ';
            if (value === '')
                errors.email = 'Email is required. ';
            break;
        } case 'password': {
            errors.password = value.length < 6 ? 'Password must be 6 characters long. ' : '';
            if (value === '')
                errors.password = 'Password is required. ';
            break;
        } default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}
export default validate;