import {
    APPLY_TRIAL_COUPON_CODE_REQUEST, APPLY_TRIAL_COUPON_CODE_SUCCESS, APPLY_TRIAL_COUPON_CODE_FAILURE,
    IS_BRAND_ON_TRIAL_REQUEST, IS_BRAND_ON_TRIAL_SUCCESS, IS_BRAND_ON_TRIAL_FAILURE,
} from "../actions";

const initialState = {
    apply_trial_coupon_code_loading: false, apply_trial_coupon_code:{}, apply_trial_coupon_code_error: [],
    is_brand_on_trial_loading: false, is_brand_on_trial:true, is_brand_on_trial_error: [],
};

export function TrialCouponReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Min Coupon redux */
        case APPLY_TRIAL_COUPON_CODE_REQUEST: {
            return {
                ...state,
                apply_trial_coupon_code_loading: true,
                apply_trial_coupon_code: 0,
                apply_trial_coupon_code_error: []
            };
        } case APPLY_TRIAL_COUPON_CODE_SUCCESS: {
            return {
                ...state,
                apply_trial_coupon_code_loading: false,
                apply_trial_coupon_code: action.data.trial,
                is_brand_on_trial: action.data.trial,
                apply_trial_coupon_code_error: [],
            };
        } case APPLY_TRIAL_COUPON_CODE_FAILURE: {
            return {
                ...state,
                apply_trial_coupon_code_loading: false,
                apply_trial_coupon_code: 0,
                apply_trial_coupon_code_error: []
            };
        }
        /** end::Min Coupon redux */

        /** begin::Min Coupon redux */
        case IS_BRAND_ON_TRIAL_REQUEST: {
            return {
                ...state,
                is_brand_on_trial_loading: true,
                is_brand_on_trial_error: []
            };
        } case IS_BRAND_ON_TRIAL_SUCCESS: {
            return {
                ...state,
                is_brand_on_trial_loading: false,
                is_brand_on_trial: action.data.trial,
                is_brand_on_trial_error: [],
            };
        } case IS_BRAND_ON_TRIAL_FAILURE: {
            return {
                ...state,
                is_brand_on_trial_loading: false,
                is_brand_on_trial_error: []
            };
        }
        /** end::Min Coupon redux */
        
        default:
            return state;
    }
}