

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { makeStyles } from '@material-ui/core'
import { formatPhoneNumberIntl } from "react-phone-number-input";

const useStyles = makeStyles({
    intlTelInputs: {
        height: `34px`,
        width: '100%',
        '& .iti-flag': {
            // marginTop: '10px',
        },
        '& .selected-dial-code': {
            paddingLeft: '10px !important'
        }
    },

    inputClass: {
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: '1px solid #ced4da',
    },
})
function FormPhoneInputNew({ label, name, value, placeholder, className, onChange, tabIndex, id, error, formOpen, ...props }) {

    const [telValue, setTelValue] = useState(value ?? '');

    const [phoneKey, setPhoneKey] = useState(1);

    const onSelectFlag = (num, country) => {
        console.log(num, country);
    }
    const classes = useStyles();

    useEffect(() => {
        console.log('number changes');
        if (formOpen === 2) {
            setTelValue('')
            setPhoneKey(phoneKey + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formOpen]);



    const handlePhoneChange = (status, teliValue, countryData, number, id) => {
        setTelValue('+' + countryData.dialCode + teliValue);
        onChange(
            {
                target:
                {
                    type: 'tel2',
                    name: name,
                    value: teliValue === '' ? '' : status ? (formatPhoneNumberIntl('+' + countryData.dialCode + teliValue)) : 'undefined',
                }
            }
        );
    }

    return (
        <div className={`form-group ${className}`}>
            <label htmlFor={name}>{label}</label>
            <IntlTelInput
                key={phoneKey}
                containerClassName={`intl-tel-input tel-wrapper ${classes.intlTelInputs}`}
                inputClassName={classes.inputClass}
                placeholder={placeholder}
                defaultValue={telValue}
                fieldName={name}
                tabIndex={tabIndex}
                onPhoneNumberChange={handlePhoneChange}
                defaultCountry='us'
                separateDialCode={true}
                formatOnInit={true}
                format={true}
                onSelectFlag={onSelectFlag}
                onPhoneNumberFocus={handlePhoneChange}
            />
            {(error) && (<div className="invalid-feedback" style={{ display: `block` }}>{error}</div>)}
        </div>
    )
}

FormPhoneInputNew.defaultProps = {
    className: ""
}

FormPhoneInputNew.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
    tabIndex: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

export { FormPhoneInputNew }

