import React from 'react'
import PropTypes from 'prop-types'

function Search({ searchText, value }) {

    const handleSearch = (event) => {
        if (event.key === 'Enter' || event.keyCode === 13 || event.target.value === '') {
            searchText(event.target.value);
        }
    }

    return (
        <input type="search" className="form-control" placeholder="Search" onKeyUp={handleSearch} onChange={handleSearch} defaultValue={value} />
    )
}



Search.propTypes = {
    value: PropTypes.string,
    searchText: PropTypes.func.isRequired
}

export { Search }
