import React, {FC} from "react"
import "./Overlay.scss"
export interface IOverlay {
	opacity?: number
	blur?: boolean
}
const Overlay: FC<IOverlay> =({opacity=0.5, blur=false, children}) => {
	if(blur) {
		return <div className="overlay blur"/>
	}

	return <div style={{backgroundColor: `rgb(255,255,255,${opacity})`}} className="overlay" >
		{children}
	</div>
}

export default Overlay
