
import React, { useEffect, useState } from 'react'
import { CouponAction } from '../../_redux/actions';
import { Import, IssuedCoupons, RemainingCoupons, TotalCoupons, MinCouponCount } from '.'
import { connect } from 'react-redux';
import { DataTable } from '.';
import { SampleCsv } from './actions/SampleCsv';

import { Box, Grid, makeStyles, Tooltip, Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons'
import { PageTitle } from '../../_layouts/page-title';
import { Card, CardHeader, CardContent } from '../../_components/card';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalHeader } from '../../_components/modal';
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    couponsWidgetContainer: {
        marginBottom: theme.spacing(2)
    },
    action: {
        margin: 0
    }
}));


function Coupons({ balance_coupons, deleteActiveCoupons, getCustomCoupons }) {
    const navigate = useNavigate();


    const classes = useStyles();

    let page = 0;

    const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false)
    const [subAccount, setSubAccount] = useState('')
    const [showLoader, setShowLoader] = useState(true);

    const onHide = () => {
        setShowDeleteWarningModal(false);
    }

    const deleteActiveCoupon = (e) => {
        e.preventDefault();
        setShowDeleteWarningModal(true);
    }

    const deleteActiveCouponsAction = () => {
        deleteActiveCoupons().then(() => {
            setShowDeleteWarningModal(false);
        })
    }

    const createSingleUseCodes = () => {
        if (subAccount) {
            let pageUrl = `/coupons/create/single-use-codes`;
            navigate(pageUrl);
        }
    }

    useEffect(() => {
        getCustomCoupons().then((response) => {
            if (response.status === 1) {
                setSubAccount(response.data.subaccount ?? '')
                setShowLoader(false);
            } else {
                setShowLoader(false);
            }
        });
    }, [])


    return (
        <Box>

            <PageTitle title="Select/Upload"
                action={
                    <React.Fragment>
                        {((!showLoader) && subAccount) && <Button variant="contained" color="primary" onClick={createSingleUseCodes}>Select Coupons</Button>}
                        <SampleCsv />
                        <Import />
                        {/* <Tooltip title="Delete Unused Coupons">
                            <Button variant="contained" color="secondary" disabled={balance_coupons === 0} startIcon={<Delete />} onClick={deleteActiveCoupon}>
                                Delete Unused Coupons
                            </Button>
                        </Tooltip> */}
                        <MinCouponCount />
                    </React.Fragment>
                }
            />

            <ConfzModal isModalOpen={showDeleteWarningModal} onHide={onHide} loader={false} size="md">
                <ConfzModalHeader id="RemoveCoupons" title="Remove all active coupons" onHide={onHide} />
                <ConfzModalBody className="text-center">
                    <h1>Are You Sure?</h1>
                    <p>{`Do you want to remove all active coupons?`}</p>
                </ConfzModalBody>
                <ConfzModalFooter>
                    <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onHide}>Cancel</Button>
                    <Button variant="contained" color="primary" size="small" className={classes.button} onClick={deleteActiveCouponsAction} >Confirm</Button>
                </ConfzModalFooter>
            </ConfzModal>

            <Grid container spacing={3} style={{ marginBottom: `15px` }}>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <TotalCoupons />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <IssuedCoupons />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <RemainingCoupons />
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Card>
                        <CardHeader
                            title="Coupon Codes"
                            classes={{ action: classes.action }}
                            action={<Button variant="contained" color="secondary" disabled={balance_coupons === 0} startIcon={<Delete />} onClick={deleteActiveCoupon}>
                            Delete Unused Coupons
                        </Button>}
                        />
                        <CardContent>
                            <DataTable page={page} search={''} filter={''} sort={''} editCoupon={''} handleSelect={''} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}


function mapStateToProps(state) {
    return {
        page: state.CouponReducer.page,
        used_coupons: state.CouponReducer.used_coupons,
        balance_coupons: state.CouponReducer.balance_coupons,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addCoupons: (params) => dispatch(CouponAction.addCoupons(params)),
        deleteActiveCoupons: () => dispatch(CouponAction.deleteActiveCoupons()),
        getCustomCoupons: () => dispatch(CouponAction.getCustomCoupons()),
    }
};

const connectedCoupon = (connect(mapStateToProps, mapDispatchToProps)(Coupons));
export { connectedCoupon as Coupons };


