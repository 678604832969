import React from 'react'
import { connect } from 'react-redux';
import { SummaryWidget } from '../../../_components/summary-widget';


function RemainingCoupons({ isLoading, balance_coupons }) {

    return <SummaryWidget loading={isLoading} text={'Coupons Remaining'} value={balance_coupons} bgColor={"#5263f7"} color={"#ffffff"} />

}

function mapStateToProps(state) {
    return {
        balance_coupons: state.CouponReducer.balance_coupons,
    };
}


const connectedCoupons = (connect(mapStateToProps, null)(RemainingCoupons));
export { connectedCoupons as RemainingCoupons };

