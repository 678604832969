import { createTheme, responsiveFontSizes } from "@material-ui/core";
import colors from "./colors";
const font = "'Montserrat', sans-serif;"
const theme = createTheme({
	palette: {
		primary: {
			main: colors.primary
		},
		secondary: {
			main: colors.secondary
		},
		error: {
			main: colors.red
		}
	},
	typography: {
		fontFamily: font,
		button: {
			textTransform: "none"
		}
	},
	zIndex: {
		drawer: 1000
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 576,
			md: 768,
			lg: 1200,
			xl: 1400,
		},
	},
})

export default responsiveFontSizes(theme)
