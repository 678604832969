import React, { useEffect, useState } from 'react'
import Config from '../../../_helpers/config';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { RouteConfig } from '../../../_routes';
import { FormGroupPassword } from '../../../_components/form-group-input';
import { FormInput } from '../../../_components/form-input';
import { handleInputChange, validateForm } from '../../../_helpers';
import { ForgotPasswordValidateEmail, ForgotPasswordVerifyOtp, ForgotPasswordSetPassword } from './ForgotPasswordValidate';
import { ForgotPasswordAction } from '../../../_redux/actions';

import { paperElevation } from '../../../config/config';
import Paper from '@material-ui/core/Paper';
import colors from '../../../theme/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import GuruLogo from '../../../components/GuruLogo';

import loginBackgroundImage from './../../../images/login_background.jpg';

function ForgotPassword({ ...props }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [data, setData] = useState({ email: '' });
    const [errors, setErrors] = useState({ email: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    const [otpData, setOtpData] = useState({ email: '', otp: '' });
    const [otpErrors, setOtpErrors] = useState({ email: '', otp: '' });

    const [passwordData, setPasswordData] = useState({ email: '', otp: '', password: '', confirm_password: '' });
    const [passwordDataErrors, setPasswordDataErrors] = useState({ email: '', otp: '', password: '', confirm_password: '' });

    const [page, setPage] = useState({ loaded: false, type: 'default', data: {} });


    const [view, setView] = useState('send-otp');


    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: 30,
            minWidth: 600,
            maxWidth: 600
        },
        title: {
            marginBottom: 40,
            color: theme.palette.common.white,
        },
        textField: {
            '& .MuiFormLabel-root': {
                color: theme.palette.common.white,
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'white',
                    color: theme.palette.common.white,
                },
                '&.Mui-focused fieldset': {
                    borderColor: colors.secondary,
                    color: theme.palette.common.white,
                },
                '&:hover fieldset': {
                    borderColor: colors.secondary,
                    color: theme.palette.common.white,
                },
                color: theme.palette.common.white,
            },
            marginBottom: 15,
        },
        whiteColor: {
            color: theme.palette.common.white,
        },
        loginBtn: {
            marginTop: 40,
            marginBottom: 20,
            backgroundColor: colors.secondary,
        },
        link: {
            color: 'white',
            cursor: 'pointer',
        },
        forgotPasswordContainer: {
            backgroundImage: `url(${loginBackgroundImage})`,
            backgroundPosition: `center`,
            backgroundSize: `cover`,
            backgroundRepeat: `no-repeat`,
            width: '100%',
            display: 'flex',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
        }
    }));

    const styles = useStyles();


    useEffect(() => {
        document.title = `${Config.app_name} | ${RouteConfig('forgot_password').name}`;
        setPage({ loaded: true, type: 'default' });
    }, []);


    /**
    * function to handle input change
    * @param object e input object
    * @author Akshay N
    */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(ForgotPasswordValidateEmail(name, value)) });
        data[name] = value;
        setData({ ...data });
        setOtpData({ ...otpData, email: data.email })
        setPasswordData({ ...passwordData, email: data.email })
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = ForgotPasswordValidateEmail(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);

        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true, isProcessing: false });
            return;
        }
        dispatchSendOtpAction();
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    function dispatchSendOtpAction() {
        dispatch(ForgotPasswordAction.sendForgotPasswordOtp(data))
            .then((response) => {
                if (response.status === 1) {
                    setView('verify-otp');
                    setAction({ isSubmitted: false, isProcessing: false });
                } else {
                    setAction({ isSubmitted: true, isProcessing: false });
                    setErrors({ ...response.errors })
                }
            });
    }


    /**
    * function to handle input change
    * @param object e input object
    * @author Akshay N
    */
    function handleVerifyOtpChange(e) {
        const { name, value } = handleInputChange(e);
        if (name === 'otp') {
            var digitPattern = new RegExp(/^[0-9\b]+$/);
            if (value === '' || digitPattern.test(value)) {
                setOtpErrors({ ...errors, ...(ForgotPasswordVerifyOtp(name, value)) });
                otpData[name] = value;
                setOtpData({ ...otpData });
                setPasswordData({ ...passwordData, otp: otpData.otp })
            }
        }
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    function handleVerifyOtp(e) {
        e.preventDefault();
        setAction({ isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(otpData)) {
            const inputError = ForgotPasswordVerifyOtp(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);

        if (!valid) {
            setOtpErrors(formErrors);
            setAction({ isSubmitted: true, isProcessing: false });
            return;
        }
        dispatchVerifyOtpAction();
    }

    /**
   * function description
   * @param Null
   * @return view
   * @author Akshay N
   * @created_at 
   */
    function dispatchVerifyOtpAction() {
        dispatch(ForgotPasswordAction.verifyForgotPasswordOtp(otpData))
            .then((response) => {
                if (response.status === 1) {
                    setAction({ isSubmitted: false, isProcessing: false });
                    setView('set-password')
                } else {
                    setAction({ isSubmitted: true, isProcessing: false });
                    setOtpErrors({ ...response.errors })
                }
            });
    }




    /**
    * function to handle input change
    * @param object e input object
    * @author Akshay N
    */
    function handleVerifyPasswordChange(e) {
        const { name, value } = handleInputChange(e);
        setPasswordDataErrors({ ...errors, ...(ForgotPasswordSetPassword(name, value, passwordData)) });
        passwordData[name] = value;
        setPasswordData({ ...passwordData });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    function handleVerifyPassword(e) {
        e.preventDefault();
        setAction({ isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(passwordData)) {
            const inputError = ForgotPasswordSetPassword(name, value, passwordData);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);

        if (!valid) {
            setPasswordDataErrors(formErrors);
            setAction({ isSubmitted: true, isProcessing: false });
            return;
        }
        dispatchChangePasswordAction();
    }


    /**
   * function description
   * @param Null
   * @return view
   * @author Akshay N
   * @created_at 
   */
    function dispatchChangePasswordAction() {
        dispatch(ForgotPasswordAction.changePassword(passwordData))
            .then((response) => {
                if (response.status === 1) {
                    setAction({ isSubmitted: false, isProcessing: false });
                    navigate("/insta-login");
                } else {
                    setAction({ isSubmitted: true, isProcessing: false });
                    setPasswordDataErrors({ ...response.errors })
                }
            });
    }



    if (page.loaded && page.type === 'default') {
        return (
            <div className={`flex-column  full-width full-height justify-center align-items-center ${styles.forgotPasswordContainer}`}>
                <Paper classes={{ root: styles.root }} elevation={paperElevation}>
                    <GuruLogo />
                    <Typography
                        color={'primary'}
                        classes={{ root: styles.title }}
                        variant={'h3'}
                    >
                        Forgot Password
                    </Typography>
                    {view === 'send-otp' &&
                        <form onSubmit={handleSubmit} className="auth-form" noValidate >
                            <FormInput
                                label="Email"
                                name="email"
                                type="email"
                                tabIndex="4"
                                error={(action.isSubmitted && (errors.email)) ? (errors.email) : ''}
                                placeholder="Enter email"
                                value={data.email}
                                onChange={handleChange}
                            />
                            <div className="form-group  form-group-action text-right pb-3">
                                <button type="submit" className="btn btn-block btn-warning btn-submit btn-icon btn-lg mb-2" tabIndex="6" disabled={action.isProcessing}>{(action.isProcessing) ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : `Send One Time Password`}</button>
                                <Link className="float-left" to={RouteConfig('sign_in').path}>Back to login? </Link>
                            </div>
                        </form>
                    }
                    {view === 'verify-otp' &&
                        <form onSubmit={handleVerifyOtp} className="auth-form" noValidate >
                            <FormInput
                                label="One Time Password"
                                name="otp"
                                type="text"
                                tabIndex="4"
                                pattern="[0-9]*"
                                error={(action.isSubmitted && (otpErrors.otp)) ? (otpErrors.otp) : ''}
                                placeholder="Enter 6 digit one time password"
                                value={otpData.otp}
                                onChange={handleVerifyOtpChange}
                                maxLength={6}
                            />
                            <div className="form-group  form-group-action text-right pb-3">
                                <button type="submit" className="btn btn-block btn-warning btn-submit btn-icon btn-lg mb-2" tabIndex="6" disabled={action.isProcessing}>{(action.isProcessing) ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : `Verify One Time Password`}</button>
                                <Link className="float-left" to={RouteConfig('sign_in').path}>Back to login? </Link>
                            </div>
                        </form>
                    }
                    {view === 'set-password' &&
                        <form onSubmit={handleVerifyPassword} className="auth-form" noValidate >
                            <FormGroupPassword
                                type="password"
                                label="Password"
                                name="password"
                                tabIndex="7"
                                error={(action.isSubmitted && (passwordDataErrors.password)) ? (passwordDataErrors.password) : ''}
                                placeholder="Enter password"
                                value={passwordData.password}
                                onChange={handleVerifyPasswordChange}
                                append='after'
                            />
                            <FormGroupPassword
                                type="password"
                                label="Confirm Password"
                                name="confirm_password"
                                tabIndex="8"
                                error={(action.isSubmitted && (passwordDataErrors.confirm_password)) ? (passwordDataErrors.confirm_password) : ''}
                                placeholder="Re-enter password"
                                value={passwordData.confirm_password}
                                onChange={handleVerifyPasswordChange}
                                append='after'
                            />
                            <div className="form-group  form-group-action text-right pb-3">
                                <button type="submit" className="btn btn-block btn-warning btn-submit btn-icon btn-lg mb-2" tabIndex="6" disabled={action.isProcessing}>{(action.isProcessing) ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : `Change Password`}</button>
                                <Link className="float-left" to={RouteConfig('sign_in').path}>Back to login? </Link>
                            </div>
                        </form>
                    }
                </Paper>
            </div>
        )
    } else if (page.loaded) {
        return (
            <React.Fragment>
                {/* <div className="logo-border">
                <img src={`${process.env.PUBLIC_URL}/img/vectors/logoBordered.svg`} alt="logo" height="60px" className="logo" />
            </div> */}

                <section className="section auth-layout" style={{ width: `100%` }}>
                    <div className="d-flex flex-wrap align-items-stretch ">
                        <div className="col-lg-6 col-md-6 col-6 order-lg-1 min-vh-100 auth-left" style={{ backgroundColor: page.data.brand_primary_color_hex, color: page.data.brand_secondary_color_hex }}>
                            <div className="row min-vh-100 d-flex align-items-center p-5">
                                <div className="col-md-12 mx-auto">
                                    <div className={`auth-col b-shadow-6 p-5`}>
                                        <h3 className="mb-4"> Forgot Password</h3>
                                        {view === 'send-otp' &&
                                            <form onSubmit={handleSubmit} noValidate >
                                                <FormInput
                                                    label="Email"
                                                    name="email"
                                                    type="email"
                                                    tabIndex="4"
                                                    error={(action.isSubmitted && (errors.email)) ? (errors.email) : ''}
                                                    placeholder="Enter email"
                                                    value={data.email}
                                                    onChange={handleChange}
                                                />
                                                <div className="form-group  form-group-action text-right pb-3">
                                                    <button style={{ backgroundColor: page.data.brand_tertiary_color_hex, borderColor: page.data.brand_tertiary_color_hex, color: page.data.brand_secondary_color_hex }} type="submit" className="btn btn-block btn-warning btn-submit btn-icon btn-lg mb-2" tabIndex="6" disabled={action.isProcessing}>{(action.isProcessing) ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : `Send One Time Password`}</button>
                                                    <Link style={{ color: page.data.brand_secondary_color_hex }} className="float-left" to={RouteConfig('sign_in').path}>Back to login? </Link>
                                                </div>
                                            </form>
                                        }
                                        {view === 'verify-otp' &&
                                            <form onSubmit={handleVerifyOtp} noValidate >
                                                <FormInput
                                                    label="One Time Password"
                                                    name="otp"
                                                    type="text"
                                                    tabIndex="4"
                                                    pattern="[0-9]*"
                                                    error={(action.isSubmitted && (otpErrors.otp)) ? (otpErrors.otp) : ''}
                                                    placeholder="Enter 6 digit one time password"
                                                    value={otpData.otp}
                                                    onChange={handleVerifyOtpChange}
                                                    maxLength={6}
                                                />
                                                <div className="form-group  form-group-action text-right pb-3">
                                                    <button style={{ backgroundColor: page.data.brand_tertiary_color_hex, borderColor: page.data.brand_tertiary_color_hex, color: page.data.brand_secondary_color_hex }} type="submit" className="btn btn-block btn-warning btn-submit btn-icon btn-lg mb-2" tabIndex="6" disabled={action.isProcessing}>{(action.isProcessing) ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : `Verify One Time Password`}</button>
                                                    <Link style={{ color: page.data.brand_secondary_color_hex }} className="float-left" to={RouteConfig('sign_in').path}>Back to login? </Link>
                                                </div>
                                            </form>
                                        }
                                        {view === 'set-password' &&
                                            <form onSubmit={handleVerifyPassword} noValidate >
                                                <FormInput
                                                    type="password"
                                                    label="Password"
                                                    name="password"
                                                    tabIndex="7"
                                                    error={(action.isSubmitted && (passwordDataErrors.password)) ? (passwordDataErrors.password) : ''}
                                                    placeholder="Enter password"
                                                    value={passwordData.password}
                                                    onChange={handleVerifyPasswordChange}
                                                />
                                                <FormInput
                                                    type="password"
                                                    label="Confirm Password"
                                                    name="confirm_password"
                                                    tabIndex="8"
                                                    error={(action.isSubmitted && (passwordDataErrors.confirm_password)) ? (passwordDataErrors.confirm_password) : ''}
                                                    placeholder="Re-enter password"
                                                    value={passwordData.confirm_password}
                                                    onChange={handleVerifyPasswordChange}
                                                />
                                                <div className="form-group  form-group-action text-right pb-3">
                                                    <button style={{ backgroundColor: page.data.brand_tertiary_color_hex, borderColor: page.data.brand_tertiary_color_hex, color: page.data.brand_secondary_color_hex }} type="submit" className="btn btn-block btn-warning btn-submit btn-icon btn-lg mb-2" tabIndex="6" disabled={action.isProcessing}>{(action.isProcessing) ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : `Change Password`}</button>
                                                    <Link style={{ color: page.data.brand_secondary_color_hex }} className="float-left" to={RouteConfig('sign_in').path}>Back to login? </Link>
                                                </div>
                                            </form>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6 order-lg-1 min-vh-100 auth-right" style={{ backgroundColor: page.data.brand_secondary_color_hex, color: page.data.brand_primary_color_hex }}>
                            <div className="row min-vh-100 d-flex align-items-center p-5">
                                <div className="col-md-12 mx-auto">
                                    <div className="text-center p-3">
                                        <a target="_blank" rel="noopener noreferrer" href={`${page.data.company_url}`}>
                                            <img src={`${page.data.brand_logo}`} height="50px" alt="Brand Logo" />
                                        </a>
                                    </div>
                                    <p className="text-center">{page.data.brand_description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    } else {
        return (
            <div className="min-vh-100" style={{ width: `100%` }}>
                <div className="row">
                    <div className="col-md-12 mx-auto">
                        <div className="row">
                            <div className="col-md-12 text-center d-flex align-items-center justify-content-center min-vh-100">
                                <div class="spinner-grow" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const connectedForgotPassword = (connect(null)(ForgotPassword));
export { connectedForgotPassword as ForgotPassword };
