import React from 'react'

import { Button, makeStyles } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    titleContainer: {
        marginBottom: theme.spacing(2),
        borderBottom: `1px solid #b0bec5`
    },
    headIcon: {
        paddingRight: theme.spacing(1)
    },
    headTitle: {
        fontWeight: 900,
        paddingBottom: theme.spacing(1),
    },
    headButton: {
        margin: '0 5px',
    },
    giveawayWinnersContainer: {
        marginBottom: theme.spacing(2)
    },
}));

function SampleCsv() {

    const classes = useStyles();

    const onDownload = () => {
        const link = document.createElement("a");
        link.download = `${process.env.PUBLIC_URL}/csv/coupon_sample_file.csv`;
        link.href = `${process.env.PUBLIC_URL}/csv/coupon_sample_file.csv`;
        link.click();
    };



    return (
        <Button variant="contained" color="primary" className={classes.headButton} startIcon={<CloudDownload>send</CloudDownload>} onClick={onDownload}>
            Download sample coupons CSV file
        </Button>
    )
}

export { SampleCsv }

