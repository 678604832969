import { authHeader, handleResponse } from "../../_helpers";
import Config from "../../_helpers/config";

export const ApproveBusinessServices = { approve };


/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function approve(id, key) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';
    return fetch(`${Config.endpoint}/ns/approve/${id}/${key}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}
