import React, { FC } from "react";
import "./LoadingIndicator.scss"
import CircularProgress from "@material-ui/core/CircularProgress";
import Overlay from "../Overlay";
export interface ILoadingIndicator {

}

const LoadingIndicator: FC<ILoadingIndicator> = () => {
	return (
		<Overlay>
			<div className="full-height full-width flex-column justify-center align-items-center">
				<CircularProgress />
			</div>
		</Overlay>
	)
}

export default LoadingIndicator
