import React, { useEffect } from 'react'
import { connect } from 'react-redux';
// import { withRouter } from 'react-router';
import { ConfzDatatable } from '../../../_components/data-table'
import { DesignationAction } from '../../../_redux/actions';
import { RouteConfig } from '../../../_routes';
import queryString from 'query-string';

function DataTable({ loading, designations, page, per_page, total, search, filter, sort, addDesignations, deleteDesignation, editDesignation, ...props }) {

    const columns = [
        { name: 'Sl.No', selector: 'sl_no', sortable: true, center: true, width: '5%', cell: (row, i) => ((per_page * (page - 1)) + (i + 1)) },
        { name: 'Name', selector: 'name', sortable: true, width: '30%' },
        { name: 'Description', selector: 'description', maxWidth: '700px' },
        { name: 'Status', selector: 'status_badge', sortable: true, center: true, width: '8%', cell: row => (<StatusRow row={row} />) },
    ];

    const StatusRow = ({ row }) => {
        let status = <span className="badge badge-sm badge-status badge-danger">{row.status}</span>;
        if (row.status === 'active')
            status = <span className="badge badge-sm badge-status badge-success">{row.status}</span>;
        else if (row.status === 'inactive')
            status = <span className="badge badge-sm badge-status badge-warning">{row.status}</span>;
        let statusHover = (
            <div className="btn-group row-action" role="group" >
                <span onClick={() => deleteDesignation(row.en_id)} ><i className="fa fa-file"></i></span>
                <span onClick={() => editDesignation(row.en_id)} ><i className="fa fa-pen"></i></span>
                {(row.status === 'active') ? <span onClick={() => deleteDesignation(row.en_id)} ><i className="fa fa-times"></i></span> : <span onClick={() => deleteDesignation(row.en_id)} ><i className="fa fa-check"></i></span>}
                {(row.status !== 'deleted') ? <span onClick={() => deleteDesignation(row.en_id)} ><i className="fa fa-trash"></i></span> : ''}
            </div>)
        return <React.Fragment>{status}{statusHover}</React.Fragment>;

    }


    useEffect(() => {

        let queryParam = filter ? filter : {};
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.p = page;
        if (sort.field) {
            queryParam.field = sort.field
            queryParam.order = sort.order
        } else {
            delete queryParam.field
            delete queryParam.order
        }
        if (search) queryParam.q = search; else delete queryParam.q
        let pageUrl = `${RouteConfig('designation_master').path}?${queryString.stringify(queryParam)}`;
        props.history.push(pageUrl);

        props.getDesignations(page);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, search, filter, sort])

    const setPage = (page, sortDirection) => {
        props.getDesignations(page);
    }

    function handleSort(page, sortDirection) {
    }

    const handleSelect = (data) => {
        console.log(data.selectedRows)
    }



    return (
        <div className="card-body card-table w-100 p-0">
            <ConfzDatatable columns={columns} data={designations} loading={loading} total={total} per_page={per_page} setPage={page => setPage(page)} handleSort={handleSort} handleSelect={handleSelect} />
        </div>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.DesignationReducer.designation_loading,
        designations: state.DesignationReducer.designations,
        page: state.DesignationReducer.page,
        per_page: state.DesignationReducer.per_page,
        total: state.DesignationReducer.total,
        total_pages: state.DesignationReducer.total_pages,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addDesignations: (params) => dispatch(DesignationAction.addDesignations(params)),
        deleteDesignation: (id) => dispatch(DesignationAction.deleteDesignations(id)),
        getDesignations: (page) => dispatch(DesignationAction.getDesignations(page)),
    }
};

// const connectedDataTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(DataTable));
const connectedDataTable = (connect(mapStateToProps, mapDispatchToProps)(DataTable));
export { connectedDataTable as DataTable };

