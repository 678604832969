import React from 'react'
import { connect } from 'react-redux';
import { ShoppersAction } from '../../../_redux/actions';
import { SummaryWidget } from '../../../_components/summary-widget';

function TotalCosts({ isLoading, total_cost }) {

    return <SummaryWidget loading={isLoading} text={'Total Cost'} value={total_cost} bgColor={"#7c82a0"} color={"#ffffff"} />

}


function mapStateToProps(state) {
    return {
        isLoading: state.ShoppersReducer.total_cost_loading,
        total_cost: state.ShoppersReducer.total_cost,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTotalCosts: dispatch(ShoppersAction.getTotalCosts())
    }
};

const connectedCoupon = (connect(mapStateToProps, mapDispatchToProps)(TotalCosts));
export { connectedCoupon as TotalCosts };
