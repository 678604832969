import { LOADER } from "../actions";

const initialState = { loader: false };

export function PageLoaderReducer(state = initialState, action) {
    switch (action.type) {
        case LOADER:
            return {
                loader: action.loader,
            };
        default:
            return state;
    }
}
