import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { drawerNavigationWidth } from '../../config/config';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';
import { UserAction } from '../../_redux/actions';
import { navigate } from '@reach/router';
import GuruLogo from '../../components/GuruLogo';
import { Box, Grid, IconButton } from '@material-ui/core';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';



export interface IDrawerNavigationItem {
	label: string;
	route: string;
	items: [];
}

const useStylesNavItemText = makeStyles((theme) => ({
	root: {
		color: theme.palette.common.white,
		fontWeight: 'bold',
	},
}));

export const DrawerNavigationItem: FC<IDrawerNavigationItem> = ({ label, route, items }) => {
	const navigate = useNavigate();
	const itemStyles = useStylesNavItemText();

	const isExpandable = items && items.length > 0
	const [open, setOpen] = React.useState(false)

	function handleClick() {
		setOpen(!open)
	}


	const MenuItemRoot = (
		<ListItem button key={label} onClick={() => isExpandable ? handleClick() : navigate(route)}>
			<Typography classes={{ root: itemStyles.root }} style={{ paddingRight: `10px` }}>{label}</Typography>
			{isExpandable && !open && <IconExpandMore style={{ color: `#fff` }} />}
			{isExpandable && open && <IconExpandLess style={{ color: `#fff` }} />}
		</ListItem>
	);

	const MenuItemChildren = isExpandable ? (
		<Collapse in={open} timeout="auto" unmountOnExit style={{ paddingLeft: '20px' }}>
			<List component="div" disablePadding>
				{items.map((item, i) => (
					// @ts-ignore
					<DrawerNavigationItem label={item.label} route={item.route} items={item.items} key={i} />
				))}
			</List>
		</Collapse>
	) : null;

	return (
		<>
			{MenuItemRoot}
			{MenuItemChildren}
		</>
	)
};

const drawerNavArr: IDrawerNavigationItem[] = [
	{ label: 'Dashboard', route: '/home', items: [] },
	// { label: 'Create Landing Page', route: '/deals', items: [] },
	// { label: 'Sweepstakes', route: '/winners', items: [] },
	{ label: 'Coupons', route: '/coupons', items: [] },
	{ label: 'Creators', route: '/shoppers', items: [] },
	{ label: 'Insights', route: '/ig-insights', items: [] },
	// { label: 'Incentive Tools', route: '/incentive-tools', items: [] },
	{ label: 'Messages', route: '/ig-messages', items: [] },
	// { label: 'Stories', route: '/stories', items: [] },
	{
		// @ts-ignore
		label: 'Settings', route: '/profile', items: [
			{ label: 'Profile', route: '/profile', items: [] },
			{ label: 'Statements', route: '/invoices', items: [] },
			// { label: 'Payments', route: '/payments', items: [] },
		]
	},

	{ label: 'Contact Support', route: '/contact-support', items: [] },
];

const useStyles = makeStyles((theme) => ({
	drawerPaper: {
		width: drawerNavigationWidth,
		backgroundColor: theme.palette.primary.main,
		"@media (max-width: 768px)": {
			display: 'none'
		}
	},
	drawer: {
		width: drawerNavigationWidth,
		flexShrink: 0,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		"@media (min-width: 768px)": {
			display: 'none'
		}
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	closeMenuButton: {
		marginRight: 0,
		marginLeft: 'auto',
	},
	appLogoItem: {
		marginBottom: theme.spacing(1),
		justifyContent: 'center'
	},
	logOutText: {
		color: theme.palette.common.white,
		cursor: 'pointer',
		fontWeight: 'bold'
	},
}));

export interface IDrawerNavigation { }

const NsDrawerNavigation: FC<IDrawerNavigation> = () => {
	const classes = useStyles();

	//app bar
	const [drawerOpen, setDrawerOpen] = React.useState(false);
	function handleDrawerToggle() {
		setDrawerOpen(!drawerOpen)
	}
	//app bar

	const logoutAction = () => {
		UserAction.logout();
		navigate('/insta-login');
		window.location.reload();
	};

	return (
		<Box>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<Grid container direction="row" justifyContent="space-between" alignItems="center">
						<Grid item>
							<Typography variant="h6" noWrap component="div"><GuruLogo style={{ height: '40px' }} /></Typography>
						</Grid>
						<Grid item>
							<IconButton color="inherit" aria-label="Open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
								<MenuIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Toolbar>
				<Collapse in={drawerOpen} timeout="auto" unmountOnExit>
					<MenuList>
						{drawerNavArr.map((item, i) => (
							<DrawerNavigationItem label={item.label} route={item.route} items={item.items} key={i} />
						))}
						<MenuItem onClick={logoutAction}>
							<Typography classes={{ root: classes.logOutText }} >
								Log Out
							</Typography>
						</MenuItem>
					</MenuList>
				</Collapse>
			</AppBar>


			<Drawer variant={'permanent'} className={classes.drawer} classes={{ paper: classes.drawerPaper }} anchor='left'>

				<List>

					<ListItem className={classes.appLogoItem}>
						<GuruLogo />
					</ListItem>

					{drawerNavArr.map((item, i) => (
						<DrawerNavigationItem label={item.label} route={item.route} items={item.items} key={i} />
					))}

					<ListItem onClick={logoutAction}>
						<Typography classes={{ root: classes.logOutText }} >
							Log Out
						</Typography>
					</ListItem>

				</List>

			</Drawer>

		</Box>
	);
};

export default NsDrawerNavigation;
