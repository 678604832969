import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

function ConfzModal({ isModalOpen, size, className, onHide, children, loader, backdrop, keyboard, ...props }) {
    return (
        <Modal show={isModalOpen} size={size} dialogClassName={``} className={className} onHide={onHide} backdrop={backdrop} keyboard={keyboard} {...props}>
            <div className={`modal-loader ${!loader ? `d-none` : ''}`}>
                <div className="bigSqr">
                    <div className="square first"></div>
                    <div className="square second"></div>
                    <div className="square third"></div>
                    <div className="square fourth"></div>
                </div>
            </div>
            {children}
        </Modal>
    )
}


ConfzModal.defaultProps = {
    isModalOpen: "false",
    size: "md",
    className: "",
    loader: false,
    keyboard: true,
    backdrop: true
}


ConfzModal.propTypes = {
    isModalOpen: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
}

export { ConfzModal }
