import { Box } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ComparisonWidget } from '../../../_components/comparison-widget';

function DcVsPhoneClicks({ isLoading, phone_call_clicks, daily_coupons }) {

    const navigate = useNavigate();

    const viewChart = () => {
        navigate({
            pathname: "/ig-insights/chart",
            search:`?metric=phone_call_clicks&type=dc`
        })
    }

    return (
        <Box style={{cursor: 'pointer'}} onClick={() => viewChart()}>
            <ComparisonWidget loading={isLoading} text={'Daily Coupon Count'} value={daily_coupons} text2={'Phone Call Clicks'} value2={phone_call_clicks} bgColor={"#a852f7"} color={"#ffffff"} />            
        </Box>
    );

}


function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
};

const connectedDcVsPhoneClicks = (connect(mapStateToProps, mapDispatchToProps)(DcVsPhoneClicks));
export { connectedDcVsPhoneClicks as DcVsPhoneClicks };
