import { ShoppersServices } from "../services";

export const GET_SHOPPERS_REQUEST = 'GET_SHOPPERS_REQUEST';
export const GET_SHOPPERS_SUCCESS = 'GET_SHOPPERS_SUCCESS';
export const GET_SHOPPERS_FAILURE = 'GET_SHOPPERS_FAILURE';

export const GET_TOTAL_COST_REQUEST = 'GET_TOTAL_COST_REQUEST';
export const GET_TOTAL_COST_SUCCESS = 'GET_TOTAL_COST_SUCCESS';
export const GET_TOTAL_COST_FAILURE = 'GET_TOTAL_COST_FAILURE';

export const GET_TOTAL_REACH_REQUEST = 'GET_TOTAL_REACH_REQUEST';
export const GET_TOTAL_REACH_SUCCESS = 'GET_TOTAL_REACH_SUCCESS';
export const GET_TOTAL_REACH_FAILURE = 'GET_TOTAL_REACH_FAILURE';

export const GET_TOTAL_COMPLETED_DEALS_REQUEST = 'GET_TOTAL_COMPLETED_DEALS_REQUEST';
export const GET_TOTAL_COMPLETED_DEALS_SUCCESS = 'GET_TOTAL_COMPLETED_DEALS_SUCCESS';
export const GET_TOTAL_COMPLETED_DEALS_FAILURE = 'GET_TOTAL_COMPLETED_DEALS_FAILURE';

export const GET_TOTAL_SHOPPERS_REQUEST = 'GET_TOTAL_SHOPPERS_REQUEST';
export const GET_TOTAL_SHOPPERS_SUCCESS = 'GET_TOTAL_SHOPPERS_SUCCESS';
export const GET_TOTAL_SHOPPERS_FAILURE = 'GET_TOTAL_SHOPPERS_FAILURE';

export const GET_SHOPPER_DETAILS_REQUEST = 'GET_SHOPPER_DETAILS_REQUEST';
export const GET_SHOPPER_DETAILS_SUCCESS = 'GET_SHOPPER_DETAILS_SUCCESS';
export const GET_SHOPPER_DETAILS_FAILURE = 'GET_SHOPPER_DETAILS_FAILURE';

export const EXPORT_SHOPPERS_REQUEST = 'EXPORT_SHOPPERS_REQUEST';
export const EXPORT_SHOPPERS_SUCCESS = 'EXPORT_SHOPPERS_SUCCESS';
export const EXPORT_SHOPPERS_FAILURE = 'EXPORT_SHOPPERS_FAILURE';

export const SET_PAGE = 'SET_PAGE';
export const CLEAR_SHOPPER_DATA = 'CLEAR_SHOPPER_DATA';

export const ShoppersAction = { setPage, getShoppersList, getTotalCosts, getTotalReach, getTotalCompletedDeals, getTotalShoppers, getShopperDetails, clearShopperDetails, exportShoppersList };

/**
 * function to set datatable page 
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function setPage(page) {
    return dispatch => { dispatch({ type: SET_PAGE, page: page }) };

}

/**
 * function to set datatable page 
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function clearShopperDetails() {
    return dispatch => { dispatch({ type: CLEAR_SHOPPER_DATA }) };

}


/**
 * function set get shoppers list for datatable 
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function getShoppersList(params) {
    return dispatch => {
        dispatch(request());
        return ShoppersServices.getShoppersList(params)
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_SHOPPERS_REQUEST, request: params } }
    function success(response) { return { type: GET_SHOPPERS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_SHOPPERS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}


/**
 * function to get total cost for the brand
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function getTotalCosts() {
    return dispatch => {
        dispatch(request());
        return ShoppersServices.getTotalCosts()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_TOTAL_COST_REQUEST, request: params } }
    function success(response) { return { type: GET_TOTAL_COST_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_TOTAL_COST_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * function to get total reach
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function getTotalReach() {
    return dispatch => {
        dispatch(request());
        return ShoppersServices.getTotalReach()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_TOTAL_REACH_REQUEST, request: params } }
    function success(response) { return { type: GET_TOTAL_REACH_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_TOTAL_REACH_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * function to get total reach
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function getTotalCompletedDeals() {
    return dispatch => {
        dispatch(request());
        return ShoppersServices.getTotalCompletedDeals()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_TOTAL_COMPLETED_DEALS_REQUEST, request: params } }
    function success(response) { return { type: GET_TOTAL_COMPLETED_DEALS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_TOTAL_COMPLETED_DEALS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * function to get total reach
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function getTotalShoppers() {
    return dispatch => {
        dispatch(request());
        return ShoppersServices.getTotalShoppers()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_TOTAL_SHOPPERS_REQUEST, request: params } }
    function success(response) { return { type: GET_TOTAL_SHOPPERS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_TOTAL_SHOPPERS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * function to get total reach
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function getShopperDetails(shopperId) {
    return dispatch => {
        dispatch(request(shopperId));
        return ShoppersServices.getShopperDetails(shopperId)
            .then(
                response => { return dispatch(success(shopperId, response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(request) { return { type: GET_SHOPPER_DETAILS_REQUEST, shopper_id: request } }
    function success(request, response) { return { type: GET_SHOPPER_DETAILS_SUCCESS, shopper_id: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_SHOPPER_DETAILS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}


/**
 * function export shoppers list for datatable 
 * @param params
 * @return view
 * @author Jasin
 * @created_at 04/03-2022
 */
 function exportShoppersList() {
    return dispatch => {
        dispatch(request());
        return ShoppersServices.exportShoppersList()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    
    function request(params) { return { type: EXPORT_SHOPPERS_REQUEST, request: params } }
    function success(response) { return { type: EXPORT_SHOPPERS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: EXPORT_SHOPPERS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

