
const validate = async (name, value, data) => {
    const errors = {};
    switch (name) {

        case 'times_between_post': {
            errors.times_between_post = '';
            if (value === '')
                errors.times_between_post = 'How often is required. ';
            break;
        }
        case 'direct_message': {
            errors.direct_message = '';
            if (value === '')
                errors.direct_message = 'Direct message is required. ';
            break;
        } default:
            errors[name] = '';
            break;
    }
    return errors;
}
export default validate;