import { ContactSupportServices } from "../services";

export const SEND_SUPPORT_MAIL_REQUEST = 'SEND_SUPPORT_MAIL_REQUEST';
export const SEND_SUPPORT_MAIL_SUCCESS = 'SEND_SUPPORT_MAIL_SUCCESS';
export const SEND_SUPPORT_MAIL_FAILURE = 'SEND_SUPPORT_MAIL_FAILURE';

export const ContactSupportAction = { sendSupportMail };


function sendSupportMail(params) {
    return dispatch => {
        dispatch(request(params));
        return ContactSupportServices.sendSupportMail(params)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(params) { return { type: SEND_SUPPORT_MAIL_REQUEST } }
    function success(response) { return { type: SEND_SUPPORT_MAIL_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: SEND_SUPPORT_MAIL_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

