import { InputLabel, FormControl, FormHelperText, Input, makeStyles } from '@material-ui/core'
import React from 'react'

import PropTypes from 'prop-types'

const useStyles = makeStyles({
    formControl: {
        // ...FormControl
    },
    label: {
        // ...FormLabel,

    },
    input: {
        // border: `1px solid ${grayColor[4]}`,
        fontSize: 14,
        width: 'auto',
        padding: '10px 15px',
        height: `42px`,
        borderRadius: `.25rem`,
        '&:before': {
            borderBottom: `unset`
        },
        '&:before &:hover': {
            borderBottom: `unset`
        }
    },
    info: {
        fontSize: 12,
        marginTop: 0
    }
})

function FormDateTimePicker({ label, name, value, placeholder, className, onChange, tabIndex, id, error, isReadOnly, pattern, maxLength, maskType, info, ...props }) {

    const classes = useStyles();

    const checkKeyPress = (e) => {
        // return maskInput(maskType, e);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({ target: { type: `date_time`, name: name, value: value } });
    }

    return (
        <FormControl fullWidth className={`${className}`} style={{ paddingLeft: `15px`, paddingRight: `15px` }}>
            <InputLabel shrink htmlFor={name} className={classes.label} style={{ fontWeight: `600`, marginBottom: `5px`, paddingLeft: `15px`, paddingRight: `15px`, color: `#000`, fontSize: `16px` }} >{label}</InputLabel>
            <Input
                className={classes.input}
                aria-describedby={`helper_${name}`}
                type={`datetime-local`}
                placeholder={placeholder}
                name={name}
                value={value}
                defaultValue={value}
                onChange={handleChange}
                onKeyPress={checkKeyPress}
                readOnly = {isReadOnly}
                style={{ marginTop: `24px`, height: `34px`, border: `1px solid #ced4da`, cursor: isReadOnly ? 'not-allowed': 'auto'} }
                {...props}
            />
            <FormHelperText id={`helper_${name}`} className={classes.info}>{info}</FormHelperText>
            {(error) && <FormHelperText id={`helper_${name}`} error className={classes.info}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`helper_${name}`} error className={classes.info}>{error}</FormHelperText>}
        </FormControl >

        // <div className={`form-group ${className}`}>

        //     <TextField
        //         id="datetime-local"
        //         label="Next appointment"
        //         type="datetime-local"
        //         defaultValue="2017-05-24T10:30"
        //         className={classes.textField}
        //         InputLabelProps={{
        //             shrink: true,
        //         }}
        //     />
        //     <label htmlFor={name}>{label}</label>
        //     <input type={type} className={`form-control ${(error) ? `is-invalid` : ``}`} name={name} readOnly={isReadOnly} tabIndex={tabIndex} placeholder={placeholder} value={value} onChange={onChange} maxLength={maxLength} onKeyPress={checkKeyPress} />
        //     {(error) && (<div className="invalid-feedback">{error}</div>)}
        // </div >
    )
}


FormDateTimePicker.defaultProps = {
    className: "",
    isReadOnly: false,
    maxLength: 255,
    maskType: ''
}

FormDateTimePicker.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
    tabIndex: PropTypes.string,
    id: PropTypes.string,
    isReadOnly: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    maxLength: PropTypes.number,
    maskType: PropTypes.string
}

export { FormDateTimePicker }
