import React from 'react'
import PropTypes from 'prop-types'

function FormTextArea({ label, name, value, placeholder, className, onChange, tabIndex, rows, id, error, ...props }) {
    return (
        <div className={`form-group ${className}`}>
            <label htmlFor={name} className="col-form-label">{label}</label>
            <textarea className={`form-control ${(error) ? `is-invalid` : ``}`} name={name} tabIndex={tabIndex} placeholder={placeholder} rows={rows} onChange={onChange} value={value}></textarea>
            {(error) && (<div className="invalid-feedback">{error}</div>)}
        </div>
    )
}

FormTextArea.defaultProps = {
    rows: "3",
    className: ""
}

FormTextArea.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
    tabIndex: PropTypes.string,
    id: PropTypes.string,
    rows: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

export { FormTextArea }


