
import { makeStyles, CardHeader as MuiCardHeader } from '@material-ui/core'
import React from 'react'


const useStyles = makeStyles((theme) => ({
    cardTitle: {
        padding: theme.spacing(3),
        paddingBottom: 0,
    },
    cardTypography: {
        fontWeight: '600',
        position: 'relative',
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            zIndex: 1,
            width: '75px',
            height: '2px',
            background: '#1242de',
            marginTop: '7px'
        }
    }
}))

function CardHeader({ title, action = null }) {

    const classes = useStyles()

    return (
        <MuiCardHeader title={title} titleTypographyProps={{ variant: 'h5', component: 'h5', className: classes.cardTypography }} className={classes.cardTitle} action={action} />
    )
}

export { CardHeader }
