import { SignInServices } from "../services";

export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';


export const INSTA_SIGNIN_REQUEST = 'INSTA_SIGNIN_REQUEST';
export const INSTA_SIGNIN_SUCCESS = 'INSTA_SIGNIN_SUCCESS';
export const INSTA_SIGNIN_FAILURE = 'INSTA_SIGNIN_FAILURE';

export const SignInAction = {
    signIn, getSubdomainDetails, getDealDetails, instaSignIn, finishRegisteration
};


/**
 * Action for singin 
 *
 * @parm json params login parameters of {email,password}
 * @author Akshay 
 */
function signIn(params) {
    return dispatch => {
        dispatch(request(params));
        return SignInServices.signIn(params)
            .then(
                response => { return dispatch(success(params, response)); },
                error => { return dispatch(failure(params, error)); });
    };

    function request(request) { return { type: SIGNIN_REQUEST, request: request } }
    function success(request, response) { return { type: SIGNIN_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(request, error) { return { type: SIGNIN_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action for singin 
 *
 * @parm json params login parameters of {email,password}
 * @author Akshay 
 */
function finishRegisteration(params) {
    return dispatch => {
        dispatch(request(params));
        return SignInServices.finishRegisteration(params)
            .then(
                response => { return dispatch(success(params, response)); },
                error => { return dispatch(failure(params, error)); });
    };

    function request(request) { return { type: SIGNIN_REQUEST, request: request } }
    function success(request, response) { return { type: SIGNIN_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(request, error) { return { type: SIGNIN_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}





/**
 * Action for singin 
 *
 * @parm json params login parameters of {email,password}
 * @author Akshay 
 */
function getSubdomainDetails(subdomain) {
    return dispatch => {
        return SignInServices.getSubdomainDetails(subdomain)
            .then(
                response => { return response },
                error => { return error }
            );
    };

}

/**
 * Action for singin 
 *
 * @parm json params login parameters of {email,password}
 * @author Akshay 
 */
function getDealDetails(subdomain) {
    return dispatch => {
        return SignInServices.getDealDetails(subdomain)
            .then(
                response => { return response },
                error => { return error }
            );
    };

}

/**
    * Action for sign in using instagram
    * @param {Object} params - The params which are used for registration api.
    * @param {string} params.email - Email of the brand user
    * @param {string} params.password - Password of the brand user
    * @author Akshay N 
    * @created_at 30 May 2022
    */
function instaSignIn(params) {
    return dispatch => {
        dispatch(request(params));
        return SignInServices.instaSignIn(params)
            .then(
                response => { return dispatch(success(params, response)); },
                error => { return dispatch(failure(params, error)); }
            );
    };

    function request(request) { return { type: INSTA_SIGNIN_REQUEST, request: request } }
    function success(request, response) { return { type: INSTA_SIGNIN_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(request, error) { return { type: INSTA_SIGNIN_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}


