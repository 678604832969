import { Card, CardContent, CardHeader } from '@material-ui/core';
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { IgInsightsAction } from '../../../_redux/actions';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function DcTextMessageClicksChart({ loading, getInsightChart, insight_chart }) {

    useEffect(() => {
        getInsightChart({ 'metric': 'text_message_clicks' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const options = {

        chart: {
            type: 'column',
            renderTo: 'container',
            backgroundColor: 'rgba(0,0,0,0)',
            style: {
                fontFamily: 'unset'
            }
        },
        title: {
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category',
            categories: insight_chart.xAxis ?? []
        },
        yAxis: {
            title: {
                text: 'Coupont Count vs Text Message Clicks'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.0f}'
                }
            }
        },

        series: [
            {
                name: 'Coupon Count',
                data: insight_chart.dc_insights ?? {}
            },
            {
                name: 'Tect Message Clicks',
                data: insight_chart.insights ?? {}
            }
        ],
        credits: {
            enabled: false
        }
    };

    return (
        <React.Fragment>
            <Card>
                <CardHeader title="Daily Coupont Count vs Text Message Clicks" />
                <CardContent>
                    {!loading && <HighchartsReact highcharts={Highcharts} options={options} />}
                </CardContent>
            </Card>
        </React.Fragment>
    );

}


function mapStateToProps(state) {
    return {
        loading: state.IgInsightsReducer.insight_chart_loading,
        insight_chart: state.IgInsightsReducer.insight_chart,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getInsightChart: (params) => dispatch(IgInsightsAction.getDcInsightChart(params)),
    }
};


const connectedDcTextMessageClicksChart = (connect(mapStateToProps, mapDispatchToProps)(DcTextMessageClicksChart));
export { connectedDcTextMessageClicksChart as DcTextMessageClicksChart };
