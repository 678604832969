



import { authHeader, handleResponse, notify } from "../../_helpers";
import Config from "../../_helpers/config";

export const PaymentServices = { getPaymentDetails, deleteCreditCard, addUpdateCard, addUpdateBankDetails, deleteBankDetails, verifyPaymentDetails, setDefaultPaymentMethod };


/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function getPaymentDetails(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    return fetch(`${Config.endpoint}/ns/payments/details`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function deleteCreditCard(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    return fetch(`${Config.endpoint}/ns/payments/delete-card`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message)
            return response;
        }).catch((error) => {
            notify(error.message);
        });
}

/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function addUpdateCard(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/payments/save-card`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        });
}

/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function addUpdateBankDetails(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/payments/save-bank-account`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function deleteBankDetails(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    return fetch(`${Config.endpoint}/ns/payments/delete-bank-account`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message)
            return response;
        }).catch((error) => {
            notify(error.message);
        });
}

/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function verifyPaymentDetails(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/payments/verify-bank-account`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function setDefaultPaymentMethod(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/payments/default-payment`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        }).catch((error) => {
            notify(error.message);
        });
}

