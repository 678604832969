import { getImageSize } from "../../../_helpers/getImageSize";

const validate = async (name, value, data) => {
    //eslint-disable-next-line
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const validInstaHandle = RegExp(/^@?[A-Z0-9_\\.]+$/i);
    const errors = {};
    switch (name) {

        case 'insta_handle': {
            errors.insta_handle = validInstaHandle.test(value) ? '' : 'Brand instagram  handle is invalid. ';;
            if (value === '')
                errors.insta_handle = 'Brand instagram handle is required. ';
            break;
        } case 'logo': {
            errors.logo = '';
            break;
        } case 'primary_color': {
            errors.primary_color = '';
            if (value === '')
                errors.primary_color = 'Background color is required. ';
            break;
        } case 'secondary_color': {
            errors.secondary_color = '';
            if (value === '')
                errors.secondary_color = 'Text color is required. ';
            break;

        } case 'link_color': {
            errors.link_color = '';
            if (value === '')
                errors.link_color = 'Button color is required. ';
            break;
        }
        case 'times_between_post': {
            errors.times_between_post = '';
            // if (value === '')
                // errors.times_between_post = 'How often a user can redeem is required. ';
            break;
        }
        case 'reward_description': {
            errors.reward_description = '';
            if (value === '')
                errors.reward_description = 'Description is required. ';
            break;
        } case 'offer_description': {
            errors.offer_description = '';
            break;
        }
        case 'hashtag': {
            errors.hashtag = '';
            if (value !== '')
                errors.hashtag = validInstaHandle.test(value) ? '' : 'Suggested hashtag is invalid. ';;
            break;
        } case 'sample_story': {
            errors.sample_story = '';
            if (value) {
                await getImageSize(value).then(data => {
                    if (!data || data.width !== 1080 || data.height !== 1920) {
                        errors.sample_story = 'Sample story resolution must be 1080 x 1920. ';
                    }
                });
            };

            // if ((value === '' || value === [] || typeof value === 'undefined') && (!((data['like'] === true) && (data['story'] === false)))) {
            //     errors.sample_story = 'Sample story is required. ';
            // } else 

            if (value && typeof value !== 'string') {
                // let extension = (value.name).split('.').pop();
                // extension = extension.toLowerCase();

                let size = value.size;
                // if (extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg') {
                //     errors.sample_story = 'Please choose a valid jpg file.';
                // } else if (size >= 2000000) {
                //     errors.sample_story = 'File size exceed. ';
                // }

                if (size >= 2000000) {
                    errors.sample_story = 'Sample story size exceed. File size must be below 2 MB.';
                }
            }
            break;
        } case 'like': {
            errors.like = '';
            // if ((value === false && data['story'] === true)) {
            //     errors.like = 'Like is required. ';
            // } else {
            // errors.story = '';
            // errors.like = '';
            // if ((value === false && data['story'] === false)) {
            //     errors.story = 'Email or text is required. ';
            //     errors.like = 'Like is required. ';
            // }
            // }
            break;
        } case 'story': {
            errors.story = '';
            errors.like = '';
            // if (value === false && data['like'] === false) {
            //     errors.story = 'Story is required. ';
            //     errors.like = 'Like is required. ';
            // }
            break;
        } case 'email_or_text': {
            errors.email_or_text = '';
            // if (value === false && data['is_giveaway'] === true) {
            //     errors.email_or_text = 'Email or text is required. ';
            // }
            break;
        } case 'giveaway_date': {
            errors.giveaway_date = '';
            if (value === '' && data['is_giveaway'] === true) {
                errors.giveaway_date = 'Sweepstakes ends in is required. ';
            }
            break;
        } case 'direct_message': {
            errors.direct_message = '';
            // if (value === '')
                // errors.direct_message = 'Direct message is required. ';
            break;
        } case 'brand_sub_domain': {
            errors.brand_sub_domain = '';
            if (value === '' && data.edit_subdomain)
                errors.brand_sub_domain = 'Subdomain name is required. ';
            break;
        } case 'sub_domain_auth': {
            errors.sub_domain_auth = '';
            if (value === false && data.edit_subdomain)
                errors.sub_domain_auth = 'Please accept terms and condition. ';
            break;
        } default:
            errors[name] = '';
            break;
    }
    return errors;
}
export default validate;