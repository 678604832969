
import { ForgotPasswordServices } from "../services";

export const FORGOT_PASSWORD_SEND_OTP_REQUEST = 'FORGOT_PASSWORD_SEND_OTP_REQUEST';
export const FORGOT_PASSWORD_SEND_OTP_SUCCESS = 'FORGOT_PASSWORD_SEND_OTP_SUCCESS';
export const FORGOT_PASSWORD_SEND_OTP_FAILURE = 'FORGOT_PASSWORD_SEND_OTP_FAILURE';

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const ForgotPasswordAction = {
    sendForgotPasswordOtp, verifyForgotPasswordOtp, changePassword
};


/**
 * Action for singin 
 *
 * @parm json params login parameters of {email,password}
 * @author Akshay 
 */
function sendForgotPasswordOtp(params) {
    return dispatch => {
        dispatch(request(params));
        return ForgotPasswordServices.sendForgotPasswordOtp(params)
            .then(
                response => { return dispatch(success(params, response)); },
                error => { return dispatch(failure(params, error)); });
    };

    function request(request) { return { type: FORGOT_PASSWORD_SEND_OTP_REQUEST, request: request } }
    function success(request, response) { return { type: FORGOT_PASSWORD_SEND_OTP_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(request, error) { return { type: FORGOT_PASSWORD_SEND_OTP_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action for singin 
 *
 * @parm json params login parameters of {email,password}
 * @author Akshay 
 */
function verifyForgotPasswordOtp(params) {
    return dispatch => {
        dispatch(request(params));
        return ForgotPasswordServices.verifyForgotPasswordOtp(params)
            .then(
                response => { return dispatch(success(params, response)); },
                error => { return dispatch(failure(params, error)); });
    };

    function request(request) { return { type: VERIFY_OTP_REQUEST, request: request } }
    function success(request, response) { return { type: VERIFY_OTP_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(request, error) { return { type: VERIFY_OTP_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action for singin 
 *
 * @parm json params login parameters of {email,password}
 * @author Akshay 
 */
function changePassword(params) {
    return dispatch => {
        dispatch(request(params));
        return ForgotPasswordServices.changePassword(params)
            .then(
                response => { return dispatch(success(params, response)); },
                error => { return dispatch(failure(params, error)); });
    };

    function request(request) { return { type: CHANGE_PASSWORD_REQUEST, request: request } }
    function success(request, response) { return { type: CHANGE_PASSWORD_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(request, error) { return { type: CHANGE_PASSWORD_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}
