import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { FormBrowseFile } from '../../../_components/form-input';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from '../../../_components/modal';
import { handleInputChange, validateForm } from '../../../_helpers';
import { CouponAction } from '../../../_redux/actions';
import validateCouponImport from './validateCouponImport';

import { Button, makeStyles } from '@material-ui/core';
import { Backup } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    titleContainer: {
        marginBottom: theme.spacing(2),
        borderBottom: `1px solid #b0bec5`
    },
    headIcon: {
        paddingRight: theme.spacing(1)
    },
    headTitle: {
        fontWeight: 900,
        paddingBottom: theme.spacing(1),
    },
    headButton: {
        margin: '0 5px',
    },
    giveawayWinnersContainer: {
        marginBottom: theme.spacing(2)
    },
}));


function Import({ importCoupons }) {

    const classes = useStyles();

    const inputs = { file: '' }

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [showImportResult, setShowImportResult] = useState(false);
    const [importResult, setImportResult] = useState({ data: [], file: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });


    useEffect(() => {
        setData({ ...inputs });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openImportModal = (e) => {
        e.preventDefault();
        setData({ ...inputs });
        setShow(true);
    }

    const onHide = () => {
        setShowImportResult(false);
        setShow(false);
        setErrors({});
    }

    /**
   * function to handle input change
   * @param object e input object
   * @author Akshay N
   */
    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateCouponImport(name, value)) });
        data[name] = value;
        setData({ ...data });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateCouponImport(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true, isProcessing: false });
            return;
        }
        formData.append('file', data.file);
        dispatchCouponImport(formData);
    }

    const dispatchCouponImport = (formData) => {
        importCoupons(formData).then((response) => {
            if (response.status === 1) {
                setShow(false);
                setAction({ isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
            } else {
                setAction({ isSubmitted: true, isProcessing: false });

                if (Object.keys(response.data).length > 0) {
                    onHide();
                    setImportResult({ ...response.data });
                    setShowImportResult(true);
                } else {
                    setErrors({ ...response.errors })
                }
            }
        });
    }

    return (
        <React.Fragment>
            <ConfzModal isModalOpen={showImportResult} onHide={onHide} size="md">
                <form onSubmit={handleSubmit} noValidate >
                    <ConfzModalHeader id="importResult" title="Import Result" onHide={onHide} />
                    <ConfzModalBody>
                        <table className="table table-bordered table-striped table-sm" >
                            <thead className="thead-light ">
                                <tr>
                                    <th className="text-center">Sl.No</th>
                                    <th>Row</th>
                                    <th>Response</th>
                                </tr>
                            </thead>
                            <tbody>
                                {importResult.data.map((result, i) => {
                                    return (
                                        <tr>
                                            <td className="text-center">{i + 1}</td>
                                            <td>{result.row}</td>
                                            <td>{result.message}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </ConfzModalBody>
                    <ConfzModalFooter>
                        <ConfzModalFooterCloseBtn tabIndex="3" onHide={onHide} />
                    </ConfzModalFooter>
                </form>
            </ConfzModal>

            <ConfzModal isModalOpen={show} onHide={onHide}>
                <form onSubmit={handleSubmit} noValidate >
                    <ConfzModalHeader id="importCoupon" title="Import Coupon" onHide={onHide} />
                    <ConfzModalBody>
                        <FormBrowseFile
                            label="Choose File"
                            name="file"
                            tabIndex="1"
                            error={(action.isSubmitted && (errors.file)) ? (errors.file) : ''}
                            placeholder="Choose your file"
                            value={data.file}
                            onChange={handleChange}
                            info="Allowed Format:csv , Allowed Maximum Size: 20 MB"
                            acceptType=".csv"
                        />
                    </ConfzModalBody>
                    <ConfzModalFooter>
                        <ConfzModalFooterCloseBtn tabIndex="3" onHide={onHide} />
                        <ConfzModalFooterSaveBtn tabIndex="2" isProcessing={action.isProcessing} text="Import" />
                    </ConfzModalFooter>
                </form>
            </ConfzModal>

            <Button variant="contained" color="primary" className={classes.headButton} startIcon={<Backup>send</Backup>} onClick={openImportModal}>
                Upload a CSV file of coupon codes
            </Button>
        </React.Fragment>
    )
}



function mapStateToProps(state) {
    return {
        // loading: state.CouponReducer.coupon_loading,
        // coupons: state.CouponReducer.coupons,
        // page: state.CouponReducer.page,
        // per_page: state.CouponReducer.per_page,
        // total: state.CouponReducer.total,
        // total_pages: state.CouponReducer.total_pages,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        importCoupons: (params) => dispatch(CouponAction.importCoupons(params)),
    }
};

const connectedImport = connect(mapStateToProps, mapDispatchToProps)(Import);
export { connectedImport as Import };


