import { Box, Link, makeStyles } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    box: {
        padding: `20px`,
    },
    heading: {
        fontWeight: `bold`,
        paddingBottom:  `10px`
    },

    heading2: {
        fontWeight: `bold`,
        paddingBottom:  `10px`,
        textDecoration: `underline`
    },

    heading3: {
        fontWeight: `bold`,
        paddingBottom:  `10px`,
    },

    heading4: {
        textDecoration: `underline`,
    },
}))

function PrivacyPolicy(props) {

    const classes = useStyles();

        return (
            <React.Fragment>

                <Box className={classes.box}>
                    <h1 className={classes.heading}>Guru.Club WEBSITE PRIVACY POLICY</h1>

                    <p>Last Updated: August 12, 2022</p>
                    <p>
                        At Guru.Club, Inc. (“Guru.Club,” “we, “us”) we consider the privacy of the visitors to our website Guru.Club (the “Site”), and the security of their personal information, to be extremely important. This Privacy Policy document describes, in detail, the types of personal information we collect and record on the Site, and how we use and disclose personal information. As used in this Privacy Policy, "personal information" means any information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with an individual or, where applicable, a household. This Privacy Policy applies only to personal information collected on the Site and personal information collected relating to our Customers (as defined below). This Privacy Policy does not apply to any information collected offline or via channels other than this Site or in connection with providing our Services (as defined below).
                    </p>

                    <h4 className={classes.heading2}>I. COLLECTING INFORMATION</h4>

                    <h5 className={classes.heading2}>HOW WE COLLECT PERSONAL INFORMATION:</h5>
                    <p> What personal information we collect depends largely on the interaction that takes place between you and Guru.Club, most of which can be categorized under the following:
                    </p>
                    <p>
                        <b>Using Guru.Club Software as a Service.</b> When you use any Guru.Club Software as a Service (the “Services”), we store all the content you provide, including but not limited to accounts created for team members, files, pictures, project information, and any other information that you provide to the Services.
                    </p>
                    <p>In and through the Services, we also collect data about usage of the Services. This may include, but is not limited to, numbers of users, flows, broadcasts etc.</p>

                    <p>
                        <b>Using the Site:</b> When you use the Site, we collect certain information, as described in more detail below that may, alone or in combination with other information, constitute personal information.
                    </p>
                    <h6 className={classes.heading3}>Types of Personal Information:</h6>
                    <p>
                        <b>(i) Guru.Club Customers (“Customers”):</b> Name, address, company name, identification, publicly available social media profile information, e-mail, IT information (IP addresses, usage data, cookie data, browser data); financial information (credit card details, account details, payment information). We use a third-party service provider to process your credit card transactions. These third parties are governed by their own agreements.
                    </p>

                    <p>
                        <b>(ii) Purchasing a subscription.</b> When you sign up for a Subscription for the Services, we collect information to process your payment and create your customer account. This information includes name, email address, physical address, telephone number, and company name where applicable. We retain the last four digits of your credit card to allow you to identify the card used for future purchases. We use a third-party service provider to process your credit card transactions. These third parties are governed by their own agreements.
                    </p>

                    <p>
                        <b>(iv) Data collected for and by our Customers.</b> As you use our Services, you may import into our system personal information you have collected from your users, customers, prospective customers, and social media and messaging platform contacts (collectively “Subscribers”) or other individuals. We have no direct relationship with your customers or any person other than you, and for that reason, you are responsible for making sure you have the appropriate permission and legal basis for us to collect and process information about those individuals. 
                    </p>

                    <p>
                        <b>(v) Information collected automatically.</b>  Our servers may automatically record certain information about how you use our Site (we refer to this information as “Log Data“), including both Customers and casual visitors. Log Data may include information such as a user’s Internet Protocol (IP) address, device and browser type, operating system, the pages or features of our Site to which a user browsed and the time spent on those pages or features, the frequency with which the Site is used by a user, search terms, the links on our Site that a user clicked on or used, and other statistics. Guru.Club and our partners may use various technologies to collect and store information when you use our Services, and this may include using cookies and similar tracking technologies on our Site, such as pixels and web beacons. Users can control the use of cookies at the individual browser level. Alone or in combination with other information, log files and information collected by means of cookies may constitute personal information.
                    </p>

                    <p>
                        <b>(vi) Sensitive personal information.</b> Subject to the following paragraph, we ask that you not send or disclose to us any sensitive personal information (e.g., social security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background or union membership) on or through the Services or otherwise.
                    </p>

                    <p>
                        If you send or disclose any sensitive personal information to us (such as when you submit Customer-generated content to the Site), you must consent to our processing and use of such sensitive personal information in accordance with this Privacy Policy. If you do not consent to our processing and use of such sensitive personal information, you must not provide it. You may use your data protection rights to object to or restrict processing of this sensitive personal information, or to delete such information, as detailed below under the heading “Your Data Protection Rights & Choices.”
                    </p>

                    <h4 className={classes.heading2}>II. PURPOSE OF DATA COLLECTION</h4>

                    <p>
                        <b>For service operations</b> (i) To operate, maintain, administer and improve the Services; (ii) to manage and communicate with you regarding your Services account, if you have one, including by sending you Services announcements, technical notices, updates, security alerts, and support and administrative messages; (iii) to process payments you make through the Services; (iv) to better understand your needs and interests, and personalize your experience with the Services; (v) to send you information about the Services by email (vi) to respond to your Services-related requests, questions and feedback.
                    </p>
                    <p>
                        <b>To communicate with you.</b> If you request information from us, register for the Services or participate in our surveys, promotions or events, we may send you Guru.Club-related marketing communications if permitted by law but will provide you with the ability to opt out.
                    </p>
                    <p>
                        <b>Administration and analysis:</b> We use automatically collected information to administer the Service and the Site and we analyze (and may engage third parties to analyze) this information to improve and enhance the Site and the Services by expanding their features and functionality and tailoring it to our Users’ needs and preferences.
                    </p>
                    <p>
                        <b>To communicate with you.</b> If you request information from us, register for the Services or participate in our surveys, promotions or events, we may send you Guru.Club-related marketing communications if permitted by law but will provide you with the ability to opt out.
                    </p>
                    <p>
                        <b>To analyze trends, administer the Site, track users’ movements around the Site, serve targeted advertisements, and gather demographic information about our user base as a whole.</b>
                    </p>

                    <p>
                        <b>To comply with law.</b> We use your personal information as we believe necessary or appropriate to comply with applicable laws, lawful requests and legal process, such as to respond to subpoenas or requests from government authorities.
                    </p>
                    <p>
                        <b>With your consent.</b> We may use or share your personal information with your consent, such as when you consent to let us post your testimonials or endorsements on our Site, you instruct us to take a specific action with respect to your personal information or you opt into third party marketing communications.
                    </p>
                    <p>
                        <b>To create de-identified data for analytics.</b> We may de-identify personal information. We de-identify personal information by excluding information that makes the data personally identifiable to you, and use that de-identified data for our lawful business purposes.
                    </p>
                    <p>
                        <b>For compliance, fraud prevention and safety.</b> We use your personal information as we believe necessary or appropriate to (a) enforce the terms and conditions that govern the Services; (b) protect our rights, privacy, safety or property, and/or that of you or others; and (c) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.
                    </p>
                    <p>
                        <b>To provide, support, and improve the Services we offer.</b> This includes our use of the data that our Customers provide us in order to enable our Customers to use the Services to communicate with Subscribers. This also includes, for example, aggregating information from your use of the Services or visit to our Site and sharing this information with third parties to improve our Services. This might also include sharing your information or the information you provide us about your Subscribers with third parties in order to provide and support our Services or to make certain features of the Services available to you. When we do have to share personal information with third parties, we take steps to protect your information by requiring these third parties to enter into a contract with us that requires them to use the personal information we transfer to them for lawful business purposes in a manner that is consistent with this Privacy Policy.
                    </p>

                    <h4 className={classes.heading2}>III. HOW WE SHARE YOUR PERSONAL INFORMATION</h4>
                    <p>We do not share or sell the personal information that you provide us with other organizations without your express consent, except as described in this Privacy Policy. We disclose personal information to third parties under the following circumstances:</p>

                    <ul>
                        <li><span className={classes.heading4}>Service Providers.</span> We may employ third party companies and individuals to administer and provide the Services on our behalf (such as bill and credit card payment processing, customer support, hosting, email delivery and database management services). These third parties are permitted to use personal information about you only to perform these tasks only for lawful business purposes in accordance with the terms of the agreement(s) we have in place with such service providers and for no other purpose.</li>

                        <li><span className={classes.heading4}>Professional Advisors.</span> We may disclose your personal information to professional advisors, such as lawyers, bankers, auditors and insurers, where necessary in the course of the professional services that they render to us.</li>

                        <li><span className={classes.heading4}>Business Transfers.</span> As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, personal information may be part of the transferred assets. You acknowledge and agree that any successor to or acquirer of Guru.Club (or its assets) will continue to have the right to use your personal information and other information in accordance with the terms of this Privacy Policy. Further, Guru.Club may also disclose aggregated personal information in order to describe our Services to prospective acquirers or business partners.</li>
  
                        <li><span className={classes.heading4}>Compliance with Laws and Law Enforcement;</span> Protection and Safety. Guru.Club may disclose information about you to government or law enforcement officials or private parties as required by law, and disclose and use such information as we believe necessary or appropriate to (a) comply with applicable laws and lawful requests and legal process, such as to respond to subpoenas or requests from government authorities; (b) enforce the terms and conditions that govern the Services; (d) protect our rights, privacy, safety or property, and/or that of you or others; and (e) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.</li>
                    </ul>

                    <h4 className={classes.heading2}>IV. YOUR DATA PROTECTION RIGHTS & CHOICES</h4>
                    <h6>You have the following rights:</h6>

                    <ul>
                        <li> If you wish to <b>access</b> your personal information that Guru.Club collects, you can do so at any time by contacting us using the contact details provided under “How to Contact Us” heading below.</li>

                        <li>Guru.Club account holders may <b>review, update, correct or delete</b> the personal information in their registration profile by logging into their account. Guru.Club account holders may also contact us to accomplish the foregoing or if you have additional requests or questions.</li>

                        <li>If you are a resident of the European Economic Area (“EEA”), you can <b>object to processing</b> of your personal information, ask us to <b>restrict processing</b> of your personal information or <b>request portability</b> of your personal information where it is technically possible. Again, you can exercise these rights by contacting us using the contact details below.</li>
  
                        <li>Similarly, if you are a resident of the EEA, if we have collected and processed your personal information with your consent, then you can <b>withdraw your consent</b> at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent.</li>

                        <li>You have the <b>right to complain to a data protection authority</b> about our collection and use of your personal information. Contact details for data protection authorities in the EEA, Switzerland and certain non-European countries (including the U.S. and Canada) are available <Link href={`https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm`} variant="body2" target="_blank" rel="noreferrer">here</Link></li>

                    </ul>

                    <p>We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws.</p>

                    <p><b>Access to Data Controlled by our Customers.</b> Guru.Club has no direct relationship with the individuals whose personal information is contained within the Custom User Fields processed by our Service. An individual who seeks access, or who seeks to correct, amend, or delete personal information provided by our users should direct their request to the Customer directly.</p>

                    <h4 className={classes.heading2}>V. RETENTION OF INFORMATION</h4>
                    <p>We will retain personal information we process on behalf of our Customers for as long as needed to provide our Services or for an indefinite time to comply with our legal obligations, resolve disputes, prevent abuse, and enforce our agreements. If required by law, we will delete personal information by erasing it from our database.</p>

                    <h4 className={classes.heading2}>VI. DATA TRANSFERS</h4>
                    <p>Your personal information may be stored and processed in any country where we have facilities or in which we engage service providers. By accepting the terms of this Privacy Policy, you acknowledge, agree and consent to (1) the transfer to and processing of personal information on servers located outside of the country where you reside and (2) our collection and use of your personal information as described herein and in accordance with the data protection laws of the countries in which have facilities or in which we engage service providers, which may be different and may be less protective than those in your country.</p>

                    <h4 className={classes.heading2}>VII. PERSONAL INFORMATION TRANSFERRED FROM THE EA, THE UK OR SWITZERLAND TO THE UNITED STATES</h4>
                    <p>Guru.Club operates in countries outside the EEA, the UK, and Switzerland, such as in the United States of America and may utilize data processors and sub-processors located in third countries. If you are located in the EEA, the UK, or Switzerland, this means that we may transfer personal information outside of your country. Such transfers are made using appropriate safeguards and supplementary measures, such as the EU Standard Contractual Clauses for Controller-to-Controller and Controller-to-Processor Transfers (“Safeguards”). If you would wish to view such Safeguards, contact us support@Guru.Club</p>

                    <h4 className={classes.heading2}>VIII. CHILDREN’S INFORMATION</h4>
                    <p>We believe it is important to provide added protection for children online. We encourage parents and guardians to spend time online with their children to observe, participate in and/or monitor and guide their online activity. The Site and/or the Services are not intended for use by anyone under the age of 16, nor does Guru.Club knowingly collect or solicit personal information from anyone under the age of 16. If you are under 16, you may not attempt to register for the Services or send any information about yourself to us, including your name, address, telephone number, or email address. In the event that we confirm that we have collected personal information from someone under the age of 16 without verification of parental consent, we will delete that information promptly. If you are a parent or legal guardian of a child under 16 and believe that we might have any information from or about such child, please contact us.</p>

                    <h4 className={classes.heading2}>IX. SECURITY</h4>
                    <h6 className={classes.heading3}>Notice of Breach of Security</h6>
                    <p>If a security breach causes an unauthorized intrusion into our system that materially affects you or your Subscribers, then Guru.Club will notify you as soon as possible and later report the action we took in response.</p>
                    <h6 className={classes.heading3}>Safeguarding Your Information</h6>
                    <p>We take reasonable and appropriate measures to protect personal information from loss, misuse and unauthorized access, disclosure, alteration and destruction, taking into account the risks involved in the processing and the nature of the personal information. Despite these efforts to store personal information collected on and through the Site and/or the Services and otherwise by us in a secure operating environment that is not available to the public, we cannot guarantee the security of personal information during its transmission or its storage on our systems. Further, while we attempt to ensure the integrity and security of personal information, we cannot guarantee that our security measures will prevent third-parties such as so-called hackers from illegally obtaining access to personal information. We do not warrant or represent that personal information about you will be protected against, loss, misuse, or alteration by third parties.</p>
                    <p>Our credit card processing vendor uses security measures to protect your information both during the transaction and after it is complete. If you have any questions about the security of your Personal Information, you may contact us by email at support@Guru.Club.com with the subject line “questions about privacy policy”.</p>

                    <h5 className={classes.heading2}>LEGAL BASIS FOR PROCESSING YOUR PERSONAL INFORMATION (EEA VISITORS/CUSTOMERS ONLY)</h5>
                    <p>If you are a person located in the European Economic Area, our legal basis for collecting and using the personal information described above will depend on the personal information concerned and the specific context in which we collect it. We will normally collect personal information from you only where we have your consent to do so, where we need the personal information to perform a contract with you, or where the processing is in our legitimate business interests. In some cases, we may also have a legal obligation to collect personal information from you.</p>
                    <p>If we ask you to provide personal information to comply with a legal requirement or to enter into a contract with you, we will make this clear at the relevant time and advise you whether the provision of your personal information is mandatory or not (as well as of the possible consequences if you do not provide your personal information). Similarly, if we collect and use your personal information in reliance on our legitimate business interests, we will make clear to you at the relevant time what those legitimate business interests are.</p>
                    <p>If you have questions about or need further information concerning the legal basis on which we collect and use your personal information, please contact us using the contact details provided under the “How To Contact Us” heading below.</p>

                    <h5 className={classes.heading2}>FOR CALIFORNIA RESIDENTS</h5>
                    <p>Under California’s Shine the Light law, Cal. Civ. Code § 1798.83, California residents have the right to request in writing from businesses with whom they have an established business relationship, (a) a list of the categories of personal information, such as name, e-mail and mailing address including billing ZIP code and the type of services provided to the customer, that a business has disclosed to third parties (including affiliates that are separate legal entities) during the immediately preceding calendar year for the third parties' direct marketing purposes and (b) the names and addresses of all such third parties. To request the above information, please contact us at privacy@Guru.Club.com. We will respond to such requests for information access within 30 days following receipt at the e-mail or mailing address stated below. Please note that we are only required to respond to an individual once per calendar year.</p>
                    <p>Additionally, California law requires that we indicate whether we honor “Do Not Track” settings in your browser concerning targeted advertising. We do not currently alter our practices when a Do Not Track signal is received. To learn about Do Not Track and for information about how to opt out of receiving targeted advertising, please click www.aboutads.info/choices.</p>
                    <p>Furthermore, subject to certain exemptions, California residents have the following rights with respect to personal information we may have collected about them:</p>
                    <p>(i) Requests to Know</p>
                    <p>You have the right to request that we disclose:</p>

                    <ul>
                        <li> The categories of personal information we have collected about you;</li>
                        <li>The categories of personal information about you we have sold or disclosed for a business purpose;</li>
                        <li>The categories of sources from which we have collected personal information about you;.</li>
                        <li>The business or commercial purposes for selling or collecting personal information about you;</li>
                        <li>The categories of personal information sold or shared, if any, about you, as well as the categories of third parties to whom the personal information was sold, by category of personal information for each party to whom personal information was sold; and</li>
                        <li>The specific pieces of personal information collected.</li>
                    </ul>
                    <p>(ii) Requests to Delete</p>
                    <p>You have the right to request that we delete any personal information about you that we have collected. Upon receiving a verified request to delete personal information, we will do so unless otherwise required or authorized by law. </p>
                    <p>(iii) Authorized Agents</p>
                    <p>You may designate an authorized agent to make requests on your behalf. You must provide an authorized agent written permission to submit a request on your behalf, and we may require that you verify your identity directly with us. Alternatively, an authorized agent that has been provided power of attorney pursuant to Probate Code sections 4000-4465 may submit a request on your behalf.</p>
                    <p>(v) The Right to Non-Discrimination</p>
                    <p>You have the right not to be discriminated against for the exercise of your California privacy rights described above. Unless permitted by the California Consumer Privacy Act, we will not:</p>

                    <ul>
                        <li>Deny you goods or services.</li>
                        <li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</li>
                        <li>Provide you a different level or quality of goods or services.</li>
                        <li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
                        <li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
                    </ul>
                    <h5 className={classes.heading2}>CHANGES TO OUR PRIVACY POLICY</h5>
                    <p>Changes to this Privacy Policy will be made when required in response to changing legal, technical or business developments. When we update our Privacy Policy, we will take appropriate measures to inform you, consistent with the significance of the changes we make. We will obtain your consent to any material Privacy Policy changes if and where this is required by applicable data protection laws.
                    </p>
                    <p>You can see when this Privacy Policy was last updated by checking the “Last Updated” date displayed at the top of this Privacy Policy. The new Privacy Policy will apply to all current and past users of the website and will replace any prior notices that are inconsistent with it.</p>
                    <h5 className={classes.heading2}>HOW TO CONTACT US</h5>
                    <p>If you require any more information or have any questions about our privacy policy, please feel free to contact us by email at support@Guru.Club with the subject line “questions about privacy policy”.</p>
                </Box>

            </React.Fragment>
        )
}

const connectedPrivacyPolicy = connect(null)(PrivacyPolicy);
export { connectedPrivacyPolicy as PrivacyPolicy };












































