import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Box, FormControlLabel, Radio, RadioGroup, Button, Divider, Typography } from '@material-ui/core';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalHeader } from '../../../../_components/modal';
import { InstaPaymentAction } from '../../../../_redux/actions';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    paymentPreferenceDetailsBox: {
        padding: theme.spacing(2),
        background: theme.palette.primary.main,
        color: `#fff`
    },
    paymentPreferenceBox: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: `100%`,
        paddingTop: theme.spacing(1)
    },
    paymentMethodTitle: {
        marginTop: theme.spacing(2)
    },
}));

function DefaultPayment({ loading, defaultPayment, cardDetails, bankDetails, setDefaultPayment, getPaymentDetails }) {

    const classes = useStyles();
    const [method, setMethod] = useState(defaultPayment);
    const [showLoader, setShowLoader] = useState(false)
    const [defaultPaymentModal, setDefaultPaymentModal] = useState(false)

    const [data, setData] = useState(null);

    const { approvalKey } = useParams();

    useEffect(() => {
        setMethod(defaultPayment);
    }, [defaultPayment])

    const setDefaultMethod = (e) => {
        setData(e.target.value);
        setDefaultPaymentModal(true);
    }


    const onHide = () => {
        setDefaultPaymentModal(false);
    }

    const setDefaultPaymentMethod = (e) => {
        setShowLoader(true);
        setDefaultPayment({ method: data, approval_key: approvalKey }).then(() => {
            getPaymentDetails(approvalKey);
            setDefaultPaymentModal(false);
            setShowLoader(false);
        })
    }

    return (
        <React.Fragment>

            {/* BEGIN: Confirmation modal for setting default payment method */}
            <ConfzModal isModalOpen={defaultPaymentModal} onHide={onHide} loader={showLoader} size="md">
                <ConfzModalHeader id="AddUpdateDeals" title="Default Payment Method" onHide={onHide} />
                <ConfzModalBody className="text-center">
                    <h1>Are You Sure?</h1>
                    <p>{`Do you want to change, ${data} as your default payment method?`}</p>
                </ConfzModalBody>
                <ConfzModalFooter>
                    <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onHide}>Cancel</Button>
                    <Button variant="contained" color="primary" size="small" className={classes.button} onClick={setDefaultPaymentMethod}>Confirm</Button>
                </ConfzModalFooter>
            </ConfzModal>
            {/* END: Confirmation modal for setting default payment method */}

            {/* BEGIN: Payment preference section based on default payment */}
            {!loading && defaultPayment &&
                <Box className={classes.paymentPreferenceDetailsBox} color='secondary'>
                    {!loading && defaultPayment === 'card' && (
                        <React.Fragment>
                            <Typography variant="h6" component="p" color="initial" className={classes.paymentMethodTitle}>Choosen Payment Method</Typography>
                            <Typography variant="caption" component="p" style={{ textTransform: `capitalize` }}>{cardDetails.card.brand} ending in {cardDetails.card.last4}</Typography>
                        </React.Fragment>
                    )}
                    {!loading && defaultPayment === 'bank' && (
                        <React.Fragment>
                            <Typography variant="h6" component="p" color="initial" className={classes.paymentMethodTitle}>Choosen Payment Method</Typography>
                            <Typography variant="caption" component="p" >{bankDetails.bank_name} account ending in {bankDetails.last4}</Typography>
                        </React.Fragment>
                    )}
                </Box>
            }
            {/* END: Payment preference section based on default payment */}

            {/* BEGIN: Section to select default payment method */}
            <Box className={classes.paymentPreferenceBox}>
                {!loading && (
                    <RadioGroup row aria-label="method" name={`method`} value={method} onChange={setDefaultMethod}>
                        <FormControlLabel value="card" control={<Radio />} label="Pay with Credit Card" disabled={!cardDetails} />
                        <FormControlLabel value="bank" control={<Radio />} label="Pay with Bank Account" disabled={!bankDetails || bankDetails.status === 'new'} />
                    </RadioGroup>
                )}
            </Box>
            <Divider />
            {/* BEGIN: Section to select default payment method */}
        </React.Fragment>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.InstaPaymentReducer.payment_details_loading,
        cardDetails: state.InstaPaymentReducer.payment_details.stripe_card_response,
        bankDetails: state.InstaPaymentReducer.payment_details.stripe_bank_response,
        defaultPayment: state.InstaPaymentReducer.payment_details.default_payment
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setDefaultPayment: (params) => dispatch(InstaPaymentAction.setDefaultPaymentMethod(params)),
        getPaymentDetails: (params) => dispatch(InstaPaymentAction.getPaymentDetails(params)),
    }
};

const connectedDefaultPayment = connect(mapStateToProps, mapDispatchToProps)(DefaultPayment);
export { connectedDefaultPayment as DefaultPayment };
