import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from "../theme/theme";
import BaseRouter from "../routers/BaseRouter";

import { ThemeProvider } from '@material-ui/styles'; // eslint-disable-line import/no-extraneous-dependencies

// import './App.global.scss';

function App() {

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BaseRouter />
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
