import { Box, Grid } from '@material-ui/core';
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FormInput, FormSaveBtn } from '../../../../_components/form-input';
import { handleInputChange, validateForm } from '../../../../_helpers';
import { IgMessageAction } from '../../../../_redux/actions';
import validate from './validate';

function AddReply({ isAddReplyLoading, sendDm }) {
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const sId = params.get('sId');

    const inputs = {
        message_content: '',
        shopper_igsid: ''
    }

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        console.log(name, value, 'validate(name, value, data)')
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }
        data.shopper_igsid = sId;
        dispatchMessageReplyAction(data);
    }

    const dispatchMessageReplyAction = (data) => {
        sendDm(data).then((response) => {
            if (response.status === 1) {
                setErrors({ ...inputs });
                setData({ ...inputs });
                setAction({ ...action, isSubmitted: false, isProcessing: false });
            }
        });
    }

    return (
        <Box >
            <form onSubmit={handleSubmit} noValidate>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item md={10}>
                        <FormInput
                            tabIndex='1'
                            placeholder='Reply'
                            name='message_content'
                            value={data.message_content}
                            error={action.isSubmitted && errors.message_content ? errors.message_content : ''}
                            onChange={handleChange}
                            autoFocus={true} />
                    </Grid>
                    <Grid item md={2}>
                        <FormSaveBtn isProcessing={action.isProcessing} text='SEND' />
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}


function mapStateToProps(state) {
    return {
        isAddReplyLoading: state.IgMessageReducer.ig_send_dm_loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sendDm: (params) => dispatch(IgMessageAction.sendDm(params))
    }
};

const connectedAddReply = (connect(mapStateToProps, mapDispatchToProps)(AddReply));
export { connectedAddReply as AddReply };
