import { Box } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SummaryWidget } from '../../../_components/summary-widget';

function PhoneClicksCount({ isLoading, phone_call_clicks }) {

    const navigate = useNavigate();

    const viewChart = () => {
        navigate({
            pathname: "/ig-insights/chart",
            search:`?metric=phone_call_clicks`
        })
    }

    return (
        <Box style={{cursor: 'pointer'}} onClick={() => viewChart()}>
            <SummaryWidget loading={isLoading} text={'Daily Phone Call Clicks'} value={phone_call_clicks} bgColor={"#a852f7"} color={"#ffffff"} />
        </Box>
    );

}


function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
};

const connectedPhoneClicksCount = (connect(mapStateToProps, mapDispatchToProps)(PhoneClicksCount));
export { connectedPhoneClicksCount as PhoneClicksCount };
