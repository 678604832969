import {
    GET_DESIGNATIONS_REQUEST, GET_DESIGNATIONS_SUCCESS, GET_DESIGNATIONS_FAILURE,
    DELETE_DESIGNATIONS_REQUEST, DELETE_DESIGNATIONS_SUCCESS, DELETE_DESIGNATIONS_FAILURE,
    GET_DESIGNATION_REQUEST, GET_DESIGNATION_SUCCESS, GET_DESIGNATION_FAILURE
} from "../actions";

const initialState = {
    page: 1, per_page: 12, total: 0, total_pages: 0,
    designation_loading: false, designations: [], designation_error: []
};

export function DesignationReducer(state = initialState, action) {
    switch (action.type) {
        /** begin::Designation redux */
        case GET_DESIGNATION_REQUEST: {
            return {
                ...state,
                designation_error: []
            };
        } case GET_DESIGNATION_SUCCESS: {
            return {
                ...state,
                // designations: action.data.designations,
                // designation_error: [],
                // page: action.data.page,
                // per_page: action.data.per_page,
                // total: action.data.total,
                // total_pages: action.data.total_pages,
            };
        } case GET_DESIGNATION_FAILURE: {
            return {
                ...state,
            };
        }
        /** end::Designation redux */

        /** begin::Designation redux */
        case GET_DESIGNATIONS_REQUEST: {
            return {
                ...state,
                designation_loading: true,
                designations: [],
                designation_error: []
            };
        } case GET_DESIGNATIONS_SUCCESS: {
            return {
                ...state,
                designation_loading: false,
                designations: action.data.designations,
                designation_error: [],
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages,
            };
        } case GET_DESIGNATIONS_FAILURE: {
            return {
                ...state,
                designation_loading: false,
                designations: [],
                designation_error: []
            };
        }
        /** end::Designation redux */

        /** begin::Designation delete redux */
        case DELETE_DESIGNATIONS_REQUEST: {
            return {
                ...state,
                designation_loading: true,
                designation_error: []
            };
        } case DELETE_DESIGNATIONS_SUCCESS: {
            // const designations = state.designations.filter((designation, i) => designation.en_id !== action.id)
            return {
                ...state,
                designation_loading: false,
                // designations: designations,
                designation_error: [],
                // total: (state.total - 1),
            };
        } case DELETE_DESIGNATIONS_FAILURE: {
            return {
                ...state,
                designation_loading: false,
                designations: [],
                designation_error: []
            };
        }
        /** end::Designation delete redux */

        default:
            return state;
    }
}