/* eslint-disable no-useless-escape */
import React from 'react'
import PropTypes from 'prop-types'

function FormGroupInput({ append, label, type, name, value, placeholder, className, onChange, tabIndex, id, ig_text, maskType, error, ...props }) {

    function returnAlphabetsOnly(e) {
        var regex = new RegExp("^[a-zA-Z]*$");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        e.preventDefault();
        return false;
    }

    function returnAlphabetsWithSpace(e) {
        var regex = new RegExp("^[a-zA-Z ]*$");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        e.preventDefault();
        return false;
    }

    function returnAlphabetsNumbersWithHiphen(e) {
        var regex = new RegExp("^[a-zA-Z0-9-]*$");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        e.preventDefault();
        return false;
    }

    function returnAlphabetsNumbersWithHiphenSmallOnly(e) {
        var regex = new RegExp("^[a-z0-9-]*$");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        e.preventDefault();
        return false;
    }

    function returnAlphabetsNumbersWithUnderScore(e) {
        var regex = new RegExp("^[a-zA-Z0-9_]*$");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        e.preventDefault();
        return false;
    }

    function returnDigitWithOneDot(e) {
        // eslint-disable-next-line no-invalid-regexp
        var regex = new RegExp("?<=^| )\d+(\.\d+)?(?=$| )|(?<=^| )\.\d+(?=$|");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        e.preventDefault();
        return false;
    }

    function returnInstagranHandle(e) {
        var regex = new RegExp("^[a-z0-9._]{1,30}");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        e.preventDefault();
        return false;
    }


    const checkKeyPress = (e) => {
        if (maskType === 'alphabets-only') {
            return returnAlphabetsOnly(e);
        } else if (maskType === 'alphabets-with-space') {
            return returnAlphabetsWithSpace(e);
        } else if (maskType === 'alphanumeric-with-hiphen') {
            return returnAlphabetsNumbersWithHiphen(e);
        } else if (maskType === 'alphanumeric-with-hiphen-small-only') {
            return returnAlphabetsNumbersWithHiphenSmallOnly(e);
        } else if (maskType === 'alphanumeric-with-underscore') {
            return returnAlphabetsNumbersWithUnderScore(e);
        } else if (maskType === 'digit-with-one-dot') {
            return returnDigitWithOneDot(e);
        } else if (maskType === 'instagram-handle') {
            return returnInstagranHandle(e);
        }
    }


    const beforeType = (
        <div className={`form-group ${className}`}>
            <label htmlFor={name}>{label}</label>
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text" id={`igt_${name}`}>{ig_text}</span>
                </div>
                <input type={type} className={`form-control ${(error) ? `is-invalid` : ``}`} name={name} tabIndex={tabIndex} placeholder={placeholder} value={value} onChange={onChange} autoComplete={`none`} onKeyPress={checkKeyPress}  {...props} />
                {(error) && (<div className="invalid-feedback">{error}</div>)}
            </div>
        </div>
    );


    const afterType = (
        <div className={`form-group ${className}`}>
            <label htmlFor={name}>{label}</label>
            <div className="input-group">
                <input type={type} className={`form-control ${(error) ? `is-invalid` : ``}`} name={name} tabIndex={tabIndex} placeholder={placeholder} value={value} onChange={onChange} onKeyPress={checkKeyPress} autoComplete={false}  {...props} />
                <div className="input-group-append">
                    <span className="input-group-text" id={`igt_${name}`}>{ig_text}</span>
                </div>
                {(error) && (<div className="invalid-feedback">{error}</div>)}
            </div>
        </div>
    )

    return ((append === 'before') ? beforeType : afterType);
}


FormGroupInput.defaultProps = {
    type: "text",
    className: "",
    append: 'before',
    maskType: ''
}

FormGroupInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'number', 'password']),
    className: PropTypes.string,
    value: PropTypes.any,
    tabIndex: PropTypes.string,
    id: PropTypes.string,
    ig_text: PropTypes.string,
    onChange: PropTypes.func,
    append: PropTypes.string,
    maskType: PropTypes.string,
    label: PropTypes.string
}

export { FormGroupInput }
