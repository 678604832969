import { authHeader, handleResponse, notify } from "../../_helpers";
import Config from "../../_helpers/config";
import queryString from 'query-string';


export const TrialCouponServices = { applyTrialCouponCode, isBrandOnTrial };


/**
 * Service for add coupon 
 *
 * @author Jasin 
 */
 function applyTrialCouponCode(params) {
     const requestOptions = {};
     requestOptions.headers = authHeader();
     requestOptions.method = 'POST';
     requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/trial-coupons/apply`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}


/**
 * Service for add coupon 
 *
 * @author Jasin 
 */
function isBrandOnTrial(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);
   return fetch(`${Config.endpoint}/ns/trial-coupons/on-trial`, requestOptions)
       .then(handleResponse)
       .then((response) => {
           return response;
       })
}
