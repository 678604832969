import { IgMessageServices } from "../services";

export const GET_IG_CONVERSATION_LIST_REQUEST = 'GET_IG_CONVERSATION_LIST_REQUEST';
export const GET_IG_CONVERSATION_LIST_SUCCESS = 'GET_IG_CONVERSATION_LIST_SUCCESS';
export const GET_IG_CONVERSATION_LIST_FAILURE = 'GET_IG_CONVERSATION_LIST_FAILURE';

export const GET_IG_CONVERSATION_DETAILS_REQUEST = 'GET_IG_CONVERSATION_DETAILS_REQUEST';
export const GET_IG_CONVERSATION_DETAILS_SUCCESS = 'GET_IG_CONVERSATION_DETAILS_SUCCESS';
export const GET_IG_CONVERSATION_DETAILS_FAILURE = 'GET_IG_CONVERSATION_DETAILS_FAILURE';

export const GET_IG_SEND_DM_REQUEST = 'GET_IG_SEND_DM_REQUEST';
export const GET_IG_SEND_DM_SUCCESS = 'GET_IG_SEND_DM_SUCCESS';
export const GET_IG_SEND_DM_FAILURE = 'GET_IG_SEND_DM_FAILURE';

export const IgMessageAction = {  getDmConversationList, getDmDetails, sendDm };


/**
 * Action get ig insights
 *
 * @author Jasin 
 */
function getDmConversationList() {
    return dispatch => {
        dispatch(request());
        return IgMessageServices.getDmConversationList()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request() { return { type: GET_IG_CONVERSATION_LIST_REQUEST } }
    function success(response) { return { type: GET_IG_CONVERSATION_LIST_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_IG_CONVERSATION_LIST_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get ig insights
 *
 * @author Jasin 
 */
 function getDmDetails(params) {
    return dispatch => {
        dispatch(request());
        return IgMessageServices.getDmDetails(params)
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_IG_CONVERSATION_DETAILS_REQUEST, request: params } }
    function success(response) { return { type: GET_IG_CONVERSATION_DETAILS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_IG_CONVERSATION_DETAILS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get ig insights
 *
 * @author Jasin 
 */
 function sendDm(params) {
    return dispatch => {
        dispatch(request());
        return IgMessageServices.sendDm(params)
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_IG_SEND_DM_REQUEST, request: params } }
    function success(response) { return { type: GET_IG_SEND_DM_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_IG_SEND_DM_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

