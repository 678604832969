import React from 'react'
import { connect } from 'react-redux';
import { UserAction } from '../../../_redux/actions';
import { SummaryWidget } from '../../../_components/summary-widget';

function FollowersCount({ isLoading, total_new_followers }) {

    return <SummaryWidget loading={isLoading} text={'Followers Count'} value={`${total_new_followers ?? 0} <span style="font-size:12px">New Followers</span>`} bgColor={"#a852f7"} color={"#ffffff"} subText={'In the past 30 days'} />

}


function mapStateToProps(state) {
    return {
        isLoading: state.UserReducer.total_new_followers_loading,
        total_new_followers: state.UserReducer.total_new_followers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTotalNewFollowers: dispatch(UserAction.getTotalNewFollowers())
    }
};

const connectedFollowersCount = (connect(mapStateToProps, mapDispatchToProps)(FollowersCount));
export { connectedFollowersCount as FollowersCount };
