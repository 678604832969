import React from 'react'
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, Typography } from '@material-ui/core';
import { IgMessageAction } from '../../../../_redux/actions';

function MessageSidebarCard({ isLoading, ig_conversation_list }) {
    const navigate = useNavigate();

    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const cId = params.get('cId'); // plan promo from url

    const viewMessage = (id, sId) => {
        const to = { pathname: '/ig-messages', search: `?cId=${id}&sId=${sId}` };
        navigate(to, { replace: true });
    }

    return (

        <Card style={{ height: `100%`, minHeight: '80vh' }}>
            <CardContent style={{ padding: 0, height: `100%` }}>
                { !isLoading && ig_conversation_list.length > 0 && 
                    <Box style={{ height: `80vh`, overflow: `scroll` }} >
                    {
                        !isLoading && ig_conversation_list.length > 0 && ig_conversation_list.map((conversation, i) => {
                            return (
                                <Card key={i} onClick={() => viewMessage(conversation.conversation_id, conversation.participant.id)} style={{ cursor: 'pointer', ...(cId === conversation.conversation_id) ? { backgroundColor: '#e6e6e6' } : {} }}>
                                    <Grid container spacing={2} display="flex" alignItems="center">
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <CardHeader
                                                avatar={<Avatar />}
                                                title={conversation.participant.username}
                                                subheader={conversation.updated_time}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ pb: 0, mb: 0 }} />
                                </Card>
                            );
                        })
                    }
                    </Box>
                }
               

                {
                    !isLoading && ig_conversation_list.length === 0 && <Typography style={{ paddingTop: '16px'}} align='center'>No messages found</Typography>
                }



                {isLoading &&
                    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center"style={{ height: `80vh` }} >
                        <Grid item >
                            <CircularProgress />
                        </Grid>
                    </Grid>}
            </CardContent>
        </Card>
    )
}


function mapStateToProps(state) {
    return {
        isLoading: state.IgMessageReducer.ig_conversation_list_loading,
        ig_conversation_list: state.IgMessageReducer.ig_conversation_list,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDmConversationList: dispatch(IgMessageAction.getDmConversationList()),
    }
};

const connectedMessageSidebarCard = (connect(mapStateToProps, mapDispatchToProps)(MessageSidebarCard));
export { connectedMessageSidebarCard as MessageSidebarCard };
