import React from 'react'
import { connect } from 'react-redux';
import { PageTitle } from '../../_layouts/page-title';
import { useLocation } from 'react-router-dom';
import { Card, CardContent, CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import { AddReply, MessageRepliesCard, MessageSidebarCard } from './Ig-message-card';

function IgMessages({ ig_conversation_details, ig_conversation_details_loading }) {
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const cId = params.get('cId'); // plan promo from url

    return (
        <React.Fragment>
            <PageTitle title="Messages" />
            <Container>
                <Grid container spacing={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <MessageSidebarCard />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <Card style={{ minHeight: `80vh` }}>
                            <CardContent style={{ padding: `2rem !important` }}>
                                <MessageRepliesCard />
                                {
                                    (!ig_conversation_details_loading && ig_conversation_details.length === 0 && !cId) && <Typography align='center'>Creator not selected</Typography>
                                }
                                {
                                    (!ig_conversation_details_loading && ig_conversation_details.length === 0 && cId) && <Typography align='center'>Messages not found</Typography>
                                }

                                {ig_conversation_details_loading &&
                                    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ height: `80vh` }}>
                                        <Grid item >
                                            <CircularProgress />
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    (!ig_conversation_details_loading && ig_conversation_details && ig_conversation_details.length > 0 && cId) && <AddReply />
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

        </React.Fragment>
    )
}


function mapStateToProps(state) {
    return {
        ig_conversation_details: state.IgMessageReducer.ig_conversation_details,
        ig_conversation_details_loading: state.IgMessageReducer.ig_conversation_details_loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
    }
};

const connectedIgMessages = (connect(mapStateToProps, mapDispatchToProps)(IgMessages));
export { connectedIgMessages as IgMessages };
