import React, { useEffect, useState } from 'react'
import Config from '../../../_helpers/config';
import { DesignationAction } from '../../../_redux/actions';
import { connect } from 'react-redux';
import { RouteConfig } from '../../../_routes';
import { Header, AddUpdateDesignation, DataTable, Import } from './';
import { FilterModal, Search, Sort } from '../../../_components/card-header-x';
import queryString from 'query-string';
// import { withRouter } from 'react-router-dom';


function Designation({ page, ...props }) {


    let searchParams = props.location.search;
    let queryParams = queryString.parse(searchParams);
    let filterParams = { created_at: queryParams.created_at ?? '' };
    let searchParam = queryParams.q ?? '';

    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [id, setId] = useState('');
    const [search, setSearch] = useState(searchParam);
    const [filter, setFilter] = useState({ ...filterParams });
    const [sort, setSort] = useState({
        field: queryParams.field ?? '', order: queryParams.order ?? '', column: [
            { label: 'Name', name: 'descriptions.name' },
            { label: 'Created By', name: 'descriptions.created_at' }
        ]
    });

    useEffect(() => {
        document.title = `${Config.app_name} | ${RouteConfig('designation_master').name}`;
    }, [])


    const addUpdateDesignationModal = () => {
        setId('');
        setShowAddUpdateModal(!showAddUpdateModal);
    }

    const editDesignation = (id) => {
        setId(id);
        setShowAddUpdateModal(!showAddUpdateModal);
    }

    const getSearchText = (text) => {
        setSearch(text);
    }


    const openFilterModal = () => {
        setShowFilterModal(!showFilterModal);
    }

    const onApplyFilter = (filterData) => {
        openFilterModal();
        setFilter(filterData);
    }

    const onSelect = (selectedData) => {

    }

    const handleSort = (e) => {
        setSort({ ...sort, ...e });
    }


    return (
        <section className="section ">
            <AddUpdateDesignation id={id} show={showAddUpdateModal} closeModal={addUpdateDesignationModal} />
            <FilterModal show={showFilterModal} closeModal={openFilterModal} onApplyFilter={onApplyFilter} filter={filter} />
            <Header />

            <div className="section-body">
                <div className="card">
                    <div className="card-header">
                        <h4>{`${RouteConfig('designation_master').name}`}</h4>
                        <div className="card-header-action">
                            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                <Search searchText={getSearchText} value={search} />
                                <span className="btn btn-sm btn-primary" onClick={() => addUpdateDesignationModal()}><i className="fa fa-plus"></i></span>
                                <span className="btn btn-sm btn-primary" onClick={() => openFilterModal()}><i className="fa fa-filter"></i></span>
                                <Sort onSort={handleSort} column={sort.column} field={sort.field} order={sort.order} />
                                <button className="btn btn-sm btn-primary btn-group" role="group">
                                    <span type="button" className="dropdown dropleft" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></span>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <Import />
                                        <span className="dropdown-item" onClick={() => addUpdateDesignationModal()}><i className="fa fa-file-export"></i>Export</span>
                                        <div className="dropdown-divider"></div>
                                        <span className="dropdown-item" onClick={() => addUpdateDesignationModal()}><i className="fa fa-check"></i>Activate</span>
                                        <span className="dropdown-item" onClick={() => addUpdateDesignationModal()}><i className="fa fa-times"></i>Inactivate</span>
                                        <span className="dropdown-item" onClick={() => addUpdateDesignationModal()}><i className="fa fa-trash"></i>Delete</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <DataTable page={page} search={search} filter={filter} sort={sort} editDesignation={editDesignation} handleSelect={onSelect} />
                </div>
            </div>
        </section>
    )
}



function mapStateToProps(state) {
    return {
        page: state.DesignationReducer.page,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addDesignations: (params) => dispatch(DesignationAction.addDesignations(params)),
    }
};

const connectedDesignation = (connect(mapStateToProps, mapDispatchToProps)(Designation));
// const connectedDesignation = withRouter(connect(mapStateToProps, mapDispatchToProps)(Designation));
export { connectedDesignation as Designation };




































