import React, { useState } from 'react'
import { connect } from 'react-redux';
import { FormInput, FormSaveBtn, FormTextArea } from '../../_components/form-input';
import { handleInputChange, validateForm } from '../../_helpers';
import { ContactSupportAction } from '../../_redux/actions';
import { Grid, CardContent, Card, Button, Typography } from '@material-ui/core';
import validateSupportMail from './validateSupportMail';
import { DoneAll } from '@material-ui/icons';

function SupportMail({ sendSupportMail }) {
    const inputs = { title: '', description: '' }

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [displayThankyou, setDisplayThankyou] = useState(false);


    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateSupportMail(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('submitted');

        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateSupportMail(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }
        dispatchContactSupportAction(data);
    }

    const dispatchContactSupportAction = (data) => {
        sendSupportMail(data).then((response) => {
            if (response.status === 1) {
                setDisplayThankyou(true);
                setErrors({ ...inputs });
                setData({ ...inputs });
                setAction({ ...action, isSubmitted: false, isProcessing: false });
            } else {
                console.log("no");
            }
        });
    }

    const ThankYou = (
        <Grid container spacing={3}  >
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
                <Card>
                    <CardContent>
                        <Grid container direction="column" alignContent="center" justifyContent="center">
                            <Grid item sm="12" md="12" xl="12">
                                <Typography variant="h1" color="success" align="center">  <DoneAll style={{ fontSize: `130px`, color: '#009d00' }} /></Typography>
                                <Typography variant="h6" color="initial" align="center"> Thank you</Typography>
                            </Grid>
                            <Grid item sm="12" md="12" xl="12">
                                <Button variant="contained" color="primary" size="small" onClick={() => setDisplayThankyou(false)}>Do you want to send another support email?</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )

    const ContactSupportForm = (
        <Grid container spacing={3}  >
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
                <Card >
                    <CardContent style={{ padding: `25px` }}>
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container>
                                <Grid item sm="12" md="12" xl="12">
                                    <FormInput
                                        label="Title"
                                        placeholder="Enter title"
                                        name="title"
                                        value={data.title}
                                        onChange={handleChange}
                                        error={(action.isSubmitted && (errors.title)) ? (errors.title) : ''}
                                        maxLength={50}
                                    />
                                </Grid>
                                <Grid item sm="12" md="12" xl="12">
                                    <FormTextArea
                                        label="Description"
                                        name="description"
                                        error={(action.isSubmitted && (errors.description)) ? (errors.description) : ''}
                                        placeholder="Enter description"
                                        value={data.description}
                                        onChange={handleChange}
                                        rows="22"
                                    />
                                </Grid>
                            </Grid>
                            <FormSaveBtn className="mr-1" isProcessing={action.isProcessing} text="Send" />
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid >
    );

    return (
        <>
            {displayThankyou ? (ThankYou) : (ContactSupportForm)}
        </>
    )
}

function mapStateToProps(state) {
    return {
        // loading: state.DealReducer.deal_loading,
        // deals: state.DealReducer.deals,
        // page: state.DealReducer.page,
        // per_page: state.DealReducer.per_page,
        // total: state.DealReducer.total,
        // total_pages: state.DealReducer.total_pages,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sendSupportMail: (params) => dispatch(ContactSupportAction.sendSupportMail(params)),
    }
};

const connectedSupportMail = connect(mapStateToProps, mapDispatchToProps)(SupportMail);
export { connectedSupportMail as SupportMail };

