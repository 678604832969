



import { authHeader, handleResponse, notify } from "../../_helpers";
import Config from "../../_helpers/config";
import queryString from 'query-string';

export const GiveawayWinnerServices = { getWinnerCount, generateWinner, getAllWinners, getLatestWinner, getParticipants, getGiveawayEntries };


/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function getWinnerCount(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/give-away/count`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function generateWinner(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/give-away/winner`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function getAllWinners(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';
    // requestOptions.body = JSON.stringify(params);

    let queryParam = {};
    queryParam.page = params.page;
    if (params.search) queryParam.search = params.search; else delete queryParam.search;

    return fetch(`${Config.endpoint}/ns/give-away/winner-list?${queryString.stringify(queryParam)}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function getLatestWinner() {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';
    // requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/give-away/latest-winner`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function getParticipants(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    let queryParam = {};
    queryParam.page = params.page;
    if (params.search) queryParam.search = params.search; else delete queryParam.search;

    return fetch(`${Config.endpoint}/ns/give-away/get-participants/${params.id}?${queryString.stringify(queryParam)}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function getGiveawayEntries(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    let queryParam = {};
    queryParam.page = params.page;
    if (params.search) queryParam.search = params.search; else delete queryParam.search;

    return fetch(`${Config.endpoint}/ns/give-away/get-give-away-entries?${queryString.stringify(queryParam)}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}
