import { UserServices } from "../services";
import CookieServices from "../services/CookieServices";

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const CHANGE_USER_PASSWORD_REQUEST = 'CHANGE_USER_PASSWORD_REQUEST';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_FAILURE = 'CHANGE_USER_PASSWORD_FAILURE';

export const GET_TOTAL_PROFILE_VIEWS_REQUEST = 'GET_TOTAL_PROFILE_VIEWS_REQUEST';
export const GET_TOTAL_PROFILE_VIEWS_SUCCESS = 'GET_TOTAL_PROFILE_VIEWS_SUCCESS';
export const GET_TOTAL_PROFILE_VIEWS_FAILURE = 'GET_TOTAL_PROFILE_VIEWS_FAILURE';

export const GET_TOTAL_NEW_FOLLOWERS_REQUEST = 'GET_TOTAL_NEW_FOLLOWERS_REQUEST';
export const GET_TOTAL_NEW_FOLLOWERS_SUCCESS = 'GET_TOTAL_NEW_FOLLOWERS_SUCCESS';
export const GET_TOTAL_NEW_FOLLOWERS_FAILURE = 'GET_TOTAL_NEW_FOLLOWERS_FAILURE';

export const GET_PROFILE_VIEWS_AND_REACH_REQUEST = 'GET_PROFILE_VIEWS_AND_REACH_REQUEST';
export const GET_PROFILE_VIEWS_AND_REACH_SUCCESS = 'GET_PROFILE_VIEWS_AND_REACH_SUCCESS';
export const GET_PROFILE_VIEWS_AND_REACH_FAILURE = 'GET_PROFILE_VIEWS_AND_REACH_FAILURE';

export const GET_FOLLOWER_CHANGE_REQUEST = 'GET_FOLLOWER_CHANGE_REQUEST';
export const GET_FOLLOWER_CHANGE_SUCCESS = 'GET_FOLLOWER_CHANGE_SUCCESS';
export const GET_FOLLOWER_CHANGE_FAILURE = 'GET_FOLLOWER_CHANGE_FAILURE';

export const GET_QR_SCAN_COUNT_REQUEST = 'GET_QR_SCAN_COUNT_REQUEST';
export const GET_QR_SCAN_COUNT_SUCCESS = 'GET_QR_SCAN_COUNT_SUCCESS';
export const GET_QR_SCAN_COUNT_FAILURE = 'GET_QR_SCAN_COUNT_FAILURE';

export const UserAction = { getUser, updateUser, changePassword, logout, getTotalProfileViews, getTotalNewFollowers, getProfileViewsAndReach, getFollowerChange, getQrScanScanCount };

/**
 * Action get user
 *
 * @author Akshay 
 */
function getUser() {
    return dispatch => {
        dispatch(request());
        return UserServices.getUser()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request() { return { type: GET_USER_REQUEST } }
    function success(response) { return { type: GET_USER_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_USER_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Update user profile
 *
 * @author Akshay 
 */
function updateUser(params) {
    return dispatch => {
        dispatch(request(params));
        return UserServices.updateUser(params)
            .then(
                response => { return dispatch(success(params, response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(request) { return { type: UPDATE_USER_REQUEST, request: request } }
    function success(request, response) { return { type: UPDATE_USER_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: UPDATE_USER_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}


/**
 * Update user profile
 *
 * @author Akshay 
 */
function changePassword(params) {
    return dispatch => {
        dispatch(request(params));
        return UserServices.changePassword(params)
            .then(
                response => { return dispatch(success(params, response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(request) { return { type: CHANGE_USER_PASSWORD_REQUEST, request: request } }
    function success(request, response) { return { type: CHANGE_USER_PASSWORD_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: CHANGE_USER_PASSWORD_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * function get total profile views
 * @param params
 * @return view
 * @author Jasin
 * @created_at 24/09/2022
*/
 function getTotalProfileViews() {
    return dispatch => {
        dispatch(request());
        return UserServices.getTotalProfileViews()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_TOTAL_PROFILE_VIEWS_REQUEST, request: params } }
    function success(response) { return { type: GET_TOTAL_PROFILE_VIEWS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_TOTAL_PROFILE_VIEWS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * function get total new followers
 * @param params
 * @return view
 * @author Jasin
 * @created_at 24/09/2022
*/
function getTotalNewFollowers() {
    return dispatch => {
        dispatch(request());
        return UserServices.getTotalNewFollowers()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_TOTAL_NEW_FOLLOWERS_REQUEST, request: params } }
    function success(response) { return { type: GET_TOTAL_NEW_FOLLOWERS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_TOTAL_NEW_FOLLOWERS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * function get profile ciews and reach
 * @param params
 * @return view
 * @author Jasin
 * @created_at 07/10/2022
*/
function getProfileViewsAndReach() {
    return dispatch => {
        dispatch(request());
        return UserServices.getProfileViewsAndReach()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_PROFILE_VIEWS_AND_REACH_REQUEST, request: params } }
    function success(response) { return { type: GET_PROFILE_VIEWS_AND_REACH_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_PROFILE_VIEWS_AND_REACH_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * function get profile ciews and reach
 * @param params
 * @return view
 * @author Jasin
 * @created_at 07/10/2022
*/
function getFollowerChange() {
    return dispatch => {
        dispatch(request());
        return UserServices.getFollowerChange()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_FOLLOWER_CHANGE_REQUEST, request: params } }
    function success(response) { return { type: GET_FOLLOWER_CHANGE_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_FOLLOWER_CHANGE_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}
/**
 * function get qr scan count
 * @param params
 * @return view
 * @author Jasin
 * @created_at 24/09/2022
*/
function getQrScanScanCount() {
    return dispatch => {
        dispatch(request());
        return UserServices.getQrScanScanCount()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_QR_SCAN_COUNT_REQUEST, request: params } }
    function success(response) { return { type: GET_QR_SCAN_COUNT_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_QR_SCAN_COUNT_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}
/**
 * Insert text at cursor position.
 * 
 * @parm
 * @author Akshay 
 */
function logout() {
    UserServices.logout();
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");
    CookieServices.remove('accessToken');
    CookieServices.remove('refreshToken');
}