import React from 'react'

function Sort({ onSort, column, field, order }) {


    const getSortParam = (e) => {
        if (e.target.checked)
            onSort({ 'field': e.target.getAttribute("data-field"), 'order': e.target.getAttribute("data-order") })
        else
            onSort({ 'field': '', 'order': '' })
    }

    return (
        <button className="btn btn-sm btn-primary btn-group btn-sort" role="group">
            <span type="button" className="dropdown dropleft" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-sort-amount-up"></i></span>
            <div className="dropdown-menu dropdown-menu-right">
                <div className="row">
                    {column.map((sort, i) => {
                        return (<React.Fragment key={i}>
                            <div className="col-md-6"><label>{sort.label}</label></div>
                            <div className="col-md-6 pl-0">
                                <label className="btn btn-sm btn-secondary btn-sort-item"><input type="checkbox" data-field={sort.name} data-order="asc" defaultChecked={(sort.name === field && `asc` === order) ? true : false} name="sort" onClick={getSortParam} /><i className="fa fa-arrow-circle-up"></i></label>
                                <label className="btn btn-sm btn-secondary btn-sort-item"><input type="checkbox" data-field={sort.name} data-order="desc" defaultChecked={(sort.name === field && `desc` === order) ? true : false} name="sort" onClick={getSortParam} /><i className="fa fa-arrow-circle-down"></i></label>
                            </div>
                        </React.Fragment>)
                    })}
                </div>
            </div>
        </button>
    )
}

export { Sort }
