import { Avatar, Box, CircularProgress, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShoppersAction } from '../../../_redux/actions';


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(4)
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

function Shopper({ isLoading, shopper, getShopperDetails, clearShopperDetails, ...props }) {

    const { id } = useParams();

    const classes = useStyles();

    useEffect(() => {
        getShopperDetails(id);
        return () => {
            clearShopperDetails()
        }
    }, [getShopperDetails, clearShopperDetails, id])


    return (
        <div className="screen-container">
            <Grid container spacing={2}>

                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography variant="p" component="h1" spacing="mb-0">Creator</Typography>
                    </Grid>
                    <Grid item xs={5} >
                        <Paper className={classes.paper} alignItems="center">
                            {isLoading && <CircularProgress color="secondary" />}
                            {!isLoading && (
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item >
                                        <Box boxShadow="3">
                                            <Avatar src={shopper.instagram.profile_image} variant="square" className={classes.large} ></Avatar>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <h4>{shopper.instagram.name}</h4>
                                        <h6 style={{ fontWeight: 400 }}><i className="fab fa-instagram" aria-hidden="true"></i> {shopper.instagram.username}</h6>
                                        <h6 style={{ fontWeight: 400 }}><i className="fa fa-envelope" aria-hidden="true"></i> {shopper.contactInfo.email}</h6>
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={7}>
                        <Paper className={classes.paper}>

                        </Paper>
                    </Grid>
                </React.Fragment>
            </Grid>
        </div >
    )
}



function mapStateToProps(state) {
    return {

        isLoading: state.ShoppersReducer.shopper_loading,
        shopper: state.ShoppersReducer.shopper,
        shopperError: state.ShoppersReducer.shopper_error,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getShopperDetails: (shopperId) => dispatch(ShoppersAction.getShopperDetails(shopperId)),
        clearShopperDetails: () => dispatch(ShoppersAction.clearShopperDetails())
    }
};

const connectedShopper = (connect(mapStateToProps, mapDispatchToProps)(Shopper));
export { connectedShopper as Shopper };
