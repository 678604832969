import { authHeader, handleResponse } from "../../_helpers";
import Config from "../../_helpers/config";


export const IgInsightsServices = { getIgInsights, getInsightChart, getDailyCouponCount, getDcInsightChart };


/**
 * Service for get deals 
 *
 * @author Jasin 
 */
function getIgInsights() {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';
    return fetch(`${Config.endpoint}/ns/ig-insights`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service for import deals
 *
 * @author Jasin 
 */
function getInsightChart(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);

    return fetch(`${Config.endpoint}/ns/ig-insights/chart`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service for import deals
 *
 * @author Jasin 
 */
function getDailyCouponCount(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    return fetch(`${Config.endpoint}/ns/ig-insights/daily-coupon-count`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service for import deals
 *
 * @author Jasin 
 */
function getDcInsightChart(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);

    return fetch(`${Config.endpoint}/ns/ig-insights/dc-chart`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}
