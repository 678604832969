import React from 'react'
import { Box, Button, CardContent, CircularProgress, Grid } from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { NoDataFound } from '../../_components/not-found';
import { PageTitle } from '../../_layouts/page-title'
import { DealAction } from '../../_redux/actions';
import { useNavigate } from 'react-router-dom';
import { DealPreview } from './';

function Deal({ latest_deal, loading, brand_sub_domain, ...props }) {


    const navigate = useNavigate();

    const AddUpdateDeals = () => {
        let pageUrl = (latest_deal._id) ? `/deals/edit/${latest_deal._id}` : `/deals/add`;
        navigate(pageUrl);
    }

    return (
        <React.Fragment>
            <PageTitle title="Offer" action={
                <React.Fragment>
                    {!latest_deal._id && (<Button variant="contained" color="primary" onClick={AddUpdateDeals} startIcon={<Add>Add</Add>} >Add Offer</Button>)}
                    {latest_deal._id && (<Button variant="contained" color="primary" startIcon={<Edit>Update</Edit>} onClick={AddUpdateDeals}> Update Offer </Button>)}
                </React.Fragment>
            } />
            <Box>

                {!loading && latest_deal._id && (
                    <Card>
                        <CardContent className='deal-card'>
                            <Grid container spacing={1}  >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}><DealPreview /></Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}
                {!loading && !latest_deal._id && <NoDataFound text="No offer found" height="85vh" />}
                {loading && (<Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ paddingTop: `300px` }}><Grid item ><CircularProgress /></Grid> </Grid>)}
            </Box>
        </React.Fragment>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.DealReducer.latest_deal_loading,
        latest_deal: state.DealReducer.latest_deal,
        brand_sub_domain: state.SettingsReducer.brand_sub_domain
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getLatestDeal: dispatch(DealAction.getLatestDeal())
    }
};

const connectedDeal = (connect(mapStateToProps, mapDispatchToProps)(Deal));
export { connectedDeal as Deal };


