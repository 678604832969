import { Home } from "../app/home";
import { Designation } from "../app/master/designation";
import { ApproveBusiness, ForgotPassword, SignIn, SignUp } from "../app/pre-signin";
import { PrivacyPolicy } from "../app/pre-signin/tos-privacy-policy";
import { Profile, ProfileUpdate, ProfileDeactivate } from "../app/profile";
import { Settings, LoginSettings, SubDomainSettings } from "../app/settings";

const allRoutes = {
    'sign_in': {
        path: "/signin",
        name: "Sign In",
        component: SignIn,
        layout: "/pre-auth"
    },
    'sign_up': {
        path: "/signup",
        name: "Sign Up",
        component: SignUp,
        layout: "/pre-auth"
    },
    'forgot_password': {
        path: "/forgot-password",
        name: "Forgot Password",
        component: ForgotPassword,
        layout: "/pre-auth"
    },
    'approve_business': {
        path: "/approve/:id/:key",
        name: "Approve",
        component: ApproveBusiness,
        layout: "/pre-auth"
    },
    'home': {
        path: "/home",
        name: "Home",
        component: Home,
        layout: "/post-auth"
    },
    'masters': {
        path: "/master",
        name: "Masters",
        component: Designation,
        layout: "/post-auth"
    },
    'designation_master': {
        path: "/master/designation",
        name: "Designation",
        component: Designation,
        layout: "/post-auth"
    },
    'profile': {
        path: "/profile/:type?",
        name: "Profile",
        component: Profile,
        layout: "/post-auth"
    },
    'profile_details': {
        path: "/profile",
        name: "Profile",
        component: Profile,
        layout: "/post-auth"
    },
    'profile_update': {
        path: "/profile/update",
        name: "Update Profile",
        component: ProfileUpdate,
        layout: "/post-auth"
    },
    // 'profile_change_password': {
    //     path: "/profile/change-password",
    //     name: "Change Password",
    //     component: ProfileChangePassword,
    //     layout: "/post-auth"
    // },
    'profile_deactivate': {
        path: "/profile/delete",
        name: "Deactivate Profile",
        component: ProfileDeactivate,
        layout: "/post-auth"
    },


    'settings': {
        path: "/settings/:type?",
        name: "Settings",
        component: Settings,
        layout: "/post-auth"
    },
    'settings_details': {
        path: "/settings",
        name: "Settings",
        component: Settings,
        layout: "/post-auth"
    },
    'login_settings': {
        path: "/settings/login",
        name: "Brand Settings",
        component: LoginSettings,
        layout: "/post-auth"
    },
    'sub_domain_settings': {
        path: "/settings/sub-domain",
        name: "Subdomain Settings",
        component: SubDomainSettings,
        layout: "/post-auth"
    },


    'deals': {
        path: "/deals",
        name: "Deals",
        component: SubDomainSettings,
        layout: "/post-auth"
    },

    'privacy_policy': {
        path: "/privacy-policy",
        name: "Privacy Policy",
        component: PrivacyPolicy,
        layout: "/pre-auth"
    }
};


function RouteConfig(keyType = null) {
    if (keyType) {
        return allRoutes[keyType];
    }

}

export { RouteConfig };
