import React from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    'root': {
        marginLeft: theme.spacing(1)
    }
}));


function ConfzModalFooterSaveBtn({ text, className, tabIndex, isProcessing, disabled, props }) {
    const classes = useStyles();
    return (
        <Button className={classes.root} type="submit" variant="contained" color={"primary"} tabIndex={tabIndex} disabled={isProcessing || disabled} loadingPosition="start" startIcon={(isProcessing ) ? (<CircularProgress size={14} />) : ('')} {...props}>
            {text}
        </Button>
    )
}


ConfzModalFooterSaveBtn.defaultProps = {
    className: "",
    text: "Save",
}


ConfzModalFooterSaveBtn.propTypes = {
    className: PropTypes.string,
    tabIndex: PropTypes.string,
    text: PropTypes.string.isRequired,
    isProcessing: PropTypes.bool,
}

export { ConfzModalFooterSaveBtn }
