const validateDesignationImport = (name, value) => {
    const errors = {};
    switch (name) {
        case 'file': {

            errors.file = '';
            if (value === '') {
                errors.file = 'Csv is required. ';
            } else {
                let extension = (value.name).split('.').pop();
                let size = value.size;
                if (extension !== 'csv') {
                    errors.file = 'Please choose a valid csv file.';
                } else if (size >= 2000) {
                    errors.file = 'File size exceed. ';
                }
            }
            break;
        } default:
            errors[name] = '';
            break;
    }
    return errors;
}
export default validateDesignationImport;