import {
	SIGNUP_FAILURE, SIGNUP_REQUEST, SIGNUP_SUCCESS,
	INSTA_SIGNUP_FAILURE, INSTA_SIGNUP_REQUEST, INSTA_SIGNUP_SUCCESS,
} from "../actions";

let user = localStorage.getItem('authToken') ? (localStorage.getItem('authToken')) : '';
const initialState = user ? { loggedIn: true, user } : {};

export function SignUpReducer(state = initialState, action) {
	switch (action.type) {


		/** begin::Sign Up redux */
		case SIGNUP_REQUEST:
			return {
				loggedIn: false,
				request: action.request,
				user: {}
			};
		case SIGNUP_SUCCESS:
			return {
				loggedIn: false,
				request: action.request,
				user: action.data,
			};
		case SIGNUP_FAILURE:
			return {
				loggedIn: false,
				user: {}
			};
		/** end::Sign Up redux */

		/** begin::Sign Up redux */
		case INSTA_SIGNUP_REQUEST:
			return {
				loggedIn: false,
				request: action.request,
				user: {}
			};
		case INSTA_SIGNUP_SUCCESS:
			return {
				loggedIn: false,
				request: action.request,
				user: action.data,
			};
		case INSTA_SIGNUP_FAILURE:
			return {
				loggedIn: false,
				user: {}
			};
		/** end::Sign Up redux */


		default:
			return state;
	}
}
