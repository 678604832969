const colors = {
	primary: '#1242DE',
	background: '#E5E5E5',
	red: '#EB5757',
	lightRed: 'rgba(235, 87, 87, 0.4)',
	secondary: '#FDBC2A',
	green: '#247D41',
	lightGreen: 'rgba(36, 125, 65, 0.4)',
	grey:'#E0E7EE'

};

export const colorArr = [colors.primary, colors.secondary, colors.grey]
export const randomColors = () => {
	let randomNum = Math.random()
	const num = Math.floor(randomNum * 4);
	return colorArr[num]
}

export default colors;
