import {
    GET_LOGIN_SETTINGS_REQUEST, GET_LOGIN_SETTINGS_SUCCESS, GET_LOGIN_SETTINGS_FAILURE,
    UPDATE_SUBDOMAIN_REQUEST, UPDATE_SUBDOMAIN_SUCCESS, UPDATE_SUBDOMAIN_FAILURE,
    UPDATE_LOGIN_SETTINGS_REQUEST, UPDATE_LOGIN_SETTINGS_SUCCESS, UPDATE_LOGIN_SETTINGS_FAILURE
} from "../actions";

const initialState = {
    settings_loading: false,
    brand_sub_domain: '',
    login_settings: {
        brand_logo: '',
        brand_title: '',
        brand_description: '',
        brand_primary_color: { r: 18, g: 66, b: 222, a: 1 },
        brand_secondary_color: { r: 255, g: 255, b: 255, a: 1 },
        brand_tertiary_color: { r: 34, g: 34, b: 34, a: 1 },
    }, settings_error: []
};

export function SettingsReducer(state = initialState, action) {
    switch (action.type) {
        /** begin::Designation redux */
        case GET_LOGIN_SETTINGS_REQUEST: {
            return {
                ...state,
                settings_loading: true
            };
        } case GET_LOGIN_SETTINGS_SUCCESS: {
            return {
                ...state,
                settings_loading: false,
                brand_sub_domain: action.data.brand_sub_domain,
                login_settings: action.data

            };
        } case GET_LOGIN_SETTINGS_FAILURE: {
            return {
                ...state,
                settings_loading: false,
                settings_error: action.error
            };
        }
        /** end::Designation redux */

        /** begin::Designation redux */
        case UPDATE_SUBDOMAIN_REQUEST: {
            return {
                ...state,
                settings_loading: true
            };
        } case UPDATE_SUBDOMAIN_SUCCESS: {
            return {
                ...state,
                brand_sub_domain: action.data.brand_sub_domain,
                settings_loading: false
            };
        } case UPDATE_SUBDOMAIN_FAILURE: {
            return {
                ...state,
                settings_loading: false,
                settings_error: action.error
            };
        }
        /** end::Designation redux */

        /** begin::Designation redux */
        case UPDATE_LOGIN_SETTINGS_REQUEST: {
            return {
                ...state,
                settings_loading: []
            };
        } case UPDATE_LOGIN_SETTINGS_SUCCESS: {
            console.log(action.data);
            return {
                ...state,
                login_settings: {
                    ...state.login_settings,
                    brand_logo: action.data.brand_logo,
                    brand_title: action.data.brand_title,
                    brand_description: action.data.brand_description,
                    brand_primary_color: action.data.brand_primary_color,
                    brand_secondary_color: action.data.brand_secondary_color,
                    brand_tertiary_color: action.data.brand_tertiary_color,
                }
            };
        } case UPDATE_LOGIN_SETTINGS_FAILURE: {
            return {
                ...state,
                settings_loading: false,
                settings_error: action.error
            };
        }
        /** end::Designation redux */




        default:
            return state;
    }
}