import React from 'react'

function Section({ children }) {
    return (
        <section className="section">
            {children}
        </section>
    )
}

export { Section }
