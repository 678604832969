
const validateSignUp = (name, value, data = {}) => {
    //eslint-disable-next-line
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const validInstaHandle = RegExp(/^@?[A-Z0-9_\\.]+$/i);
    const errors = {};
    switch (name) {
        case 'company_name': {
            errors.company_name = value.length < 3 ? 'Company name must be at leaset 3 characters long. ' : '';
            if (value === '')
                errors.company_name = 'Company name is required. ';
            break;
        } case 'insta_handle': {
            errors.insta_handle = validInstaHandle.test(value) ? '' : 'Public instagram handle is invalid. ';;
            if (value === '')
                errors.insta_handle = 'Public instagram handle is required. ';
            break;
        } case 'name': {
            errors.name = (value.length < 3) ? 'Company representative name must be atleaset 3 charecters. ' : '';
            if (value === '')
                errors.name = 'Company representative name is required ';
            break;
        } case 'email': {
            errors.email = validEmailRegex.test(value) ? '' : 'Company representative email is invalid. ';
            if (value === '')
                errors.email = 'Company representative email is required. ';
            break;
        } case 'company_url': {
            errors.company_url = '';
            if (value === '')
                errors.company_url = 'Company URL is required. ';
            break;
        } case 'company_phone': {
            errors.company_phone = '';
            if (value === 'undefined') {
                errors.company_phone = 'The Company phone number is invalid. '
            }
            if (value === '')
                errors.company_phone = 'Company phone number is required. ';
            break;
        } case 'password': {
            errors.password = value.length < 6 ? 'Password must be 6 characters long. ' : '';
            if (value === '')
                errors.password = 'Password is required. ';
            break;
        } case 'confirm_password': {
            const password = data.password ?? '';
            errors.confirm_password = value.length < 6 ? 'Confirm password must be 6 characters long. ' : '';
            if (password !== '' && password !== value)
                errors.confirm_password = 'Confirm password should match with the Password.';
            if (value === '')
                errors.confirm_password = 'Confirm password is required.';
            break;
        } case 'terms_and_condition': {
            errors.terms_and_condition = '';
            if (value === false)
                errors.terms_and_condition = 'Please accept terms and condition. ';
            break;
        } default:
            break;
    }
    return errors;
}
export default validateSignUp;