const validateLoginSettings = (name, value, data = {}) => {
    //eslint-disable-next-line
    const errors = {};
    switch (name) {
        case 'brand_logo': {
            errors.brand_logo = '';
            if (value === '' || value === [] || typeof value === 'undefined') {
                errors.brand_logo = 'Brand logo is required. ';
            } else if (typeof value !== 'string') {
                let extension = (value.name).split('.').pop();
                extension = extension.toLowerCase();
                let size = value.size;
                if (extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg') {
                    errors.brand_logo = 'Please choose a valid file.';
                } else if (size >= 2000000) {
                    errors.brand_logo = 'File size exceed. ';
                }
            }
            break;



            // } case 'brand_title': {
            //     errors.brand_title = '';
            //     if (value === '')
            //         errors.brand_title = 'Brand title is required. ';
            //     break;
        } case 'brand_description': {
            errors.brand_description = '';
            if (value === '')
                errors.brand_description = 'Brand description is required. ';
            break;
        }
        case 'brand_primary_color': {
            errors.brand_primary_color = '';
            if (value === '')
                errors.brand_primary_color = 'Brand background color is required. ';
            break;
        } case 'brand_secondary_color': {
            errors.brand_secondary_color = '';
            if (value === '')
                errors.brand_secondary_color = 'Brand text color is required. ';
            break;
        } case 'brand_tertiary_color': {
            errors.brand_tertiary_color = '';
            if (value === '')
                errors.brand_tertiary_color = 'Brand button color is required. ';
            break;
        } default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}
export default validateLoginSettings;