const validate = (name, value, data) => {
    //eslint-disable-next-line
    const errors = {};
    switch (name) {

        case 'message_content': {
            errors.message_content = '';
            if (value === '')
                errors.message_content = 'Reply is required. ';
            break;

        } default:
            errors[name] = '';
            break;
    }
    return errors;
}
export default validate;