import {
    GET_IG_CONVERSATION_LIST_REQUEST, GET_IG_CONVERSATION_LIST_SUCCESS, GET_IG_CONVERSATION_LIST_FAILURE,
    GET_IG_CONVERSATION_DETAILS_REQUEST, GET_IG_CONVERSATION_DETAILS_SUCCESS, GET_IG_CONVERSATION_DETAILS_FAILURE,
    GET_IG_SEND_DM_REQUEST, GET_IG_SEND_DM_SUCCESS, GET_IG_SEND_DM_FAILURE,

} from "../actions";

const initialState = {
    ig_conversation_list_loading: false, ig_conversation_list: [], ig_conversation_list_error: {},
    ig_conversation_details_loading: false, ig_conversation_details: [], ig_conversation_details_error: {},
    ig_send_dm_loading: false, ig_send_dm: {}, ig_send_dm_error: {},
};

export function IgMessageReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Ig conversation list redux */
        case GET_IG_CONVERSATION_LIST_REQUEST: {
            return {
                ...state,
                ig_conversation_list_loading: true,
                ig_conversation_list_error: {}
            };
        } case GET_IG_CONVERSATION_LIST_SUCCESS: {
            return {
                ...state,
                ig_conversation_list_loading: false,
                ig_conversation_list: action.data,
                ig_conversation_list_error: {}
            };
        } case GET_IG_CONVERSATION_LIST_FAILURE: {
            return {
                ...state,
                ig_conversation_list_loading: false,
                ig_conversation_list_error: action.error
            };
        }
        /** end::Ig conversation list redux */

        /** begin::Ig conversation details redux */
        case GET_IG_CONVERSATION_DETAILS_REQUEST: {
            return {
                ...state,
                ig_conversation_details_loading: true,
                ig_conversation_details_error: {}
            };
        } case GET_IG_CONVERSATION_DETAILS_SUCCESS: {
            return {
                ...state,
                ig_conversation_details_loading: false,
                ig_conversation_details: action.data,
                ig_conversation_details_error: {}
            };
        } case GET_IG_CONVERSATION_DETAILS_FAILURE: {
            return {
                ...state,
                ig_conversation_details_loading: false,
                ig_conversation_details_error: action.error,
                ig_conversation_details: []
            };
        }
        /** end::Ig conversation list redux */

        
        /** begin::Ig send dm redux */
        case GET_IG_SEND_DM_REQUEST: {
            return {
                ...state,
                ig_send_dm_loading: true,
                ig_send_dm_error: {}
            };
        } case GET_IG_SEND_DM_SUCCESS: {
            let newArray = state.ig_conversation_details;
            newArray = newArray.concat(action.data);
            
            return {
                ...state,
                ig_send_dm_loading: false,
                ig_conversation_details: newArray,
                ig_send_dm_error: {}
            };
        } case GET_IG_SEND_DM_FAILURE: {
            return {
                ...state,
                ig_send_dm_loading: false,
                ig_send_dm_error: action.error,
            };
        }
        /** end::Ig conversation list redux */

        default:
            return state;
    }
}