import React, { useRef } from 'react'
import { PageTitle } from '../../../_layouts/page-title'
import { ComponentToPrint } from './ComponentToPrint';
import { useReactToPrint } from 'react-to-print';
import { connect } from 'react-redux';
import { Box, Button } from '@material-ui/core';

function GenerateQrCode({ brand_sub_domain, hideTitle = false, hideAction = false, qrSize=200 }) {

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleSave = () => {
        const svg = componentRef.current.children[0];
        const svgData = new XMLSerializer().serializeToString(svg);

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = "QRCode";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };

    return (
        <React.Fragment>
            {!hideTitle && <PageTitle title="QR Code" />}
            <ComponentToPrint brand_sub_domain={brand_sub_domain} ref={componentRef} qrSize={qrSize}/>
            <Box display={`flex`} alignItems={`center`} justifyContent={`center`}>
                {!hideAction && <Box display={`flex`} alignItems={`center`} justifyContent={`center`} mt={3}>
                    <Button variant="contained" color="primary" onClick={handlePrint}>Print QR Code</Button>
                    <Button variant="contained" color="primary" onClick={handleSave} style={{ marginLeft: 5 }} >Save QR Code</Button>
                </Box>}
            </Box>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        brand_sub_domain: state.SettingsReducer.brand_sub_domain
    };
}

const connectedGenerateQrCode = connect(mapStateToProps, null)(GenerateQrCode);
export { connectedGenerateQrCode as GenerateQrCode };
