



import { authHeader, handleResponse, notify } from "../../_helpers";
import Config from "../../_helpers/config";

export const SettingsServices = { getLoginSettings, updateSubDomain, updateLoginSettings };


/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function getLoginSettings() {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';
    return fetch(`${Config.endpoint}/ns/settings/login-details`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}
/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function updateSubDomain(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/settings/update-sub-domain`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}
/**
 * Service for get designations 
 *
 * @author Akshay 
 */
function updateLoginSettings(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    delete requestOptions.headers['Content-Type'];
    requestOptions.headers['form-data'] = true;
    requestOptions.method = 'POST';
    requestOptions.body = (params);
    return fetch(`${Config.endpoint}/ns/settings/update-login-settings`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}