import { ApproveBusinessServices } from "../services";
export const ApproveBusinessAction = { approve };


/**
 * Action get designation
 *
 * @author Akshay 
 */
function approve(id, key) {
    return dispatch => {
        return ApproveBusinessServices.approve(id, key)
            .then(
                response => { return response },
                error => { return error });
    };
}

