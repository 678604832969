import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles({
    paper: {
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },

    TableCol1: {
        width: '45%'
    },

    TableCol2: {
        width: '10%'
    },

    TableCol3: {
        width: '45%'
    },

    TableHead: {
        padding: '16px 16px 0px 16px',
        verticalAlign: 'top'
    },

    TableCell: {
        padding: '0px 16px 24px 16px'
    }
});

function ComparisonWidget({ loading, text, text2, value, value2, bgColor, bgColor2, color, color2 }) {

    const classes = useStyles();

    return (
        <Card style={{ backgroundColor: bgColor, color: color, height: "100%", minHeight: `140px`, display: `flex`, alignItems: `center` }}>
            <CardContent style={{ padding: '0px' }}>
                <React.Fragment>
                    <table>
                        <tr>
                            <th className={`${classes.TableHead} ${classes.TableCol1}`}>
                                <Typography className={classes.title} gutterBottom dangerouslySetInnerHTML={{ __html: text }} />
                            </th>
                            <th className={`${classes.TableHead} ${classes.TableCol2}`}>
                                <Typography className={classes.title} gutterBottom >V/S</Typography></th>
                            <th className={`${classes.TableHead} ${classes.TableCol3}`}>
                                <Typography className={classes.title} gutterBottom dangerouslySetInnerHTML={{ __html: text2 }} />
                            </th>
                        </tr>
                        <tr>
                            <td className={classes.TableCell}>
                                <Typography variant="h5" component="h2" dangerouslySetInnerHTML={{ __html: (loading ? '<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>' : value) }} /></td>
                            <td className={classes.TableCell}> </td>
                            <td className={classes.TableCell}>
                                <Typography variant="h5" component="h2" dangerouslySetInnerHTML={{ __html: (loading ? '<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>' : value2) }} />
                            </td>
                        </tr>

                    </table>
                </React.Fragment>
            </CardContent>
        </Card>
    )
}

export { ComparisonWidget }
