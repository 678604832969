import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FormGroupPassword } from '../../../_components/form-group-input';
import { FormCheckbox, FormInput } from '../../../_components/form-input';
import { handleInputChange, validateForm } from '../../../_helpers';
import { SignInAction } from '../../../_redux/actions';
import { RouteConfig } from '../../../_routes';
import validate from './validate';
import Config from '../../../_helpers/config';
import { paperElevation } from '../../../config/config';
import Paper from '@material-ui/core/Paper';
import colors from '../../../theme/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import GuruLogo from '../../../components/GuruLogo';

import loginBackgroundImage from './../../../images/login_background.jpg';
import { Grid } from '@material-ui/core';

function SignIn(props) {

    const inputs = { email: '', password: '', remember_me: false };
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [page, setPage] = useState({ loaded: false, type: 'default', data: {} });


    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: 30,
            maxWidth: 600
        },
        title: {
            marginBottom: 40,
            color: theme.palette.common.white,
        },
        textField: {
            '& .MuiFormLabel-root': {
                color: theme.palette.common.white,
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'white',
                    color: theme.palette.common.white,
                },
                '&.Mui-focused fieldset': {
                    borderColor: colors.secondary,
                    color: theme.palette.common.white,
                },
                '&:hover fieldset': {
                    borderColor: colors.secondary,
                    color: theme.palette.common.white,
                },
                color: theme.palette.common.white,
            },
            marginBottom: 15,
        },
        whiteColor: {
            color: theme.palette.common.white,
        },
        loginBtn: {
            marginTop: 40,
            marginBottom: 20,
            backgroundColor: colors.secondary,
        },
        link: {
            color: 'white',
            cursor: 'pointer',
        },
        loginContainer: {
            backgroundImage: `url(${loginBackgroundImage})`,
            backgroundPosition: `center`,
            backgroundSize: `cover`,
            backgroundRepeat: `no-repeat`,
            width: '100%',
            display: 'flex',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
        }

    }));

    const styles = useStyles();

    useEffect(() => {
        document.title = `${Config.app_name} | ${RouteConfig('sign_in').name}`;
        setPage({ loaded: true, type: 'default' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * function to handle input change
     * @param object e input object
     * @author Akshay N
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true, isProcessing: false });
            return;
        }
        dispatchSignInAction();
    }

    /**
     * function to dispatch sign in
     * @author Akshay N
     */
    function dispatchSignInAction() {
        dispatch(SignInAction.signIn(data))
            .then((response) => {
                if (response.status === 1) {
                    // let redirectPath = ((props.location.state && props.location.state.from.pathname) ? (props.location.state.from.pathname) : '/');
                    let redirectPath = ('/home');
                    const { from } = { from: { pathname: redirectPath } };
                    navigate(from);
                    window.location.reload();
                } else {
                    setAction({ isSubmitted: true, isProcessing: false });
                    setErrors({ ...response.errors })
                }
            });
    }

    if (page.loaded && page.type === 'default') {
        return (
            <div className={` ${styles.loginContainer}`}>
                <Paper classes={{ root: styles.root }} elevation={paperElevation}>
                    <GuruLogo />
                    <Typography
                        color={'primary'}
                        classes={{ root: styles.title }}
                        variant={'h3'}
                    >
                        Brand Login
                    </Typography>
                    <form onSubmit={handleSubmit} className="auth-form" noValidate >
                        <Grid container spacing="3">
                            <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                                <FormInput
                                    label="Email"
                                    name="email"
                                    type="email"
                                    tabIndex="4"
                                    error={(action.isSubmitted && (errors.email)) ? (errors.email) : ''}
                                    placeholder="Enter email"
                                    value={data.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                                <FormGroupPassword
                                    type="password"
                                    label="Password"
                                    placeholder="Enter password"
                                    name="password"
                                    tabIndex="7"
                                    value={data.name}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.password)) ? (errors.password) : ''}
                                    append='after'
                                />
                            </Grid>
                            <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                                <FormCheckbox
                                    label="Keep me logged in"
                                    name="keep_me_logged"
                                    tabIndex="8"
                                    error={(action.isSubmitted && (errors.name)) ? (errors.name) : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                                <div className="form-group  form-group-action text-right pb-3">
                                    <button type="submit" className="btn btn-block btn-warning btn-submit btn-icon btn-lg mb-2" tabIndex="6" disabled={action.isProcessing}>{(action.isProcessing) ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : `Sign In`}</button>
                                    <Link className="float-left p-0" to={RouteConfig('sign_up').path}>Create New Account </Link>
                                    <Link className="float-right p-0" to={RouteConfig('forgot_password').path}>Forgot Password?</Link>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </div>
        )
    } else if (page.loaded) {
        return (
            <React.Fragment>
                {/* <div className="logo-border">
                <img src={`${process.env.PUBLIC_URL}/img/vectors/logoBordered.svg`} alt="logo" height="60px" className="logo" />
            </div> */}

                <section className="section auth-layout" style={{ width: `100%` }}>
                    <div className="d-flex flex-wrap align-items-stretch ">
                        <div className="col-lg-6 col-md-6 col-6 order-lg-1 min-vh-100 auth-left" style={{ backgroundColor: page.data.brand_primary_color_hex, color: page.data.brand_secondary_color_hex }}>
                            <div className="row min-vh-100 d-flex align-items-center p-5">
                                <div className="col-md-12 mx-auto">
                                    <div className={`auth-col b-shadow-6 p-5`}>
                                        <h3 className="mb-4">Login</h3>
                                        <form onSubmit={handleSubmit} noValidate >
                                            <FormInput
                                                label="Email"
                                                name="email"
                                                type="email"
                                                tabIndex="4"
                                                error={(action.isSubmitted && (errors.email)) ? (errors.email) : ''}
                                                placeholder="Enter email"
                                                value={data.name}
                                                onChange={handleChange}
                                            />
                                            <FormInput
                                                type="password"
                                                label="Password"
                                                name="password"
                                                tabIndex="7"
                                                error={(action.isSubmitted && (errors.password)) ? (errors.password) : ''}
                                                placeholder="Enter password"
                                                value={data.name}
                                                onChange={handleChange}
                                            />
                                            <FormCheckbox
                                                label="Keep me logged in"
                                                name="keep_me_logged"
                                                tabIndex="8"
                                                error={(action.isSubmitted && (errors.name)) ? (errors.name) : ''}
                                                onChange={handleChange}
                                            />
                                            <div className="form-group  form-group-action text-right pb-3">
                                                <button style={{ backgroundColor: page.data.brand_tertiary_color_hex, borderColor: page.data.brand_tertiary_color_hex, color: page.data.brand_secondary_color_hex }} type="submit" className="btn btn-block btn-warning btn-submit btn-icon btn-lg mb-2" tabIndex="6" disabled={action.isProcessing}>{(action.isProcessing) ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : `Sign In`}</button>
                                                <Link style={{ color: page.data.brand_secondary_color_hex }} className="float-left p-0" to={RouteConfig('sign_up').path}>Create New Account </Link>
                                                <Link style={{ color: page.data.brand_secondary_color_hex }} className="float-right p-0" to={RouteConfig('forgot_password').path}>Forgot Password? </Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6 order-lg-1 min-vh-100 auth-right" style={{ backgroundColor: page.data.brand_secondary_color_hex, color: page.data.brand_primary_color_hex }}>
                            <div className="row min-vh-100 d-flex align-items-center p-5">
                                <div className="col-md-12 mx-auto">
                                    <div className="text-center p-3">
                                        <a target="_blank" rel="noopener noreferrer" href={`${page.data.company_url}`}>
                                            <img src={`${page.data.brand_logo}`} height="50px" alt="Brand Logo" />
                                        </a>
                                    </div>
                                    <p className="text-center">{page.data.brand_description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    } else {
        return (
            <div className="min-vh-100" style={{ width: `100%` }}>
                <div className="row">
                    <div className="col-md-12 mx-auto">
                        <div className="row">
                            <div className="col-md-12 text-center d-flex align-items-center justify-content-center min-vh-100">
                                <div className="spinner-grow" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const connectedSignIn = connect(null)(SignIn);
export { connectedSignIn as SignIn };












































