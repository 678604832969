const validateDesignation = (name, value) => {
    //eslint-disable-next-line
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const errors = {};
    switch (name) {
        case 'name': {
            errors.name = '';
            if (value === '')
                errors.name = 'Designation is required. ';
            break;
        } default:
            errors[name] = '';
            break;
    }
    return errors;
}
export default validateDesignation;