import React, { useEffect } from 'react'
import { connect } from 'react-redux';

import { Box, Button, CardHeader, Grid, IconButton, Menu, MenuItem, Typography, styled, Card, CardContent } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { DealMode } from './deal-mode';
import { DefaultCouponSettings } from './default-coupon-settings';
import { useNavigate } from 'react-router-dom';
import { CouponAction, DealAction } from '../../../_redux/actions';

const HeaderAction = styled(({ ...props }) => (<Box {...props} />))``;

function DefaultDealSettings({ balance_coupons, getCoupons }) {

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const addCoupons = () => {
        let pageUrl = `/coupons/`;
        navigate(pageUrl);
    }

    useEffect(() => {
        getCoupons({ page: 1, search: '' });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Grid container spacing={3} >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card>
                    <CardContent style={{ padding: `40px` }}>
                        <DealMode balance_coupons={balance_coupons}/>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card>
                    <CardHeader
                        titleTypographyProps={{ variant: 'h4', component: 'h4' }}
                        action={
                            <React.Fragment>
                                <HeaderAction>
                                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                        <Button variant="contained" color={"primary"} onClick={addCoupons}>Add Coupons</Button>
                                    </Box>
                                    {balance_coupons === 0 &&
                                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                            <Typography style={{color:"#D22B2B"}} variant='body1'>Coupons needed!</Typography>
                                        </Box>
                                    }
                                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                                        <IconButton aria-haspopup="true" onClick={handleClick} ><MoreVert /></IconButton>
                                        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}   >
                                            <MenuItem onClick={addCoupons}>
                                                <Typography>Add Coupons</Typography>
                                            </MenuItem>
                                            {balance_coupons === 0 && <Typography style={{color:"#D22B2B"}} variant='body1'>Coupons needed!</Typography>}
                                        </Menu>

                                    </Box>
                                </HeaderAction>
                            </React.Fragment>

                        }
                        style={{ padding: `40px 40px 20px 40px` }}
                    />
                    <CardContent style={{ padding: `20px 40px 40px  40px` }}>
                        <DefaultCouponSettings />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )

}


function mapStateToProps(state) {
    return {
        balance_coupons: state.CouponReducer.balance_coupons,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getLatestDeal: dispatch(DealAction.getLatestDeal()),
        getCoupons: (params) => dispatch(CouponAction.getCoupons(params)),
    }
};


const connectedDefaultDealSettings = (connect(mapStateToProps, mapDispatchToProps)(DefaultDealSettings));
export { connectedDefaultDealSettings as DefaultDealSettings };

