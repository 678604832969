import { authHeader, notify } from '../../_helpers';
import Config from '../../_helpers/config';
import { handleResponse } from '../../_helpers/handleResponse';
import CookieServices from './CookieServices';


export const SignInServices = { signIn, getSubdomainDetails, getDealDetails, instaSignIn, finishRegisteration };

/**
 * Service for singin 
 *
 * @parm json params login parameters of {email,password}
 * @author Akshay 
 */
function signIn(params) {

	const requestOptions = {};
	requestOptions.headers = authHeader(false);
	requestOptions.method = 'POST';
	requestOptions.body = JSON.stringify(params);
	return fetch(`${Config.endpoint}/ns/login`, requestOptions)
		.then(handleResponse)
		.then((response) => {
			let date = new Date();
			date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
			const options = { path: '/', expires: date };
			localStorage.setItem('authToken', response.token)
			CookieServices.set('accessToken', response.token, options);
			// notify(response.message);
			return response;
		})
}
/**
 * Service for singin 
 *
 * @parm json params login parameters of {email,password}
 * @author Akshay 
 */
function finishRegisteration(params) {

	const requestOptions = {};
	requestOptions.headers = authHeader(false);
	requestOptions.method = 'POST';
	requestOptions.body = JSON.stringify({ approval_key: params, ig_token: CookieServices.get("igToken") });


	return fetch(`${Config.endpoint}/ns/ig/finish`, requestOptions)
		.then(handleResponse)
		.then((response) => {
			let date = new Date();
			date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
			const options = { path: '/', expires: date };
			localStorage.setItem('authToken', response.token)
			CookieServices.set('accessToken', response.token, options);
			// notify(response.message);
			return response;
		})
}
/**
 * Service for singin 
 *
 * @parm json params login parameters of {email,password}
 * @author Akshay 
 */
function getSubdomainDetails(subdomain) {

	const requestOptions = {};
	requestOptions.headers = authHeader(false);
	requestOptions.method = 'GET';
	return fetch(`${Config.endpoint}/ns/pre-auth/${subdomain}`, requestOptions)
		.then(handleResponse)
		.then((response) => {
			return response;
		})
}
/**
 * Service for singin 
 *
 * @parm json params login parameters of {email,password}
 * @author Akshay 
 */
function getDealDetails(subdomain) {

	const requestOptions = {};
	requestOptions.headers = authHeader(false);
	requestOptions.method = 'GET';
	return fetch(`${Config.endpoint}/ns/pre-auth/deal/${subdomain}`, requestOptions)
		.then(handleResponse)
		.then((response) => {
			return response;
		})
}

/**
	 * Service for instagram signin 
	 * @param {Object} params - The params which are used for signin api.
	 * @param {string} params.email - Email of the brand user
	 * @param {string} params.password - Password the brand user
	 * @author Akshay N 
	 * @created_at 30 May 2022
	 */
function instaSignIn(params) {

	const requestOptions = {};
	requestOptions.headers = authHeader(false);
	requestOptions.method = 'POST';
	requestOptions.body = JSON.stringify(params);
	return fetch(`${Config.endpoint}/ns/ig/login`, requestOptions)
		.then(handleResponse)
		.then((response) => {

			if (response.status === 2) {
				notify(response.errors.message)
			} else {
				let date = new Date();
				date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
				const options = { path: '/', expires: date };

				if (response.data.status === 'active') {
					localStorage.setItem('authToken', response.token)
					CookieServices.set('accessToken', response.token, options);
				} else {
					CookieServices.set('igToken', response.data.ig_token.user_id, options);
				}
			}

			return response;
		})
}
