import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Config from '../../../_helpers/config';
import { InitialLoader } from '../../../_layouts/loader';
import { ApproveBusinessAction } from '../../../_redux/actions';
import './approve_business.css'

function ApproveBusiness({ ...props }) {

    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true);
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { id, key } = useParams();

    useEffect(() => {

        const businsess_id = id;
        const businsess_key = key;
        dispatch(ApproveBusinessAction.approve(businsess_id, businsess_key)).then((response) => {
            if (response.status === 1) {
                setSuccess(true);
                setLoader(false);
                setSuccessMessage(response.message);
                document.title = `${Config.app_name} | Approved`;
            } else {
                setLoader(false);
                setError(true);
                setErrorMessage(response.message);
                document.title = `${Config.app_name} | Oops..`;
            }
        });

    }, [id, key, dispatch])

    return (
        <div className="approve-business">
            {
                success && (
                    <div className="row">
                        <div className="col-md-12 mt-2 text-center" >
                            <i className="fa fa-check-circle" aria-hidden="true"></i>
                        </div>
                        <div className="col-md-12 mt-3 text-center" >
                            <h3 dangerouslySetInnerHTML={{ __html: successMessage }}></h3>
                        </div>
                    </div>
                )
            }
            {
                error && (
                    <div className="row">
                        <div className="col-md-12 mt-2 text-center" >
                            <i className="fa fa-times-circle" aria-hidden="true"></i>
                        </div>
                        <div className="col-md-12 mt-3 text-center" >
                            <h3 dangerouslySetInnerHTML={{ __html: errorMessage }}></h3>
                        </div>
                    </div>
                )
            }
            <InitialLoader loader={loader} />
        </div>
    )
}


export { ApproveBusiness };



