export const appName = 'GuruBrandDashBoard'
export const requestTimeout = 10000
export const authToken = `${appName}:authToken`
export const authProfile=`${appName}:profileId`
export const drawerNavigationWidth = 230
export const paperElevation = 3
export const publicKey = "-----BEGIN CERTIFICATE-----\nMIIDxzCCAq+gAwIBAgIJAMQpoXY1vuv7MA0GCSqGSIb3DQEBCwUAMHoxCzAJBgNV\nBAYTAlVTMRYwFAYDVQQIDA1NYXNzYWNodXNldHRzMRAwDgYDVQQKDAdDb25kdWl0\nMR0wGwYDVQQDDBRDb25kdWl0IEJhY2tlbmQgQ2VydDEiMCAGCSqGSIb3DQEJARYT\ncG1hcmF0aGFzQGd1cnUuY2x1YjAeFw0xODA0MTIxOTE2MDhaFw0xOTA0MTIxOTE2\nMDhaMHoxCzAJBgNVBAYTAlVTMRYwFAYDVQQIDA1NYXNzYWNodXNldHRzMRAwDgYD\nVQQKDAdDb25kdWl0MR0wGwYDVQQDDBRDb25kdWl0IEJhY2tlbmQgQ2VydDEiMCAG\nCSqGSIb3DQEJARYTcG1hcmF0aGFzQGd1cnUuY2x1YjCCASIwDQYJKoZIhvcNAQEB\nBQADggEPADCCAQoCggEBAPGUuYODYN1LaDIfwU/Tb8n6SKWb7BRuL2QX+RF4Jn7V\nOO0EuUrrc7ApfDj61mIOAFXzxJG2z6tjljhFqPgfrUThbk1uwADb1erTTkFX5yag\n91Cqy1aFlIizS+1hsAjLITK/YbC0Qq31eZE0/yYdaGn0pOjfZwK/iKIt7lFQKG71\nWJKjOxlumd1T+RPpeMGV7Ek0FCNvo0GW7xnCnFYCXhjwAc+hwAKLu47w4qAVP7cQ\nxkdMMf1tw/o5i/4XcikyM3Q0MmVgUAwxVt4Gw/zeEdAUO6bvXxXldu9XHfBJ4kF6\nufwlieUW6K0GCZKlQGJ1RTOUgRNXHpXoWTV25RerLdECAwEAAaNQME4wHQYDVR0O\nBBYEFLqZpZ5yLQYVCsQUapYXje+C6kF/MB8GA1UdIwQYMBaAFLqZpZ5yLQYVCsQU\napYXje+C6kF/MAwGA1UdEwQFMAMBAf8wDQYJKoZIhvcNAQELBQADggEBANYLx3HR\n/YzodMVR2VBPUUepSwihs/K+FFXzBuBjW5vy2x3vVfE7GRAdP0bpPGaqgGj9QWhA\nPz3DLogI03UwVzkaMkJIcn+t96F54s+UvAoyoKliDhUPjUZR5obTDVqkr+t2V8Tm\nRMp3dDOLbWztJXUBvKii7C7SO1xiwztUjYwxWtngtm4DmJ418KAJQMUaLCfCqg53\nypZikc1pLD3u1HWwRmIFzoM6Xg+6hXvmT19q0dOyF7g9mcafNd/5nhWvFm6hVtaB\nwxFsCToOTEovqPAH8/54gmuJ84VHSO6i578MdnYWthLdKWQ8f6PoekAXILb6tSjA\nWyokNrdR6wA3Z+M=\n-----END CERTIFICATE-----"
export const profilePictureCDN = 'https://dr2ucvwr1tnfi.cloudfront.net/'
export const recentPostCDN ='https://d2zpss0d178jbv.cloudfront.net/'

export const mailtoEmail = 'jbuckets@guru.club'
export const shopifyAppURL = 'https://apps.shopify.com/guru-club'
export const tourVideoURL ='https://www.youtube.com/watch?v=gtKbS_vvy-s&t=2s';
export const usageCost = 300;
export const ShopifyAPIKey = '01d859927a9dc9f1377984778b676409'
