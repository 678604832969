import React from 'react'
import { RouteConfig } from '../../../_routes'

function Header() {
    return (
        <div className="section-header">
            <h5>{`${RouteConfig('designation_master').name}`}</h5>
            <div className="section-header-breadcrumb">
                <div className="breadcrumb-item"><span>{`${RouteConfig('home').name}`}</span></div>
                <div className="breadcrumb-item"><span>{`${RouteConfig('masters').name}`}</span></div>
                <div className="breadcrumb-item active">{`${RouteConfig('designation_master').name}`}</div>
            </div>
        </div>
    )
}

export { Header }
