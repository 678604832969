import { Box } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SummaryWidget } from '../../../_components/summary-widget';

function ReachCount({ isLoading, reach }) {

    const navigate = useNavigate();

    const viewChart = () => {
        navigate({
            pathname: "/ig-insights/chart",
            search:`?metric=reach`
        })
    }

    return (
        <Box style={{cursor: 'pointer'}} onClick={() => viewChart()}>
            <SummaryWidget loading={isLoading} text={'Daily Reach'} value={reach} bgColor={"#7c82a0"} color={"#ffffff"} />
        </Box>
    );

}


function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
};

const connectedReachCount = (connect(mapStateToProps, mapDispatchToProps)(ReachCount));
export { connectedReachCount as ReachCount };
