import React, { useEffect, useState } from 'react'
import { Grid, Card, CardContent } from '@material-ui/core';
import { handleInputChange, validateForm } from '../../../../_helpers';
import validate from './validate';
import { FormCancelBtn, FormInput, FormSaveBtn } from '../../../../_components/form-input';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { CouponAction } from '../../../../_redux/actions';

function CreateCoupons({ account, createCoupons }) {

    const navigate = useNavigate();

    const inputs = {
        first_name: '',
        last_name: '',
        email: '',
        company_name: '',
    }

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [subAccount, setSubAccount] = useState('');

    useEffect(() => {
        if (account && Object.keys(account).length > 0) {
            setData({
                first_name: account.first_name,
                last_name: account.last_name,
                email: account.email,
                company_name: account.company_name,
            });
            setSubAccount(account.subaccount ?? '')
        }
            
    }, [account])

    const handleChange = async (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(await validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = await validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }

        dispatchCreateCouponAction(data);
    }

    const dispatchCreateCouponAction = (formData) => {
        let dispatchAction = createCoupons(formData);
        dispatchAction.then((response) => {
            if (response.status === 1) {
                setAction({ ...action, isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
                let pageUrl = `/coupons/upload`;
                navigate(pageUrl);
            } else {
                setErrors({ ...response.errors })
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }
        });
    }

    const returnToCoupons = () => {
        navigate(`/coupons`);
    }

    return (
        <React.Fragment>
            <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
                    <Card style={{ overflow: `initial` }}>
                        <CardContent style={{ padding: `25px` }}>
                            <form onSubmit={handleSubmit} noValidate className='ns-form' >

                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            label="First Name"
                                            placeholder="Enter first name"
                                            name="first_name"
                                            tabIndex="1"
                                            value={data.first_name}
                                            onChange={handleChange}
                                            error={(action.isSubmitted && (errors.first_name)) ? (errors.first_name) : ''}
                                            maxLength={100}
                                            className="col-md-12"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            label="Last Name"
                                            placeholder="Enter last name"
                                            name="last_name"
                                            tabIndex="2"
                                            value={data.last_name}
                                            onChange={handleChange}
                                            error={(action.isSubmitted && (errors.last_name)) ? (errors.last_name) : ''}
                                            maxLength={100}
                                            className="col-md-12"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            label="Email"
                                            placeholder="Enter email"
                                            name="email"
                                            type="email"
                                            tabIndex="3"
                                            value={data.email}
                                            onChange={handleChange}
                                            error={(action.isSubmitted && (errors.email)) ? (errors.email) : ''}
                                            maxLength={100}
                                            className="col-md-12"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            label="Company Name"
                                            placeholder="Enter company name"
                                            name="company_name"
                                            tabIndex="4"
                                            value={data.company_name}
                                            onChange={handleChange}
                                            error={(action.isSubmitted && (errors.company_name)) ? (errors.company_name) : ''}
                                            maxLength={100}
                                            className="col-md-12"
                                        />
                                    </Grid>
                                    
                                </Grid>
                                <Grid container>
                                    <Grid item >
                                        <FormSaveBtn disabled={subAccount ? true : false} isProcessing={action.isProcessing} text='Create Coupons' style={{ marginRight: `5px` }} />
                                        <FormCancelBtn text='Cancel' onClick={returnToCoupons} />
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment >
    )
}



function mapStateToProps(state) {
    return {
        loading: state.CouponReducer.create_coupons_loading,
        create_coupons: state.CouponReducer.create_coupons,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createCoupons: (params) => dispatch(CouponAction.createCoupons(params)),
        getCustomCoupons: () => dispatch(CouponAction.getCustomCoupons()),
    }
};

const connectedCreateCoupons = connect(mapStateToProps, mapDispatchToProps)(CreateCoupons);
export { connectedCreateCoupons as CreateCoupons };
