import React from 'react'
import { connect } from 'react-redux';
import { SummaryWidget } from '../../../_components/summary-widget';

function IssuedCoupons({ isLoading, issued_coupons }) {

    return <SummaryWidget loading={isLoading} text={'Issued Coupons'} value={issued_coupons} bgColor={"#0ec164"} color={"#ffffff"} />

}


function mapStateToProps(state) {
    return {
        issued_coupons: state.CouponReducer.used_coupons,
    };
}


const connectedIssuedCoupons = (connect(mapStateToProps, null)(IssuedCoupons));
export { connectedIssuedCoupons as IssuedCoupons };


