const validateSubDomain = (name, value, data = {}) => {
    //eslint-disable-next-line
    const errors = {};
    switch (name) {
        case 'brand_sub_domain': {
            errors.brand_sub_domain = '';
            if (value === '')
                errors.brand_sub_domain = 'Subdomain name is required. ';
            break;
        } case 'sub_domain_auth': {
            errors.sub_domain_auth = '';
            if (value === false)
                errors.sub_domain_auth = 'Please accept terms and condition. ';
            break;
        } default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}
export default validateSubDomain;