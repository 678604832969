import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';

function SubaccountCard({ account }) {

    return (
        <React.Fragment>
            <Box>
                <Grid container spacing={2} className="deals-details-box">
                    <Grid item xs={5} md={5}>
                        <Typography variant="subtitle1">Name</Typography>
                    </Grid>
                    <Grid item xs={7} md={7}>
                        <Typography variant="subtitle1">{`${account.first_name} ${account.last_name}`}</Typography>
                    </Grid>

                    <Grid item xs={5} md={5}>
                        <Typography variant="subtitle1">Email</Typography>
                    </Grid>
                    <Grid item xs={7} md={7}>
                        <Typography variant="subtitle1">{account.email}</Typography>
                    </Grid>

                    <Grid item xs={5} md={5}>
                        <Typography variant="subtitle1">Company Name</Typography>
                    </Grid>
                    <Grid item xs={7} md={7}>
                        <Typography variant="subtitle1">{account.company_name}</Typography>
                    </Grid>

                </Grid>
            </Box>
        </React.Fragment >
    )
}



function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
};

const connectedSubaccountCard = connect(mapStateToProps, mapDispatchToProps)(SubaccountCard);
export { connectedSubaccountCard as SubaccountCard };
