import React from 'react'
import { Card as MuiCard } from '@material-ui/core'

function Card({ ...props }) {
    return (
        <MuiCard>{props.children} </MuiCard>
    )
}

export { Card }
