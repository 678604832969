import React, { useEffect, useState } from 'react'
import { PageTitle } from '../../../_layouts/page-title';
import { Button, Grid, Card, CardContent, CircularProgress } from '@material-ui/core';
import { handleInputChange, validateForm } from '../../../_helpers';
import validate from './validate';
import { FormBrowseFile, FormCancelBtn, FormCheckbox, FormColorInput, FormDateTimePickerNew, FormRichTextEditor, FormSaveBtn, FormSelect, FormTextArea } from '../../../_components/form-input';
import { FormSwitch } from '../../../_components/form-switch';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { DealAction, SettingsAction } from '../../../_redux/actions';
import { ArrowBack } from '@material-ui/icons';
import moment from 'moment'
import { DealsSubdomainSettings } from './dealsSubdomainSettings/DealsSubdomainSettings';
import { FormGroupInput } from '../../../_components/form-group-input';



const timeBetweenPostOptions = [
    { value: 'One Time', label: 'One Time' },
    { value: '1 Day', label: 'Once a day' },
    { value: '1 Week', label: 'Once a week' },
    { value: '1 Month', label: 'Once a month' }

]

function AddUpdateDeal({ addDeals, editDeals, getDeal, business, brand_sub_domain, settings_loading, settings }) {


    const { id } = useParams();
    const navigate = useNavigate();

    const inputs = {
        deal_id: null,
        insta_handle: business.insta_handle, logo: '',
        primary_color: { r: 18, g: 66, b: 222, a: 1 },
        secondary_color: { r: 255, g: 255, b: 255, a: 1 },
        link_color: { r: 0, g: 0, b: 0, a: 1 },
        font: '',
        reward: '',
        reward_description: `Tag @${business.insta_handle} in an Instagram story for *Insert Coupon Amount*`,
        headline: '',
        offer_description: 'Create your own content or repost ours. Account must be public at the time your story is posted.<br/><br/> *Add any additional terms of your Coupon*',
        sample_story: '',
        times_between_post: '',
        hashtag: '',
        giveaway_date: '',
        coupon_redemption_url: '',
        is_giveaway: false,
        story: false,
        like: false,
        email_or_text: false,
        can_send_followup_msg: false,
        direct_message: '*Thank your creator and insert your offer url for more info*',
        brand_sub_domain: brand_sub_domain,
        sub_domain_auth: false,
        edit_subdomain: false
    }

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, reward_description: '', offer_description: '', direct_message: '' });
    const [showLoader, setShowLoader] = useState(true);
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [isGiveAway, setIsGiveAway] = useState(false);

    useEffect(() => {


        if (id) {
            getDeal(id).then((response) => {
                if (response.status === 1) {
                    setData({
                        deal_id: response.data._id,
                        insta_handle: response.data.insta_handle,
                        logo: response.data.logo,
                        primary_color: ((response.data.primary_color) ? (response.data.primary_color) : (inputs.primary_color)),
                        secondary_color: ((response.data.secondary_color) ? (response.data.secondary_color) : (inputs.secondary_color)),
                        link_color: ((response.data.link_color) ? (response.data.link_color) : (inputs.link_color)),
                        reward_description: response.data.reward_description ?? '',
                        offer_description: response.data.offer_description ?? '',
                        sample_story: response.data.sample_story,
                        times_between_post: response.data.times_between_post,
                        giveaway_date: ((response.data.giveaway_date) ? moment.utc(response.data.giveaway_date).toDate() : (inputs.giveaway_date)),
                        is_giveaway: response.data.is_giveaway ?? false,
                        story: response.data.story ?? false,
                        like: response.data.like ?? false,
                        email_or_text: response.data.email_or_text ?? false,
                        can_send_followup_msg: response.data.can_send_followup_msg ?? false,
                        direct_message: response.data.direct_message ? response.data.direct_message : '',
                        brand_sub_domain: response.data.brand_sub_domain,
                        sub_domain_auth: false,
                        edit_subdomain: false
                    });
                    setIsGiveAway(response.data.is_giveaway ?? false);
                    setShowLoader(false);
                }
            });
        } else {
            setData({ ...inputs, insta_handle: business.insta_handle, reward_description: `Tag @${business.insta_handle} in an Instagram story for *Insert Coupon Amount*`, });
            setShowLoader(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        settings().then((response) => {
            if (response.status === 1) {
                setData({
                    ...data,
                    brand_sub_domain: response.data.brand_sub_domain,
                    sub_domain_auth: false,
                    edit_subdomain: false
                });
            }
        });;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleChange = async (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(await validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
        checkRequired(name, value);
    }

    const checkRequired = async (name, value) => {

        if (name === "is_giveaway") {
            if (value === true) {
                setIsGiveAway(true)
                data['email_or_text'] = true;
            } else {
                setIsGiveAway(false)
                // data['email_or_text'] = false;
                data['giveaway_date'] = '';
            }
        } else if (name === "email_or_text") {
            if (isGiveAway === true) {
                data['email_or_text'] = true;
            }
            // } else if (name === "story") {
            //     if (value === true) {
            //         data['like'] = true;
            //     } else {
            //         data['like'] = false;
            //     }
            // } else if (name === "like") {
            //     if (data['story'] === true) {
            //         data['like'] = true;
            //     }

        }
        setData({ ...data });
        setErrors({ ...errors, ...(await validate(name, data[name], data)) });
        return;
    }

    const handleShowEditSubdomain = async (editSubdomain) => {
        setData({ 
            ...data,
            edit_subdomain: editSubdomain
        });
        
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = await validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }


        formData.append("id", data.deal_id);
        formData.append("insta_handle", data.insta_handle);
        formData.append('logo', data.logo);
        formData.append("primary_color", JSON.stringify(data.primary_color));
        formData.append("secondary_color", JSON.stringify(data.secondary_color));
        formData.append("link_color", JSON.stringify(data.link_color));
        formData.append("reward_description", data.reward_description);
        formData.append("offer_description", data.offer_description);
        formData.append('sample_story', data.sample_story);
        formData.append('times_between_post', data.times_between_post);
        formData.append('giveaway_date', data.giveaway_date);
        formData.append('is_giveaway', data.is_giveaway);
        formData.append('story', data.story);
        formData.append('like', data.like);
        formData.append('email_or_text', data.email_or_text);
        formData.append('can_send_followup_msg', data.can_send_followup_msg);
        formData.append('direct_message', data.direct_message);
        formData.append('brand_sub_domain', data.brand_sub_domain);
        formData.append('edit_subdomain', data.edit_subdomain);
        dispatchDealAction(formData);
    }

    const dispatchDealAction = (formData) => {
        let dispatchAction = (data.deal_id) ? editDeals(data.deal_id, formData) : addDeals(formData);
        dispatchAction.then((response) => {
            if (response.status === 1) {
                setAction({ ...action, isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
                navigate(`/deals`);
            } else {
                setErrors({ ...response.errors })
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }
        });
    }

    const returnToDeals = () => {
        navigate(`/deals`);
    }

    return (
        <React.Fragment>
            <PageTitle title={`${id ? 'Update Offer' : 'Add Offer'}`} action={<Button variant="contained" color="primary" onClick={returnToDeals} startIcon={<ArrowBack />} >Back</Button>} />
            {showLoader && <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ paddingTop: `300px` }}><Grid item ><CircularProgress /></Grid> </Grid>}
            {!showLoader && (
                <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
                        <Card style={{ overflow: `initial` }}>
                            <CardContent style={{ padding: `25px` }}>
                                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <FormBrowseFile
                                                label="Choose Logo"
                                                name="logo"
                                                error={(action.isSubmitted && (errors.logo)) ? (errors.logo) : ''}
                                                placeholder="Choose your logo"
                                                value={data.logo}
                                                onChange={handleChange}
                                                acceptType=".png,.jpg,.jpeg,.svg,.gif,.apng,.bmp,.ico"
                                                info="Allowed Format: png,jpg,jpeg,svg,gif,apng,bmp,ico , Allowed Maximum Size: 2 MB"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingTop: `50px` }} >
                                            {/* <FormGroupInput
                                                label="Brand Instagram Handle"
                                                placeholder="Enter brand instagram handle"
                                                name="insta_handle"
                                                value={data.insta_handle}
                                                onChange={handleChange}
                                                error={(action.isSubmitted && (errors.insta_handle)) ? (errors.insta_handle) : ''}
                                                ig_text={`@`}
                                                maskType='instagram-handle'
                                                maxLength={30}
                                                onPaste={(e) => { e.preventDefault(); return false; }}
                                                onCopy={(e) => { e.preventDefault(); return false; }}
                                            /> */}
                                            <FormColorInput
                                                label="Background Color"
                                                name="primary_color"
                                                value={data.primary_color}
                                                onChange={handleChange}
                                            />
                                            <FormColorInput
                                                label="Text Color"
                                                name="secondary_color"
                                                value={data.secondary_color}
                                                onChange={handleChange}
                                            />
                                            <FormColorInput
                                                label="Button Color"
                                                name="link_color"
                                                value={data.link_color}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <FormSwitch
                                                id="test"
                                                // label="Is this a sweepstakes?"
                                                label="Giveaway Countdown"
                                                name="is_giveaway"
                                                error={(action.isSubmitted && (errors.is_giveaway)) ? (errors.is_giveaway) : ''}
                                                value={data.is_giveaway}
                                                onChange={handleChange}
                                                className={`mb-0 mt-3`}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <FormDateTimePickerNew
                                                // label={`Sweepstakes Ends In`}
                                                label={`End Date`}
                                                name="giveaway_date"
                                                error={(action.isSubmitted && (errors.giveaway_date)) ? (errors.giveaway_date) : ''}
                                                placeholder="Enter your deal"
                                                value={data.giveaway_date}
                                                onChange={handleChange}
                                                isReadOnly={!isGiveAway}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <FormSelect
                                                label={`How Often A User Can Redeem`}
                                                name={`times_between_post`}
                                                placeholder={`Select time between post`}
                                                onChange={handleChange}
                                                data={timeBetweenPostOptions}
                                                value={data.times_between_post}
                                                error={(action.isSubmitted && (errors.times_between_post)) ? (errors.times_between_post) : ''}
                                            />
                                        </Grid> */}
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <FormRichTextEditor
                                                label="Description"
                                                name="reward_description"
                                                error={(action.isSubmitted && (errors.reward_description)) ? (errors.reward_description) : ''}
                                                placeholder="Enter description"
                                                value={data.reward_description}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <FormRichTextEditor
                                                label="Fine Print"
                                                name="offer_description"
                                                error={(action.isSubmitted && (errors.offer_description)) ? (errors.offer_description) : ''}
                                                placeholder="Enter fine print"
                                                value={`${data.offer_description}`}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            {/* <FormCheckbox
                                                label="Send follow up message to user when they can post again"
                                                name="can_send_followup_msg"
                                                error={(action.isSubmitted && (errors.can_send_followup_msg)) ? (errors.can_send_followup_msg) : ''}
                                                onChange={handleChange}
                                                value={data.can_send_followup_msg}
                                                checked={data.can_send_followup_msg}
                                                className='p-2'
                                            /> */}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            {/* <FormRichTextEditor
                                                label="Add a direct message to accompany the coupon code or confirmation that the user has entered a giveaway."
                                                name="direct_message"
                                                error={(action.isSubmitted && (errors.direct_message)) ? (errors.direct_message) : ''}
                                                placeholder="Enter direct message"
                                                value={data.direct_message}
                                                onChange={handleChange}
                                                tools={{ link: true }}
                                            /> */}
                                            {/* <FormTextArea
                                                label="Add a direct message to accompany the coupon code or confirmation that the user has entered a sweepstakes."
                                                name="direct_message"
                                                error={(action.isSubmitted && (errors.direct_message)) ? (errors.direct_message) : ''}
                                                placeholder="Enter direct message"
                                                value={data.direct_message}
                                                onChange={handleChange}
                                            /> */}
                                        </Grid>
                                    </Grid>
                                    {/* <Grid container>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className={`form-group`}>
                                                <label>Require</label>
                                                <div className="row" style={{ marginLeft: `15px` }}>
                                                    <FormCheckbox
                                                        label="Story"
                                                        name="story"
                                                        error={(action.isSubmitted && (errors.story)) ? (errors.story) : ''}
                                                        onChange={handleChange}
                                                        value={data.story}
                                                        checked={data.story}
                                                        className='p-2'
                                                    />
                                                    <FormCheckbox
                                                        label="Like"
                                                        name="like"
                                                        error={(action.isSubmitted && (errors.like)) ? (errors.like) : ''}
                                                        onChange={handleChange}
                                                        value={data.like}
                                                        checked={data.like}
                                                        className='p-2'
                                                    />
                                                    <FormCheckbox
                                                        label="Email"
                                                        name="email_or_text"
                                                        error={(action.isSubmitted && (errors.email_or_text)) ? (errors.email_or_text) : ''}
                                                        onChange={handleChange}
                                                        value={data.email_or_text}
                                                        checked={data.email_or_text}
                                                        className='p-2'
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid> */}

                                    <Grid container style={{marginBottom: '20px'}}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            {(!settings_loading) && <DealsSubdomainSettings handleShowEditSubdomain={handleShowEditSubdomain}>
                                                <FormGroupInput
                                                    append="after"
                                                    ig_text={`.${process.env.REACT_APP_DOMAIN}`}
                                                    label="Subdomain name"
                                                    name="brand_sub_domain"
                                                    tabIndex="1"
                                                    error={(action.isSubmitted && (errors.brand_sub_domain)) ? (errors.brand_sub_domain) : ''}
                                                    placeholder="Enter subdomain name"
                                                    value={data.brand_sub_domain}
                                                    onChange={handleChange}
                                                    maskType={`alphanumeric-with-hiphen-small-only`}
                                                    className="col-md-6 mr-auto"
                                                    onPaste={(e) => {
                                                        e.preventDefault()
                                                        return false;
                                                    }}
                                                />
                                                <FormCheckbox
                                                    label="I authorize Guru Club to create the subdomain"
                                                    name="sub_domain_auth"
                                                    tabIndex="2"
                                                    checked={data.sub_domain_auth}
                                                    error={(action.isSubmitted && (errors.sub_domain_auth)) ? (errors.sub_domain_auth) : ''}
                                                    onChange={handleChange}
                                                    className="col-md-12 mr-auto"
                                                />
                                            </DealsSubdomainSettings>}
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormBrowseFile
                                                label="Sample Story (Optional)"
                                                name="sample_story"
                                                error={(action.isSubmitted && (errors.sample_story)) ? (errors.sample_story) : ''}
                                                placeholder="Choose your sample story"
                                                value={data.sample_story}
                                                onChange={handleChange}
                                                acceptType=".png,.jpg,.jpeg,.svg,.gif,.apng,.bmp,.ico"
                                                info="Allowed Format: png,jpg,jpeg,svg,gif,apng,bmp,ico , Allowed Maximum Size: 2 MB , Recommended Aspect Ratio: 1080x1920"
                                                isDisabled={(data.like && (!data.story) ? true : false)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item >
                                            <FormSaveBtn isProcessing={action.isProcessing} text={`${id ? 'Update Offer' : 'Add Offer'}`} style={{ marginRight: `5px` }} />
                                            <FormCancelBtn text='Cancel' onClick={returnToDeals} />
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )
            }
        </React.Fragment >
    )
}



function mapStateToProps(state) {
    return {
        loading: state.DealReducer.deal_loading,
        deals: state.DealReducer.deals,
        page: state.DealReducer.page,
        per_page: state.DealReducer.per_page,
        total: state.DealReducer.total,
        total_pages: state.DealReducer.total_pages,
        business: state.UserReducer.user,
        brand_sub_domain: state.SettingsReducer.brand_sub_domain,
        settings_loading: state.SettingsReducer.settings_loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDeal: (params) => dispatch(DealAction.getDeal(params)),
        addDeals: (params) => dispatch(DealAction.addDeals(params)),
        editDeals: (id, params) => dispatch(DealAction.editDeals(id, params)),
        settings: (id, params) => dispatch(SettingsAction.getLoginSettings()),
    }
};

const connectedAddUpdateDeal = connect(mapStateToProps, mapDispatchToProps)(AddUpdateDeal);
export { connectedAddUpdateDeal as AddUpdateDeal };
