export default {
	fullLogo: require("../images/gc-logo-shadow.svg"),
	shopifyLogo: require("../images/shopify_logo.png"),
	loginBackground: require("../images/login_background.png"),
	blurLoginBackground: require("../images/blur_login_background.jpg"),
	blurTenLoginBackground: require("../images/blur_10_login_background.jpg"),
	blurGraphPerformance: require("../images/blurred_graph_performance.png"),
	blurGuruDetails: require("../images/blurred_guru_details.png"),
	blurPaginatedTable: require("../images/blurred_paginated_table.png"),
	blurPopularProducts: require("../images/blurred_popular_products.png"),
	blurStories: require("../images/blurred_stories.png"),
	blurKPI: require("../images/blurred_vertical_kpis.png"),
	gc360LogoColor: require("../images/gc360__full_color.png"),
	gc360WhiteColor: require("../images/gc360__all_white_green_heart.png"),
	widgetProduct: require("../images/product_widget.jpg"),
}
