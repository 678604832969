import React from 'react'
import PropTypes from 'prop-types'

function FormInput({ label, type, name, value, placeholder, className, onChange, tabIndex, id, error, isReadOnly, pattern, maxLength, maskType, ...props }) {

    function returnAlphabetsOnly(e) {
        var regex = new RegExp("^[a-zA-Z]*$");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        e.preventDefault();
        return false;
    }

    function returnDigits(e) {
        var regex = new RegExp("^[0-9]*$");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        e.preventDefault();
        return false;
    }

    function returnAlphabetsWithSpace(e) {
        var regex = new RegExp("^[a-zA-Z ]*$");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        e.preventDefault();
        return false;
    }

    function returnAlphabetsNumbersWithHiphen(e) {
        var regex = new RegExp("^[a-zA-Z0-9-]*$");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        e.preventDefault();
        return false;
    }

    function returnAlphabetsNumbersWithUnderScore(e) {
        var regex = new RegExp("^[a-zA-Z0-9_]*$");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        e.preventDefault();
        return false;
    }

    function returnDigitWithOneDot(e) {

        var keyCodeEntered = (e.which) ? e.which : (window.e.keyCode) ? window.e.keyCode : -1;
        if ((keyCodeEntered >= 48) && (keyCodeEntered <= 57)) {
            return true;
        }
        else if (keyCodeEntered === 43) {
            if ((e.value) && (e.value.indexOf('+') >= 0)) {
                e.preventDefault();
                return false;
            } else
                return true;
        }
        // '-' sign...
        else if (keyCodeEntered === 45) {
            // Allow only 1 minus sign ('-')...
            if ((e.value) && (e.value.indexOf('-') >= 0)) {
                e.preventDefault();
                return false;
            } else
                return true;
        }
        // '.' decimal point...
        else if (keyCodeEntered === 46) {
            // Allow only 1 decimal point ('.')...
            if ((e.value) && (e.value.indexOf('.') >= 0)) {
                e.preventDefault();
                return false;
            } else
                return true;
        }
        e.preventDefault();
        return false;


    }


    const checkKeyPress = (e) => {
        if (maskType === 'alphabets-only') {
            return returnAlphabetsOnly(e);
        } else if (maskType === 'alphabets-with-space') {
            return returnAlphabetsWithSpace(e);
        } else if (maskType === 'alphanumeric-with-hiphen') {
            return returnAlphabetsNumbersWithHiphen(e);
        } else if (maskType === 'alphanumeric-with-underscore') {
            return returnAlphabetsNumbersWithUnderScore(e);
        } else if (maskType === 'digit-with-one-dot') {
            return returnDigitWithOneDot(e);
        } else if (maskType === 'digit-only') {
            return returnDigits(e);
        }
    }


    return (
        <div className={`form-group ${className}`}>
            <label htmlFor={name} dangerouslySetInnerHTML={{ __html: label }} ></label>
            <input type={type} className={`form-control ${(error) ? `is-invalid` : ``}`} name={name} readOnly={isReadOnly} tabIndex={tabIndex} placeholder={placeholder} value={value} onChange={onChange} maxLength={maxLength} onKeyPress={checkKeyPress} />
            {(error) && (<div className="invalid-feedback">{error}</div>)}
        </div>
    )
}

FormInput.defaultProps = {
    type: "text",
    className: "",
    isReadOnly: false,
    maxLength: 255,
    maskType: ''
}

FormInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'email', 'number', 'password']),
    className: PropTypes.string,
    value: PropTypes.any,
    tabIndex: PropTypes.string,
    id: PropTypes.string,
    isReadOnly: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    maxLength: PropTypes.number,
    maskType: PropTypes.string
}

export { FormInput }
