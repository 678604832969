import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { FormInput } from '../../../_components/form-input';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from '../../../_components/modal';
import { handleInputChange, validateForm } from '../../../_helpers';
import { CouponAction } from '../../../_redux/actions';
import validateMinCouponCount from './validateMinCouponCount';

import { Button, makeStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    headButton: {
        margin: '0 5px',
    },
}));


function MinCouponCount({ minimumCoupons, getMinimumCoupons }) {

    const classes = useStyles();

    const inputs = { minimum_coupons_configured: '' }

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setShowLoader(true);
        getMinimumCoupons().then((response) => {
            if (response.status === 1) {
                setShowLoader(false);
                setData({ minimum_coupons_configured: (response.data.minimum_coupons_configured ?? '') });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openMinCouponModal = (e) => {
        e.preventDefault();
        setShow(true);
    }

    const onHide = () => {
        setShow(false);
        setErrors({});
    }

    /**
   * function to handle input change
   * @param object e input object
   * @author Jasin
   */
    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateMinCouponCount(name, value)) });
        data[name] = value;
        setData({ ...data });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateMinCouponCount(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true, isProcessing: false });
            return;
        }

        formData.append('minimum_coupons_configured', data.minimum_coupons_configured);

        dispatchCouponCount(data);
    }

    const dispatchCouponCount = (data) => {
        minimumCoupons(data).then((response) => {
            if (response.status === 1) {
                setShow(false);
                setAction({ isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
            } else {
                setAction({ isSubmitted: true, isProcessing: false });

                if (Object.keys(response.data).length > 0) {
                    onHide();
                } else {
                    setErrors({ ...response.errors })
                }
            }
        });
    }

    return (
        <React.Fragment>
            <ConfzModal isModalOpen={show} onHide={onHide} loader={showLoader}>
                <form onSubmit={handleSubmit} noValidate >
                    <ConfzModalHeader id="minCoupons" title="Minimum Coupons" onHide={onHide} />
                    <ConfzModalBody>
                        {!showLoader && <FormInput
                            label="Minimum Coupons"
                            name="minimum_coupons_configured"
                            type="text"
                            tabIndex="1"
                            error={(action.isSubmitted && (errors.minimum_coupons_configured)) ? (errors.minimum_coupons_configured) : ''}
                            placeholder="Enter minimum coupons"
                            value={data.minimum_coupons_configured}
                            onChange={handleChange}
                            className="col-md-12"
                            maskType='digit-only'
                        />
                        }
                    </ConfzModalBody>
                    <ConfzModalFooter>
                        <ConfzModalFooterCloseBtn tabIndex="3" onHide={onHide} />
                        <ConfzModalFooterSaveBtn tabIndex="2" isProcessing={action.isProcessing} />
                    </ConfzModalFooter>
                </form>
            </ConfzModal>

            <Button variant="contained" color="primary" className={classes.headButton} startIcon={<Edit />} onClick={openMinCouponModal}>
                Configure Minimum Coupons
            </Button>
        </React.Fragment>
    )
}



function mapStateToProps(state) {
    return {
        loading: state.CouponReducer.coupon_loading,
        minimum_coupons_configured: state.CouponReducer.minimum_coupons_configured
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getMinimumCoupons: () => dispatch(CouponAction.getMinimumCoupons()),
        minimumCoupons: (params) => dispatch(CouponAction.minimumCoupons(params)),
    }
};

const connectedMinCouponCount = connect(mapStateToProps, mapDispatchToProps)(MinCouponCount);
export { connectedMinCouponCount as MinCouponCount };


