const validateSupportMail = (name, value, data) => {
    //eslint-disable-next-line
    const errors = {};
    switch (name) {

        case 'title': {
            errors.title = '';
            if (value === '')
                errors.title = 'Title is required. ';
            break;

        } case 'description': {
            errors.description = '';
            if (value === '')
                errors.description = 'Description is required. ';
            break;

        } default:
            errors[name] = '';
            break;
    }
    return errors;
}
export default validateSupportMail;