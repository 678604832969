import Config from '../../_helpers/config';
import { handleResponse } from '../../_helpers/handleResponse';
import { authHeader } from "../../_helpers";
import CookieServices from './CookieServices';


export const SignUpServices = { signUp, instaSignUp };

/**
 * Service for signup
 *
 * @parm json params register parameters of {name,email,password,confirm_password,terms_and_conditions}
 * @author Akshay 
 */
function signUp(params) {

	const requestOptions = {};
	requestOptions.headers = authHeader(false);
	requestOptions.method = 'POST';
	requestOptions.body = JSON.stringify(params);

	return fetch(`${Config.endpoint}/ns/register`, requestOptions)
		.then(handleResponse)
		.then((response) => {
			return response;
		})
}

/**
 * Service for signup
 *
 * @parm json params register parameters of {name,email,password,confirm_password,terms_and_conditions}
 * @author Akshay 
 */
function instaSignUp(params) {

	const requestOptions = {};
	requestOptions.headers = authHeader(false);
	requestOptions.method = 'POST';
	requestOptions.body = JSON.stringify(params);

	return fetch(`${Config.endpoint}/ns/ig/register`, requestOptions)
		.then(handleResponse)
		.then((response) => {
			let date = new Date();
			date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
			const options = { path: '/', expires: date };
			CookieServices.set('igToken', response.data.ig_token.user_id, options);
			return response;
		})
}
