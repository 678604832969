import { authHeader, handleResponse } from "../../_helpers";
import Config from "../../_helpers/config";


export const IgMessageServices = { getDmConversationList, getDmDetails, sendDm };


/**
 * Service for get conversation list 
 *
 * @author Jasin 
 */
function getDmConversationList() {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';
    return fetch(`${Config.endpoint}/ns/ig/message-list`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service for get converastion details
 *
 * @author Jasin 
 */
function getDmDetails(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/ig/message-details`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service to send reply
 *
 * @author Jasin 
 */
function sendDm(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/ig/send-dm`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}
