const ForgotPasswordValidateEmail = (name, value) => {
    //eslint-disable-next-line
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const errors = {};
    switch (name) {
        case 'email': {
            errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid. ';
            if (value === '')
                errors.email = 'Email is required. ';
            break;
        } default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}

const ForgotPasswordVerifyOtp = (name, value) => {
    //eslint-disable-next-line
    const errors = {};
    switch (name) {
        case 'otp': {
            errors.otp = value.length < 6 ? 'One time password must be 6 numbers long. ' : '';
            var digitPattern = new RegExp(/^[0-9\b]+$/);
            if (value === '' || digitPattern.test(value)) {
                if (value === '')
                    errors.otp = 'Otp is required.';
            }
            if (value === '')
                errors.otp = 'One time password is required. ';
            break;
        } default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}

const ForgotPasswordSetPassword = (name, value, data) => {
    //eslint-disable-next-line
    const errors = {};
    switch (name) {
        case 'password': {
            errors.password = value.length < 6 ? 'Password must be 6 characters long. ' : '';
            if (value === '')
                errors.password = 'Password is required. ';
            break;
        } case 'confirm_password': {
            const password = data.password ?? '';
            errors.confirm_password = value.length < 6 ? 'Confirm password must be 6 characters long. ' : '';
            if (password !== '' && password !== value)
                errors.confirm_password += 'Password and confirm password must match.';
            if (value === '')
                errors.confirm_password = 'Confirm password is required. ';
            break;
        } default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
}
export { ForgotPasswordValidateEmail, ForgotPasswordVerifyOtp, ForgotPasswordSetPassword };