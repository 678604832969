import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Container, Box, Grid, makeStyles, Typography, Avatar, Card, CardContent } from '@material-ui/core'
import { BlurOn } from '@material-ui/icons';

import { GiveawayWinnerAction } from '../../../_redux/actions';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: `15px`,
    },

    headIcon: {
        paddingRight: theme.spacing(1)
    },
    headTitle: {
        fontWeight: 900,
        paddingBottom: `10px`,
        borderBottom: `1px solid #b0bec5`
    },
    cardList: {
        paddingTop: theme.spacing(1)
    }
}))



const GiveawayParticipants = ({ participants, getParticipants }) => {

    const classes = useStyles();

    const { id } = useParams();

    const [pagination, setPagination] = useState({ limit: 100, page: 1 });

    const getNext = async () => {
        const result = await getParticipants({ id: id, ...pagination });
        if (result.status) {
            setPagination({ ...pagination, page: pagination.page + 1 });
        }
    }

    useEffect(() => {

        getNext() // Initially load participants list

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])



    return (
        <Box className="screen-container" >
            <Container maxWidth={false}>
                <Grid container spacing={1} >
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                        <Typography variant="h4" component="h4" color="initial" className={classes.headTitle}>
                            <BlurOn className={classes.headIcon} />Sweepstakes Participants
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth={false}>
                <InfiniteScroll dataLength={participants.length} next={getNext} hasMore={true} >
                    <Grid container spacing={1} alignItems="center" className={classes.cardList}  >
                        {
                            participants.length > 0 && participants.map((participant, i) => {
                                return (
                                    <Grid item md={4} key={i}>
                                        <Card>
                                            <CardContent>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item >
                                                        <Box boxShadow="3">
                                                            <Avatar src={participant.profile_image} variant="square" className={classes.large} ></Avatar>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item>
                                                        <h4>{participant.name}</h4>
                                                        <h6 style={{ fontWeight: 400 }}><i className="fab fa-instagram" aria-hidden="true"></i> {participant.insta_handle}</h6>
                                                        <h6 style={{ fontWeight: 400 }}><i className="fa fa-envelope" aria-hidden="true"></i> {participant.email}</h6>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            })
                        }
                        {participants.length < 1 && (
                            <Grid item md={12} >
                                <Typography variant="h6" color="initial"> No Participants Available..</Typography>
                            </Grid>
                        )}
                    </Grid>
                </InfiniteScroll>
            </Container>
        </Box >
    )
}


function mapStateToProps(state) {
    return {
        participants: state.GiveawayWinnerReducer.participants,
    };
}

function mapDispatchToProps(dispatch, ownProps) {

    return {
        getParticipants: (params) => dispatch(GiveawayWinnerAction.getParticipants(params))
    }

};

const connectedGiveawayParticipants = (connect(mapStateToProps, mapDispatchToProps)(GiveawayParticipants));
export { connectedGiveawayParticipants as GiveawayParticipants };

