import React from 'react'
import { connect } from 'react-redux';
import { ShoppersAction } from '../../../_redux/actions';
import { SummaryWidget } from '../../../_components/summary-widget';

function TotalCompletedDeals({ isLoading, total_completed_deals }) {

    return (
        <SummaryWidget loading={isLoading} text={'Coupons Sent'} value={total_completed_deals} bgColor={"#5263f7"} color={"#ffffff"} />
    )
}


function mapStateToProps(state) {
    return {
        isLoading: state.ShoppersReducer.total_completed_deals_loading,
        total_completed_deals: state.ShoppersReducer.total_completed_deals,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTotalCompletedDeals: dispatch(ShoppersAction.getTotalCompletedDeals())
    }
};

const connectedCoupon = (connect(mapStateToProps, mapDispatchToProps)(TotalCompletedDeals));
export { connectedCoupon as TotalCompletedDeals };
