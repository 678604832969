import React, { lazy, Suspense, useState } from 'react'
import { connect } from 'react-redux';
import FormGroup from '@material-ui/core/FormGroup';

import { CardHeader, CardContent } from '../../../_components/card';
import { Box, CircularProgress, Grid, Switch, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DealAction } from '../../../_redux/actions';

const ShopperApp = lazy(() => import("./ShopperApp"));


const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

function DealPreview({ loading, latest_deal, brand_sub_domain, changeDealMode }) {


    const setDealMode = (e) => {
        changeDealMode({ deal_mode: e.target.checked }).then(() => {
            console.log('ok')
        })
    }
    if (loading)
        return ''

    return (
        <Box>
            <CardHeader
                title="Mobile Preview"
                titleTypographyProps={{ variant: 'h4', component: 'h4' }}
                // action={
                //     <FormGroup>
                //         <Typography component="div">
                //             <Grid component="label" container alignItems="center" spacing={1}>
                //                 <Grid item>Deal Mode:</Grid>
                //                 <Grid item>Offline</Grid>
                //                 <Grid item>
                //                     <AntSwitch onChange={setDealMode} checked={latest_deal.is_deal_live} name="checkedC" />
                //                 </Grid>
                //                 <Grid item>Live</Grid>
                //             </Grid>
                //         </Typography>
                //     </FormGroup>
                // }
            />
            <CardContent>
                <Box>
                    <Grid container direction="column" justifyContent="center" alignItems="center" >
                        <Grid item sx={12} sm={12} md={12} lg={12} xl={12} >
                            <Suspense fallback={<CircularProgress />}>
                                <ShopperApp src={`${process.env.REACT_APP_HTP}${brand_sub_domain}.${process.env.REACT_APP_DOMAIN}`} class="deal-iframe" width="400" height={650} />
                            </Suspense>
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Box>
    )
}


function mapStateToProps(state) {
    return {
        brand_sub_domain: state.SettingsReducer.brand_sub_domain,
        latest_deal: state.DealReducer.latest_deal,
        loading: state.DealReducer.latest_deal_loading,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        changeDealMode: (params) => dispatch(DealAction.changeDealMode(params)),
    }
};


const connectedDealPreview = (connect(mapStateToProps, mapDispatchToProps)(DealPreview));
export { connectedDealPreview as DealPreview };


