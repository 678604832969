import { authHeader, notify } from '../../_helpers';
import Config from '../../_helpers/config';
import { handleResponse } from '../../_helpers/handleResponse';


export const ForgotPasswordServices = { sendForgotPasswordOtp, verifyForgotPasswordOtp, changePassword };

/**
 * Service for singin 
 *
 * @parm json params login parameters of {email,password}
 * @author Akshay 
 */
function sendForgotPasswordOtp(params) {

    const requestOptions = {};
    requestOptions.headers = authHeader(false);
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/forgot-password`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for singin 
 *
 * @parm json params login parameters of {email,password}
 * @author Akshay 
 */
function verifyForgotPasswordOtp(params) {

    const requestOptions = {};
    requestOptions.headers = authHeader(false);
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/forgot-password-verify-otp`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for singin 
 *
 * @parm json params login parameters of {email,password}
 * @author Akshay 
 */
function changePassword(params) {

    const requestOptions = {};
    requestOptions.headers = authHeader(false);
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/change-password`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}
