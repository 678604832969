import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

const option = {
    position: "bottom-left",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    draggablePercent: 60
}

export function notify(content, position = null) {

    const options = { ...option };
    if (position) {
        options.position = position;
    }
    
    
    toast.dark(content, options)
};