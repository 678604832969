import React from 'react'
import { RouteConfig } from '../../../_routes'

function ProfileDeactivate() {
    return (
        <React.Fragment>
            <div className="author-box-name mb-3">
                <a href="!#">{RouteConfig('profile_deactivate').name}</a>
            </div>
            <h6>Deactivating your account can be temporary.</h6>
            <p>Your account will be disabled and</p>
        </React.Fragment>
    )
}

export { ProfileDeactivate }
