import { authHeader, handleResponse, notify } from "../../_helpers";
import Config from "../../_helpers/config";

export const ContactSupportServices = { sendSupportMail };


/**
 * Service for contact support
 *
 * @author Jasin 
 */
function sendSupportMail(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/business/sent-support-email`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

