import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { FormInput, FormTextArea } from '../../../_components/form-input';
import { ConfzModal, ConfzModalHeader, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn } from '../../../_components/modal';

import { handleInputChange, validateForm } from '../../../_helpers';
import { DesignationAction } from '../../../_redux/actions';
import validateDesignation from './validateDesignation';


function AddUpdateDesignation({ id, show, closeModal, addDesignations, editDesignations, getDesignation }) {

    const inputs = { designation_id: id, name: '', description: '' }


    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [showLoader, setShowLoader] = useState(false);
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    useEffect(() => {
        if (id) {
            setShowLoader(true);
            getDesignation(id).then((response) => {
                if (response.status === 1) {
                    setShowLoader(false);
                    setData({ designation_id: response.data.en_id, name: response.data.name, description: response.data.description });
                }
            });
        } else {
            setData({ ...inputs });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    /**
    * function to handle input change
    * @param object e input object
    * @author Akshay N
    */
    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateDesignation(name, value)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateDesignation(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }
        dispatchDesignationAction();
    }

    const dispatchDesignationAction = () => {
        let dispatchAction = (data.designation_id) ? editDesignations(data) : addDesignations(data);
        dispatchAction.then((response) => {
            if (response.status === 1) {
                closeModal(false);
                setAction({ ...action, isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
            } else {
                setErrors({ ...response.errors })
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }
        });
    }

    const onHide = () => {
        closeModal(false);
    }

    return (
        <ConfzModal isModalOpen={show} onHide={onHide} loader={showLoader}>
            <form onSubmit={handleSubmit} noValidate>
                <ConfzModalHeader id="addUpdateDesignation" title="Add Designation" onHide={onHide} />
                <ConfzModalBody>
                    <FormInput label="Designation" name="name" type="text" tabIndex="1" error={(action.isSubmitted && (errors.name)) ? (errors.name) : ''} placeholder="Enter your designation" value={data.name} onChange={handleChange} />
                    <FormTextArea label="Description" name="description" tabIndex="2" error={(action.isSubmitted && (errors.description)) ? (errors.description) : ''} placeholder="Enter description (optional)" value={data.description} onChange={handleChange} />
                </ConfzModalBody>
                <ConfzModalFooter>
                    <ConfzModalFooterCloseBtn tabIndex="4" onHide={onHide} />
                    <ConfzModalFooterSaveBtn tabIndex="3" isProcessing={action.isProcessing} />
                </ConfzModalFooter>
            </form>
        </ConfzModal>
    )
}



function mapStateToProps(state) {
    return {
        loading: state.DesignationReducer.designation_loading,
        designations: state.DesignationReducer.designations,
        page: state.DesignationReducer.page,
        per_page: state.DesignationReducer.per_page,
        total: state.DesignationReducer.total,
        total_pages: state.DesignationReducer.total_pages,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDesignation: (params) => dispatch(DesignationAction.getDesignation(params)),
        addDesignations: (params) => dispatch(DesignationAction.addDesignations(params)),
        editDesignations: (params) => dispatch(DesignationAction.editDesignations(params)),
    }
};

const connectedAddUpdateDesignation = connect(mapStateToProps, mapDispatchToProps)(AddUpdateDesignation);
export { connectedAddUpdateDesignation as AddUpdateDesignation };

