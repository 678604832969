import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalHeader } from '../../_components/modal';
import { CouponAction } from '../../_redux/actions';

function ViewDetails({ id, show, getCoupon, closeModal }) {

    const inputs = { coupon_code: '', status: '', created_at: '' }
    const [showLoader, setShowLoader] = useState(false);
    const [data, setData] = useState({ ...inputs });

    useEffect(() => {
        if (id) {
            setShowLoader(true);
            getCoupon(id).then((response) => {
                if (response.status === 1) {
                    setShowLoader(false);
                    setData({ coupon_id: response.data._id, coupon_code: response.data.coupon_code, created_at: response.data.created_at, status: response.data.status });
                }
            });
        } else {
            setData({ ...inputs });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const onHide = () => {
        closeModal(false);
    }



    return (
        <ConfzModal isModalOpen={show} onHide={onHide} loader={showLoader} size="lg">
            <ConfzModalHeader id="AddUpdateCoupons" title="Coupon Details" onHide={onHide} />
            <ConfzModalBody>
                <div className="row">
                    <div className="col-md-6 p-3 text-center">
                        <label>Coupon Code</label>
                        <h1>{data.coupon_code}</h1>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-center h-100">
                            <div className="row">
                                <p className="col-md-4">Created At:</p>
                                <h6 className="col-md-8">{data.created_at}</h6>
                                <p className="col-md-4">Status</p>
                                <h6 className="col-md-8" style={{ textTransform: "capitalize" }}>{data.status}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </ConfzModalBody>
            <ConfzModalFooter>
                <ConfzModalFooterCloseBtn tabIndex="4" onHide={onHide} />
            </ConfzModalFooter>
        </ConfzModal>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.CouponReducer.coupon_loading,
        coupons: state.CouponReducer.coupons,
        page: state.CouponReducer.page,
        per_page: state.CouponReducer.per_page,
        total: state.CouponReducer.total,
        total_pages: state.CouponReducer.total_pages,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCoupon: (params) => dispatch(CouponAction.getCoupon(params)),
    }
};

const connectedViewDetails = connect(mapStateToProps, mapDispatchToProps)(ViewDetails);
export { connectedViewDetails as ViewDetails };


