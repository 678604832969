import React, { useState } from 'react'

import PropTypes from 'prop-types'

function FormGroupPassword({ append, label, type, name, value, placeholder, className, onChange, tabIndex, id, maskType, error, ...props }) {

    const [passType, setPassType] = useState(false);
    const [icon, setIcon] = useState('fa fa-eye');

    const togglePasswordVisiblity = () => {
        setPassType(!(passType));
        setIcon(passType ? 'fa fa-eye' : 'fa fa-eye-slash');
    };

    const afterType = (
        <div className={`form-group ${className}`}>
            <label htmlFor={name}>{label}</label>
            <div className="input-group">
                <input type={passType ? "text" : "password"} className={`form-control ${(error) ? `is-invalid` : ``}`} name={name} tabIndex={tabIndex} placeholder={placeholder} value={value} onChange={onChange} aria-describedby={`igt_${name}`}  {...props} />
                <div className="input-group-append">
                    <span className="input-group-text" id={`igt_${name}`} onClick={togglePasswordVisiblity} style={{ cursor: 'pointer' }}><i className={icon}></i></span>
                </div>
                {(error) && (<div className="invalid-feedback">{error}</div>)}
            </div>
        </div>
    )

    return afterType;
}


FormGroupPassword.defaultProps = {
    type: "password",
    className: "",
    append: 'after',
    maskType: ''
}

FormGroupPassword.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'number', 'password']),
    className: PropTypes.string,
    value: PropTypes.any,
    tabIndex: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    append: PropTypes.string,
    maskType: PropTypes.string,
    label: PropTypes.string
}

export { FormGroupPassword }
