import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

function FormSelect({ label, data, name, value, placeholder, className, onChange, error, ...props }) {


    const [selected, setSelected] = useState((value !== '') ? ({ label: value, value: value }) : '');

    useEffect(() => {
        const resultIndex = data.findIndex(result => result.value === value); //finding index of the item
        setSelected(data[resultIndex])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const handleSelectChange = (e, a) => {
        let target = {
            'name': name,
            'value': e.value,
            'type': 'select'
        }
        setSelected(e);
        onChange({ target })
    }

    return (
        <div className={`form-group ${className}`}>
            <label htmlFor={name}>{label}</label>
            <Select
                placeholder={placeholder}
                className={`ns-select ${(error) ? `is-invalid` : ``}`}
                options={data}
                // defaultValue={data[0]}
                // isSearchable
                onChange={handleSelectChange}
                value={selected}
                name={name}
                {...props}
            />
            {(error) && (<div className="ns-form-error">{error}</div>)}
        </div>
    )
}

FormSelect.defaultProps = {
    className: "",
}

FormSelect.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}

export { FormSelect }
