import { Box } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ComparisonWidget } from '../../../_components/comparison-widget';

function DcVsProfileViews({ isLoading, profile_views, daily_coupons }) {

    const navigate = useNavigate();

    const viewChart = () => {
        navigate({
            pathname: "/ig-insights/chart",
            search:`?metric=profile_views&type=dc`
        })
    }

    return (
        <Box style={{cursor: 'pointer'}} onClick={() => viewChart()}>
            <ComparisonWidget loading={isLoading} text={'Daily Coupon Count'} value={daily_coupons} text2={'Profile Views'} value2={profile_views} bgColor={"#7c82a0"} color={"#ffffff"} />            
        </Box>
    );

}


function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
};

const connectedDcVsProfileViews = (connect(mapStateToProps, mapDispatchToProps)(DcVsProfileViews));
export { connectedDcVsProfileViews as DcVsProfileViews };
