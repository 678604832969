const validateMinCouponCount = (name, value) => {
    const errors = {};
    switch (name) {
        case 'minimum_coupons_configured': {
            errors.minimum_coupons_configured = '';
            if (value === '' || typeof value === 'undefined') {
                errors.minimum_coupons_configured = 'Minimum coupons is required. ';
            } 
            break;
        } default:
            errors[name] = '';
            break;
    }
    return errors;
}
export default validateMinCouponCount;