
const validateAddUpdateCreditCard = (name, value, data) => {
    const errors = {};
    switch (name) {

        case 'number': {
            errors.number = value.length < 15 ? 'Credit card number must be 15 or 16 characters long.' : '';
            if (value === '')
                errors.number = 'Credit card number is required. ';
            break;
        } case 'card_holder': {
            errors.card_holder = '';
            if (value === '')
                errors.card_holder = 'Credit holder name is required. ';
            break;
        } case 'exp_month': {
            errors.exp_month = '';
            if (value === '')
                errors.exp_month = 'Expiration date is required. ';
            break;
        } case 'exp_year': {
            errors.exp_year = '';
            if (value === '')
                errors.exp_year = 'Expiration year is required. ';
            break;
        } case 'cvc': {
            errors.cvc = value.length < 3 ? 'CVC must be 3 characters long.' : '';
            if (value === '')
                errors.cvc = 'CVC is required. ';
            break;

        } case 'name': {
            errors.name = '';
            if (value === '')
                errors.name = 'Full name is required. ';
            break;
        } case 'address_line1': {
            errors.address_line1 = '';
            if (value === '')
                errors.address_line1 = 'Address line 1 is required. ';
            break;
        } case 'city': {
            errors.city = '';
            if (value === '')
                errors.city = 'City is required. ';
            break;
        } case 'state': {
            errors.state = '';
            if (value === '')
                errors.state = 'State/Province/Region is required. ';
            break;
        } case 'postal_code': {
            errors.postal_code = '';
            if (value === '')
                errors.postal_code = 'Postal code is required. ';
            break;
        } case 'country': {
            errors.country = '';
            if (value === '')
                errors.country = 'Country/Region is required. ';
            break;
        } case 'phone': {
            errors.phone = '';
            if (value === 'undefined') {
                errors.phone = 'Phone number is invalid. '
            }
            if (value === '')
                errors.phone = 'Phone number is required. ';
            break;
        } default:
            errors[name] = '';
            break;
    }
    return errors;
}
export default validateAddUpdateCreditCard;