import { CouponServices } from "../services";

export const GET_COUPON_REQUEST = 'GET_COUPON_REQUEST';
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_FAILURE = 'GET_COUPON_FAILURE';

export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_FAILURE = 'GET_COUPONS_FAILURE';

export const DELETE_COUPONS_REQUEST = 'DELETE_COUPONS_REQUEST';
export const DELETE_COUPONS_SUCCESS = 'DELETE_COUPONS_SUCCESS';
export const DELETE_COUPONS_FAILURE = 'DELETE_COUPONS_FAILURE';

export const DELETE_ACTIVE_COUPONS_REQUEST = 'DELETE_ACTIVE_COUPONS_REQUEST';
export const DELETE_ACTIVE_COUPONS_SUCCESS = 'DELETE_ACTIVE_COUPONS_SUCCESS';
export const DELETE_ACTIVE_COUPONS_FAILURE = 'DELETE_ACTIVE_COUPONS_FAILURE';

export const ADD_COUPONS_REQUEST = 'ADD_COUPONS_REQUEST';
export const ADD_COUPONS_SUCCESS = 'ADD_COUPONS_SUCCESS';
export const ADD_COUPONS_FAILURE = 'ADD_COUPONS_FAILURE';

export const EDIT_COUPONS_REQUEST = 'EDIT_COUPONS_REQUEST';
export const EDIT_COUPONS_SUCCESS = 'EDIT_COUPONS_SUCCESS';
export const EDIT_COUPONS_FAILURE = 'EDIT_COUPONS_FAILURE';

export const IMPORT_COUPONS_REQUEST = 'IMPORT_COUPONS_REQUEST';
export const IMPORT_COUPONS_SUCCESS = 'IMPORT_COUPONS_SUCCESS';
export const IMPORT_COUPONS_FAILURE = 'IMPORT_COUPONS_FAILURE';

export const UPDATE_STATUS_REQUEST = 'UPDATE_STATUS_REQUEST';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAILURE = 'UPDATE_STATUS_FAILURE';

export const ADD_COUPON_COUNT_REQUEST = 'ADD_COUPON_COUNT_REQUEST';
export const ADD_COUPON_COUNT_SUCCESS = 'ADD_COUPON_COUNT_SUCCESS';
export const ADD_COUPON_COUNT_FAILURE = 'ADD_COUPON_COUNT_FAILURE';

export const GET_COUPON_COUNT_REQUEST = 'GET_COUPON_COUNT_REQUEST';
export const GET_COUPON_COUNT_SUCCESS = 'GET_COUPON_COUNT_SUCCESS';
export const GET_COUPON_COUNT_FAILURE = 'GET_COUPON_COUNT_FAILURE';

export const CREATE_COUPONS_REQUEST = 'CREATE_COUPONS_REQUEST';
export const CREATE_COUPONS_SUCCESS = 'CREATE_COUPONS_SUCCESS';
export const CREATE_COUPONS_FAILURE = 'CREATE_COUPONS_FAILURE';

export const GET_CUSTOM_COUPONS_REQUEST = 'GET_CUSTOM_COUPONS_REQUEST';
export const GET_CUSTOM_COUPONS_SUCCESS = 'GET_CUSTOM_COUPONS_SUCCESS';
export const GET_CUSTOM_COUPONS_FAILURE = 'GET_CUSTOM_COUPONS_FAILURE';

export const CREATE_SINGLE_USE_CODES_REQUEST = 'CREATE_SINGLE_USE_CODES_REQUEST';
export const CREATE_SINGLE_USE_CODES_SUCCESS = 'CREATE_SINGLE_USE_CODES_SUCCESS';
export const CREATE_SINGLE_USE_CODES_FAILURE = 'CREATE_SINGLE_USE_CODES_FAILURE';

export const GET_CUSTOM_COUPON_CAMPAIGNS_REQUEST = 'GET_CUSTOM_COUPON_CAMPAIGNS_REQUEST';
export const GET_CUSTOM_COUPON_CAMPAIGNS_SUCCESS = 'GET_CUSTOM_COUPON_CAMPAIGNS_SUCCESS';
export const GET_CUSTOM_COUPON_CAMPAIGNS_FAILURE = 'GET_CUSTOM_COUPON_CAMPAIGNS_FAILURE';

export const GET_CUSTOM_CODES_REQUEST = 'GET_CUSTOM_CODES_REQUEST';
export const GET_CUSTOM_CODES_SUCCESS = 'GET_CUSTOM_CODES_SUCCESS';
export const GET_CUSTOM_CODES_FAILURE = 'GET_CUSTOM_CODES_FAILURE';

export const SET_PAGE = 'SET_PAGE';


export const CouponAction = { getCoupons, getCoupon, deleteCoupons, deleteActiveCoupons, addCoupons, editCoupons, importCoupons, updateStatus, setPage, minimumCoupons, getMinimumCoupons, createCoupons, getCustomCoupons, createSingleUseCodes, getCustomCouponCampaigns, getAllCustomCodes };

/**
 * Action get coupon
 *
 * @author Akshay 
 */
function getCoupon(id) {
    return dispatch => {
        dispatch(request(id));
        return CouponServices.getCoupon(id)
            .then(
                response => { return dispatch(success(id, response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(id) { return { type: GET_COUPON_REQUEST, request: id } }
    function success(id, response) { return { type: GET_COUPON_SUCCESS, request: id, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_COUPON_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get coupon
 *
 * @author Akshay 
 */
function getCoupons(params) {
    return dispatch => {
        dispatch(request());
        return CouponServices.getCoupons(params)
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_COUPONS_REQUEST, request: params } }
    function success(response) { return { type: GET_COUPONS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get coupon
 *
 * @author Madhav 
 */
function deleteActiveCoupons() {
    return dispatch => {
        dispatch(request());
        return CouponServices.deleteActiveCoupons()
            .then(
                response => {
                    dispatch(CouponAction.getCoupons(1));
                    return dispatch(success(response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request() { return { type: DELETE_ACTIVE_COUPONS_REQUEST } }
    function success(response) { return { type: DELETE_ACTIVE_COUPONS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: DELETE_ACTIVE_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get coupon
 *
 * @author Akshay 
 */
function deleteCoupons(id) {
    return dispatch => {
        dispatch(request(id));
        return CouponServices.deleteCoupons(id)
            .then(
                response => { return dispatch(success(id, response)); },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(id) { return { type: DELETE_COUPONS_REQUEST, id: id } }
    function success(id, response) { return { type: DELETE_COUPONS_SUCCESS, id: id, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: DELETE_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get coupon
 *
 * @author Akshay 
 */
function addCoupons(params) {
    return dispatch => {
        dispatch(request(params));
        return CouponServices.addCoupons(params)
            .then(
                response => {
                    dispatch(CouponAction.getCoupons(1));
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(request) { return { type: ADD_COUPONS_REQUEST, request: request } }
    function success(request, response) { return { type: ADD_COUPONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: ADD_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get coupon
 *
 * @author Akshay 
 */
function editCoupons(id, params) {
    return dispatch => {
        dispatch(request(id, params));
        return CouponServices.editCoupons(id, params)
            .then(
                response => {
                    dispatch(CouponAction.getCoupons(1));
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(id, request) { return { type: EDIT_COUPONS_REQUEST, request: request } }
    function success(request, response) { return { type: EDIT_COUPONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: EDIT_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get coupon
 *
 * @author Akshay 
 */
function updateStatus(id) {
    return dispatch => {
        dispatch(request(id));
        return CouponServices.updateStatus(id)
            .then(
                response => {
                    return dispatch(success(id, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(request) { return { type: UPDATE_STATUS_REQUEST, request: request } }
    function success(request, response) { return { type: UPDATE_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: UPDATE_STATUS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action to import coupon
 *
 * @author Akshay 
 */
function importCoupons(params) {
    return dispatch => {
        dispatch(request(params));
        return CouponServices.importCoupons(params)
            .then(
                response => {
                    dispatch(CouponAction.getCoupons(1));
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(request) { return { type: IMPORT_COUPONS_REQUEST, request: request } }
    function success(request, response) { return { type: IMPORT_COUPONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: IMPORT_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action to import coupon
 *
 * @author Jasin 
 */
 function minimumCoupons(params) {
    return dispatch => {
        dispatch(request(params));
        return CouponServices.minimumCoupons(params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(params) { return { type: ADD_COUPON_COUNT_REQUEST, request: request } }
    function success(request, response) { return { type: ADD_COUPON_COUNT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: ADD_COUPON_COUNT_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}


/**
 * Action to import coupon
 *
 * @author Jasin 
 */
function getMinimumCoupons() {
    return dispatch => {
        dispatch(request());
        return CouponServices.getMinimumCoupons()
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => { return dispatch(failure(error)); }
            );
    };
    
    function request() { return { type: GET_COUPON_COUNT_REQUEST } }
    function success(response) { return { type: GET_COUPON_COUNT_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_COUPON_COUNT_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

}

/**
 * Action to create coupons
 *
 * @author Jasin 
 */
function createCoupons(params) {
    return dispatch => {
        dispatch(request(params));
        return CouponServices.createCoupons(params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(request) { return { type: CREATE_COUPONS_REQUEST, request: request } }
    function success(request, response) { return { type: CREATE_COUPONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: CREATE_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}


/**
 * Action get custom coupons
 *
 * @author Akshay 
 */
function getCustomCoupons() {
    return dispatch => {
        dispatch(request());
        return CouponServices.getCustomCoupons()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request() { return { type: GET_CUSTOM_COUPONS_REQUEST } }
    function success(response) { return { type: GET_CUSTOM_COUPONS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_CUSTOM_COUPONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action to create single use codes
 *
 * @author Jasin 
 */
function createSingleUseCodes(params) {
    return dispatch => {
        dispatch(request(params));
        return CouponServices.createSingleUseCodes(params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(request) { return { type: CREATE_SINGLE_USE_CODES_REQUEST, request: request } }
    function success(request, response) { return { type: CREATE_SINGLE_USE_CODES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: CREATE_SINGLE_USE_CODES_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get custom coupons
 *
 * @author Akshay 
 */
function getCustomCouponCampaigns() {
    return dispatch => {
        dispatch(request());
        return CouponServices.getCustomCouponCampaigns()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request() { return { type: GET_CUSTOM_COUPON_CAMPAIGNS_REQUEST } }
    function success(response) { return { type: GET_CUSTOM_COUPON_CAMPAIGNS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_CUSTOM_COUPON_CAMPAIGNS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get coupon
 *
 * @author Akshay 
 */
function getAllCustomCodes(params) {
    return dispatch => {
        dispatch(request());
        return CouponServices.getAllCustomCodes(params)
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_CUSTOM_CODES_REQUEST, request: params } }
    function success(response) { return { type: GET_CUSTOM_CODES_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_CUSTOM_CODES_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}
/**
 * Action to import coupon
 *
 * @author Akshay 
 */
function setPage(page) {
    return dispatch => { dispatch({ type: SET_PAGE, page: page }) };

}
