import React from 'react'
import { connect } from 'react-redux';
import { Box } from '@material-ui/core'
import { PageTitle } from '../../_layouts/page-title';
import { SupportMail } from './SupportMail';

function ContactSupport() {

    return (
        <Box>
            <PageTitle title="Contact Support" />
            <SupportMail />
        </Box>
    )
}


function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
    }
};

const connectedContactSupport = (connect(mapStateToProps, mapDispatchToProps)(ContactSupport));
export { connectedContactSupport as ContactSupport };
