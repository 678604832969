import { IgInsightsServices } from "../services";

export const GET_IG_INSIGHTS_REQUEST = 'GET_IG_INSIGHTS_REQUEST';
export const GET_IG_INSIGHTS_SUCCESS = 'GET_IG_INSIGHTS_SUCCESS';
export const GET_IG_INSIGHTS_FAILURE = 'GET_IG_INSIGHTS_FAILURE';

export const GET_INSIGHT_CHART_REQUEST = 'GET_INSIGHT_CHART_REQUEST';
export const GET_INSIGHT_CHART_SUCCESS = 'GET_INSIGHT_CHART_SUCCESS';
export const GET_INSIGHT_CHART_FAILURE = 'GET_INSIGHT_CHART_FAILURE';

export const GET_IG_INSIGHTS_DAILY_COUPON_REQUEST = 'GET_IG_INSIGHTS_DAILY_COUPON_REQUEST';
export const GET_IG_INSIGHTS_DAILY_COUPON_SUCCESS = 'GET_IG_INSIGHTS_DAILY_COUPON_SUCCESS';
export const GET_IG_INSIGHTS_DAILY_COUPON_FAILURE = 'GET_IG_INSIGHTS_DAILY_COUPON_FAILURE';

export const IgInsightsAction = {  getIgInsights, getInsightChart, getDailyCouponCount, getDcInsightChart };


/**
 * Action get ig insights
 *
 * @author Jasin 
 */
function getIgInsights() {
    return dispatch => {
        dispatch(request());
        return IgInsightsServices.getIgInsights()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request() { return { type: GET_IG_INSIGHTS_REQUEST } }
    function success(response) { return { type: GET_IG_INSIGHTS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_IG_INSIGHTS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get deal
 *
 * @author Akshay 
 */
 function getInsightChart(params) {
    return dispatch => {
        dispatch(request());
        return IgInsightsServices.getInsightChart(params)
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_INSIGHT_CHART_REQUEST, request: params } }
    function success(response) { return { type: GET_INSIGHT_CHART_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_INSIGHT_CHART_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get ig insights
 *
 * @author Jasin 
 */
 function getDailyCouponCount() {
    return dispatch => {
        dispatch(request());
        return IgInsightsServices.getDailyCouponCount()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request() { return { type: GET_IG_INSIGHTS_DAILY_COUPON_REQUEST } }
    function success(response) { return { type: GET_IG_INSIGHTS_DAILY_COUPON_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_IG_INSIGHTS_DAILY_COUPON_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get deal
 *
 * @author Akshay 
 */
 function getDcInsightChart(params) {
    return dispatch => {
        dispatch(request());
        return IgInsightsServices.getDcInsightChart(params)
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_INSIGHT_CHART_REQUEST, request: params } }
    function success(response) { return { type: GET_INSIGHT_CHART_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_INSIGHT_CHART_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

