import React, { useState } from 'react'
import { Button, Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { connect } from 'react-redux';
import { PaymentAction } from '../../../_redux/actions';
import { AddUpdateBankDetails } from './AddUpdateBankDetails';
import { Check, Delete } from '@material-ui/icons';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalHeader } from '../../../_components/modal';
import { FormInput } from '../../../_components/form-input';
import { handleInputChange, validateForm } from '../../../_helpers';
import { ErrorAlert } from '../../../_components/alert';
import { useLocation, useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    paymentPreferenceBox: {
        padding: theme.spacing(2)
    },
    table: {
        marginBottom: theme.spacing(2)
    },
    tableHeader: {
        backgroundColor: `#b3e5fc`
    },
    verifyBankButton: {
        marginRight: theme.spacing(1)
    },
    bankVerificationGrid: {
        paddingBottom: theme.spacing(2)
    }
}));

const inputs = { first_deposit: '', second_deposit: '' };

function BankDetails({ loading, bankDetails, deleteBankDetails, getPaymentDetails, verifyPaymentDetails, setDefaultPayment }) {

    const navigate = useNavigate();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const fromType = params.get('type'); // plan promo from url

    const classes = useStyles();

    const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false)
    const [showVerifyBankModal, setShowVerifyBankModal] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    const [defaultPaymentModal, setDefaultPaymentModal] = useState(false)

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, terms_and_condition: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    const [responseMessage, setResponseMessage] = useState(null)

    const deleteBank = (e) => {
        e.preventDefault();
        setShowDeleteWarningModal(true);
    }

    const onHide = () => {
        setShowDeleteWarningModal(false);
        setShowVerifyBankModal(false);
        setDefaultPaymentModal(false);
        getPaymentDetails();
    }

    const deleteBankDetailsAction = () => {
        deleteBankDetails().then(() => {
            getPaymentDetails();
            setShowDeleteWarningModal(false);
        })
    }

    const verifyBank = (e) => {
        e.preventDefault();
        setShowVerifyBankModal(true);
    }

    const handleChange = (e) => {
        setResponseMessage(null);
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateVerification(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    const handleVerifySubmit = (e) => {
        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateVerification(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }
        dispatchBankVerificationAction();
    }

    const dispatchBankVerificationAction = (e) => {

        const { first_deposit, second_deposit } = data;
        const paymentData = {
            first_deposit: first_deposit * 100,
            second_deposit: second_deposit * 100,
        }

        verifyPaymentDetails(paymentData)
            .then((response) => {
                if (response.status === 1) {
                    setAction({ ...action, isSubmitted: false, isProcessing: false });
                    setErrors({ ...response.errors })
                    setData({ ...inputs });
                    setShowVerifyBankModal(false);
                    getPaymentDetails();
                    if (!response.data.default_payment) {
                        setDefaultPaymentModal(true);
                    } else {
                        if (fromType && fromType === 'trial_coupons') {
                            navigate({
                                pathname: "/invoices",
                                search:`?type=trial_coupons`
                            })
                        }

                    }
                    setResponseMessage(null);
                } else {
                    setResponseMessage(response.message);
                    setErrors({ ...response.errors })
                    setAction({ ...action, isSubmitted: true, isProcessing: false });
                }
            });
    }

    const validateVerification = (name, value, data) => {
        //eslint-disable-next-line
        const errors = {};
        switch (name) {
            case 'first_deposit': {
                errors.first_deposit = '';
                if (value === '')
                    errors.first_deposit = 'First deposit is required. ';
                break;
            } case 'second_deposit': {
                errors.second_deposit = '';
                if (value === '')
                    errors.second_deposit = 'Second deposit is required. ';
                break;
            } default:
                errors[name] = '';
                break;
        }
        return errors;
    }

    const setDefaultPaymentMethod = (e) => {
        setShowLoader(true);
        setDefaultPayment({ method: 'bank' }).then(() => {
            getPaymentDetails();
            setDefaultPaymentModal(false);
            setShowLoader(false);
            
            if (fromType && fromType === 'trial_coupons') {
                navigate({
                    pathname: "/invoices",
                    search:`?type=trial_coupons`
                })
            }
        })
    }

    return (
        <Box className={classes.paymentPreferenceBox}>

            {/* BEGIN: Confirmation modal for setting default payment method */}
            <ConfzModal isModalOpen={defaultPaymentModal} onHide={onHide} loader={showLoader} size="md">
                <ConfzModalHeader id="AddUpdateDeals" title="Default Payment Method" onHide={onHide} />
                <ConfzModalBody className="text-center">
                    <h1>New Bank Added</h1>
                    <p>{`Do you want to change, bank as your default payment method?`}</p>
                </ConfzModalBody>
                <ConfzModalFooter>
                    <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onHide}>Cancel</Button>
                    <Button variant="contained" color="primary" size="small" className={classes.button} onClick={setDefaultPaymentMethod}>Confirm</Button>
                </ConfzModalFooter>
            </ConfzModal>
            {/* END: Confirmation modal for setting default payment method */}

            <ConfzModal isModalOpen={showDeleteWarningModal} onHide={onHide} loader={showLoader} size="md">
                <ConfzModalHeader id="AddUpdateDeals" title="Remove Bank Details" onHide={onHide} />
                <ConfzModalBody className="text-center">
                    <h1>Are You Sure?</h1>
                    <p>{`Do you want to remove this bank account?`}</p>
                </ConfzModalBody>
                <ConfzModalFooter>
                    <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onHide}>Cancel</Button>
                    <Button variant="contained" color="primary" size="small" className={classes.button} onClick={deleteBankDetailsAction}>Confirm</Button>
                </ConfzModalFooter>
            </ConfzModal>

            <ConfzModal isModalOpen={showVerifyBankModal} onHide={onHide} loader={showLoader} size="md">
                <ConfzModalHeader id="AddUpdateDeals" title="Verify Bank Details" onHide={onHide} />
                <form onSubmit={handleVerifySubmit} noValidate >
                    <ConfzModalBody>
                        <Grid container spacing={2} className={classes.bankVerificationGrid}>

                            {responseMessage && <ErrorAlert message={responseMessage} />}
                            <Grid item xs={12}>
                                <Box className="col-md-12">
                                    <Typography variant="caption"  >To verify your account, enter the two micro deposits below.If you don't see them, they should arrive in 1-3 business days.</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    label="First Deposit (amount as seen on your online bank statement, e.g. 0.12 or 0.25 or .34)"
                                    placeholder="Enter first deposit"
                                    name="first_deposit"
                                    tabIndex="1"
                                    value={data.first_deposit}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.first_deposit)) ? (errors.first_deposit) : ''}
                                    className="col-md-12"
                                    maxLength={4}
                                    maskType={'digit-with-one-dot'}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    label="Second Deposit (amount as seen on your online bank statement, e.g. 0.12 or 0.25 or .34)"
                                    placeholder="Enter second deposit"
                                    name="second_deposit"
                                    tabIndex="2"
                                    value={data.second_deposit}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.second_deposit)) ? (errors.second_deposit) : ''}
                                    className="col-md-12"
                                    maxLength={4}
                                    maskType={'digit-with-one-dot'}
                                />
                            </Grid>
                        </Grid>
                    </ConfzModalBody>
                    <ConfzModalFooter>
                        <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onHide}>Cancel</Button>
                        <Button type="submit" variant="contained" color="primary" size="small" className={classes.button} >Verify</Button>
                    </ConfzModalFooter>
                </form>
            </ConfzModal>

            <TableContainer >
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell>Acccount</TableCell>
                            <TableCell>Name on Account</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {!loading && !bankDetails && (
                            <TableRow>
                                <TableCell colSpan={3}>Bank details not added</TableCell>
                            </TableRow>
                        )}

                        {!loading && bankDetails && (
                            <TableRow >
                                <TableCell>
                                    {bankDetails.status === 'new' && <img src={`${process.env.PUBLIC_URL}/img/vectors/bank.svg`} style={{ height: `25px`, marginRight: `12px` }} alt="bank" />}
                                    {bankDetails.status !== 'new' && <img src={`${process.env.PUBLIC_URL}/img/vectors/bank-with-check.svg`} style={{ height: `25px`, marginRight: `12px` }} alt="bank without check" />}
                                    {bankDetails.bank_name} account ending in {bankDetails.last4}</TableCell>
                                <TableCell>{bankDetails.account_holder_name}</TableCell>
                                <TableCell>
                                    {bankDetails.status === 'new' && (<Button variant="contained" color="primary" size="small" className={classes.verifyBankButton} startIcon={<Check />} onClick={verifyBank}>Verify</Button>)}
                                    <Button variant="contained" color="secondary" size="small" className={classes.button} startIcon={<Delete />} onClick={deleteBank}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        )}

                    </TableBody>
                </Table>
            </TableContainer>
            {!loading && !bankDetails && <AddUpdateBankDetails />}
        </Box>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.PaymentReducer.payment_details_loading,
        bankDetails: state.PaymentReducer.payment_details.stripe_bank_response
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteBankDetails: () => dispatch(PaymentAction.deleteBankDetails()),
        getPaymentDetails: () => dispatch(PaymentAction.getPaymentDetails()),
        verifyPaymentDetails: (params) => dispatch(PaymentAction.verifyPaymentDetails(params)),
        setDefaultPayment: (params) => dispatch(PaymentAction.setDefaultPaymentMethod(params)),
    }
};

const connectedBankDetails = connect(mapStateToProps, mapDispatchToProps)(BankDetails);
export { connectedBankDetails as BankDetails };
