import React from 'react';
import ReactDOM from 'react-dom';
import App from './screens/App';

import { AppContainer } from 'react-hot-loader';
import { store } from './_redux/store';
import { Provider } from 'react-redux';

import * as serviceWorker from './serviceWorker';
import 'fontsource-roboto';
import './index.scss';

const render = (AppComponent: any) => {
	ReactDOM.render(
		<React.StrictMode>
			<Provider store={store}>
				<AppContainer>
					<AppComponent />
				</AppContainer>
			</Provider>
		</React.StrictMode>,
		document.getElementById('root')
	);
};
render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Hot Module Replacement (HMR).
if ((module as any).hot) {
	(module as any).hot.accept('./screens/App.tsx', () => {
		const NextApp = require('./screens/App.tsx').default; // eslint-disable-line global-require
		render(NextApp);
	});
}
