import { StoriesServices } from "../services";

export const GET_STORIES_REQUEST = 'GET_STORIES_REQUEST';
export const GET_STORIES_SUCCESS = 'GET_STORIES_SUCCESS';
export const GET_STORIES_FAILURE = 'GET_STORIES_FAILURE';

export const StoriesAction = { getStories };

/**
 * function to get participants list for  giveaway
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 
 */
function getStories(params) {
    return dispatch => {
        dispatch(request(params));
        return StoriesServices.getStories(params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };


    function request(request) { return { type: GET_STORIES_REQUEST, request: request } }
    function success(request, response) { return { type: GET_STORIES_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_STORIES_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };


}
