import { Card, CardContent, CardHeader } from '@material-ui/core';
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { IgInsightsAction } from '../../../_redux/actions';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function DirectionClicksChart({ loading, getInsightChart, insight_chart }) {

    useEffect(() => {
        getInsightChart({ 'metric': 'get_directions_clicks' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const options = {

        chart: {
            type: 'column',
            renderTo: 'container',
            backgroundColor: 'rgba(0,0,0,0)',
            style: {
                fontFamily: 'unset'
            }
        },
        title: {
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category',
            categories: insight_chart.xAxis ?? []
        },
        yAxis: {
            title: {
                text: 'Direction clicks'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.0f}'
                }
            }
        },

        tooltip: {
            formatter: function () {
                return '<span style="font-size:11px">' + this.point.date + ' </span> <br><span style="color: ' + this.point.color + '">Total</span>: <b>' + this.point.y + '<b/> directions clicks </br>'
            }
        },

        series: [
            {
                colorByPoint: true,
                data: insight_chart.insights ?? {}
            }
        ],
        credits: {
            enabled: false
        }
    };

    return (
        <React.Fragment>
            <Card>
                <CardHeader title="Daily Directions Clicks" />
                <CardContent>
                    {!loading && <HighchartsReact highcharts={Highcharts} options={options} />}
                </CardContent>
            </Card>
        </React.Fragment>
    );

}


function mapStateToProps(state) {
    return {
        loading: state.IgInsightsReducer.insight_chart_loading,
        insight_chart: state.IgInsightsReducer.insight_chart,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getInsightChart: (params) => dispatch(IgInsightsAction.getInsightChart(params)),
    }
};


const connectedDirectionClicksChart = (connect(mapStateToProps, mapDispatchToProps)(DirectionClicksChart));
export { connectedDirectionClicksChart as DirectionClicksChart };
