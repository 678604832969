import { DealServices } from "../services";

export const GET_DEAL_REQUEST = 'GET_DEAL_REQUEST';
export const GET_DEAL_SUCCESS = 'GET_DEAL_SUCCESS';
export const GET_DEAL_FAILURE = 'GET_DEAL_FAILURE';

export const GET_LATEST_DEAL_REQUEST = 'GET_LATEST_DEAL_REQUEST';
export const GET_LATEST_DEAL_SUCCESS = 'GET_LATEST_DEAL_SUCCESS';
export const GET_LATEST_DEAL_FAILURE = 'GET_LATEST_DEAL_FAILURE';

export const GET_DEALS_REQUEST = 'GET_DEALS_REQUEST';
export const GET_DEALS_SUCCESS = 'GET_DEALS_SUCCESS';
export const GET_DEALS_FAILURE = 'GET_DEALS_FAILURE';

export const DELETE_DEALS_REQUEST = 'DELETE_DEALS_REQUEST';
export const DELETE_DEALS_SUCCESS = 'DELETE_DEALS_SUCCESS';
export const DELETE_DEALS_FAILURE = 'DELETE_DEALS_FAILURE';

export const ADD_DEALS_REQUEST = 'ADD_DEALS_REQUEST';
export const ADD_DEALS_SUCCESS = 'ADD_DEALS_SUCCESS';
export const ADD_DEALS_FAILURE = 'ADD_DEALS_FAILURE';

export const EDIT_DEALS_REQUEST = 'EDIT_DEALS_REQUEST';
export const EDIT_DEALS_SUCCESS = 'EDIT_DEALS_SUCCESS';
export const EDIT_DEALS_FAILURE = 'EDIT_DEALS_FAILURE';

export const IMPORT_DEALS_REQUEST = 'IMPORT_DEALS_REQUEST';
export const IMPORT_DEALS_SUCCESS = 'IMPORT_DEALS_SUCCESS';
export const IMPORT_DEALS_FAILURE = 'IMPORT_DEALS_FAILURE';

export const UPDATE_STATUS_REQUEST = 'UPDATE_STATUS_REQUEST';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAILURE = 'UPDATE_STATUS_FAILURE';

export const UPDATE_DEAL_MODE_REQUEST = 'UPDATE_DEAL_MODE_REQUEST';
export const UPDATE_DEAL_MODE_SUCCESS = 'UPDATE_DEAL_MODE_SUCCESS';
export const UPDATE_DEAL_MODE_FAILURE = 'UPDATE_DEAL_MODE_FAILURE';

export const GET_MAIL_CONTENT_REQUEST = 'GET_MAIL_CONTENT_REQUEST';
export const GET_MAIL_CONTENT_SUCCESS = 'GET_MAIL_CONTENT_SUCCESS';
export const GET_MAIL_CONTENT_FAILURE = 'GET_MAIL_CONTENT_FAILURE';

export const ADD_DEFAULT_COUPON_SETTINGS_REQUEST = 'ADD_DEFAULT_COUPON_SETTINGS_REQUEST';
export const ADD_DEFAULT_COUPON_SETTINGS_SUCCESS = 'ADD_DEFAULT_COUPON_SETTINGS_SUCCESS';
export const ADD_DEFAULT_COUPON_SETTINGS_FAILURE = 'ADD_DEFAULT_COUPON_SETTINGS_FAILURE';

export const CLEAR_MAIL_CONTENT = 'CLEAR_MAIL_CONTENT';

export const SET_PAGE = 'SET_PAGE';


export const DealAction = { getDeals, getDeal, deleteDeals, addDeals, editDeals, importDeals, updateStatus, setPage, getLatestDeal, getMailContent, clearMailContent, sendTestMail, changeDealMode, addDefaultCouponSettings };

/**
 * Action get deal
 *
 * @author Akshay 
 */
function getDeal(id) {
    return dispatch => {
        dispatch(request(id));
        return DealServices.getDeal(id)
            .then(
                response => { return dispatch(success(id, response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(id) { return { type: GET_DEAL_REQUEST, request: id } }
    function success(id, response) { return { type: GET_DEAL_SUCCESS, request: id, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_DEAL_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get deal
 *
 * @author Akshay 
 */
function getLatestDeal() {
    return dispatch => {
        dispatch(request());
        return DealServices.getLatestDeal()
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request() { return { type: GET_LATEST_DEAL_REQUEST } }
    function success(response) { return { type: GET_LATEST_DEAL_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_LATEST_DEAL_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get deal
 *
 * @author Akshay 
 */
function getDeals(params) {
    return dispatch => {
        dispatch(request());
        return DealServices.getDeals(params)
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_DEALS_REQUEST, request: params } }
    function success(response) { return { type: GET_DEALS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_DEALS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get deal
 *
 * @author Akshay 
 */
function deleteDeals(id) {
    return dispatch => {
        dispatch(request(id));
        return DealServices.deleteDeals(id)
            .then(
                response => { return dispatch(success(id, response)); },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(id) { return { type: DELETE_DEALS_REQUEST, id: id } }
    function success(id, response) { return { type: DELETE_DEALS_SUCCESS, id: id, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: DELETE_DEALS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get deal
 *
 * @author Akshay 
 */
function addDeals(params) {
    return dispatch => {
        dispatch(request(params));
        return DealServices.addDeals(params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(request) { return { type: ADD_DEALS_REQUEST, request: request } }
    function success(request, response) { return { type: ADD_DEALS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: ADD_DEALS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get deal
 *
 * @author Akshay 
 */
function editDeals(id, params) {
    return dispatch => {
        dispatch(request(id, params));
        return DealServices.editDeals(id, params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(id, request) { return { type: EDIT_DEALS_REQUEST, request: request } }
    function success(request, response) { return { type: EDIT_DEALS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: EDIT_DEALS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get deal
 *
 * @author Akshay 
 */
function updateStatus(id) {
    return dispatch => {
        dispatch(request(id));
        return DealServices.updateStatus(id)
            .then(
                response => {
                    return dispatch(success(id, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(request) { return { type: UPDATE_STATUS_REQUEST, request: request } }
    function success(request, response) { return { type: UPDATE_STATUS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: UPDATE_STATUS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get deal
 *
 * @author Akshay 
 */
function changeDealMode(params) {
    return dispatch => {
        dispatch(request(params));
        return DealServices.changeDealMode(params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(request) { return { type: UPDATE_DEAL_MODE_REQUEST, request: request } }
    function success(request, response) { return { type: UPDATE_DEAL_MODE_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: UPDATE_DEAL_MODE_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action to import deal
 *
 * @author Akshay 
 */
function importDeals(params) {
    return dispatch => {
        dispatch(request(params));
        return DealServices.importDeals(params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(request) { return { type: IMPORT_DEALS_REQUEST, request: request } }
    function success(request, response) { return { type: IMPORT_DEALS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: IMPORT_DEALS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action to import deal
 *
 * @author Akshay 
 */
function getMailContent() {
    return dispatch => {
        dispatch(request());
        return DealServices.getMailContent()
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request() { return { type: GET_MAIL_CONTENT_REQUEST } }
    function success(response) { return { type: GET_MAIL_CONTENT_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_MAIL_CONTENT_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action to import deal
 *
 * @author Akshay 
 */
function sendTestMail() {
    return dispatch => {
        return DealServices.sendTestMail()
            .then(
                response => { return response; },
                error => { return error }
            );
    };
}

/**
 * Action to import deal
 *
 * @author Akshay 
 */
function clearMailContent(page) {
    return dispatch => { dispatch({ type: CLEAR_MAIL_CONTENT }) };
}

/**
 * Action to import deal
 *
 * @author Akshay 
 */
function setPage(page) {
    return dispatch => { dispatch({ type: SET_PAGE, page: page }) };
}

/**
 * Action add default coupon settings
 *
 * @author Akshay 
 */
function addDefaultCouponSettings(params) {
    return dispatch => {
        dispatch(request(params));
        return DealServices.addDefaultCouponSettings(params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(request) { return { type: ADD_DEFAULT_COUPON_SETTINGS_REQUEST, request: request } }
    function success(request, response) { return { type: ADD_DEFAULT_COUPON_SETTINGS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: ADD_DEFAULT_COUPON_SETTINGS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}
