import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { UserAction } from '../../../_redux/actions';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function ProfileViewsReachGraph({ profile_view_and_reach_graph, isLoading }) {

    const { profile_views, reach, xAxis, date_period } = profile_view_and_reach_graph || { profile_views: [], reach: [], xAxis: [], date_period: {} };

    const options = {
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        legend: {
            align: 'right',
            verticalAlign: 'top',
            itemMarginBottom: 10
        },
        xAxis: {
            title: {
                text: `${date_period.from ?? ''} - ${date_period.to ?? ''}`
            },
            type: 'category',
            categories: xAxis
        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
                formatter: function () {
                    return (this.value >= 1000) ? this.value / 1000 + 'k' : this.value;
                }
            }
        },
        plotOptions: {
            area: {
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 2,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            }
        },

        series: [{
            name: 'Profile Views',
            data: profile_views,
            color: '#5a6fd6'
        }, {
            name: 'Reach',
            data: reach,
            color: '#58c7c3'
        }]
    };

    return (
        <React.Fragment>
            <Card>
                <CardContent style={{ minHeight: `550px` }}>
                    <Grid container>
                        <Grid container item xs={12} md={8} spacing={2}>
                            <Grid item xs={5} md={4}>
                                <Typography variant="subtitle1" color="initial">Date Period:</Typography>
                            </Grid>
                            <Grid item xs={7} md={8}>
                                <Typography variant="subtitle1" color="initial">{date_period.from ?? ''} - {date_period.to ?? ''}</Typography>
                            </Grid>
                            <Grid item xs={5} md={4}>
                                <Typography variant="subtitle1" color="initial">Duration:</Typography>
                            </Grid>
                            <Grid item xs={7} md={8}>
                                <Typography variant="subtitle1" color="initial">{date_period.duration ? date_period.duration + ' days' : ''}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    {(typeof (isLoading) !== 'undefined' && !isLoading) && <HighchartsReact highcharts={Highcharts} options={options} />}
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        isLoading: state.UserReducer.profile_view_and_reach_graph_loading,
        profile_view_and_reach_graph: state.UserReducer.profile_view_and_reach_graph
    };
}


function mapDispatchToProps(dispatch) {
    return {
        getProfileViewsAndReach: dispatch(UserAction.getProfileViewsAndReach())
    }
};

const connectedProfileViewsReachGraph = connect(mapStateToProps, mapDispatchToProps)(ProfileViewsReachGraph);
export { connectedProfileViewsReachGraph as ProfileViewsReachGraph };
