import {
    GET_STORIES_REQUEST, GET_STORIES_SUCCESS, GET_STORIES_FAILURE
} from "../actions";

const initialState = {
    stories_loading: false, stories: [], stories_errors: {}
};

export function StoriesReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Designation redux */
        case GET_STORIES_REQUEST: {
            return {
                ...state,
                stories_loading: true,
            };
        } case GET_STORIES_SUCCESS: {

            let newArray = [...state.stories]; //making a new array
            action.data.stories.forEach((payload) => {
                const storyIndex = newArray.findIndex(story => story._id === payload._id);
                if (storyIndex !== -1) {
                    newArray[storyIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });


            return {
                ...state,
                stories_loading: false,
                stories: newArray
            };
        } case GET_STORIES_FAILURE: {
            return {
                ...state,
                stories_loading: false,
                stories_error: action.error
            };
        }
        /** end::Designation redux */
        default:
            return state;
    }
}