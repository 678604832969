import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { FormInput } from '../../_components/form-input';
import { ConfzModal, ConfzModalHeader, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn } from '../../_components/modal';

import { handleInputChange, validateForm } from '../../_helpers';
import { CouponAction } from '../../_redux/actions';
import validateCoupon from './validateCoupon';



function AddUpdateCoupons({ id, show, closeModal, addCoupons, editCoupons, getCoupon }) {

    const inputs = { coupon_id: id, coupon_code: '' }


    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [showLoader, setShowLoader] = useState(false);
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });



    useEffect(() => {
        if (id) {
            setShowLoader(true);
            getCoupon(id).then((response) => {
                if (response.status === 1) {
                    setShowLoader(false);
                    setData({ coupon_id: response.data._id, coupon_code: response.data.coupon_code });
                }
            });
        } else {
            setData({ ...inputs });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    /**
    * function to handle input change
    * @param object e input object
    * @author Akshay N
    */
    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateCoupon(name, value)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateCoupon(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        console.log(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }
        dispatchCouponAction();
    }

    const dispatchCouponAction = () => {
        let dispatchAction = (data.coupon_id) ? editCoupons(data.coupon_id, data) : addCoupons(data);
        dispatchAction.then((response) => {
            if (response.status === 1) {
                closeModal(false);
                setAction({ ...action, isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
            } else {
                setErrors({ ...response.errors })
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }
        });
    }

    const onHide = () => {
        closeModal(false);
    }

    return (
        <ConfzModal isModalOpen={show} onHide={onHide} loader={showLoader} size="lg">
            <form onSubmit={handleSubmit} noValidate className='ns-form' >
                <ConfzModalHeader id="AddUpdateCoupons" title="Add Coupon" onHide={onHide} />
                <ConfzModalBody>
                    <div className="row">
                        <FormInput
                            label="Coupon Code"
                            name="coupon_code"
                            type="text"
                            tabIndex="1"
                            error={(action.isSubmitted && (errors.coupon_code)) ? (errors.coupon_code) : ''}
                            placeholder="Enter coupon code" value={data.coupon_code}
                            onChange={handleChange}
                            className="col-md-6"
                        />
                    </div>
                </ConfzModalBody>
                <ConfzModalFooter>
                    <ConfzModalFooterCloseBtn tabIndex="4" onHide={onHide} />
                    <ConfzModalFooterSaveBtn tabIndex="3" isProcessing={action.isProcessing} />
                </ConfzModalFooter>
            </form>
        </ConfzModal>
    )
}



function mapStateToProps(state) {
    return {
        loading: state.CouponReducer.coupon_loading,
        coupons: state.CouponReducer.coupons,
        page: state.CouponReducer.page,
        per_page: state.CouponReducer.per_page,
        total: state.CouponReducer.total,
        total_pages: state.CouponReducer.total_pages,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCoupon: (params) => dispatch(CouponAction.getCoupon(params)),
        addCoupons: (params) => dispatch(CouponAction.addCoupons(params)),
        editCoupons: (id, params) => dispatch(CouponAction.editCoupons(id, params)),
    }
};

const connectedAddUpdateCoupons = connect(mapStateToProps, mapDispatchToProps)(AddUpdateCoupons);
export { connectedAddUpdateCoupons as AddUpdateCoupons };

