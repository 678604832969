import {
    GET_INVOICES_LIST_REQUEST, GET_INVOICES_LIST_SUCCESS, GET_INVOICES_LIST_FAILURE,

    CLEAR_INVOICES_DATA, SET_PAGE,
} from "../actions";

const initialState = {
    page: 1, per_page: 18, total: 0, total_pages: 0,
    invoice_list_loading: false, invoice_list: [], invoice_list_error: [],
    invoice_loading: true, invoice: {}, invoice_error: []
};

export function InvoicesReducer(state = initialState, action) {
    switch (action.type) {

        case SET_PAGE: {
            return {
                ...state,
                page: action.page
            };
        }

        /** begin::invoices_list redux */
        case GET_INVOICES_LIST_REQUEST: {
            return {
                ...state,
                invoice_list_loading: true,
                invoice_list: [],
                invoice_list_error: []
            };
        } case GET_INVOICES_LIST_SUCCESS: {
            return {
                ...state,

                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages,

                invoice_list_loading: false,
                invoice_list: action.data.payments,
                invoice_list_error: [],
            };
        } case GET_INVOICES_LIST_FAILURE: {
            return {
                ...state,
                invoice_list_loading: false,
                invoice_list: [],
                invoice_list_error: []
            };
        }
        /** end::invoice_list redux */

        case CLEAR_INVOICES_DATA:
            return { ...state, invoice_loading: true, invoice: {}, invoice_error: [] }



        default:
            return state;
    }
}