import React from 'react'
import DataTable, { defaultThemes } from 'react-data-table-component';
import PropTypes from 'prop-types'
import { TableLoader } from '../../_layouts/loader';
import { NoDataFound } from '../not-found';

function ConfzDatatable({ columns, data, dense, loading, total, per_page, setPage, handleSort, handleSelect, props }) {


    const customStyles = {
        headRow: {
            style: {
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: defaultThemes.default.divider.default,
                fontWeight: '900',
                fontSize: '16px',
                minHeight: '50px !important',
                backgroundColor: '#eaeaea'
            },
        },
        headCells: {
            style: {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
                minWidth: '100px !important',
                ':first-child': {
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '1px',
                    borderLeftColor: defaultThemes.default.divider.default,
                    minWidth: '100px !important',
                    overflow: `unset`,
                },
            },
        },
        cells: {
            style: {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
                minWidth: '100px !important',
                ':first-child': {
                    minWidth: '100px !important',
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '1px',
                    borderLeftColor: defaultThemes.default.divider.default,
                },
            },
        },
        rows: {
            style: {
                '.row-action': {
                    width: '100% !important',
                    display: 'none'
                },
                '&:hover': {
                    zIndex: '2',
                    backgroundColor: '#fff !important',
                    boxShadow: `inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)`,
                    '.badge-status': {
                        opacity: '0.1'
                    },
                    '.row-action': {
                        'span': {
                            padding: '0 2px !important'
                        },
                        display: 'flex',
                        position: 'absolute',
                        justifyContent: 'center'
                    },
                }
            }
        },
    };


    return (
        <DataTable
            columns={columns}
            data={data}
            progressPending={loading}
            customStyles={customStyles}
            progressComponent={<TableLoader />}
            pointerOnHover={true}
            paginationTotalRows={total}
            paginationPerPage={per_page}
            paginationComponentOptions={{ noRowsPerPage: true }}
            onChangePage={page => setPage(page)}
            onSort={handleSort}
            striped
            dense={dense}
            noHeader
            highlightOnHover
            // selectableRows
            onSelectedRowsChange={handleSelect}
            pagination
            paginationServer
            noDataComponent={<NoDataFound />}
            {...props}
        />
    )
}


ConfzDatatable.defaultProps = {
    className: "",
    dense: true
}


ConfzDatatable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    className: PropTypes.string,
    loading: PropTypes.bool,
    total: PropTypes.number,
    per_page: PropTypes.number,
    setPage: PropTypes.func.isRequired,
    handleSort: PropTypes.func.isRequired,
    handleSelect: PropTypes.func.isRequired
}


export { ConfzDatatable };
