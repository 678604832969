import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { ConfzDatatable } from '../../_components/data-table'
import { InvoicesAction } from '../../_redux/actions';
import queryString from 'query-string';
import { Button } from '@material-ui/core';
import { PayNowModal, PaymentInitiatedModal } from '.';


function DataTable({ isLoading, per_page, total, invoices, search, filter, sort, getInvoicesList, makePayment, createCheckOutSession, invoicePayment, sessionId, ...props }) {

    const [page, setPage] = useState(1);
    const [paymentData, setPaymentData] = useState({ id: '', invoice_id: '' });
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showPaymentInitiatedModal, setShowPaymentInitiatedModal] = useState(false);
    const [payNowModalLoader, setPayNowModalLoader] = useState(false);


    const payNow = (invoiceId, invoiceNumber) => {
        setPaymentData({ id: invoiceId, invoice_number: invoiceNumber });
        setShowPaymentModal(!showPaymentModal);
    }

    const paymentModal = () => {
        setPaymentData({ id: '', invoice_number: '' });
        setShowPaymentModal(!showPaymentModal);
    }

    const paymentInitiatedModal = () => {
        setShowPaymentInitiatedModal(!showPaymentInitiatedModal);
    }

    // const updatePaymentAction = (id) => {
    //     setPayNowModalLoader(true);
    //     makePayment(id).then(() => {
    //         setPayNowModalLoader(false);
    //         setShowPaymentModal(!showPaymentModal);
    //     });
    // }

    const updatePaymentAction = (id) => {
        setPayNowModalLoader(true);
        createCheckOutSession({'invoice_id' : id }).then((result) => {
            if (result.status === 1) {
                window.location.href = result.data;
            }
            setPayNowModalLoader(false);
            setShowPaymentModal(!showPaymentModal);
        });
    }


    const columns = [
        { name: '#', selector: (row, i) => (row._id), center: true, width: '5%', cell: (row, i) => ((per_page * (page - 1)) + (i + 1)) },
        { name: 'Statement Number', selector: (row, i) => (row.invoice_number), sortable: true, width: '17%', wrap: true },
        { name: 'Statement Date', selector: (row, i) => (row.created_at), sortable: true, width: '15%', wrap: true },
        { name: 'Coupons Date Range', selector: (row, i) => (row.invoice_start_date + ' - ' + row.invoice_end_date), sortable: true, width: '19%', wrap: true, style: { whiteSpace: `unset` } },
        { name: 'Number Of Coupons', selector: (row, i) => (row.total_coupons), center: true, sortable: true, width: '10%', wrap: true, allowOverflow: true },
        { name: 'Amount ($)', selector: (row, i) => ('$' + row.total_amount), center: true, sortable: true, width: '10%', wrap: true },
        { name: 'Payment Status', selector: (row, i) => (row.status), center: true, sortable: true, width: '12%', wrap: true, cell: row => (<StatusRow row={row} />) },
        { name: 'Paid On', selector: (row, i) => (row.created_at), center: true, sortable: true, width: '12%', wrap: true, cell: row => (<PaidOnRow row={row} />) },
    ];


    const StatusRow = ({ row }) => {
        let status = <span className="badge  badge-danger" style={{ minWidth: '68px', padding: '5px 0px 3px 0px' }}>{row.status}</span>;
        if (row.status === 'Success')
            status = <span className="badge  badge-success" style={{ minWidth: '68px', padding: '5px 0px 3px 0px' }}>{row.status}</span>;
        else if (row.status === 'Pending')
            status = <span className="badge  badge-warning" style={{ minWidth: '68px', padding: '5px 0px 3px 0px' }}>{row.status}</span>;
        return <React.Fragment>{status}</React.Fragment>;

    }

    const PaidOnRow = ({ row }) => {
        let status = '';
        if (row.status === 'Success')
            status = row.created_at;
        else if (row.status === 'Pending')
            status = (<Button variant="contained" color="secondary" onClick={() => payNow(row._id, row.invoice_number)} size="small" style={{ whiteSpace: 'nowrap', minWidth: '78px', padding: '0 10px' }}>Pay Now</Button>)
        return <React.Fragment>{status}</React.Fragment>;

    }



    useEffect(() => {

        let queryParam = filter ? filter : {};
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q
        let pageUrl = `/invoices?${queryString.stringify(queryParam)}`;
        window.history.pushState({}, '', pageUrl);
        setPage(page);
        getInvoicesList({ page: page, search: search });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, search, filter, sort])

    useEffect(() => {
        if (sessionId) {
            setShowPaymentInitiatedModal(true)

            setTimeout(() => {
                invoicePayment({ session_id: sessionId }).then((result) => {
                    setPage(1);
                    getInvoicesList({ page: 1 });
                    setShowPaymentInitiatedModal(false)
                });
            }, 2000);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId])

    function handleSort(page, sortDirection) {
        // console.log(page.selector, sortDirection);
    }

    const handleSelect = (data) => {
        // console.log(data.selectedRows)
    }

    return (
        <div className="card-body card-table w-100">

            <PayNowModal show={showPaymentModal} closeModal={paymentModal} data={paymentData} showLoader={payNowModalLoader} updatePayment={updatePaymentAction} />

            <PaymentInitiatedModal show={showPaymentInitiatedModal} closeModal={paymentInitiatedModal} showLoader={false}/>

            <ConfzDatatable
                loading={isLoading}
                columns={columns}
                data={invoices}
                total={total}
                per_page={per_page}
                setPage={page => setPage(page)}
                handleSort={handleSort}
                handleSelect={handleSelect}
                wrap={true}
                dense={false}
            />
        </div>
    )
}


function mapStateToProps(state) {
    return {
        page: state.InvoicesReducer.page,
        per_page: state.InvoicesReducer.per_page,
        total: state.InvoicesReducer.total,
        total_pages: state.InvoicesReducer.total_pages,

        isLoading: state.InvoicesReducer.invoice_list_loading,
        invoices: state.InvoicesReducer.invoice_list,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getInvoicesList: (params) => dispatch(InvoicesAction.getInvoicesList(params)),
        makePayment: (invoiceId) => dispatch(InvoicesAction.makePayment(invoiceId)),
        createCheckOutSession: (params) => dispatch(InvoicesAction.createCheckOutSession(params)),
        invoicePayment: (params) => dispatch(InvoicesAction.invoicePayment(params))

    }
};

const connectedDataTable = (connect(mapStateToProps, mapDispatchToProps)(DataTable));
export { connectedDataTable as DataTable };

