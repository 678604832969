const validateTrialCoupon = (name, value) => {
    const errors = {};
    switch (name) {
        case 'trial_coupon_code': {
            errors.trial_coupon_code = '';
            if (value === '' || typeof value === 'undefined') {
                errors.trial_coupon_code = 'Trial coupon code is required. ';
            } 
            break;
        } default:
            errors[name] = '';
            break;
    }
    return errors;
}
export default validateTrialCoupon;