import { Box } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SummaryWidget } from '../../../_components/summary-widget';

function EmailClicksCount({ isLoading, email_contacts }) {

    const navigate = useNavigate();

    const viewChart = () => {
        navigate({
            pathname: "/ig-insights/chart",
            search:`?metric=email_contacts`
        })
    }

    return (
        <Box style={{cursor: 'pointer'}} onClick={() => viewChart()}>
            <SummaryWidget loading={isLoading} text={'Daily Email Clicks'} value={email_contacts} bgColor={"#008B8B"} color={"#ffffff"} />
        </Box>
    );


}


function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
};

const connectedEmailClicksCount = (connect(mapStateToProps, mapDispatchToProps)(EmailClicksCount));
export { connectedEmailClicksCount as EmailClicksCount };
