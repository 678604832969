import { InvoicesServices } from "../services";

export const GET_INVOICES_LIST_REQUEST = 'GET_INVOICES_LIST_REQUEST';
export const GET_INVOICES_LIST_SUCCESS = 'GET_INVOICES_LIST_SUCCESS';
export const GET_INVOICES_LIST_FAILURE = 'GET_INVOICES_LIST_FAILURE';

export const CREATE_CHECKOUT_SESSION_REQUEST = 'CREATE_CHECKOUT_SESSION_REQUEST';
export const CREATE_CHECKOUT_SESSION_SUCCESS = 'CREATE_CHECKOUT_SESSION_SUCCESS';
export const CREATE_CHECKOUT_SESSION_FAILURE = 'CREATE_CHECKOUT_SESSION_FAILURE';

export const INVOICE_PAYMENT_REQUEST = 'INVOICE_PAYMENT_REQUEST';
export const INVOICE_PAYMENT_SUCCESS = 'INVOICE_PAYMENT_SUCCESS';
export const INVOICE_PAYMENT_FAILURE = 'INVOICE_PAYMENT_FAILURE';

export const SET_PAGE = 'SET_PAGE';
export const CLEAR_INVOICES_DATA = 'CLEAR_INVOICES_DATA';

export const InvoicesAction = { setPage, getInvoicesList, clearInvoiceDetails, generateInvoice, makePayment, createCheckOutSession, invoicePayment };

/**
 * function to set datatable page 
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function setPage(page) {
    return dispatch => { dispatch({ type: SET_PAGE, page: page }) };

}

/**
 * function to set datatable page 
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function clearInvoiceDetails() {
    return dispatch => { dispatch({ type: CLEAR_INVOICES_DATA }) };


}


/**
 * function set get shoppers list for datatable 
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function getInvoicesList(params) {
    return dispatch => {
        dispatch(request());
        return InvoicesServices.getInvoicesList(params)
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: GET_INVOICES_LIST_REQUEST, request: params } }
    function success(response) { return { type: GET_INVOICES_LIST_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_INVOICES_LIST_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

function makePayment(invoiceId) {
    return dispatch => {
        return InvoicesServices.makePayment(invoiceId)
            .then(
                response => { dispatch(getInvoicesList({ page: 1 })); return response; },
                error => { return error; }
            )
    };
}

function createCheckOutSession(params) {
    return dispatch => {
        dispatch(request());
        return InvoicesServices.createCheckOutSession(params)
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: CREATE_CHECKOUT_SESSION_REQUEST, request: params } }
    function success(response) { return { type: CREATE_CHECKOUT_SESSION_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: CREATE_CHECKOUT_SESSION_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

function invoicePayment(params) {
    return dispatch => {
        dispatch(request());
        return InvoicesServices.invoicePayment(params)
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(params) { return { type: INVOICE_PAYMENT_REQUEST, request: params } }
    function success(response) { return { type: INVOICE_PAYMENT_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: INVOICE_PAYMENT_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * function set get shoppers list for datatable 
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function generateInvoice() {
    return dispatch => {
        return InvoicesServices.generateInvoice()
            .then(
                response => { dispatch(getInvoicesList({ page: 1 })); return response; },
                error => { return error; }
            )
    };

}


