import CookieServices from "../_redux/services/CookieServices";

export function authHeader(checkAuth = true) {
	if (checkAuth) {
		let user = JSON.parse(localStorage.getItem('user'));
		if (user && user.authdata) {
			return { 'Authorization': 'Bearer ' + user.authdata };
		} else if (CookieServices.get("accessToken")) {
			return {
				'Authorization': 'Bearer ' + CookieServices.get("accessToken"),
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'access-control-allow-origin': '*',
				'access-control-allow-headers': '*'
			};
		} else {
			return {};
		}
	} else {
		return {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'access-control-allow-origin': '*',
			'access-control-allow-headers': '*',
		};
	}
}
