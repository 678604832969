
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { PageTitle } from '../../_layouts/page-title';
import { useNavigate } from 'react-router-dom';
import { TotalCoupons } from './widgets/TotalCoupons';
import { IssuedCoupons } from './widgets/IssuedCoupons';
import { RemainingCoupons } from './widgets/RemainingCoupons';
import { Card, CardContent, CardHeader } from '../../_components/card';
import { DataTable } from './DataTable';
import { Delete } from '@material-ui/icons';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalHeader } from '../../_components/modal';
import { CouponAction } from '../../_redux/actions';

const useStyles = makeStyles((theme) => ({
    couponsWidgetContainer: {
        marginBottom: theme.spacing(2)
    },
    action: {
        margin: 0
    }
}));


function CreateOrUploadCoupons({ deleteActiveCoupons, balance_coupons, getCustomCoupons }) {

    const classes = useStyles();

    const navigate = useNavigate();
    let page = 0;
    const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false)
    const [subAccount, setSubAccount] = useState('')
    const [showLoader, setShowLoader] = useState(true);

    const createCoupons = () => {
        let pageUrl = `/coupons/create/`;
        navigate(pageUrl);
    }

    const uploadCoupons = () => {
        let pageUrl = `/coupons/upload/`;
        navigate(pageUrl);
    }


    const onHide = () => {
        setShowDeleteWarningModal(false);
    }

    const deleteActiveCoupon = (e) => {
        e.preventDefault();
        setShowDeleteWarningModal(true);
    }

    const deleteActiveCouponsAction = () => {
        deleteActiveCoupons().then(() => {
            setShowDeleteWarningModal(false);
        })
    }

    useEffect(() => {
        getCustomCoupons().then((response) => {
            if (response.status === 1) {
                setSubAccount(response.data.subaccount ?? '')
                setShowLoader(false);
            } else {
                setShowLoader(false);
            }
        });
    }, [])

    return (
        <Box>
            <React.Fragment>
                {/* <PageTitle title="Custom Coupons" action={
                    <React.Fragment>
                        <Button variant="contained" color="primary" onClick={uploadCoupons}>Select Coupons</Button>
                        <Button variant="contained" color="primary" onClick={createCoupons} >Create Coupons</Button>
                    </React.Fragment>
                } /> */}
                <Box>
                    <Grid container spacing={3}  >
                        <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
                            <Grid container direction="row" alignContent="center" justifyContent="center" spacing={2}>
                                <Grid item sm={4} md={4} xl={4}>
                                    {!(!showLoader && subAccount) && <Button variant="contained" color="primary" size="large" fullWidth={true} style={{ margin: '10px' }} onClick={createCoupons} disabled={showLoader}>Create Coupons</Button>}
                                    {!showLoader && subAccount && <Button variant="contained" color="primary" size="large" fullWidth={true} style={{margin: '10px'}} target="_blank"  href="https://createcoupon.guru.club/">Create Coupons</Button>}

                                    {/* <Button variant="contained" color="primary" size="large" fullWidth={true} style={{margin: '10px'}} onClick={uploadCoupons}>Upload Coupons</Button> */}
                                </Grid>
                                <Grid container item sm={4} md={4} xl={4} alignItems="center" justifyContent="center">
                                    <Button variant="contained" color="primary" size="large" fullWidth={true} style={{ margin: '10px' }} onClick={uploadCoupons}>Select/Upload</Button>
                                    <Grid item>
                                        <Typography variant='body1' align="center">Create your coupons then select them here. Or, upload your own.</Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                {/*
                <Grid container spacing={3} style={{ marginBottom: `15px` }}>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <TotalCoupons />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <IssuedCoupons />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <RemainingCoupons />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Card>
                            <CardHeader
                                title="Coupon Links"
                                classes={{ action: classes.action }}
                                action={<Button variant="contained" color="secondary" disabled={balance_coupons === 0} startIcon={<Delete />} onClick={deleteActiveCoupon}>
                                Delete Unused Coupons
                            </Button>}
                            />
                            <CardContent>
                                <DataTable page={page} search={''} filter={''} sort={''} editCoupon={''} handleSelect={''} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <ConfzModal isModalOpen={showDeleteWarningModal} onHide={onHide} loader={false} size="md">
                    <ConfzModalHeader id="RemoveCoupons" title="Remove all active coupons" onHide={onHide} />
                    <ConfzModalBody className="text-center">
                        <h1>Are You Sure?</h1>
                        <p>{`Do you want to remove all active coupons?`}</p>
                    </ConfzModalBody>
                    <ConfzModalFooter>
                        <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onHide}>Cancel</Button>
                        <Button variant="contained" color="primary" size="small" className={classes.button} onClick={deleteActiveCouponsAction} >Confirm</Button>
                    </ConfzModalFooter>
                </ConfzModal> */}
            </React.Fragment>
        </Box>
    )
}


function mapStateToProps(state) {
    return {
        page: state.CouponReducer.page,
        balance_coupons: state.CouponReducer.balance_coupons,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteActiveCoupons: () => dispatch(CouponAction.deleteActiveCoupons()),
        getCustomCoupons: () => dispatch(CouponAction.getCustomCoupons()),

    }
};

const connectedCreateOrUploadCoupons = (connect(mapStateToProps, mapDispatchToProps)(CreateOrUploadCoupons));
export { connectedCreateOrUploadCoupons as CreateOrUploadCoupons };
