import React, { useState } from 'react'
import { connect } from 'react-redux';
import { FormBrowseFile } from '../../../../_components/form-input';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from '../../../../_components/modal';
import { handleInputChange, validateForm } from '../../../../_helpers';
import { DesignationAction } from '../../../../_redux/actions';
import validateDesignationImport from './validateDesignationImport';

function Import({ importDesignations }) {

    const inputs = { file: '' }

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [showImportResult, setShowImportResult] = useState(false);
    const [importResult, setImportResult] = useState({ data: [], file: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    const onHide = () => {
        setShowImportResult(false);
        setShow(false);
    }

    /**
   * function to handle input change
   * @param object e input object
   * @author Akshay N
   */
    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateDesignationImport(name, value)) });
        data[name] = value;
        setData({ ...data });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateDesignationImport(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true, isProcessing: false });
            return;
        }
        formData.append('file', data.file);
        dispatchDesignationImport(formData);
    }

    const dispatchDesignationImport = (formData) => {
        importDesignations(formData).then((response) => {
            if (response.status === 1) {
                setShow(false);
                setImportResult({ ...response.data });
                setShowImportResult(true);
                setAction({ isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
            } else {
                setAction({ isSubmitted: true, isProcessing: false });
                setErrors({ ...response.errors })
            }
        });
    }

    return (
        <React.Fragment>
            <ConfzModal isModalOpen={showImportResult} onHide={onHide} size="xl">
                <form onSubmit={handleSubmit} noValidate >
                    <ConfzModalHeader id="importResult" title="Import Result" onHide={onHide} />
                    <ConfzModalBody>
                        <table className="table table-bordered table-striped table-sm" >
                            <thead className="thead-light ">
                                <tr>
                                    <th className="text-center">Sl.No</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Response</th>
                                </tr>
                            </thead>
                            <tbody>
                                {importResult.data.map((result, i) => {
                                    const request = JSON.parse(result.request);
                                    const response = result.response;
                                    const isCreated = result.isCreated;
                                    return (
                                        <tr>
                                            <td className="text-center">{i + 1}</td>
                                            <td>{request.name}</td>
                                            <td>{request.description}</td>
                                            <td>{request.status}</td>
                                            <td className={(isCreated) ? `text-success` : `text-warning`}>{response}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </ConfzModalBody>
                    <ConfzModalFooter>
                        <ConfzModalFooterCloseBtn tabIndex="3" onHide={onHide} />
                        <a download href={importResult.file} className="btn btn-sm btn-primary text-white">Export</a>
                    </ConfzModalFooter>
                </form>
            </ConfzModal>

            <ConfzModal isModalOpen={show} onHide={onHide}>
                <form onSubmit={handleSubmit} noValidate >
                    <ConfzModalHeader id="importDesignation" title="Import Designation" onHide={onHide} />
                    <ConfzModalBody>
                        <FormBrowseFile label="Choose File" name="file" tabIndex="1" error={(action.isSubmitted && (errors.file)) ? (errors.file) : ''} placeholder="Choose your file (csv)" value={data.file} onChange={handleChange} />
                    </ConfzModalBody>
                    <ConfzModalFooter>
                        <ConfzModalFooterCloseBtn tabIndex="3" onHide={onHide} />
                        <ConfzModalFooterSaveBtn tabIndex="2" isProcessing={action.isProcessing} text="Import" />
                    </ConfzModalFooter>
                </form>
            </ConfzModal>

            <span className="dropdown-item" onClick={() => setShow(true)} ><i className="fa fa-file-import"></i>Import</span>
        </React.Fragment>
    )
}



function mapStateToProps(state) {
    return {
        // loading: state.DesignationReducer.designation_loading,
        // designations: state.DesignationReducer.designations,
        // page: state.DesignationReducer.page,
        // per_page: state.DesignationReducer.per_page,
        // total: state.DesignationReducer.total,
        // total_pages: state.DesignationReducer.total_pages,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        importDesignations: (params) => dispatch(DesignationAction.importDesignations(params)),
    }
};

const connectedImport = connect(mapStateToProps, mapDispatchToProps)(Import);
export { connectedImport as Import };


