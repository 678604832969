import {
	SIGNIN_REQUEST, SIGNIN_SUCCESS, SIGNIN_FAILURE,
	INSTA_SIGNIN_REQUEST, INSTA_SIGNIN_SUCCESS, INSTA_SIGNIN_FAILURE
} from "../actions";

let token = localStorage.getItem('authToken') ? (localStorage.getItem('authToken')) : '';
const initialState = token ? { loggedIn: true, user: {} } : {};

export function SignInReducer(state = initialState, action) {
	switch (action.type) {

		/** begin::Sign Up redux */
		case SIGNIN_REQUEST:
			return {
				loggedIn: false,
				request: action.request,
				user: {}
			};
		case SIGNIN_SUCCESS:
			return {
				loggedIn: false,
				request: action.request,
				user: action.data,
			};
		case SIGNIN_FAILURE:
			return {
				loggedIn: false,
				user: {}
			};
		/** end::Sign Up redux */


		/** begin::intagram Sign in redux */
		case INSTA_SIGNIN_REQUEST: {
			return {
				insta_signin_loading: true,
				loggedIn: false,
				request: action.request,
				user: {}
			};
		}
		case INSTA_SIGNIN_SUCCESS: {
			return {
				insta_signin_loading: false,
				loggedIn: true,
				user: action.data,
			};
		}
		case INSTA_SIGNIN_FAILURE: {
			return {
				insta_signin_loading: false,
				loggedIn: false,
				user: {},
				errors: action.errors
			};
		}
		/** end::Sign in redux */

		default:
			return state;
	}
}
