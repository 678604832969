import { Box } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SummaryWidget } from '../../../_components/summary-widget';

function TextMessageClicksCount({ isLoading, text_message_clicks }) {

    const navigate = useNavigate();

    const viewChart = () => {
        navigate({
            pathname: "/ig-insights/chart",
            search:`?metric=text_message_clicks`
        })
    }

    return (
        <Box style={{cursor: 'pointer'}} onClick={() => viewChart()}>
            <SummaryWidget loading={isLoading} text={'Daily Text Message Clicks'} value={text_message_clicks} bgColor={"#0ec164"} color={"#ffffff"} />
        </Box>
    );
}


function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
};

const connectedTextMessageClicksCount = (connect(mapStateToProps, mapDispatchToProps)(TextMessageClicksCount));
export { connectedTextMessageClicksCount as TextMessageClicksCount };
