import React from 'react'
import { connect } from 'react-redux';

import { Grid, CardContent, Card, CircularProgress } from '@material-ui/core';

import { PaymentAction } from '../../_redux/actions';
import { CreditCardDetails } from './credit-card/CreditCardDetails';
import { BankDetails } from './bank-details/BankDetails';
import { DefaultPayment } from '.';


function Payments({ loading }) {

    return (
        <Grid container spacing={3}  >
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}  >
                <Card>
                    <CardContent style={{ minHeight: `472px` }}>
                        {loading &&
                            <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ paddingTop: `150px` }}>
                                <Grid item >
                                    <CircularProgress color="secondary" />
                                </Grid>
                            </Grid>
                        }
                        {!loading &&
                            <React.Fragment>
                                <DefaultPayment />
                                <CreditCardDetails />
                                <BankDetails />
                            </React.Fragment>
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}



function mapStateToProps(state) {
    return {
        loading: state.PaymentReducer.payment_details_loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPaymentDetails: dispatch(PaymentAction.getPaymentDetails()),
    }
};

const connectedPayments = connect(mapStateToProps, mapDispatchToProps)(Payments);
export { connectedPayments as Payments };

