import { SignUpServices } from "../services";

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const INSTA_SIGNUP_REQUEST = 'INSTA_SIGNUP_REQUEST';
export const INSTA_SIGNUP_SUCCESS = 'INSTA_SIGNUP_SUCCESS';
export const INSTA_SIGNUP_FAILURE = 'INSTA_SIGNUP_FAILURE';


export const SignUpAction = {
    signUp, instaSignUp
};

/**
 * Action for singup
 *
 * @parm json params register parameters of {name,email,password,confirm_password,terms_and_confitions}
 * @author Akshay 
 */
function signUp(params) {
    return dispatch => {
        dispatch(request(params));
        return SignUpServices.signUp(params)
            .then(
                response => { return dispatch(success(params, response)); },
                error => { return dispatch(failure(params, error)); });
    };

    function request(request) { return { type: SIGNUP_REQUEST, request: request } }
    function success(request, response) { return { type: SIGNUP_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(request, error) { return { type: SIGNUP_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}



/**
 * Action for singup
 *
 * @parm json params register parameters of {name,email,password,confirm_password,terms_and_confitions}
 * @author Akshay 
 */
function instaSignUp(params) {
    return dispatch => {
        dispatch(request(params));
        return SignUpServices.instaSignUp(params)
            .then(
                response => { return dispatch(success(params, response)); },
                error => { return dispatch(failure(params, error)); });
    };

    function request(request) { return { type: INSTA_SIGNUP_REQUEST, request: request } }
    function success(request, response) { return { type: INSTA_SIGNUP_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(request, error) { return { type: INSTA_SIGNUP_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}
