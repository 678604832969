import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import validateLoginSettings from './validateLoginSettings';
import { handleInputChange, validateForm } from '../../../_helpers';
import { FormBrowseFile, FormCancelBtn, FormColorInput, FormSaveBtn, FormTextArea } from '../../../_components/form-input';
import { SettingsAction } from '../../../_redux/actions';

function LoginSettings({ business, brand_sub_domain, login_settings }) {


    const dispatch = useDispatch();
    const inputs = { brand_logo: '', brand_title: '', brand_description: '', brand_primary_color: { r: 18, g: 66, b: 222, a: 1 }, brand_secondary_color: { r: 255, g: 255, b: 255, a: 1 }, brand_tertiary_color: { r: 34, g: 34, b: 34, a: 1 } }

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [edit, setEdit] = useState(false);



    useEffect(() => {
        setData({ ...login_settings });
    }, [login_settings])



    /**
   * function to handle input change
   * @param object e input object
   * @author Akshay N
   */
    const handleChange = (e) => {
        console.log(e);
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateLoginSettings(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
    * function to handle submit action
    * @param object e input object
    * @author Akshay N
    */
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ ...action, isSubmitted: true, isProcessing: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateLoginSettings(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }
        console.log('--', data);
        formData.append('brand_logo', data.brand_logo);
        formData.append('brand_description', data.brand_description);
        formData.append('brand_primary_color', JSON.stringify(data.brand_primary_color));
        formData.append('brand_secondary_color', JSON.stringify(data.brand_secondary_color));
        formData.append('brand_tertiary_color', JSON.stringify(data.brand_tertiary_color));
        dispatchLoginSettingsUpdateAction(formData);
    }

    /**
     * function to dispatch signup action
     * @param object e input object
     * @author Akshay N
     */
    const dispatchLoginSettingsUpdateAction = (formData) => {
        dispatch(SettingsAction.updateLoginSettings(formData))
            .then((response) => {
                if (response.status === 1) {
                    setAction({ isSubmitted: false, isProcessing: false });
                    setErrors({ ...response.errors });
                    setEdit(false);
                } else {
                    setErrors({ ...response.errors })
                    setAction({ isSubmitted: true, isProcessing: false });
                }
            });
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const resetAndBack = (e) => {
        setData({ ...login_settings });
        setErrors({ ...inputs });
        setEdit(false);
    }



    return (
        <React.Fragment>
            <div className="mb-3">
                <h4 className="title"><a href={`http://${brand_sub_domain}.${process.env.REACT_APP_DOMAIN}`} target="_blank" rel="noopener noreferrer">{brand_sub_domain}</a>
                    <span onClick={() => setEdit(!edit)} className="float-right edit-icon c-pointer"><i className="fa fa-edit"></i> Edit</span>
                </h4>
            </div>
            {edit ? (

                <form onSubmit={handleSubmit} encType="multipart/form-data" noValidate >

                    <FormBrowseFile
                        label="Choose Logo"
                        name="brand_logo"
                        error={(action.isSubmitted && (errors.brand_logo)) ? (errors.brand_logo) : ''}
                        placeholder="Choose your logo"
                        value={data.brand_logo}
                        onChange={handleChange}
                        className="col-md-6"
                        acceptType=".png,.jpg,.jpeg"
                        info="Allowed Format: png,jpg,jpeg , Allowed Maximum Size: 2 MB"
                    />

                    {/* <FormInput
                        label="Brand Title"
                        name="brand_title"
                        error={(action.isSubmitted && (errors.brand_title)) ? (errors.brand_title) : ''}
                        placeholder="Enter brand title"
                        value={data.brand_title}
                        onChange={handleChange}
                        className="col-md-6"
                    /> */}

                    <FormTextArea
                        label="Description"
                        name="brand_description"
                        error={(action.isSubmitted && (errors.brand_description)) ? (errors.brand_description) : ''}
                        placeholder="Enter brand title"
                        value={data.brand_description}
                        onChange={handleChange}
                        className="col-md-6"

                    />

                    <FormColorInput
                        label="Background Color"
                        name="brand_primary_color"
                        value={data.brand_primary_color}
                        className="col-md-6"
                        onChange={handleChange}
                    />
                    <FormColorInput
                        label="Text Color"
                        name="brand_secondary_color"
                        value={data.brand_secondary_color}
                        className="col-md-6"
                        onChange={handleChange}
                    />
                    <FormColorInput
                        label="Button Color"
                        name="brand_tertiary_color"
                        value={data.brand_tertiary_color}
                        className="col-md-6"
                        onChange={handleChange}
                    />

                    <div className="col-md-12">
                        <FormSaveBtn className="mr-1" isProcessing={action.isProcessing} />
                        <FormCancelBtn onClick={resetAndBack} text="Cancel" />
                    </div>

                </form>
            ) : (
                <div className="details">
                    <div className="row">
                        <p className="col-md-4">Logo:</p>
                        <span className="col-md-8">
                            <a target="_blank" rel="noopener noreferrer" href={`${business.company_url}`}>
                                <img src={login_settings.brand_logo} height="50" className="mb-3" style={{ maxWidth: '200px' }} alt="Brand Logo" />
                            </a>
                        </span>
                    </div>
                    {/* <div className="row">
                        <p className="col-md-4">Brand Title:</p>
                        <h6 className="col-md-8">{login_settings.brand_title}</h6>
                    </div> */}
                    <div className="row">
                        <p className="col-md-4">Description:</p>
                        <h6 className="col-md-8">{login_settings.brand_description}</h6>
                    </div>
                    <div className="row">
                        <p className="col-md-4">Background Color</p>
                        <h6 className="col-md-8" style={{ padding: `10px 15px` }} >
                            <div style={{ border: `1px solid black`, width: `25px`, height: `20px`, backgroundColor: `rgb(${login_settings.brand_primary_color.r},${login_settings.brand_primary_color.g},${login_settings.brand_primary_color.b})` }}>
                            </div>
                        </h6>
                    </div>
                    <div className="row">
                        <p className="col-md-4">Text Color</p>
                        <h6 className="col-md-8" style={{ padding: `10px 15px` }} >
                            <div style={{ border: `1px solid black`, width: `25px`, height: `20px`, backgroundColor: `rgb(${login_settings.brand_secondary_color.r},${login_settings.brand_secondary_color.g},${login_settings.brand_secondary_color.b})` }}>
                            </div>
                        </h6>
                    </div>
                    <div className="row">
                        <p className="col-md-4">Button Color</p>
                        <h6 className="col-md-8" style={{ padding: `10px 15px` }} >
                            <div style={{ border: `1px solid black`, width: `25px`, height: `20px`, backgroundColor: `rgb(${login_settings.brand_tertiary_color.r},${login_settings.brand_tertiary_color.g},${login_settings.brand_tertiary_color.b})` }}>
                            </div>
                        </h6>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}



function mapStateToProps(state) {
    return {
        brand_sub_domain: state.SettingsReducer.brand_sub_domain,
        login_settings: state.SettingsReducer.login_settings,
        business: state.UserReducer.user
    };
}




const connectedLoginSettings = connect(mapStateToProps)(LoginSettings);
export { connectedLoginSettings as LoginSettings };


