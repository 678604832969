import React, { useEffect, useState } from 'react'
import { PageTitle } from '../../../_layouts/page-title';
import { Button, Grid, CircularProgress, makeStyles, Link as AnchorLink } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Add, ArrowBack, Delete } from '@material-ui/icons';
import { CouponAction } from '../../../_redux/actions';
import { CreateCoupons } from './create-coupons';
import { SubaccountCard } from './subaccount-card';
import { Card, CardContent, CardHeader } from '../../../_components/card';
import { CodesDataTable } from './codes-datatable/CodesDataTable';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalHeader } from '../../../_components/modal';


const useStyles = makeStyles((theme) => ({
    couponsWidgetContainer: {
        marginBottom: theme.spacing(2)
    },
    action: {
        margin: 0
    },

    linkButton: {
        '&:hover': {
            'color': '#fff'
        }
    }
}));

function CustomCoupons({ getCustomCoupons, deleteActiveCoupons, balance_coupons, getCoupons, loading, business }) {

    const navigate = useNavigate();

    const [data, setData] = useState({});
    const [subaccount, setSubAccount] = useState('');
    const [showLoader, setShowLoader] = useState(true);
    let page = 0;
    const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false)
    const classes = useStyles();


    useEffect(() => {
        getCustomCoupons().then((response) => {
            if (response.status === 1) {
                setData({
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    email: response.data.email,
                    company_name: response.data.company_name,
                    subaccount: response.data.subaccount ?? ''
                });
                setSubAccount(response.data.subaccount ?? '')
                setShowLoader(false);
            } else {
                setShowLoader(false);
            }
        });
    }, [])

    
    useEffect(() => {
        if (!showLoader && !subaccount && business) {
            setData({
                first_name: business.first_name,
                last_name: business.last_name,
                email: business.email,
                company_name: business.company_name,
            });
            
        } else if (!showLoader && subaccount) {
            window.open(`https://createcoupon.guru.club/`);
        }
    }, [showLoader, subaccount, business])


    const returnToCoupons = () => {
        navigate(`/coupons`);
    }

    const createSingleUseCodes = () => {
        if (subaccount) {
            let pageUrl = `/coupons/create/single-use-codes`;
            navigate(pageUrl);
        }
    }


    const deleteActiveCouponsAction = () => {
        deleteActiveCoupons().then(() => {
            setShowDeleteWarningModal(false);
        })
    }

    const onHide = () => {
        setShowDeleteWarningModal(false);
    }

    const deleteActiveCoupon = (e) => {
        e.preventDefault();
        setShowDeleteWarningModal(true);
    }

    useEffect(() => {
        getCoupons({ page: 1, search: '' });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <PageTitle title={(showLoader) ? '' : !subaccount ? 'Please enter your details for creating coupons' : 'Custom Coupons'} action={
                <React.Fragment>
                    {((!showLoader) && subaccount) && <Button variant="contained" color="primary" onClick={createSingleUseCodes}>Select Coupons</Button>}
                    {/* {((!showLoader) && subaccount ) && <Button className={classes.linkButton} style={{marginBottom: "5px"}} target="_blank" variant="contained" color="primary" href="https://login.coupontools.com/">Create Coupons</Button>} */}
                    {((!showLoader) && subaccount) && <Button className={classes.linkButton} style={{ marginBottom: "5px" }} target="_blank" variant="contained" color="primary" href="https://createcoupon.guru.club/">Create Coupons</Button>}

                    <Button variant="contained" color="primary" onClick={returnToCoupons} startIcon={<ArrowBack />} >Back</Button>
                </React.Fragment>
            } />
            {showLoader && <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ paddingTop: `300px` }}><Grid item ><CircularProgress /></Grid> </Grid>}
            {!showLoader && (
                <React.Fragment>
                    {(!subaccount) &&
                        <CreateCoupons account={data} />
                    }
                    {(!loading && subaccount) &&
                        <React.Fragment>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <Card>
                                        <CardContent>
                                            <SubaccountCard account={data} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <Card>
                                        <CardHeader
                                            title="Coupon Links"
                                            action={<Button variant="contained" color="secondary" disabled={balance_coupons === 0} startIcon={<Delete />} onClick={deleteActiveCoupon}>
                                                Delete Unused Coupons
                                            </Button>}
                                        />
                                        <CardContent>
                                            <CodesDataTable page={page} search={''} filter={''} sort={''} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>


                            <ConfzModal isModalOpen={showDeleteWarningModal} onHide={onHide} loader={false} size="md">
                                <ConfzModalHeader id="RemoveCoupons" title="Remove all active coupons" onHide={onHide} />
                                <ConfzModalBody className="text-center">
                                    <h1>Are You Sure?</h1>
                                    <p>{`Do you want to remove all active coupons?`}</p>
                                </ConfzModalBody>
                                <ConfzModalFooter>
                                    <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onHide}>Cancel</Button>
                                    <Button variant="contained" color="primary" size="small" className={classes.button} onClick={deleteActiveCouponsAction} >Confirm</Button>
                                </ConfzModalFooter>
                            </ConfzModal>
                        </React.Fragment>
                    }
                </React.Fragment>
            )
            }
        </React.Fragment >
    )
}

function mapStateToProps(state) {
    return {
        balance_coupons: state.CouponReducer.balance_coupons,
        loading: state.CouponReducer.coupon_loading,
        business: state.UserReducer.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCustomCoupons: () => dispatch(CouponAction.getCustomCoupons()),
        deleteActiveCoupons: () => dispatch(CouponAction.deleteActiveCoupons()),
        getCoupons: (params) => dispatch(CouponAction.getCoupons(params)),
    }
};

const connectedCustomCoupons = connect(mapStateToProps, mapDispatchToProps)(CustomCoupons);
export { connectedCustomCoupons as CustomCoupons };
