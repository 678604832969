import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Avatar, Box, CircularProgress, Grid, Link, Typography } from '@material-ui/core';
import { IgMessageAction } from '../../../../_redux/actions';

function MessageRepliesCard({ isConversationLoading, ig_conversation_details, getDmDetails }) {

    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const cId = params.get('cId'); // plan promo from url


    const replyEndRef = useRef(null)

    const scrollToBottom = () => {
        replyEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [ig_conversation_details]);

    useEffect(() => {
        getDmDetails({ 'conversation_id': cId })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cId])

    if (isConversationLoading)
        return ''

    const displayStory = (reply) => {
        if (reply.data.story && reply.data.story.mention.link) {
            return (
                <React.Fragment>
                    <Grid container justifyContent="flex-end" direction="column">
                        <Grid item md={12} xs={12} style={{ ...reply.from_user_type === 'shopper' ? { textAlign: 'right' } : {} }}>
                            <img src={`${reply.data.story.mention.link}`} height="100px" alt="Story" />
                        </Grid>
                        <Grid item md={12} xs={12} style={{ ...reply.from_user_type === 'shopper' ? { textAlign: 'right' } : {} }}>
                            <Link href={reply.data.story.mention.link} target="_blank" rel="noreferrer">
                                View story
                            </Link>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    return (
        <React.Fragment >
            {!isConversationLoading && ig_conversation_details && ig_conversation_details.length > 0 &&
                <Box style={{ height: `65vh`, overflow: `scroll` }} >
                    <Grid container spacing={2} display={`flex`} alignItems={`center`}>
                        {ig_conversation_details.length > 0 && ig_conversation_details.map((reply, i) => {
                            return (reply.from_user_type === 'shopper') ?
                                (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={i}>
                                        <Box display={`flex`} alignItems={`center`} justifyContent={`right`} style={{ padding: '2vh', backgroundColor: `#d9d9d9`, borderRadius: '2vh' }}>
                                            <Box mr={2} pl={2} >
                                                <Typography variant="body2" style={{ textAlign: 'right' }}>{reply.data.message}</Typography>
                                                {displayStory(reply)}
                                                <Typography variant="body2" textAlign={`right`} style={{ fontSize: `10px` }}> {reply.created_time}</Typography>
                                            </Box>
                                            <Avatar />
                                        </Box>
                                    </Grid>
                                ) :
                                (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={i}>
                                        <Box display={`flex`} alignItems={`center`} justifyContent={`left`} style={{ padding: '2vh', borderRadius: '2vh', backgroundColor: '#99ccff' }}>
                                            <Avatar />
                                            <Box ml={2} sx={{ width: `100%` }}>
                                                <Typography variant="body2">{reply.data.message}</Typography>
                                                {displayStory(reply)}
                                                <Typography variant="body2" style={{ fontSize: `10px` }}> {reply.created_time}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                        })}
                    </Grid>
                    <Box ref={replyEndRef}></Box>
                </Box>
            }
            {
                isConversationLoading &&
                <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ height: `80vh` }}>
                    <Grid item >
                        <CircularProgress />
                    </Grid>
                </Grid>
            }

        </React.Fragment>
    )
}


function mapStateToProps(state) {
    return {
        isConversationLoading: state.IgMessageReducer.ig_conversation_details_loading,
        ig_conversation_details: state.IgMessageReducer.ig_conversation_details,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDmDetails: (params) => dispatch(IgMessageAction.getDmDetails(params))
    }
};

const connectedMessageRepliesCard = (connect(mapStateToProps, mapDispatchToProps)(MessageRepliesCard));
export { connectedMessageRepliesCard as MessageRepliesCard };
