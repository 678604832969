import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { FormGroup, Grid, Typography, withStyles, Switch } from '@material-ui/core';
import { DealAction } from '../../../../_redux/actions';
import { notify } from '../../../../_helpers';

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

function DealMode({loading, latest_deal, changeDealMode, balance_coupons}) {

    const [latestDeal, setLatestDeal] = useState(false);

    useEffect(() => {
        if (!loading && latest_deal) {
            setLatestDeal(latest_deal.is_deal_live ?? false);
        }
    }, [latest_deal])
    
    const setDealMode = (e) => {

        if (!latest_deal || (latest_deal && !latest_deal.direct_message) || balance_coupons === 0) {
            
            let message = `${balance_coupons === 0 ? 'Please add coupons' : ''}${balance_coupons === 0 && !latest_deal.direct_message ? ' and ' : ''}${!latest_deal || (latest_deal && !latest_deal.direct_message) ? 'Enter direct message' : ''}`;

            message = message || 'Please add coupons and Enter direct message';
            
            notify(capitalizeFirstLetter(message), 'top-right')

         } else {
            changeDealMode({ deal_mode: e.target.checked }).then(() => {
                console.log('ok')
            })
        }
    }

    const capitalizeFirstLetter = (string) => {
        if (string.length === 0) {
          return '';
        }
        const firstLetter = string.charAt(0).toUpperCase();
        const restOfTheString = string.slice(1).toLowerCase();
        return firstLetter + restOfTheString;
    };
    

    return (
        <React.Fragment>
            {/* {latest_deal && latest_deal._id && <Grid container > */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormGroup>
                        <Typography component="div">
                            <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>Deal Mode:</Grid>
                                <Grid item>Offline</Grid>
                                <Grid item>
                                    <AntSwitch onChange={setDealMode} checked={latestDeal} name="checkedC" />
                                </Grid>
                                <Grid item>Live</Grid>
                            </Grid>
                        </Typography>
                    </FormGroup>
                </Grid>
            {/* </Grid>} */}
        </React.Fragment>
    )

}


function mapStateToProps(state) {
    return {
        latest_deal: state.DealReducer.latest_deal,
        loading: state.DealReducer.latest_deal_loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeDealMode: (params) => dispatch(DealAction.changeDealMode(params)),
    }
};


const connectedDealMode = (connect(mapStateToProps, mapDispatchToProps)(DealMode));
export { connectedDealMode as DealMode };
