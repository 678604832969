import React from 'react'
import PropTypes from 'prop-types'

function ConfzModalFooter({ className, children }) {
    return (
        <div className={`modal-footer ${className}`}>
            {children}
        </div>
    )
}


ConfzModalFooter.defaultProps = {
    className: ""
}


ConfzModalFooter.propTypes = {
    className: PropTypes.string,
}

export { ConfzModalFooter }
