import React, { useState } from 'react'
import { connect } from 'react-redux';

import { Grid, Button, styled, Box} from '@material-ui/core'
import { Card, CardHeader, CardContent } from '../../../../_components/card'
import { DealsSubdomainDetails } from './DealsSubdomainDetails';
import { Edit } from '@material-ui/icons';


const HeaderAction = styled(({ ...props }) => (<Box {...props} />))``;


function DealsSubdomainSettings({ children, handleShowEditSubdomain }) {

    const [edit, setEdit] = useState(false);

    const onEditClick = () => {
        handleShowEditSubdomain(!edit);
        setEdit(!edit);
    };

    return (
        <Card>
            <Grid container >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CardHeader
                        title="Subdomain Settings"
                        titleTypographyProps={{ variant: 'h4', component: 'h4' }}
                        action={
                            <HeaderAction>
                                <Box>
                                    <Button variant="contained" color={"primary"} onClick={() => onEditClick()} startIcon={<Edit />}>Edit</Button>
                                </Box>
                            </HeaderAction>
                        }
                    />
                    <CardContent>
                        {edit ? (
                            children
                        ) :
                            (<DealsSubdomainDetails />)}
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    )
}



function mapStateToProps(state) {
    return {
        brand_sub_domain: state.SettingsReducer.brand_sub_domain
    };
}


function mapDispatchToProps(dispatch) {
    return {
    }
};


const connectedDealsSubdomainSettings = connect(mapStateToProps, mapDispatchToProps)(DealsSubdomainSettings);
export { connectedDealsSubdomainSettings as DealsSubdomainSettings };


