import {
    GET_SHOPPERS_REQUEST, GET_SHOPPERS_SUCCESS, GET_SHOPPERS_FAILURE,
    GET_TOTAL_COST_REQUEST, GET_TOTAL_COST_SUCCESS, GET_TOTAL_COST_FAILURE,
    GET_TOTAL_REACH_REQUEST, GET_TOTAL_REACH_SUCCESS, GET_TOTAL_REACH_FAILURE,
    GET_TOTAL_COMPLETED_DEALS_REQUEST, GET_TOTAL_COMPLETED_DEALS_SUCCESS, GET_TOTAL_COMPLETED_DEALS_FAILURE,
    GET_TOTAL_SHOPPERS_REQUEST, GET_TOTAL_SHOPPERS_SUCCESS, GET_TOTAL_SHOPPERS_FAILURE,
    GET_SHOPPER_DETAILS_REQUEST, GET_SHOPPER_DETAILS_SUCCESS, GET_SHOPPER_DETAILS_FAILURE,

    CLEAR_SHOPPER_DATA, SET_PAGE, 
    EXPORT_SHOPPERS_REQUEST, EXPORT_SHOPPERS_SUCCESS, EXPORT_SHOPPERS_FAILURE,
} from "../actions";

const initialState = {
    page: 1, per_page: 18, total: 0, total_pages: 0,
    shoppers_list_loading: false, shoppers_list: [], shoppers_list_error: [],
    total_cost_loading: false, total_cost: 0, total_cost_error: [],
    total_reach_loading: false, total_reach: 0, total_reach_error: [],
    total_completed_deals_loading: false, total_completed_deals: 0, total_completed_deals_error: [],
    total_shoppers_loading: false, total_shoppers: 0, total_shoppers_error: [],
    shopper_loading: true, shopper: {}, shopper_error: [],
    export_shoppers_list_loading: false, export_shoppers_list: [], export_shoppers_list_error: [],
};

export function ShoppersReducer(state = initialState, action) {
    switch (action.type) {

        case SET_PAGE: {
            return {
                ...state,
                page: action.page
            };
        }

        /** begin::shoppers_list redux */
        case GET_SHOPPERS_REQUEST: {
            return {
                ...state,
                shoppers_list_loading: true,
                shoppers_list: [],
                shoppers_list_error: []
            };
        } case GET_SHOPPERS_SUCCESS: {
            return {
                ...state,

                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages,

                shoppers_list_loading: false,
                shoppers_list: action.data.shoppers,
                shoppers_list_error: [],
            };
        } case GET_SHOPPERS_FAILURE: {
            return {
                ...state,
                shoppers_list_loading: false,
                shoppers_list: [],
                shoppers_list_error: []
            };
        }
        /** end::shoppers_list redux */


        case GET_TOTAL_COST_REQUEST: {
            return {
                ...state,
                total_cost_loading: true,
                total_cost_error: []
            };
        }
        case GET_TOTAL_COST_SUCCESS: {
            return {
                ...state,
                total_cost_loading: false,
                total_cost: action.data.total_cost,
                total_cost_error: []
            };
        }
        case GET_TOTAL_COST_FAILURE: {
            return {
                ...state,
                total_cost_loading: false,
                total_cost_error: action.error
            };
        }

        case GET_TOTAL_REACH_REQUEST: {
            return {
                ...state,
                total_reach_loading: true,
                total_reach_error: []
            };
        }
        case GET_TOTAL_REACH_SUCCESS: {
            return {
                ...state,
                total_reach_loading: false,
                total_reach: action.data.total_reach,
                total_reach_error: []
            };
        }
        case GET_TOTAL_REACH_FAILURE: {
            return {
                ...state,
                total_reach_loading: false,
                total_reach_error: action.error
            };
        }

        case GET_TOTAL_COMPLETED_DEALS_REQUEST: {
            return {
                ...state,
                total_completed_deals_loading: true,
                total_completed_deals_error: []
            };
        }
        case GET_TOTAL_COMPLETED_DEALS_SUCCESS: {
            return {
                ...state,
                total_completed_deals_loading: false,
                total_completed_deals: action.data.total_completed_deals,
                total_completed_deals_error: []
            };
        }
        case GET_TOTAL_COMPLETED_DEALS_FAILURE: {
            return {
                ...state,
                total_completed_deals_loading: false,
                total_completed_deals_error: action.error
            };
        }

        case GET_TOTAL_SHOPPERS_REQUEST: {
            return {
                ...state,
                total_shoppers_loading: true,
                total_shoppers_error: []
            };
        }
        case GET_TOTAL_SHOPPERS_SUCCESS: {
            return {
                ...state,
                total_shoppers_loading: false,
                total_shoppers: action.data.total_shoppers,
                total_shoppers_error: []
            };
        }
        case GET_TOTAL_SHOPPERS_FAILURE: {
            return {
                ...state,
                total_shoppers_loading: false,
                total_shoppers_error: action.error
            };
        }


        case GET_SHOPPER_DETAILS_REQUEST: {
            return {
                ...state,
                shopper_loading: true,
                shopper: [],
                shopper_error: [],
            };
        }
        case GET_SHOPPER_DETAILS_SUCCESS: {
            return {
                ...state,
                shopper_loading: false,
                shopper: action.data,
                shopper_error: []
            };
        }
        case GET_SHOPPER_DETAILS_FAILURE: {
            return {
                ...state,
                shopper_loading: false,
                shopper_error: action.error
            };
        }

        case CLEAR_SHOPPER_DATA:
            return { ...state, shopper_loading: true, shopper: {}, shopper_error: [] }


        /** begin::export shoppers_list redux */
        case EXPORT_SHOPPERS_REQUEST: {
            return {
                ...state,
                export_shoppers_list_loading: true,
                export_shoppers_list: [],
                export_shoppers_list_error: []
            };
        } case EXPORT_SHOPPERS_SUCCESS: {
            return {
                ...state,
                export_shoppers_list_loading: false,
                export_shoppers_list: action.data,
                export_shoppers_list_error: [],
            };
        } case EXPORT_SHOPPERS_FAILURE: {
            return {
                ...state,
                export_shoppers_list_loading: false,
                export_shoppers_list: [],
                export_shoppers_list_error: []
            };
        }
        /** end::export shoppers_list redux */
        default:
            return state;
    }
}