import { InstaPaymentServices } from "../services";


export const GET_PAYMENT_DETAILS_REQUEST = 'GET_PAYMENT_DETAILS_REQUEST';
export const GET_PAYMENT_DETAILS_SUCCESS = 'GET_PAYMENT_DETAILS_SUCCESS';
export const GET_PAYMENT_DETAILS_FAILURE = 'GET_PAYMENT_DETAILS_FAILURE';

export const ADD_UPDATE_CARD_REQUEST = 'ADD_UPDATE_CARD_REQUEST';
export const ADD_UPDATE_CARD_SUCCESS = 'ADD_UPDATE_CARD_SUCCESS';
export const ADD_UPDATE_CARD_FAILURE = 'ADD_UPDATE_CARD_FAILURE';

export const DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAILURE = 'DELETE_CARD_FAILURE';

export const ADD_UPDATE_BANK_REQUEST = 'ADD_UPDATE_BANK_REQUEST';
export const ADD_UPDATE_BANK_SUCCESS = 'ADD_UPDATE_BANK_SUCCESS';
export const ADD_UPDATE_BANK_FAILURE = 'ADD_UPDATE_BANK_FAILURE';

export const DELETE_BANK_REQUEST = 'DELETE_BANK_REQUEST';
export const DELETE_BANK_SUCCESS = 'DELETE_BANK_SUCCESS';
export const DELETE_BANK_FAILURE = 'DELETE_BANK_FAILURE';

export const VERIFY_BANK_REQUEST = 'VERIFY_BANK_REQUEST';
export const VERIFY_BANK_SUCCESS = 'VERIFY_BANK_SUCCESS';
export const VERIFY_BANK_FAILURE = 'VERIFY_BANK_FAILURE';

export const SET_DEFAULT_PAYMENT_METHOD_REQUEST = 'SET_DEFAULT_PAYMENT_METHOD_REQUEST';
export const SET_DEFAULT_PAYMENT_METHOD_SUCCESS = 'SET_DEFAULT_PAYMENT_METHOD_SUCCESS';
export const SET_DEFAULT_PAYMENT_METHOD_FAILURE = 'SET_DEFAULT_PAYMENT_METHOD_FAILURE';

export const InstaPaymentAction = { addUpdateCard, deleteCreditCard, getPaymentDetails, addUpdateBankDetails, deleteBankDetails, verifyPaymentDetails, setDefaultPaymentMethod };



/**
 * function description
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 
 */
function getPaymentDetails(approvalKey) {
    return dispatch => {
        dispatch(request());
        return InstaPaymentServices.getPaymentDetails(approvalKey)
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); }
            );
    };


    function request() { return { type: GET_PAYMENT_DETAILS_REQUEST, } }
    function success(response) { return { type: GET_PAYMENT_DETAILS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_PAYMENT_DETAILS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

}



/**
 * function description
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 
 */
function deleteCreditCard(approvalKey) {
    return dispatch => {
        dispatch(request(approvalKey));
        return InstaPaymentServices.deleteCreditCard(approvalKey)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => { return dispatch(failure(error)); }
            );
    };


    function request() { return { type: DELETE_CARD_REQUEST } }
    function success(response) { return { type: DELETE_CARD_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: DELETE_CARD_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

}

/**
 * function description
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 
 */
function addUpdateCard(params) {
    return dispatch => {
        dispatch(request(params));
        return InstaPaymentServices.addUpdateCard(params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => {
                    return dispatch(failure(error));
                }
            );
    };


    function request(params) { return { type: ADD_UPDATE_CARD_REQUEST, request: params } }
    function success(params, response) { return { type: ADD_UPDATE_CARD_SUCCESS, request: params, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: ADD_UPDATE_CARD_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

}

/**
 * function description
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 
 */
function addUpdateBankDetails(params) {
    return dispatch => {
        dispatch(request(params));
        return InstaPaymentServices.addUpdateBankDetails(params)
            .then(
                response => {
                    return dispatch(success(params, response));
                },
                error => {
                    return dispatch(failure(error));
                }
            );
    };


    function request(params) { return { type: ADD_UPDATE_BANK_REQUEST, request: params } }
    function success(params, response) { return { type: ADD_UPDATE_BANK_SUCCESS, request: params, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: ADD_UPDATE_BANK_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

}


/**
 * function description
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 
 */
function deleteBankDetails(approvalKey) {
    return dispatch => {
        dispatch(request());
        return InstaPaymentServices.deleteBankDetails(approvalKey)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => { return dispatch(failure(error)); }
            );
    };


    function request() { return { type: DELETE_BANK_REQUEST } }
    function success(response) { return { type: DELETE_BANK_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: DELETE_BANK_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

}

/**
 * function description
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 
 */
function verifyPaymentDetails(params) {
    return dispatch => {
        dispatch(request(params));
        return InstaPaymentServices.verifyPaymentDetails(params)
            .then(
                response => { return dispatch(success(params, response)); },
                error => { return dispatch(failure(error)); }
            );
    };


    function request(params) { return { type: VERIFY_BANK_REQUEST, request: params } }
    function success(params, response) { return { type: VERIFY_BANK_SUCCESS, request: params, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: VERIFY_BANK_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

}

/**
 * function description
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 
 */
function setDefaultPaymentMethod(params) {
    return dispatch => {
        dispatch(request(params));
        return InstaPaymentServices.setDefaultPaymentMethod(params)
            .then(
                response => { return dispatch(success(params, response)); },
                error => { return dispatch(failure(error)); }
            );
    };


    function request(params) { return { type: SET_DEFAULT_PAYMENT_METHOD_REQUEST, request: params } }
    function success(params, response) { return { type: SET_DEFAULT_PAYMENT_METHOD_SUCCESS, request: params, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: SET_DEFAULT_PAYMENT_METHOD_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

}
