import React from 'react'
import { ConfzModal, ConfzModalBody } from '../../_components/modal';

function PaymentInitiatedModal({ show, showLoader, closeModal }) {


    const onHide = () => {
        closeModal(false);
    }

    return (
        <ConfzModal isModalOpen={show} onHide={onHide} loader={showLoader} size="md" backdrop="static" keyboard="false">
            <ConfzModalBody className="text-center">
                <h1>Payment Initiated</h1>
                <p>{`Please wait...`}</p>
            </ConfzModalBody>
        </ConfzModal>
    )
}

export { PaymentInitiatedModal }
