import { Avatar, Box, Card, CardContent, Grid, Typography } from '@material-ui/core'
import { Email, Instagram, People } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import { NoDataFound } from '../../../_components/not-found'

import { GiveawayWinnerAction } from '../../../_redux/actions'


const GiveawayEntries = ({ getGiveawayEntries, giveawayEntries, generateWinnerLoading, props }) => {

    const { id } = useParams();

    const [pagination, setPagination] = useState({ limit: 100, page: 1 });

    const getNext = async () => {
        const result = await getGiveawayEntries({ id: id, ...pagination });
        if (result.status) {
            setPagination({ ...pagination, page: pagination.page + 1 });
        }
    }

    useEffect(() => {
        if (generateWinnerLoading === true) {
            setPagination({ limit: 100, page: 1 });
        }

        if (generateWinnerLoading === false) {
            getNext() // Initially load participants list
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, generateWinnerLoading])



    return (
        <React.Fragment>
            {giveawayEntries.length > 0 && (
                <div id="scrollableDiv" style={{ height: "100%", overflow: 'auto' }}>
                    <InfiniteScroll dataLength={giveawayEntries.length} next={getNext} hasMore={true} height={400} scrollableTarget="scrollableDiv">
                        <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                            {
                                giveawayEntries.length > 0 && giveawayEntries.map((participant, i) => {
                                    return (
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={i}>
                                            <Card>
                                                <CardContent style={{ display: 'flex', padding: `15px` }}>
                                                    <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                                                        <Grid item >
                                                            <Avatar alt={participant.name} src={participant.profile_image} style={{ width: 75, height: 75 }} />
                                                        </Grid>
                                                        <Grid item >
                                                            <Box style={{ paddingLeft: `15px` }}>
                                                                <Typography variant="h6" color="initial">{participant.name}</Typography>
                                                                <Typography variant="caption" component="div" color="initial"><Instagram style={{ fontSize: `14px` }} />&nbsp;{`@${participant.insta_handle}`}</Typography>
                                                                <Typography variant="caption" component="div" color="initial"><Email style={{ fontSize: `14px` }} />&nbsp;{participant.email}</Typography>
                                                                <Typography variant="caption" component="div" color="initial"><People style={{ fontSize: `14px` }} />&nbsp;{participant.followers_count} Followers</Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </InfiniteScroll>
                </div>
            )}
            {giveawayEntries.length < 1 && (
                <NoDataFound text="No sweepstakes entries found" />
            )}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    loading: state.GiveawayWinnerReducer.giveaway_entries_loading,
    giveawayEntries: state.GiveawayWinnerReducer.giveaway_entries,
    generateWinnerLoading: state.GiveawayWinnerReducer.generate_winner_loading,
})


function mapDispatchToProps(dispatch, ownProps) {
    return {
        getGiveawayEntries: (params) => dispatch(GiveawayWinnerAction.getGiveawayEntries(params))
    }
};



const connectedGiveawayEntries = connect(mapStateToProps, mapDispatchToProps)(GiveawayEntries);
export { connectedGiveawayEntries as GiveawayEntries };
