const validateCouponImport = (name, value) => {
    const errors = {};
    switch (name) {
        case 'file': {
            errors.file = '';
            if (value === '' || value === [] || typeof value === 'undefined') {
                errors.file = 'Csv is required. ';
            } else if (typeof value !== 'string') {
                let extension = (value.name).split('.').pop();
                let size = value.size;
                if (extension !== 'csv') {
                    errors.file = 'Please choose a valid csv file.';
                } else if (size >= 20971520) {
                    errors.file = 'File size exceed. ';
                }
            }
            break;
        } default:
            errors[name] = '';
            break;
    }
    return errors;
}
export default validateCouponImport;