import { DesignationServices } from "../services";

export const GET_DESIGNATION_REQUEST = 'GET_DESIGNATION_REQUEST';
export const GET_DESIGNATION_SUCCESS = 'GET_DESIGNATION_SUCCESS';
export const GET_DESIGNATION_FAILURE = 'GET_DESIGNATION_FAILURE';

export const GET_DESIGNATIONS_REQUEST = 'GET_DESIGNATIONS_REQUEST';
export const GET_DESIGNATIONS_SUCCESS = 'GET_DESIGNATIONS_SUCCESS';
export const GET_DESIGNATIONS_FAILURE = 'GET_DESIGNATIONS_FAILURE';

export const DELETE_DESIGNATIONS_REQUEST = 'DELETE_DESIGNATIONS_REQUEST';
export const DELETE_DESIGNATIONS_SUCCESS = 'DELETE_DESIGNATIONS_SUCCESS';
export const DELETE_DESIGNATIONS_FAILURE = 'DELETE_DESIGNATIONS_FAILURE';

export const ADD_DESIGNATIONS_REQUEST = 'ADD_DESIGNATIONS_REQUEST';
export const ADD_DESIGNATIONS_SUCCESS = 'ADD_DESIGNATIONS_SUCCESS';
export const ADD_DESIGNATIONS_FAILURE = 'ADD_DESIGNATIONS_FAILURE';

export const EDIT_DESIGNATIONS_REQUEST = 'EDIT_DESIGNATIONS_REQUEST';
export const EDIT_DESIGNATIONS_SUCCESS = 'EDIT_DESIGNATIONS_SUCCESS';
export const EDIT_DESIGNATIONS_FAILURE = 'EDIT_DESIGNATIONS_FAILURE';

export const IMPORT_DESIGNATIONS_REQUEST = 'IMPORT_DESIGNATIONS_REQUEST';
export const IMPORT_DESIGNATIONS_SUCCESS = 'IMPORT_DESIGNATIONS_SUCCESS';
export const IMPORT_DESIGNATIONS_FAILURE = 'IMPORT_DESIGNATIONS_FAILURE';


export const DesignationAction = { getDesignations, getDesignation, deleteDesignations, addDesignations, editDesignations, importDesignations };

/**
 * Action get designation
 *
 * @author Akshay 
 */
function getDesignation(id) {
    return dispatch => {
        dispatch(request(id));
        return DesignationServices.getDesignation(id)
            .then(
                response => { return dispatch(success(id, response)); },
                error => { return dispatch(failure(error)); });
    };

    function request(id) { return { type: GET_DESIGNATION_REQUEST, request: id } }
    function success(id, response) { return { type: GET_DESIGNATION_SUCCESS, request: id, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_DESIGNATION_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get designation
 *
 * @author Akshay 
 */
function getDesignations(page) {
    return dispatch => {
        dispatch(request());
        return DesignationServices.getDesignations(page)
            .then(
                response => { return dispatch(success(response)); },
                error => { return dispatch(failure(error)); });
    };

    function request() { return { type: GET_DESIGNATIONS_REQUEST } }
    function success(response) { return { type: GET_DESIGNATIONS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: GET_DESIGNATIONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get designation
 *
 * @author Akshay 
 */
function deleteDesignations(id) {
    return dispatch => {
        dispatch(request(id));
        return DesignationServices.deleteDesignations(id)
            .then(
                response => { return dispatch(success(id, response)); },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(id) { return { type: DELETE_DESIGNATIONS_REQUEST, id: id } }
    function success(id, response) { return { type: DELETE_DESIGNATIONS_SUCCESS, id: id, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: DELETE_DESIGNATIONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get designation
 *
 * @author Akshay 
 */
function addDesignations(params) {
    return dispatch => {
        dispatch(request(params));
        return DesignationServices.addDesignations(params)
            .then(
                response => {
                    dispatch(DesignationAction.getDesignations(1));
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(request) { return { type: ADD_DESIGNATIONS_REQUEST, request: request } }
    function success(request, response) { return { type: ADD_DESIGNATIONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: ADD_DESIGNATIONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action get designation
 *
 * @author Akshay 
 */
function editDesignations(params) {
    return dispatch => {
        dispatch(request(params));
        return DesignationServices.editDesignations(params)
            .then(
                response => {
                    dispatch(DesignationAction.getDesignations(1));
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(request) { return { type: EDIT_DESIGNATIONS_REQUEST, request: request } }
    function success(request, response) { return { type: EDIT_DESIGNATIONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: EDIT_DESIGNATIONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}

/**
 * Action to import designation
 *
 * @author Akshay 
 */
function importDesignations(params) {
    return dispatch => {
        dispatch(request(params));
        return DesignationServices.importDesignations(params)
            .then(
                response => {
                    dispatch(DesignationAction.getDesignations(1));
                    return dispatch(success(params, response));
                },
                error => { return dispatch(failure(error)); }
            );
    };

    function request(request) { return { type: IMPORT_DESIGNATIONS_REQUEST, request: request } }
    function success(request, response) { return { type: IMPORT_DESIGNATIONS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
    function failure(error) { return { type: IMPORT_DESIGNATIONS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
}
