import React from 'react'
import { CardContent as MuiCardContent, makeStyles } from '@material-ui/core'



const useStyles = makeStyles((theme) => ({
    cardcontent: {
        padding: theme.spacing(3),
        "&:last-child": {
            paddingBottom: theme.spacing(3)
        }
    }
}))

function CardContent({ ...props }) {

    const classes = useStyles()

    return (
        <MuiCardContent className={classes.cardcontent} {...props}>{props.children}</MuiCardContent>
    )
}

export { CardContent }
