import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';

import { Button, makeStyles } from '@material-ui/core';
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from '../../../_components/modal';
import { FormInput } from '../../../_components/form-input';
import validateTrialCoupon from './validateTrialCoupon';
import { handleInputChange, validateForm } from '../../../_helpers';
import { PaymentAction, TrialCouponAction } from '../../../_redux/actions';
import { useLocation, useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    headButton: {
        margin: '0 5px',
    },
}));

function TrialCouponCodes({ applyTrialCouponCode, isBrandOnTrial, loading, is_brand_on_trial, getPaymentDetails, cardDetails, bankDetails, defaultPayment, isPaymentLoading }) {

    const navigate = useNavigate();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const fromType = params.get('type'); // plan promo from url

    const classes = useStyles();

    const inputs = { trial_coupon_code: '' }

    const [show, setShow] = useState(false);
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        isBrandOnTrial();
        getPaymentDetails();
    }, [])

    useEffect(() => {
        if (fromType && fromType === 'trial_coupons') {
            setShow(true);
        }
    }, [])
    

    const openMinCouponModal = (e) => {
        e.preventDefault();

        if(!((cardDetails || bankDetails) && defaultPayment)) {
            navigate({
                pathname: "/payments",
                search:`?type=trial_coupons`
            })
        } else {
            setShow(true);
        }
    }

    const onHide = () => {
        setShow(false);
        setErrors({});
        setData({ ...inputs });

        if (fromType) {
            params.delete('type')
        }
    }

    /**
   * function to handle input change
   * @param object e input object
   * @author Jasin
   */
    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateTrialCoupon(name, value)) });
        data[name] = value;
        setData({ ...data });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateTrialCoupon(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true, isProcessing: false });
            return;
        }

        formData.append('trial_coupon_code', data.trial_coupon_code);

        dispatchCouponCount(data);
    }

    const dispatchCouponCount = (data) => {
        applyTrialCouponCode(data).then((response) => {
            if (response.status === 1) {
                setShow(false);
                setData({ ...inputs });
                setAction({ isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
            } else {
                setAction({ isSubmitted: true, isProcessing: false });

                if (Object.keys(response.data).length > 0) {
                    onHide();
                } else {
                    setErrors({ ...response.errors })
                }
            }
        });
    }

    return (
        <React.Fragment>
            <ConfzModal isModalOpen={show} onHide={onHide} loader={showLoader}>
                <form onSubmit={handleSubmit} noValidate >
                    <ConfzModalHeader id="minCoupons" title="Trial Coupon Code" onHide={onHide} />
                    <ConfzModalBody>
                        {!showLoader && <FormInput
                            label="Trial Coupon Code"
                            name="trial_coupon_code"
                            type="text"
                            tabIndex="1"
                            error={(action.isSubmitted && (errors.trial_coupon_code)) ? (errors.trial_coupon_code) : ''}
                            placeholder="Enter trial coupon code"
                            value={data.trial_coupon_code}
                            onChange={handleChange}
                            className="col-md-12"
                        />
                        }
                    </ConfzModalBody>
                    <ConfzModalFooter>
                        <ConfzModalFooterCloseBtn tabIndex="3" onHide={onHide} />
                        <ConfzModalFooterSaveBtn tabIndex="2" isProcessing={action.isProcessing} />
                    </ConfzModalFooter>
                </form>
            </ConfzModal>

            {/* <Button variant="contained" color="primary" className={classes.headButton} onClick={openMinCouponModal} disabled={is_brand_on_trial}>
                Do you have a trial coupon code?
            </Button> */}
        </React.Fragment>

    )
}

function mapStateToProps(state) {
    return {
        loading: state.TrialCouponReducer.is_brand_on_trial_loading,
        is_brand_on_trial: state.TrialCouponReducer.is_brand_on_trial,
        isPaymentLoading: state.PaymentReducer.payment_details_loading,
        cardDetails: state.PaymentReducer.payment_details.stripe_card_response,
        bankDetails: state.PaymentReducer.payment_details.stripe_bank_response,
        defaultPayment: state.PaymentReducer.payment_details.default_payment
    };
}

function mapDispatchToProps(dispatch) {
    return {
        applyTrialCouponCode: (params) => dispatch(TrialCouponAction.applyTrialCouponCode(params)),
        isBrandOnTrial: (params) => dispatch(TrialCouponAction.isBrandOnTrial(params)),
        getPaymentDetails: () => dispatch(PaymentAction.getPaymentDetails()),

    }
};

const connectedTrialCouponCodes = (connect(mapStateToProps, mapDispatchToProps)(TrialCouponCodes));
export { connectedTrialCouponCodes as TrialCouponCodes };

