import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { ConfzDatatable } from '../../_components/data-table'
import { ShoppersAction } from '../../_redux/actions';
import queryString from 'query-string';


function DataTable({ isLoading, page, per_page, total, shoppers, search, filter, sort, setPage, getShoppersList, ...props }) {

    const columns = [
        { name: '#', selector: (row, i) => (row._id), center: true, width: '5%', cell: (row, i) => ((per_page * (page - 1)) + (i + 1)) },
        { name: 'Name', selector: (row, i) => (row.name), sortable: true, width: '16%', wrap: true, style: { whiteSpace: `unset` } },
        { name: 'Instagram Handle', selector: (row, i) => (row.username), sortable: true, width: '16%', cell: (row, i) => { return `@${row.username}` }, style: { whiteSpace: `unset` } },
        { name: 'Email', selector: (row, i) => (row.email), sortable: true, width: '22%', wrap: true, style: { whiteSpace: `unset` } },
        { name: 'Last Active On', selector: (row, i) => (row.last_active), center: true, sortable: true, wrap: true, width: '17%' },
        { name: 'Earned Coupons', selector: (row, i) => (row.done), center: true, sortable: true, width: '8%' },
        // { name: 'Total Cost ($)', selector: (row, i) => (row.done * 3), center: true, sortable: true, width: '8%' },
        { name: 'Total Posts', selector: (row, i) => (row.total_posts), center: true, sortable: true, width: '8%' },
        { name: 'Total Followers', selector: (row, i) => (row.followers_count), center: true, sortable: true, width: '8%' },
    ];

    useEffect(() => {

        let queryParam = filter ? filter : {};
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q
        let pageUrl = `/shoppers?${queryString.stringify(queryParam)}`;
        window.history.pushState({}, '', pageUrl);
        getShoppersList({ page: page, search: search });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, search, filter, sort])


    function handleSort(page, sortDirection) {
        // console.log(page.selector, sortDirection);
    }

    const handleSelect = (data) => {
        // console.log(data.selectedRows)
    }

    return (
        <div className="card-body card-table w-100 p-0">
            <ConfzDatatable
                loading={isLoading}
                columns={columns}
                data={shoppers}
                total={total}
                per_page={per_page}
                setPage={page => setPage(page)}
                handleSort={handleSort}
                handleSelect={handleSelect}
            />
        </div>
    )
}


function mapStateToProps(state) {
    return {
        page: state.ShoppersReducer.page,
        per_page: state.ShoppersReducer.per_page,
        total: state.ShoppersReducer.total,
        total_pages: state.ShoppersReducer.total_pages,

        isLoading: state.ShoppersReducer.shoppers_list_loading,
        shoppers: state.ShoppersReducer.shoppers_list,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getShoppersList: (params) => dispatch(ShoppersAction.getShoppersList(params)),
    }
};

const connectedDataTable = (connect(mapStateToProps, mapDispatchToProps)(DataTable));
export { connectedDataTable as DataTable };

