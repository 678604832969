import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { FormSaveBtn, FormSelect, FormTextArea } from '../../../../_components/form-input';
import { handleInputChange, validateForm } from '../../../../_helpers';
import validate from './validate';
import { DealAction } from '../../../../_redux/actions';


const timeBetweenPostOptions = [
    { value: 'One Time', label: 'One Time' },
    { value: '1 Day', label: 'Once a day' },
    { value: '1 Week', label: 'Once a week' },
    { value: '1 Month', label: 'Once a month' }
]

function DefaultCouponSettings({ loading, latest_deal, addDefaultCouponSettings }) {

    const inputs = {
        times_between_post: '',
        direct_message: ''
    }

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, direct_message: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    const handleChange = async (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(await validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    useEffect(() => {
        if (!loading && latest_deal && latest_deal._id) {
            setData({
                deal_id: latest_deal._id,
                times_between_post: latest_deal.times_between_post,
                direct_message: latest_deal.direct_message ? latest_deal.direct_message : ''
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latest_deal])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = await validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }

        dispatchDealAction(data);
    }

    const dispatchDealAction = (formData) => {
        let dispatchAction = addDefaultCouponSettings(formData);
        dispatchAction.then((response) => {
            if (response.status === 1) {
                setAction({ ...action, isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
            } else {
                setErrors({ ...response.errors })
                setAction({ ...action, isSubmitted: true, isProcessing: false });
            }
        });
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormTextArea
                                label="Add a direct message to accompany your coupon."
                                name="direct_message"
                                error={(action.isSubmitted && (errors.direct_message)) ? (errors.direct_message) : ''}
                                placeholder="Please enter message text here."
                                value={data.direct_message}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography>When someone mentions you in an Instagram story, they will automatically receive a coupon</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormSelect
                                label={`How Often`}
                                name={`times_between_post`}
                                placeholder={`Select time between post`}
                                onChange={handleChange}
                                data={timeBetweenPostOptions}
                                value={data.times_between_post}
                                error={(action.isSubmitted && (errors.times_between_post)) ? (errors.times_between_post) : ''}
                                className='p-2'
                                menuPosition={'fixed'} 
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item >
                            <FormSaveBtn isProcessing={action.isProcessing || loading} text={`Save`} style={{ margin: `5px` }} />
                        </Grid>
                    </Grid>
                </form>

            </Grid>
        </Grid>
    )

}


function mapStateToProps(state) {
    return {
        latest_deal: state.DealReducer.latest_deal,
        loading: state.DealReducer.latest_deal_loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addDefaultCouponSettings: (params) => dispatch(DealAction.addDefaultCouponSettings(params)),

    }
};

const connectedDefaultCouponSettings = connect(mapStateToProps, mapDispatchToProps)(DefaultCouponSettings);
export { connectedDefaultCouponSettings as DefaultCouponSettings };

