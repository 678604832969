function handleResponse(response) {
	return response.text().then((text) => {
		if (response.status !== 404) {
			const data = text && JSON.parse(text);
			if (!response.ok) {
				if (response.status === 401) {
					// UserActions.logout();
				}
				data.status_code = response.status;
				return Promise.reject(data);
			}
			if (!data.status) {
				data.message = (data.message === 'validation error') ? (data.data) : data.message;
				data.status_code = response.status;
				data.data = (data.data) ? (data.data) : {};
				return Promise.reject({ errors: {}, data: data.data, status_code: response.status, message: data.message, status: false });
			}
			data.message = data.message ? data.message : '';
			data.status_code = response.status;
			data.data = (data.data) ? (data.data) : {};
			return data;
		}
		const data = text && JSON.parse(text);
		return Promise.reject({ errors: {}, data: {}, status_code: response.status, message: data.message, status: false });
	}).catch(error => {
		return Promise.reject(error)
	});
}

export { handleResponse };
