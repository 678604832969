const validate = async (name, value, data) => {
    //eslint-disable-next-line
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const errors = {};
    switch (name) {

        case 'first_name': {
            errors.first_name = '';
            if (value === '')
                errors.first_name = 'First name is required. ';
            break;
        }  case 'last_name': {
            errors.last_name = '';
            if (value === '')
                errors.last_name = 'Last name is required. ';
            break;
        }  case 'email': {
            errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid. ';
            if (value === '')
                errors.email = 'Email is required. ';
            break;
        } case 'company_name': {
            errors.company_name = '';
            if (value === '')
                errors.company_name = 'Company name is required. ';
            break;
        }default:
            errors[name] = '';
            break;
    }
    return errors;
}
export default validate;