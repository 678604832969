import React, { useEffect, useState } from 'react'
import { FormInput, FormSaveBtn } from '../../../_components/form-input';
import { useDispatch } from 'react-redux';
import { SignInAction } from '../../../_redux/actions';
import { FormGroupInput } from '../../../_components/form-group-input';

const BrandDeal = (props) => {

    const dispatch = useDispatch();

    const [page, setPage] = useState({ loaded: false, type: 'loading', data: {} });

    useEffect(() => {
        if (window.location.host.split('.')[0]) {
            let subdomain = window.location.host.split('.')[0];
            dispatch(SignInAction.getDealDetails(subdomain))
                .then((response) => {
                    if (response.status === 1) {
                        setPage({ loaded: true, type: 'custom', data: response.data, error: '' });
                    } else {
                        setPage({ loaded: true, type: 'default', data: {}, message: response.message });
                    }
                });
        };

        document.body.className = 'ns-brand-deal';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (!page.loaded && page.type === 'loading') {
        return (
            <div className="min-vh-100" style={{ width: `100%` }}>
                <div className="row">
                    <div className="col-md-12 mx-auto">
                        <div className="row">
                            <div className="col-md-12 text-center d-flex align-items-center justify-content-center min-vh-100">
                                <div class="spinner-grow" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (page.type === 'custom') {
        return (
            <React.Fragment>
                <section className="section auth-layout" style={{ width: `100%`, padding: `100px 200px`, backgroundColor: `#ffff` }}>
                    <div className="d-flex flex-wrap align-items-stretch " style={{ boxShadow: `0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0,0,0,0.23)` }}>
                        <div className="col-md-6 py-5 d-flex align-items-center justify-content-center" style={{ backgroundColor: `${(page.data.primary_color_hex) ? (page.data.primary_color_hex) : ''}`, color: `${(page.data.secondary_color_hex) ? (page.data.secondary_color_hex) : ''}`, fontFamily: page.data.font }}>
                            <div className="row">
                                <div className="col-md-12 text-center pb-3">
                                    <a target="_blank" rel="noopener noreferrer" href={`${page.data.company_url}`}>
                                        <img src={page.data.logo} alt="s" style={{ maxWidth: '200px' }} />
                                    </a>
                                </div>
                                <div className="col-md-12 text-center">
                                    <h6 style={{ fontWeight: `600` }} className="pb-2">Tag <span style={{ fontWeight: `900`, fontStyle: `italic` }}>
                                        <a target="_blank" rel="noopener noreferrer" style={{ color: `${(page.data.link_color_hex) ? (page.data.link_color_hex) : ''}` }} href={`https://www.instagram.com/${page.data.insta_handle}`}>@{page.data.insta_handle}</a></span> in an Instagram story </h6>
                                    <h5> <span style={{ fontWeight: `900` }}>{page.data.reward_description}</span></h5>
                                </div>
                                <div className="col-md-12 text-center pt-4">
                                    <h2 style={{ fontWeight: `900` }} className={`mb-2`}>Sign Up</h2>
                                    <div className="row">
                                        <div className="col-md-8 mx-auto text-left">
                                            <FormGroupInput
                                                label="Public Instagram Handle"
                                                placeholder="Enter public instagram handle"
                                                name="insta_handle"
                                                tabIndex="2"
                                                ig_text={`@`}
                                                className="col-md-12"
                                                maxLength={50}
                                            />
                                            <FormInput
                                                label="Email"
                                                name="email"
                                                type="email"
                                                placeholder="Enter your email address"
                                                className="col-md-12"
                                                maxLength={100}
                                            />
                                            <div className="col-md-12">
                                                <FormSaveBtn className='btn-block' text="Sign Up" style={{ backgroundColor: `${(page.data.link_color_hex) ? (page.data.link_color_hex) : ''}`, borderColor: `${(page.data.link_color_hex) ? (page.data.link_color_hex) : ''}`, color: `${(page.data.primary_color_hex) ? (page.data.primary_color_hex) : ''}` }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center" style={{ backgroundColor: `rgb(${page.data.secondary_color.r},${page.data.secondary_color.g},${page.data.secondary_color.b})`, color: `rgb(${page.data.primary_color.r},${page.data.primary_color.g},${page.data.primary_color.b})` }}>
                            <div className=" ">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <img src={page.data.sample_story} width="100%" style={{ maxWidth: '400px' }} alt="Story" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    } else {
        return (
            <div className="min-vh-100" style={{ width: `100%` }}>
                <div className="row">
                    <div className="col-md-12 mx-auto">
                        <div className="row">
                            <div className="col-md-12 text-center d-flex align-items-center justify-content-center min-vh-100">
                                <h3>{page.message}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export { BrandDeal }

