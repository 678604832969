import { Box, IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import { Edit, MoreVert } from '@material-ui/icons';
import React from 'react'
import { connect } from 'react-redux';
import { Link, NavLink, Route, Routes } from 'react-router-dom';
import { RouteConfig } from '../../_routes'
import { ProfileDetails } from './profile-details/ProfileDetails';
import { ProfileUpdate } from './profile-update/ProfileUpdate';


function Profile({ user, ...props }) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <Box>
            <div className="card profile-settings">
                <div className="card-body row">
                    <div className="col-md-4 text-center profile-left">
                        <Box sx={{ display: { xs: 'block', md: 'none' } }} style={{ position: `absolute`, right: 0 }}>
                            <IconButton aria-haspopup="true" onClick={handleClick} ><MoreVert /></IconButton>
                            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}   >
                                <MenuItem as={Link} to="/">
                                    <NavLink to={RouteConfig('profile_update').path}>
                                        <ListItemIcon> <Edit />{RouteConfig('profile_update').name}</ListItemIcon>
                                    </NavLink>
                                </MenuItem>
                                {/* <MenuItem>
                                    <NavLink to={RouteConfig('profile_change_password').path}>
                                        <ListItemIcon> <Edit />{RouteConfig('profile_change_password').name}</ListItemIcon>
                                    </NavLink>
                                </MenuItem> */}
                            </Menu>

                        </Box>
                        <img alt="round" src={user.profile_image} className="rounded-circle author-box-picture" />
                        <h4>{user.name}</h4>
                        <p>{user.email}</p>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            <hr className="dashed" />
                            <NavLink to={RouteConfig('profile_update').path} className="btn  btn-block text-left mt-0"><i className="icon-note"></i> &nbsp; {RouteConfig('profile_update').name}</NavLink>
                            {/* <NavLink to={RouteConfig('profile_change_password').path} className="btn btn-block text-left mt-0"><i className="icon-note"></i> &nbsp; {RouteConfig('profile_change_password').name}</NavLink> */}
                        </Box>
                    </div>
                    <div className="col-md-8 profile-right">
                        <Routes>
                            <Route path={'/'} element={<ProfileDetails />}></Route>
                            <Route path={'/update'} element={<ProfileUpdate />}></Route>
                            {/* <Route path={'/change-password'} element={<ProfileChangePassword />}></Route> */}
                        </Routes>

                    </div>
                </div>
            </div>
        </Box>
    )
}



function mapStateToProps(state) {
    return {
        user: state.UserReducer.user,
    };
}

const connectedProfile = connect(mapStateToProps)(Profile);
export { connectedProfile as Profile };
